import './Calendar.css';
import React, { useEffect, useState, } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
// import dayGridWeek from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timeGridWeek from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import 'bootstrap/dist/css/bootstrap.min.css';
import { dateEventInVar, calendarSlotsVar, eventsDataVar, calendarActiveMonthVar,appFromCalVar } from '../../../cache/cache.js';
import { isUserStaff, urlActions, SNACK_DURATION, ERROR_MESSAGE, error_options, } from '../../../Common/helpers.js';
import { withRouter } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'react-simple-snackbar';
import i18n from 'locales/i18n';
import { Link } from 'react-router-dom';

const Calendar = (props) => {
  const {
    // data,
    myAppointments,
    selectedDoctor,
    doctorLeavesList,
    fullCalendarRef,
    history,
    selectedDateEvent,
    selectedSession,
    referralObjectData,
    GetEvents,
  } = props;

  const [openSnackbar] = useSnackbar(error_options);

  const REQUEST_SETTINGS = gql`
      query {
        settings
      }
  `;

  // GET leave detail
  const { data: settings_obj = null } = useQuery(
    REQUEST_SETTINGS,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  const [calendarView, setCalendarView] = useState('timeGridWeek');
  const [calendarSlotDuration, setCalendarSlotDuration] = useState(30);
  const [activeDocShiftArr, setActiveDocShiftArr] = useState([]);
  const isStaff = isUserStaff();
  const [amountOfEvents, setAmountOfEvents] = useState(25);
  const [data, setData] = useState([]);
  const [availableSlotsArr, setAvailableSlotsArr] = useState([]);
  const [recurringEventsList, setRecurringEventsList] = useState([]);
  const [calendarBodyHeight, setCalendarBodyHeight] = useState({});
  const [coords, setcoords] = useState(null);
  const [selectedDateStr, setSelectedDateStr] = useState(null);
  const { t  } = useTranslation();
  const eventsDataMain = useReactiveVar(eventsDataVar);
  const calendarActiveMonth = useReactiveVar(calendarActiveMonthVar);
  const appFromCal = useReactiveVar(appFromCalVar);
  const appCreateFromCal = props?.location?.state?.appCreateFromCal;

  let calendarExtraProps = {};
  
  console.log("recurringEventsList,",recurringEventsList);

  useEffect(() => {
    if (selectedSession) {
      let duration = selectedSession.node.duration;
      setCalendarSlotDuration(duration);
    }
  }, [selectedSession])

  console.log("activeDocShiftArr",activeDocShiftArr);
  // SET STATE FROM REACTIVE VARIBALE
  useEffect(() => {
    let refCalendarView = fullCalendarRef && fullCalendarRef.current ? fullCalendarRef.current.getApi().view : null;
    if(refCalendarView && selectedDoctor?.node?.availability){
      getActiveDocShifts(refCalendarView.activeStart, refCalendarView.activeEnd,selectedDoctor,recurringEventsList);
    }

    if(!selectedDoctor){
      setActiveDocShiftArr([]);
    }
  }, [fullCalendarRef,selectedDoctor,recurringEventsList])

  const getActiveDocShifts=(activeStart,activeEnd,selectedDoctor,recurringEvents)=>{
    let shiftArr=[];
    console.log("getActiveDocShifts",selectedDoctor);
    console.log("recurringEvents",recurringEvents)
    for (var d = activeStart; d <= activeEnd; d.setDate(d.getDate() + 1)) {
        let shift_for_day = selectedDoctor.node.availability.edges.find(i=>i.node.day === d.getDay()+"");
        console.log("shift_for_day",shift_for_day);
        let dd = String(d.getDate()).padStart(2, '0');
        let mm = String(d.getMonth() + 1).padStart(2, '0'); 
        let yyyy = d.getFullYear();

        let activeDateStr = yyyy + '-' + mm + '-' + dd;
        let activeDateObj = new Date(activeDateStr);
        var SHIFT_OBJ=null;

        SHIFT_OBJ=shift_for_day;
        
        console.log("SHIFT_OBJSHIFT_OBJSHIFT_OBJ",SHIFT_OBJ);

        if(SHIFT_OBJ?.node?.shifts?.edges){
          let activeRecurringDay = recurringEvents.filter(i => (i.node.days.includes(d.getDay() + ""))); //converting int active day to string to compare
          console.log("recurringEvents",recurringEvents)
          console.log("activeRecurringDay",activeRecurringDay)
            SHIFT_OBJ.node.shifts.edges.map(shift=>{
              let shiftStHrMin=shift.node.startTime.split(":");
              let shiftSt=new Date(d);
              shiftSt.setHours(shiftStHrMin[0]);
              shiftSt.setMinutes(shiftStHrMin[1]);
              
              let shiftEnHrMin=shift.node.endTime.split(":");
              let shiftEnd=new Date(d);
              shiftEnd.setHours(shiftEnHrMin[0]);
              shiftEnd.setMinutes(shiftEnHrMin[1]);
              
              let yyyy = shiftSt.getFullYear();

              let dd = String(shiftSt.getDate()).padStart(2, '0');
              let mm = String(shiftSt.getMonth() + 1).padStart(2, '0'); 
              let hr = String(shiftSt.getHours()).padStart(2, '0'); 
              let mn = String(shiftSt.getMinutes()).padStart(2, '0'); 

              let ddEn = String(shiftEnd.getDate()).padStart(2, '0'); 
              let mmEn = String(shiftEnd.getMonth()+1).padStart(2, '0'); 
              let hrEn = String(shiftEnd.getHours()).padStart(2, '0'); 
              let mnEn = String(shiftEnd.getMinutes()).padStart(2, '0'); 
                for (var k = 0; k < activeRecurringDay.length; k++) {
                  let currRecurr = activeRecurringDay[k];
                  if ((currRecurr.node.dateUntil === null) || (currRecurr.node.dateUntil && d <= new Date(currRecurr.node.dateUntil) && d >= new Date(currRecurr.node.startDate))) {
                    console.log("RECC EVENT",d);
                    console.log("currRecurr",currRecurr);
                    let recEventSt=currRecurr.node.startTime.split(":");
                    let recEventEn=currRecurr.node.endTime.split(":");
                    shiftArr.push({
                      groupId: 'recurringGroupId',
                      start: yyyy+"-"+mm+"-"+dd+'T'+recEventSt[0]+":"+recEventSt[1]+":00",
                      end:  yyyy+"-"+mmEn+"-"+ddEn+'T'+recEventEn[0]+":"+recEventEn[1]+":00",
                      backgroundColor:'#288dcc',
                      textColor:'#fff',
                      title:currRecurr.node.title
                    });
                  }
                    
                }
           
                shiftArr.push({
                  groupId: 'shiftGroupId',
                  start: yyyy+"-"+mm+"-"+dd+'T'+hr+":"+mn+":00",
                  end:  yyyy+"-"+mmEn+"-"+ddEn+'T'+hrEn+":"+mnEn+":00",
                  display: 'background'
                });
            });
        }

    }
    setActiveDocShiftArr(shiftArr);
    console.log("shiftArr",shiftArr)
  }


  // ======================================== DOCTOR AND SESSION AVAILABILITY ================================
  let weekdayAvailability = [];
  let businessHours = [];
  let workingHours = [];

  // if availability exists for the selected session/service- use that, otherwise use availability for doctor shifts 
  // Weekday availability and shift hours based on Selected SERVICE
  if (
    selectedSession &&
    selectedSession.node.availability &&
    selectedSession.node.availability.edges.length > 0
  ) {
    weekdayAvailability = selectedSession.node.availability.edges.map((available) =>
      parseInt(available.node.day),
    );
    selectedSession.node.availability.edges.map((available) => {
      let shifts = available.node.shifts.edges;
      for (var i = 0; i < shifts.length; i++) {
        let shift = shifts[i];
        let dayArr = Array.from(String(available.node.day), Number);
        workingHours.push({
          daysOfWeek: dayArr,
          startTime: shift.node.startTime.substring(0, 5),
          endTime: shift.node.endTime.substring(0, 5),
        });
      }
      return null;
    });
  }
  else if (  // Weekday availability and buisness hours based on DOCTOR
    selectedDoctor &&
    selectedDoctor.node.availability &&
    selectedDoctor.node.availability.edges.length > 0
  ) {
    weekdayAvailability = selectedDoctor.node.availability.edges.map((available) =>
      parseInt(available.node.day),
    );
    selectedDoctor.node.availability.edges.map((available) => {
      let shifts = available.node.shifts.edges;
      for (var i = 0; i < shifts.length; i++) {
        let shift = shifts[i];
        let dayArr = Array.from(String(available.node.day), Number);
        workingHours.push({
          daysOfWeek: dayArr,
          startTime: shift.node.startTime.substring(0, 5),
          endTime: shift.node.endTime.substring(0, 5),
        });
      }
      return null;
    });
  }

  let defaultHours = { daysOfWeek: weekdayAvailability, startTime: '09:00', endTime: '17:00' };
  let defaultBuisness = [];
  defaultBuisness.push(defaultHours);
  calendarExtraProps['selectConstraint'] = 'businessHours';
  if (workingHours && workingHours.length > 0) {
    businessHours = workingHours;
  } else {
    businessHours = defaultBuisness;
  }


  calendarExtraProps['businessHours'] = businessHours;

  // ======================================== DOCTOR AND SESSION AVAILABILITY END ================================


  const addMinutes = (time, minsToAdd) => {
    function D(J) {
      return (J < 10 ? '0' : '') + J;
    }
    var piece = time.split(':');
    var mins = piece[0] * 60 + +piece[1] + +minsToAdd;

    return D(((mins % (24 * 60)) / 60) | 0) + ':' + D(mins % 60);
  };

  const handleChangeView = (daySelect) => {
    if (daySelect.view.type === 'dayGridMonth') {
      daySelect.view.calendar.changeView('timeGridDay', `${daySelect.startStr}`);
      setCalendarView('timeGridDay');
    } else if (daySelect.view.type === 'timeGridDay') {
      return false;
    }
  };

  const handleSelectAllow = (info) => {
    if (calendarView === 'dayGridMonth' || info.allDay) {
      return true;
    } else {
      let allAvailableEvents =
        fullCalendarRef && fullCalendarRef.current
          ? fullCalendarRef.current.getApi().getEvents()
          : [];
      let is_allowed = allAvailableEvents.find((i) => i.startStr === info.startStr);
      if (is_allowed) {
        return true;
      } else {
        return false;
      }
    }
  };
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    localStorage.setItem('calendarViewStored', '');

    //To initialize calendar based on url params
    let calendarInitDate = urlActions(window.location.href, 'get', 'calendarInitDate');
    let calendarInitView = urlActions(window.location.href, 'get', 'calendarInitView');
    if (calendarInitView && calendarInitDate) {
      let dtSt = new Date(calendarInitDate);
      dtSt.setHours(1); 
      let endDate = new Date(dtSt);
      endDate.setDate(dtSt.getDate() + 30); 
      GetEvents({
        variables: {
          start: dtSt,
          end: endDate,
        },
      })
      fullCalendarRef.current.getApi().changeView(calendarInitView, calendarInitDate);
    }

    return () => window.removeEventListener("resize", updateWidth);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let calendarViewStored = localStorage.getItem('calendarViewStored');

  useEffect(() => {
    if (calendarViewStored) {
      setCalendarView(calendarViewStored);
    }
  }, [calendarViewStored]);


  // FUNCTION THAT CALCULATES  AVAILABLE SLOTS
  const getAllAvailableSlots = (start_date, end_date) => {
    let doctor_conditions = selectedDoctor && !!selectedDoctor.node.identifier;
    let session_conditions = selectedSession && selectedSession !== null ? true : false;
    if (referralObjectData && referralObjectData.referralObj && referralObjectData.referralObj.referral) {
      doctor_conditions = true;
      session_conditions = true;
    }
    let enddate=end_date; // create slots for 7 more days incase any week view has ending of nth month and start of (n+1)th month
    enddate.setDate(7);

    if (doctor_conditions && session_conditions && !myAppointments) {
      // var today = new Date();
      var today = start_date;
      if (today < new Date()) {
        today = new Date();

        // IF PATIENT, START DATE IS AFTER THE PAYMENT CUTOFF TIME SO THAT THE NEXT AVAILABLE TAKES USER TO NEXT APPLICABLE SLOT 
        if (!isStaff && settings_obj && settings_obj.settings && settings_obj.settings.downpayment_cutoff_time) {
          let currentTime = new Date();
          currentTime.setHours(currentTime.getHours() + settings_obj.settings.downpayment_cutoff_time);
          today = currentTime;
        }
      }
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();
      // let buffer_minutes = selectedSession.node ? parseInt(selectedSession.node.buffer) : 0; // change default to 0 after testing

      // YYYY-MM-DD
      var todayDateStr = yyyy + '-' + mm + '-' + dd;
      let raw_events = data;
      let holidays = doctorLeavesList
        ? doctorLeavesList.leaves.edges.map((leave) => leave.node.date)
        : [];

      var allevents = [];
      // today is new date because we do not care about creating slots for previous days
      for (var d = today; d <= enddate; d.setDate(d.getDate() + 1)) {
        // date in the range
        let activeDate = new Date(d);

        let dd = String(activeDate.getDate()).padStart(2, '0');
        let mm = String(activeDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = activeDate.getFullYear();

        let activeDay = activeDate.getDay();
        // YYYY-MM-DD
        let activeDateStr = yyyy + '-' + mm + '-' + dd;
        let businesshours = calendarExtraProps['businessHours'];
        // Check if date is on holiday do nothing
        if (
          !holidays.includes(activeDateStr) &&
          weekdayAvailability.includes(activeDate.getDay())
        ) {
          // business start and endtime
          let calendarBusinesshours = businesshours.filter((i) =>
            i.daysOfWeek.includes(activeDate.getDay()),
          );
          for (var a in calendarBusinesshours) {
            let businesshour = calendarBusinesshours[a];
            let startTime = businesshour.startTime;
            let endTime = businesshour.endTime;

            // initial time for creation of events
            let activeStartTime = startTime;
            // today current time
            let todayTime =
              today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

            // get events only for day
            // for loop on the active day and remove all date less than the active date.
            let raw_events_today = [];
            for (var i in raw_events) {
              let event = raw_events[i];
              // start ex. 2020-12-06T10:00:00Z
              let [event_date] = event.start.split('T');
              if (event_date === activeDateStr) {
                // raw_events.splice(i, 1);
                raw_events_today.push(event);
              } else {
                break;
              }
            }
            // slot inside day. while activeStartTime <= endTime append event to the list
            while (
              Date.parse('01/01/2011 ' + activeStartTime) <= Date.parse('01/01/2011 ' + endTime)
            ) {
              // create the time slot
              let slot_end = addMinutes(activeStartTime, calendarSlotDuration); // '18:35'
              let create_slot = true;
              var appointment_buffer = 0;
              var nextStartBuffer = addMinutes(slot_end, parseInt(appointment_buffer));
              let buffer_end = new Date(activeDateStr + 'T' + nextStartBuffer);
              let recurringEvent = false;
              //based on appointments on backend,change the slot end and buffer end time
              for (var j in data) {
                let appointment = data[j];
                if (appointment && !appointment.holdWaitingReplacement) {
                  appointment_buffer = appointment.doctorEventType.buffer;
                  nextStartBuffer = addMinutes(slot_end, parseInt(appointment_buffer));
                  buffer_end = new Date(activeDateStr + 'T' + nextStartBuffer);
                  let app_st = appointment.start;
                  var app_en = appointment.end;
                  let appStart = new Date(app_st);
                  var appEnd = new Date(app_en);
                  let current_slot = {
                    start: activeDateStr + 'T' + activeStartTime,
                    end: activeDateStr + 'T' + slot_end,
                  };
                  if (current_slot) {
                    let etSt = new Date(current_slot.start);
                    let etEn = new Date(current_slot.end);
                    let stDate = new Date(current_slot.start);
                    let etDate = new Date(app_st);
                    if (stDate.setHours(0, 0, 0, 0) === etDate.setHours(0, 0, 0, 0)) {
                      if ( //etSt is suggested slot start date , etEn is suggested slot end. appStart: existing appointment start and appEnd is existing appointment end
                        //buffer_end is the buffer end the current existing appointment buffer end time in for loop that is being compared against new suggested slot 
                        (etSt < appEnd && etSt >= appStart) ||
                        (etEn < appEnd && etEn > appStart) ||
                        (etSt <= appStart && etEn >= appEnd) ||
                        (buffer_end <= appEnd && buffer_end > appStart) ||
                        (etSt <= appStart && buffer_end >= appEnd)
                      ) {

                        create_slot = false;
                        break;
                      }
                    }
                  }
                  console.log("recurringEventsList",recurringEventsList)
                  console.log("activeDay",activeDay)
                  // do not create slot if recurring events exists for this date and time of the loop
                  if (activeDay && recurringEventsList && recurringEventsList.length > 0) {
                    let activeRecurringDay = recurringEventsList.filter(i => (i.node.days.includes(activeDay + ""))); //converting int active day to string to compare
                    if (activeStartTime && activeRecurringDay) {
                      for (var k = 0; k < activeRecurringDay.length; k++) {
                        let currRecurr = activeRecurringDay[k];
                        if ((currRecurr.node.dateUntil === null) || (currRecurr.node.dateUntil && activeDate <= new Date(currRecurr.node.dateUntil) && activeDate >= new Date(currRecurr.node.startDate))) {
                          let currRecurrStart = currRecurr.node.startTime.slice(0, -3) // to convert 10:00:00 to 10:00 to match the format of existing start time in code
                          let currRecurrEnd = currRecurr.node.endTime.slice(0, -3);
                          let recurrEventSt = new Date(activeDateStr + 'T' + currRecurrStart);
                          let slotStart = new Date(activeDateStr + 'T' + activeStartTime);
                          let slotEnd = new Date(activeDateStr + 'T' + slot_end);
                          let recurrEventEnd = new Date(activeDateStr + 'T' + currRecurrEnd);
                          if (currRecurr && (
                            (recurrEventSt.getTime() === slotStart.getTime()) ||
                            (slotStart >= recurrEventSt && slotEnd <= recurrEventEnd) ||
                            (slotStart < recurrEventSt && recurrEventSt < slotEnd) ||
                            (slotStart < recurrEventEnd && recurrEventEnd < slotEnd)
                          )) {
                            create_slot = false;
                            recurringEvent = true;
                            nextStartBuffer = addMinutes(currRecurrEnd, parseInt(0));  // no buffer is applied for recurring events so 0
                            break;
                          }
                        }
                      }
                    }
                  }
                }
              }
              let proposed_start = slot_end;
              if (!create_slot) {
                proposed_start = nextStartBuffer;
                if (appEnd >= buffer_end && !recurringEvent) { // we care about appointment end if slot was not created due to existing appointment not if it was a recurring event
                  let new_start =
                    appEnd.getHours() + ':' + String(appEnd.getMinutes()).padStart(2, '0');
                  new_start = addMinutes(new_start, parseInt(appointment_buffer));
                  proposed_start = new_start;
                }
              }
              if (
                create_slot &&
                Date.parse('01/01/2011 ' + slot_end) <= Date.parse('01/01/2011 ' + endTime) &&
                !(
                  todayDateStr === activeDateStr &&
                  Date.parse('01/01/2011 ' + activeStartTime) <=
                  Date.parse('01/01/2011 ' + todayTime)
                )
              ) {
                allevents.push({
                  title: 'Available',
                  start: activeDateStr + 'T' + activeStartTime,
                  end: activeDateStr + 'T' + slot_end,
                  backgroundColor: selectedSession.node.eventType.color?.hexCode,
                  borderColor: selectedSession.node.eventType.color?.hexCode,
                });
              }
              activeStartTime = proposed_start;
            }
          }
        }
      }
      let allFilters = allevents.filter((i) => i !== null && i !== undefined);

      console.log("allFilters",allFilters);
      
      if(appFromCal && appFromCal.selectedDateStr && appCreateFromCal){
        let dateToBlock=appFromCal.selectedDateStr;
        let dd=String(dateToBlock.getDate()).padStart(2, '0');
        let mm=String(dateToBlock.getMonth() + 1).padStart(2, '0');
        let yy=dateToBlock.getFullYear();
        let time="T"+String(dateToBlock.getHours()).padStart(2, '0')+":"+String(dateToBlock.getMinutes()).padStart(2, '0');
        let dateToBlockStr=yy+"-"+mm+"-"+dd+time;
        let dateToBlockEnd=new Date(dateToBlock);
        dateToBlockEnd.setMinutes(dateToBlockEnd.getMinutes()+selectedSession.node.duration);

        let ddEnd=String(dateToBlockEnd.getDate()).padStart(2, '0');
        let mmEnd=String(dateToBlockEnd.getMonth() + 1).padStart(2, '0');
        let yyEnd=dateToBlockEnd.getFullYear();
        let timeEnd="T"+String(dateToBlockEnd.getHours()).padStart(2, '0')+":"+String(dateToBlockEnd.getMinutes()).padStart(2, '0');
        let dateToBlockEndStr=yyEnd+"-"+mmEnd+"-"+ddEnd+timeEnd;

        console.log("dateToBlockStr",dateToBlockStr);
        console.log("selectedSession",selectedSession);

        if(allFilters.find(i=>i.start===dateToBlockStr)){
          console.log("SELECT THIS DATE");
          dateEventInVar({
            start: dateToBlockStr,
            end: dateToBlockEndStr,
          });
        }
        else {
          alert("The selected date and timeslot on " +dateToBlock.toString() + " is not available please select other timeslot");
          let dataToSave=appFromCal;
          dataToSave["selectedDateStr"]=null;
          appFromCalVar(dataToSave);
        }
      }


      // console.log("info.end", info.end);
      // if (
      //   (calendarData && calendarData.calendarSlots === null) ||
      //   (calendarData &&
      //     calendarData.calendarSlots &&
      //     new Date(calendarData.calendarSlots.slotsUntil) <= new Date(info.end))
      // ) {

      calendarSlotsVar({
        allFilters: allFilters,
        slotsUntil: enddate,
      });
      setAvailableSlotsArr(allFilters);
    } else if (myAppointments) {
      setAvailableSlotsArr(data);
    } else {
      setAvailableSlotsArr([]);
    }
  }

  // INITIALIZE CALENDAR BASED ON FULL CALENDAR REF AND EVENTS DATA
  useEffect(() => {
    let refCalendarView = fullCalendarRef && fullCalendarRef.current ? fullCalendarRef.current.getApi().view : null;
    if (refCalendarView) {
      let current_month = refCalendarView.activeEnd.getMonth();
      let calendarEnd = refCalendarView.activeEnd;
      let current_year = refCalendarView.activeStart.getFullYear();
      if (calendarActiveMonth!==null && current_month !== calendarActiveMonth) {
        current_month = calendarActiveMonth;
      }
      let dateStart = new Date(current_year, current_month, 1);
      let dateEnd = new Date(current_year, current_month + 1, 1);
      if (calendarEnd > dateEnd ) {
        dateEnd = calendarEnd;
      }
      // dateEnd.setHours(23);
      getAllAvailableSlots(dateStart, dateEnd);
      if (calendarActiveMonth === null) {
        calendarActiveMonthVar(current_month);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullCalendarRef, data])

  // SET STATE FROM REACTIVE VARIBALE
  useEffect(() => {
    setData(eventsDataMain);
  }, [eventsDataMain])

  // RECURRING EVENTS DAYS SET BASED ON EVERYDAY OR FEW DAYS 
  useEffect(() => {
    if (selectedDoctor && selectedDoctor.node) {
      let recurringEvents = selectedDoctor.node.recurringEvents.edges;
      let recurrArr = recurringEvents.map(item => {
        if (item.node.everyday) {
          let new_item = {
            dateUntil: item.node.dateUntil,
            days: ["1", "2", "3", "4", "5", "6", "7"],
            description: item.node.description,
            endTime: item.node.endTime,
            everyday: true,
            startTime: item.node.startTime,
            startDate: item.node.startDate,
            title: item.node.title,
          }
          return {
            node: new_item
          };
        }
        return item;
      })
      setRecurringEventsList(recurrArr);
    }
  }, [selectedDoctor])


  //FUNCTION THAT TRIGGERS ON EVERY VIEW CHANGE
  const handleViewChange = (info) => {
    // let current_month = info.start.getMonth();
    console.log("handleViewChange",info);
    let current_year =  info.view.activeStart.getFullYear();
    let cur_cal_month =  info.view.activeStart.getMonth();
    let calendarEnd = info.end;

    if(selectedDoctor?.node?.availability){
      getActiveDocShifts(info.view.activeStart, info.view.activeEnd, selectedDoctor,recurringEventsList);
    }
    
    console.log("calendarActiveMonth && cur_cal_month !== calendarActiveMonth",calendarActiveMonth,cur_cal_month,calendarActiveMonth)

    if (calendarActiveMonth!==null && cur_cal_month !== calendarActiveMonth) {
      let dateStart = new Date(current_year, cur_cal_month, 1);
      let dateEnd = new Date(current_year, cur_cal_month + 1, 30);
      if (calendarEnd > dateEnd) {
        dateEnd = calendarEnd;
      }
      // dateEnd.setHours(23);
      let today = new Date();
      if (!myAppointments && dateStart < today) {
        dateStart = today;
      }

      calendarActiveMonthVar(cur_cal_month);

      let getEventsVar = {
        // doctor_identifier: selectedDoctor.node.identifier,
        start: dateStart,
        end: dateEnd,
      }

      console.log("getEventsVar",getEventsVar);

      if (!myAppointments) {
        if (selectedDoctor) {
          getEventsVar["doctor_identifier"] = selectedDoctor.node.identifier;
        }
        else if (referralObjectData && referralObjectData.referralObj && referralObjectData.referralObj.referral.referredDoctor) {
          getEventsVar["doctor_identifier"] = referralObjectData.referralObj.referral.referredDoctor.identifier;
        }
        GetEvents({ variables: getEventsVar });
      }
      else if (myAppointments) {
        GetEvents({ variables: getEventsVar });
      }
    }
    let amt = 0;
    if (info.view.type === 'timeGridWeek') {
      amt = 25;
    } else if (info.view.type === 'dayGridMonth') {
      amt = 3;
    }
    if (amt !== amountOfEvents) {
      setAmountOfEvents(amt);
    }
  }

  const getHeight = () => {
    const bodyHeight = document.querySelector('.fc-view-harness');
    if (bodyHeight) {
      setCalendarBodyHeight({ height: bodyHeight.clientHeight + 'px' });
    }
  }


  useEffect(() => {
    setTimeout(() => {
      getHeight();
    }, 200);
    window.addEventListener('resize', getHeight);
    return () => {
      window.removeEventListener('resize', getHeight);
      calendarSlotsVar(null);
    }
  }, []);

  const renderPopup = () => {
    if (coords?.length > 0){
      return (
        <div className="cancel" style={{
          position:'fixed', 
          top:0, 
          right:0, 
          left:0, 
          bottom:0, 
          backgroundColor:'transparent', 
          zIndex:99,}}
          onClick={() => { setcoords([]) }}
          >
            
          <div style={{
            top: coords[1],
            left:coords[0], 
          }}
          className="cal-popup-container">
            <div>{selectedDateStr?.getHours()}:{("0" + selectedDateStr?.getMinutes()).slice(-2)} - Create a:</div>
            <div className="cal-link-container">
            <Link 
            to={{ pathname: '/create/appointment', state: { appCreateFromCal: true} }}
            // to={`/create/appointment`} 
            // params={{appFromCal:true}}
            onClick={() => { 
              let dataToPass=appFromCal?appFromCal:{};
              dataToPass["selectedDateStr"]=selectedDateStr;
              appFromCalVar(dataToPass);
            }} 
            className="hover-link">
            Appointment
            </Link>
            </div>
          </div>
        </div>
      ) 
    }
  }

  return (
    <>
    {renderPopup()}
    <div className='calendar-container'>
      <FullCalendar
        locale={i18n.language}
        eventTextColor='#000'
        datesSet={handleViewChange}
        titleFormat={{ year: 'numeric', month: 'short', day: 'numeric' }}
        ref={fullCalendarRef}
        plugins={[dayGridPlugin, timeGridWeek, timeGridPlugin, interactionPlugin]}
        initialView={calendarViewStored !== '' && calendarViewStored !== null ? calendarViewStored : calendarView}
        weekends={true}
        moreLinkText={width <= 500 ? '' : 'more'}
        editable={false}
        unselectAuto={false}
        selectable={true}
        selectMirror={false}
        slotDuration={'00:' + calendarSlotDuration + ':00'}
        slotMinTime={'06:00:00'}
        slotMaxTime={'22:00:00'}
        eventDisplay="list-item"
        // events={(info, successCallback, failureCallback) => {}}
        // events={availableSlotsArr}
        events={availableSlotsArr.length>0?[...availableSlotsArr,...activeDocShiftArr]:[...activeDocShiftArr]}
        dayMaxEvents={amountOfEvents}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        eventClick={(info) => {
          if (!myAppointments) {
            dateEventInVar({
              start: info.event.startStr,
              end: info.event.endStr,
            });
          } else {
            let publicId = null;
            if (info.event._def.extendedProps) {
              publicId = info.event._def.publicId;
            }
            if (publicId) {
              let app_detail_url = isStaff ? '/appointment/detail/' : '/patientPortal/appointment/detail/';
              let app_url = app_detail_url + publicId;
              history.push(app_url);
            }
          }
        }}
        select={handleChangeView}
        showNonCurrentDates={false}
        eventClassNames={(info) => {
          if (
            selectedDateEvent &&
            selectedDateEvent.dateEvent &&
            selectedDateEvent.dateEvent.start &&
            selectedDateEvent.dateEvent.end
          ) {
            let selectedStart = new Date(selectedDateEvent.dateEvent.start).toString();
            let selectedEnd = new Date(selectedDateEvent.dateEvent.end).toString();
            let infoStart = new Date(info.event.startStr).toString();
            let infoEnd = new Date(info.event.endStr).toString();
            if (infoStart === selectedStart && infoEnd === selectedEnd) {
              return 'selectedSlot';
            }
          }
        }}
        selectAllow={handleSelectAllow}
        dateClick={(info) => {
          console.log("infoinfo",info);
          if(info.date>=new Date() && myAppointments && info.view.type!=='dayGridMonth' && info.jsEvent.target.classList.contains('fc-bg-event')){
            const api = fullCalendarRef.current.getApi();
            console.log(info.jsEvent.pageX + ',' + info.jsEvent.pageY);
            setcoords([info.jsEvent.pageX,info.jsEvent.pageY ]);
            setSelectedDateStr(info.date);
            console.log(info.dateStr)
            // setTimeRange(info.js)
            // api.addEvent({ // this object will be "parsed" into an Event Object
            //   title: 'The Title', // a property!
            //   start: '2018-09-01', // a property!
            //   end: '2018-09-02' // a property! ** see important note below about 'end' **
            // });
          }
        }}
      />
      {
        (!selectedDoctor || !selectedSession) && !myAppointments && (!referralObjectData || !referralObjectData.referralObj)
          ?
          <div style={calendarBodyHeight} className='calendar-overlay'>
            <span>{t('patientCalendar.selectPractitionerAnd')} <br /> {t('patientCalendar.practitionerSession')}</span>
          </div>
          : null
      }
    </div>
    </>
  );
};

export default withRouter(Calendar);
