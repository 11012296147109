import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";
import Base from "./base.js";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { dataPatientInVar, dataReferralObject } from "../cache/cache.js";
import UpcomingAppointmentCard from "../component/upcomingAppointmentCard";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  PER_PAGE_COUNT,
  urlActions,
  getDate,
  getForamttedTime,
  success_options,
  // getPermissionForAction,
  getPatientsAge,
  getPermissionForAction,
  displayMiddleName,
} from "../Common/helpers";
import CustomModals from "../component/Modal/modal";
import DatePicker from "react-datepicker";
import Preloader from "../Common/Preloder/Preloader";
import { Formik } from "formik";
import FormBlock from "../component/DynamicFormBlock/formBlock";
import { assertNullableType } from "graphql";
import LabsComponent from "./Labs/LabsComponent";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

// FIXME changing anything rerenders the entire component more then once

var today = new Date();
today = today.toISOString();

function PatientRecord(props) {
  const identifier = props.match.params.identifier;
  const formikRef = useRef();
  const formikRefIntake = useRef();
  const formikRefReferral = useRef();
  const formikRefVitals = useRef();
  const [activeUpcomingPage, setActiveUpcomingPage] = useState(1);
  const [skipUpcomingQuery, setSkipUpcomingQuery] = useState(false);
  const [upcomingApointments, setUpcomingApointments] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [encounterIdList, setEncounterIdList] = useState([]);
  let returnAllSectionPermissions = true;
  const patient_permission = getPermissionForAction(
    "patient",
    "",
    returnAllSectionPermissions
  );
  const appointment_permissions = getPermissionForAction(
    "appointment",
    "",
    returnAllSectionPermissions
  );
  const referral_permissions = getPermissionForAction(
    "referral",
    "",
    returnAllSectionPermissions
  );
  const lab_request_permission = getPermissionForAction(
    "lab_request",
    "",
    returnAllSectionPermissions
  );
  const patient_record_permission = getPermissionForAction(
    "patient_record",
    "",
    returnAllSectionPermissions
  );
  const intake_permission = getPermissionForAction(
    "intake_form",
    "",
    returnAllSectionPermissions
  );
  const survey_permission = getPermissionForAction(
    "survey",
    "",
    returnAllSectionPermissions
  );
  const encounter_permission = getPermissionForAction(
    "encounter",
    "",
    returnAllSectionPermissions
  );
  const medical_record_permission = getPermissionForAction(
    "medical_record",
    "",
    returnAllSectionPermissions
  );
  const [showModal, setShowModal] = useState(false);
  const [addEncounterModal, setAddEncounterModal] = useState(false);
  const [addReferralModal, setAddReferralModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [addVitalsModal, setAddVitalsModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [vitalDataState, setVitalDataState] = useState({ edges: [] });
  const [jsonData, setJsonData] = useState(null);
  const [initialValuesForVital, setInitialValuesForVital] = useState({
    height: null,
    weight: null,
    waistCircumference: null,
    temperature: null,
    bp: "",
    pulse: "",
    patient: null,
  });

  useEffect(() => {
    let upcoming = urlActions(window.location.href, "get", "upcoming");
    if (upcoming) {
      setActiveUpcomingPage(parseInt(upcoming));
    }
  }, []);

  const REQUEST_PATIENT_VITAL = gql`
    query {
      patients(identifier:"${identifier}"){
        edges {
          node {
            vitals{
              edges{
                node{
                  id
                  created
                  modified
                  bmi
                  bp
                  height
                  weight
                  waistCircumference
                  pulse
                  temperature
                }
              }
            }
          }
        }
      }
    }
  `;

  const REQUEST_PATIENT = gql`
  query {
    patients(identifier:"${identifier}"){
      totalCount
      edges {
        node {
          patientId
          firstName
          middleName
          lastName
          dob
          id
          phone
          email
          gender
          civilId
          doctor{
            firstName
            lastName
            id
            identifier
          }
          vitals{
            edges{
              node{
                id
                created
                modified
                bmi
                bp
                height
                weight
                waistCircumference
                pulse
                temperature
              }
            }
          }
          appointment{
            edges{
              node{
                title
                id
                start
                duration
                end
                location{
                  id
                  title
                }
                eventType{
                  id
                }
                doctor{
                  identifier
                  id
                }
                
              }
            }
          }
          encounters{
            edges{
              node{
                id
                date  
                staticId         
                notes{
                  edges{
                    node{
                      text
                    }
                  }
                }
                encounterType{
                  name
                  id
                }
              }
            }
          }
          formData{
            id
            data
            dynamicFormData
            commonFormBlocks {
              dynamicFormBlocks {
                edges {
                  node {
                    id
                    formBlock {
                      name
                      id
                      staticId
                      isMedication
                      blockFormFields {
                        edges {
                          node {
                            formField {
                              id
                              name
                              fieldName
                              variant
                              staticId
                              defaultBooleanVariant
                              options {
                                edges {
                                  node {
                                    id
                                    staticId
                                    value
                                    default
                                  }
                                }
                              }
                            }
                            formBlock {
                              id
                            }
                            required
                            width
                          }
                        }
                      }
                    }
                  }
                }
              }
          }
        }
          discount
          firstVisit
          address
          referredBy
          impressionDiagnosis
          medication
          identifier
          governorate{
            name
          }
          area{
            name
          }
          country{
            name
            code
          }
          referrals{
            edges{
              node{
                title
                id
                followUp
                dynamicForms{
                  edges{
                    node{
                      name
                      id
                      formType
                    }
                  }
                }
                encounter{
                  date
                }
                description
                referredDoctor{
                  firstName
                  lastName
                  id
                  identifier
                  eventType {
                    edges {
                      node {
                        id
                        title{
                          name
                        }
                        duration
                        buffer
                        price
                        eventType {
                          id
                          name
                          color{
                            hexCode
                            name
                            colorId
                          }
                        }
                        eventLocation {
                          id
                          title
                        }
                      }
                    }
                  }
                  recurringEvents{
                    edges {
                      node {
                        startDate
                        startTime
                        endTime
                        title
                        description
                        everyday
                        days
                        dateUntil
                      }
                    }
                  }
                  availability {
                    edges {
                      node {
                        id
                        day
                        shifts{
                          edges {
                            node {
                              startTime
                              endTime
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
                patient {
                  firstName
                  middleName
                  lastName
                  id
                  identifier
                }
                referredDoctorSession{
                  id
                  title{
                    name
                  }
                  duration
                  buffer
                  price
                  eventType {
                    id
                    name
                    color{
                      hexCode
                      name
                      colorId
                    }
                  }
                  eventLocation {
                    id
                    title
                  }
                }
                createdAppointment{
                  start
                }
              }
            }
          }
        }
      }
    }
  }
`;

  const REQUEST_UPCOMING_EVENTS = gql`
  query {
    events(patient_Identifier:"${identifier}",active:true, start:"${today}", orderby:"start", offset:${
    PER_PAGE_COUNT * (activeUpcomingPage - 1)
  }){
      totalCount
      edges{
        node{
          start
          end 
          displayStatus
          id
          doctor{
            identifier
          }
          eventType{
          name
          id
          }
        }
      }
    }
  }
`;
  const REQUEST_ENCOUNTER_EVENTS = gql`
  query {
    events(patient_Identifier:"${identifier}",active:true, orderby:"start", withoutEncounter:true
    ){
      totalCount
      edges{
        node{
          start
          end 
          displayStatus
          id
          location{
            title
          }
          doctor{
            identifier
          }
          eventType{
          name
          id
          }
        }
      }
    }
  }
`;

  const REQUEST_ENCOUNTER_TYPES = gql`
    query {
      encounterTypes {
        edges {
          node {
            id
            name
            dynamicForm {
              id
              name
            }
          }
        }
      }
    }
  `;

  const REQUEST_LOCATION = gql`
    query {
      listEventLocation {
        edges {
          node {
            title
            id
          }
        }
      }
    }
  `;
  const REQUEST_DOCTOR = gql`
    query {
      doctors {
        edges {
          node {
            firstName
            lastName
            identifier
            id
            eventType {
              edges {
                node {
                  id
                  title {
                    name
                  }

                  duration
                  buffer
                  price
                  eventType {
                    id
                    name
                  }
                  eventLocation {
                    id
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const REQUEST_DYNAMIC_FORMS = gql`
    query {
      dynamicForms {
        edges {
          node {
            name
            id
            formType
          }
        }
      }
    }
  `;

  const CREATE_UPDATE_PATIENT_VITAL = gql`
    mutation createUpdatePatientVital(
      $id: ID
      $patient: ID!
      $height: Int
      $weight: Float
      $waistCircumference: Float
      $temperature: Float
      $bp: String
      $pulse: String
    ) {
      createUpdatePatientVital(
        input: {
          id: $id
          patient: $patient
          height: $height
          weight: $weight
          waistCircumference: $waistCircumference
          temperature: $temperature
          bp: $bp
          pulse: $pulse
        }
      ) {
        obj {
          created
          id
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const CREATE_ENCOUNTER = gql`
    mutation createEncounter(
      $patient: ID!
      $doctor: ID!
      $facility: ID!
      $encounterType: ID!
      $appointment: ID
      $date: DateTime
    ) {
      createEncounter(
        input: {
          patient: $patient
          doctor: $doctor
          facility: $facility
          encounterType: $encounterType
          date: $date
          appointment: $appointment
        }
      ) {
        obj {
          id
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const CREATE_UPDATE_REFERRAL = gql`
    mutation createUpdateReferral(
      $patient: ID!
      $doctor: ID!
      $doctor_session: ID!
      $description: String!
      $recommendedDate: DateTime
    ) {
      createUpdateReferral(
        input: {
          patient: $patient
          referredDoctor: $doctor
          referredDoctorSession: $doctor_session
          description: $description
          recommendedDate: $recommendedDate
        }
      ) {
        obj {
          id
          created
          modified
          title
          description
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const CREATE_UPDATE_INTAKE = gql`
    mutation createUpdateIntakeForm($patient: ID!, $dynamicForm: ID!) {
      createUpdateIntakeForm(
        input: { patient: $patient, dynamicForm: $dynamicForm }
      ) {
        obj {
          created
          dynamicForm {
            id
            name
          }
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const CREATE_UPDATE_SURVEY = gql`
    mutation createUpdateSurvey($patient: ID!, $dynamicForm: ID!) {
      createUpdateSurvey(
        input: { patient: $patient, dynamicForm: $dynamicForm }
      ) {
        obj {
          created
          dynamicForm {
            id
            name
          }
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const DELETE_REFERRAL = gql`
    mutation deleteReferral($id: ID!) {
      deleteReferral(id: $id) {
        deleted
      }
    }
  `;

  const DELETE_PATIENT_VITAL = gql`
    mutation deletePatientVital($id: ID!) {
      deletePatientVital(id: $id) {
        deleted
      }
    }
  `;

  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;
  const { data: userDetailsData } = useQuery(USER_DETAILS);

  var userDetails =
    userDetailsData && userDetailsData.userDetails
      ? userDetailsData.userDetails
      : null;
  if (userDetails && userDetails.indexOf("username") > -1) {
    userDetails = JSON.parse(userDetails);
  }

  const [openSnackbar] = useSnackbar(error_options);

  // GET PATIENT DATA
  const { data, loading } = useQuery(REQUEST_PATIENT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setVitalDataState(data?.patients?.edges[0]?.node?.vitals);
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const [getVitals, { data: dataPatientVital, loadingPatientVital }] =
    useLazyQuery(REQUEST_PATIENT_VITAL, {
      fetchPolicy: "network-only",
      onCompleted: () => {
        setVitalDataState(dataPatientVital?.patients?.edges[0]?.node?.vitals);
      },
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    });

  const patient_record = data && data.patients ? data.patients.edges[0] : null;

  useEffect(() => {
    if (patient_record) {
      let encIdArr = patient_record.node.encounters.edges.map((encounter) => {
        return {
          staticId: encounter.node.staticId,
          relayId: encounter.node.id,
        };
      });
      setEncounterIdList(encIdArr);
      setPatientInfo();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_record]);

  const setPatientInfo = () => {
    if (patient_record) {
      dataPatientInVar(patient_record.node);
    }
  };

  // // GET UPCOMING APPOINTMENT DATA
  const {
    data: upcoming_events_obj = null,
    loading: loading_upcoming = false,
  } = useQuery(REQUEST_UPCOMING_EVENTS, {
    fetchPolicy: "network-only",
    skip: skipUpcomingQuery, //If skip is true, the query will be skipped entirely. Not available with useLazyQuery.
    // using skip as a workaround so that both the queries(past and upcoming appointments) are not triggered when page for only one of them is changed/clicked.
    onCompleted: () => {
      setSkipUpcomingQuery(true);
    },
    onError: () => {
      setSkipUpcomingQuery(true);
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });
  useEffect(() => {
    let upcoming_events = [];
    if (upcoming_events_obj) {
      if (upcoming_events_obj.events) {
        upcoming_events = upcoming_events_obj.events.edges;
        setUpcomingApointments(upcoming_events);
        setTotalCount(upcoming_events_obj.events.totalCount);
      }
    }
    console.log("upcoming_events_obj from useEffect! ", upcoming_events_obj);
  }, [upcoming_events_obj]);

  // GET ENCOUNTER_TYPES DATA
  const { data: encounter_types_obj = null } = useQuery(
    REQUEST_ENCOUNTER_TYPES,
    {
      fetchPolicy: "network-only",
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    }
  );

  const { data: encounter_events = null } = useQuery(REQUEST_ENCOUNTER_EVENTS, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });
  console.log("encounter_events! ", encounter_events);

  // GET REQUEST_LOCATION DATA
  const { data: locations_obj = null } = useQuery(REQUEST_LOCATION, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // GET DOCTOR DATA
  const { data: doctors_obj = null } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // GET DYNAMIC FORMS
  const { data: dynamic_forms_obj = null } = useQuery(REQUEST_DYNAMIC_FORMS, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });
  // CREATE ENCOUNTER
  const [createEncounter] = useMutation(CREATE_ENCOUNTER, {
    onCompleted: ({ createEncounter }) => {
      if (createEncounter.errors && createEncounter.errors.length > 0) {
        let error_messages = createEncounter.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (createEncounter.obj) {
        console.log("ENCOUNTER CREATED", createEncounter.obj);
        props.history.push("/encounter/detail/" + createEncounter.obj.id);
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // CREATE REFERRAL
  const [createUpdateReferral] = useMutation(CREATE_UPDATE_REFERRAL, {
    onCompleted: ({ createUpdateReferral }) => {
      if (
        createUpdateReferral.errors &&
        createUpdateReferral.errors.length > 0
      ) {
        let error_messages = createUpdateReferral.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (createUpdateReferral.obj) {
        console.log("createUpdateReferral CREATED", createUpdateReferral.obj);
        let alert_msg = `Referral for ${selectedDoctor.node.firstName} ${selectedDoctor.node.lastName} - ${selectedSession.node.title.name} created`;
        openSnackbarSuccess(alert_msg, [SNACK_DURATION]);
        setAddReferralModal(false);
        setModalType(null);
      }
    },
    refetchQueries: [{ query: REQUEST_PATIENT, fetchPolicy: "network-only" }],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // CREATE INTAKE FORM FOR PATIENT
  const [createUpdateIntakeForm] = useMutation(CREATE_UPDATE_INTAKE, {
    onCompleted: ({ createUpdateIntakeForm }) => {
      if (
        createUpdateIntakeForm.errors &&
        createUpdateIntakeForm.errors.length > 0
      ) {
        let error_messages = createUpdateIntakeForm.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (createUpdateIntakeForm.obj) {
        console.log("createUpdateReferral CREATED", createUpdateIntakeForm.obj);
        let alert_msg = "Intake form Created ";
        openSnackbarSuccess(alert_msg, [SNACK_DURATION]);
        setAddReferralModal(false);
        setModalType(null);
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // CREATE SURVEY FORM FOR PATIENT
  const [createUpdateSurvey] = useMutation(CREATE_UPDATE_SURVEY, {
    onCompleted: ({ createUpdateSurvey }) => {
      if (createUpdateSurvey.errors && createUpdateSurvey.errors.length > 0) {
        let error_messages = createUpdateSurvey.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (createUpdateSurvey.obj) {
        console.log("createUpdateReferral CREATED", createUpdateSurvey.obj);
        let alert_msg = "Survey form Created ";
        openSnackbarSuccess(alert_msg, [SNACK_DURATION]);
        setAddReferralModal(false);
        setModalType(null);
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // DELETE_REFERRAL
  const [deleteReferral] = useMutation(DELETE_REFERRAL, {
    onCompleted: ({ deleteReferral }) => {
      console.log("deleteReferral", deleteReferral);
    },
    refetchQueries: [{ query: REQUEST_PATIENT, fetchPolicy: "network-only" }],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // CREATE_UPDATE_PATIENT_VITAL
  const [createUpdatePatientVital] = useMutation(CREATE_UPDATE_PATIENT_VITAL, {
    onCompleted: ({ createUpdatePatientVital }) => {
      if (
        createUpdatePatientVital.errors &&
        createUpdatePatientVital.errors.length > 0
      ) {
        let error_messages = createUpdatePatientVital.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (createUpdatePatientVital.obj) {
        getVitals();
        let alert_msg = "Vitals Saved";
        openSnackbarSuccess(alert_msg, [SNACK_DURATION]);
        setAddVitalsModal(false);
        setIsEdit(false);
        setModalType(null);
      }
    },
    refetchQueries: [
      { query: REQUEST_PATIENT_VITAL, fetchPolicy: "network-only" },
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const [deletePatientVital] = useMutation(DELETE_PATIENT_VITAL, {
    onCompleted: ({ deletePatientVital }) => {
      getVitals();
      let alert_msg = "Vitals Deleted";
      openSnackbarSuccess(alert_msg, [SNACK_DURATION]);
    },
    refetchQueries: [{ query: REQUEST_PATIENT_VITAL, fetchPolicy: "no-cache" }],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  userDetails = null;
  const renderUpcomingAppointments = (events_list) => {
    if (events_list.length > 0) {
      let appointments = events_list.filter(
        (i) => i.node.displayStatus === "Confirmed"
      );
      // let appointments = events_list;
      let canViewAppointments = null;
      if (userDetails && userDetails.canViewAppointments) {
        canViewAppointments = userDetails.canViewAppointments.edges.map(
          (doc) => {
            return doc.node.identifier;
          }
        );
        appointments = appointments.filter(
          (i) => canViewAppointments.includes(i.node.doctor.identifier) === true
        );
      }

      if (appointments.length > 0) {
        return appointments.slice(0, 3).map((appointment, index) => {
          let event = appointment?.node;
          if (event) {
            return (
              <UpcomingAppointmentCard
                patient_record={patient_record?.node}
                event={event}
                key={index}
              />
            );
          } else {
            return null;
          }
        });
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const deleteRefFunc = (id) => {
    if (window.confirm("Delete Referral?")) {
      let val = {};
      val["id"] = id;
      console.log("VALL", val);
      deleteReferral({ variables: val });
    }
  };

  const deleteVital = (id) => {
    if (window.confirm("Delete Vital?")) {
      let val = {};
      val["id"] = id;
      deletePatientVital({ variables: val });
    }
  };

  const createAppFromRef = (referral) => {
    let patient = {
      id: patient_record && patient_record.node ? patient_record.node.id : null,
      firstName:
        patient_record && patient_record.node
          ? patient_record.node.firstName
          : null,
      middleName:
        patient_record && patient_record.node
          ? displayMiddleName(patient_record.node.middleName)
          : null,
      lastName:
        patient_record && patient_record.node
          ? patient_record.node.lastName
          : null,
      identifier:
        patient_record && patient_record.node
          ? patient_record.node.identifier
          : null,
    };
    let ref_obj = {
      patient: patient,
      referral: referral,
    };
    dataReferralObject(ref_obj);
    props.history.push("/create/appointment");
  };

  const renderReferrals = (referrals_list) => {
    let referralsArr = referrals_list.slice(0, 1);
    return referralsArr.map((item, index) => {
      return (
        <Card key={index}>
          <Card.Body className="p-4">
            <div className="mb-2 text-uppercase"> {item.node.title}</div>
            {item.node.followUp && item.node.encounter.date ? (
              <h6 className="textPrimary">
                {" "}
                Follow up for previous encounter:
                {getDate(new Date(item.node.encounter.date))}{" "}
              </h6>
            ) : null}
            <div className="mt-1 ">
              {" "}
              Doctor: {item.node.referredDoctor.firstName}{" "}
              {item.node.referredDoctor.lastName}{" "}
            </div>
            <div className="mt-1 ">
              {" "}
              Duration: {item.node.referredDoctorSession.duration} Minutes{" "}
            </div>
            <div className="mt-1 ">
              {" "}
              Type: {item.node.referredDoctorSession.eventType.name}{" "}
            </div>
            {item.node.referredDoctorSession.eventLocation.title ? (
              <div className="">
                {" "}
                Location: {
                  item.node.referredDoctorSession.eventLocation.title
                }{" "}
              </div>
            ) : null}
            {item.node.recommendedDate ? (
              <div className="mt-1">
                {" "}
                Recommended Date:{" "}
                {getDate(new Date(item.node.recommendedDate)) +
                  " " +
                  getForamttedTime(new Date(item.node.recommendedDate))}
              </div>
            ) : null}
            <div className="mt-1">
              {" "}
              Price: {item.node.referredDoctorSession.price} KWD{" "}
            </div>
            {item.node.description ? (
              <div className="mt-1">
                <div>Description:</div>
                <EllipsisWithTooltip placement="top">
                  {item.node.description}
                </EllipsisWithTooltip>
              </div>
            ) : null}
            <Button
              variant="primary"
              block
              className="back mt-4"
              onClick={() => createAppFromRef(item.node)}
            >
              Create Appointment
            </Button>
            <Button
              variant="danger"
              block
              className="back mt-2"
              onClick={() => deleteRefFunc(item.node.id)}
            >
              Delete Referral
            </Button>
          </Card.Body>
        </Card>
      );
    });
  };

  const openModal = (type) => {
    setAddReferralModal(true);
    setModalType(type);
  };

  const renderButtons = () => {
    return (
      <>
        <Row className="mb-4 d-flexƒ patient_page_buttons">
          {encounter_permission.includes("add") ? (
            <Col xs={6} md={4} lg={4}>
              <div className="mb-2 d-flex justify-content-center">
                <Button
                  block
                  className="patient-record-btn btn-small-display-padding-encounter"
                  variant="primary"
                  size="md"
                  onClick={() => setAddEncounterModal(true)}
                >
                  Add Encounter
                </Button>
              </div>
            </Col>
          ) : null}
          {appointment_permissions.includes("add") ? (
            <Col xs={6} md={4} xs={6} md={4} lg={4}>
              <div className="mb-2 d-flex justify-content-center">
                <Link to="/create/appointment" className="patient-record-link">
                  <Button
                    block
                    className="patient-record-btn-link"
                    variant="primary"
                    size="md"
                    onClick={setPatientInfo}
                  >
                    {" "}
                    New Appointment
                  </Button>
                </Link>
              </div>
            </Col>
          ) : null}
          {referral_permissions.includes("add") ? (
            <Col xs={6} md={4} xs={6} md={4} lg={4}>
              <div className="mb-2 d-flex justify-content-center">
                <Button
                  block
                  className="patient-record-btn"
                  variant="primary"
                  size="md"
                  onClick={() => openModal("referral")}
                >
                  Create Referral
                </Button>
              </div>
            </Col>
          ) : null}
          {/* <Col xs={6} md={4} xs={6} md={4} lg={4}>
            <div md={4} className="mb-2 d-flex justify-content-center">
              <DropdownButton block title="More" className='patient-record-dropdown'>
                <Dropdown.Item>
                  <Link to={`/manage/intake/forms/${identifier}`}>Intake Forms</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to={`/manage/surveys/forms/${identifier}`}>Survey Forms</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to={`/patient/labrequests/${identifier}`}>Lab Requests</Link>
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </Col> */}
          {intake_permission.includes("add") ||
          intake_permission.includes("view") ? (
            <Col xs={6} md={4} xs={6} md={4} lg={4}>
              <div md={4} className="mb-2 d-flex justify-content-center">
                <DropdownButton
                  block
                  title="Intake Forms"
                  className="patient-record-dropdown"
                >
                  {intake_permission.includes("view") ? (
                    <Dropdown.Item>
                      <Link
                        className="d-flex"
                        to={`/manage/intake/forms/${identifier}`}
                      >
                        View
                      </Link>
                    </Dropdown.Item>
                  ) : null}
                  {intake_permission.includes("add") ? (
                    <Dropdown.Item onClick={() => openModal("intake_form")}>
                      Add
                    </Dropdown.Item>
                  ) : null}
                </DropdownButton>
              </div>
            </Col>
          ) : null}
          {survey_permission.includes("add") ||
          survey_permission.includes("view") ? (
            <Col xs={6} md={4} xs={6} md={4} lg={4}>
              <div md={4} className="mb-2 d-flex justify-content-center">
                <DropdownButton
                  block
                  title="Survey Forms"
                  className="patient-record-dropdown"
                >
                  {survey_permission.includes("view") ? (
                    <Dropdown.Item>
                      <Link
                        className="d-flex"
                        to={`/manage/surveys/forms/${identifier}`}
                      >
                        View
                      </Link>
                    </Dropdown.Item>
                  ) : null}
                  {survey_permission.includes("add") ? (
                    <Dropdown.Item onClick={() => openModal("survey")}>
                      Add
                    </Dropdown.Item>
                  ) : null}
                </DropdownButton>
              </div>
            </Col>
          ) : null}
          {lab_request_permission.includes("add") ||
          lab_request_permission.includes("view") ? (
            <Col xs={6} md={4} xs={6} md={4} lg={4} className="mr-auto">
              <div md={4} className="d-flex justify-content-center">
                <DropdownButton
                  block
                  title="Lab Requests"
                  className="patient-record-dropdown"
                >
                  {lab_request_permission.includes("view") ? (
                    <Dropdown.Item to>
                      <Link
                        className="d-flex"
                        to={`/patient/labrequests/${identifier}`}
                      >
                        View
                      </Link>
                    </Dropdown.Item>
                  ) : null}
                  {lab_request_permission.includes("add") ? (
                    <Dropdown.Item onClick={() => setShowModal(true)}>
                      Add
                    </Dropdown.Item>
                  ) : null}
                </DropdownButton>
              </div>
            </Col>
          ) : null}
          {patient_record_permission.includes("view") ? (
            <Col xs={6} md={4} xs={6} md={4} lg={4} className="mr-auto">
              <div md={4} className="d-flex justify-content-center">
                <DropdownButton
                  block
                  title="Report Requests"
                  className="patient-record-dropdown"
                >
                  {patient_record_permission.includes("view") ? (
                    <Dropdown.Item to>
                      {patient_record?.node?.id ? (
                        <Link
                          className="d-flex"
                          to={`/patient/report/request?patientId=${patient_record.node.id}`}
                        >
                          View
                        </Link>
                      ) : null}
                    </Dropdown.Item>
                  ) : null}
                </DropdownButton>
              </div>
            </Col>
          ) : null}
          {patient_record?.node &&
          medical_record_permission &&
          medical_record_permission.includes("view") ? (
            <Col xs={6} md={4} xs={6} md={4} lg={4}>
              <Link to={"/patient/record/files/" + patient_record.node.id}>
                <div className="d-flex justify-content-center">
                  <Button
                    block
                    className="patient-record-btn"
                    variant="primary"
                    size="md"
                  >
                    Files
                  </Button>
                </div>
              </Link>
            </Col>
          ) : null}
          {patient_record &&
          patient_record.node &&
          medical_record_permission &&
          medical_record_permission.includes("view") ? (
            <Col xs={6} md={4} xs={6} md={4} lg={4}>
              <Link
                to={`/accounts/patient/${patient_record.node.identifier}`}
                params={{ patient_record }}
              >
                <div className="d-flex justify-content-center">
                  <Button
                    block
                    className="patient-record-btn"
                    variant="primary"
                    size="md"
                  >
                    Payment Records
                  </Button>
                </div>
              </Link>
            </Col>
          ) : null}

          {/* <Col xs={6} md={4} xs={6} md={4} lg={4}>
            <div md={4} className="mb-2 d-flex justify-content-center">
              <Button variant="primary" block className='patient-record-btn' size="md" onClick={() => setShowModal(true)}>
                Labs
            </Button>
            </div>
          </Col>

          <Col xs={6} md={4} xs={6} md={4} lg={4}>
            <div md={4} className="mb-2 d-flex justify-content-center">
              <Button block className='patient-record-btn btn-small-display-padding-intake' variant="primary" size="md" onClick={() => openModal("intake_form")}>
                Intake Form
              </Button>
            </div>
          </Col>

          <Col xs={6} md={4} xs={6} md={4} lg={4}>
            <div md={4} className="mb-2 d-flex justify-content-center">
              <Button block className='patient-record-btn' variant="primary" size="md" onClick={() => openModal("survey")}>
                Survey Form
              </Button>
            </div>
          </Col> */}

          <Col xs={6} md={4} xs={6} md={4} lg={4}></Col>
        </Row>
      </>
    );
  };

  const setActionDate = (info, elemRef, fldName) => {
    elemRef.current.setFieldValue(fldName, info);
  };
  const initialValues = {
    appointment: "",
    doctor: "",
    encounter_type: "",
    facility: "",
    has_appointment: true,
    encounter_date: new Date(),
  };

  const initialValuesReferral = {
    doctor: "",
    doctor_session: "",
    description: "",
    patient:
      patient_record && patient_record.node ? patient_record.node.id : "",
  };

  const onSubmit = (values, { resetForm }) => {
    let val = {
      doctor: values.doctor,
      patient:
        patient_record && patient_record.node ? patient_record.node.id : null,
      facility: values.facility,
      encounterType: values.encounter_type,
      date: values.encounter_date ? values.encounter_date.toISOString() : "",
    };
    if (values.has_appointment && values.appointment) {
      let appointment_selected = patient_record.node.appointment.edges.find(
        (i) => i.node.id === values.appointment
      );
      if (appointment_selected) {
        appointment_selected = appointment_selected.node;
        val["date"] = appointment_selected.start;
        val["facility"] = appointment_selected.location.id;
        val["doctor"] = appointment_selected.doctor.id;
        val["appointment"] = appointment_selected.id;
        val["encounterType"] = appointment_selected.eventType.id;
      }
    }
    console.log("valvalval", val);

    createEncounter({ variables: val });
  };

  const onSubmitReferral = (values, { resetForm }) => {
    let val = {
      doctor: values.doctor,
      doctor_session: values.session,
      patient:
        patient_record && patient_record.node ? patient_record.node.id : null,
      description: values.description,
      // recommendedDate: values.recommended_date,
    };
    console.log("values", values);
    console.log("valvalval", val);
    if (values.months || values.weeks) {
      let recommended_date = new Date();
      if (values.months) {
        recommended_date.setMonth(recommended_date.getMonth() + values.months);
      }
      if (values.weeks) {
        recommended_date.setDate(
          recommended_date.getDate() + parseInt(values.weeks) * 7
        );
      }
      console.log("today", new Date());
      console.log("recommended_date", recommended_date);
      val["recommendedDate"] = new Date(recommended_date);
    }

    createUpdateReferral({ variables: val });
  };

  const onSubmitVitals = (values, { resetForm }) => {
    let val = {
      height: values.height,
      weight: values.weight,
      waistCircumference: values.waistCircumference,
      temperature: values.temperature,
      bp: values.bp,
      pulse: values.pulse,
      patient:
        patient_record && patient_record.node && patient_record.node.id
          ? patient_record.node.id
          : null,
    };
    if (initialValuesForVital && initialValuesForVital.id) {
      val["id"] = initialValuesForVital.id;
    }
    if (
      !val["height"] &&
      !val["weight"] &&
      !val["waistCircumference"] &&
      !val["temperature"] &&
      !val["bp"] &&
      !val["pulse"]
    ) {
      alert("At least one of the field should be filled");
    } else {
      createUpdatePatientVital({ variables: val });
    }
  };

  const editVital = (vital) => {
    setIsEdit(true);
    setInitialValuesForVital({
      height: vital.node.height,
      weight: vital.node.weight,
      waistCircumference: vital.node.waistCircumference,
      temperature: vital.node.temperature,
      bp: vital.node.bp,
      pulse: vital.node.pulse,
      patient:
        patient_record && patient_record.node && patient_record.node.id
          ? patient_record.node.id
          : null,
      id: vital.node.id,
    });
    setAddVitalsModal(true);
  };

  const resetInitialValues = () => {
    setInitialValuesForVital({
      height: null,
      weight: null,
      waistCircumference: null,
      temperature: null,
      bp: "",
      pulse: "",
      patient:
        patient_record && patient_record.node && patient_record.node.id
          ? patient_record.node.id
          : null,
      id: null,
    });
    setAddVitalsModal(true);
  };

  const renderEncounterForm = (handleChange, values) => {
    return (
      <>
        {encounter_types_obj &&
        encounter_types_obj.encounterTypes.edges &&
        encounter_types_obj.encounterTypes.edges.length > 0 ? (
          <Form.Group as={Row}>
            <Form.Label column sm={3} md={3} className="text-right required">
              Encounter Type
            </Form.Label>
            <Col sm={8} md={8}>
              <Form.Control
                as="select"
                name="encounter_type"
                required
                onChange={handleChange}
              >
                <option value={""}> Select Encounter Type</option>
                {encounter_types_obj.encounterTypes.edges.map((item, index) => {
                  return (
                    <option value={item.node.id} key={index}>
                      {item.node.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Form.Group>
        ) : null}
        {doctors_obj && doctors_obj.doctors ? (
          <Form.Group as={Row}>
            <Form.Label column sm={3} md={3} className="text-right required">
              Seen By
            </Form.Label>
            <Col sm={8} md={8}>
              <Form.Control
                autoComplete="off"
                as="select"
                name="doctor"
                onChange={handleChange}
                required
              >
                <option value={""}> Select Doctor</option>
                {doctors_obj.doctors.edges.map((item, index) => {
                  return (
                    <option value={item.node.id} key={index}>
                      {item.node.firstName} - {item.node.lastName}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Form.Group>
        ) : null}
        {locations_obj && locations_obj.listEventLocation ? (
          <Form.Group as={Row}>
            <Form.Label column sm={3} md={3} className="text-right required">
              Location
            </Form.Label>
            <Col sm={8} md={8}>
              <Form.Control
                autoComplete="off"
                as="select"
                name="facility"
                onChange={handleChange}
                required
              >
                <option value={""}> Select Location</option>
                {locations_obj.listEventLocation.edges.map((item, index) => {
                  return (
                    <option value={item.node.id} key={index}>
                      {item.node.title}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Form.Group>
        ) : null}
        <Form.Group as={Row} className="text-right">
          <Form.Label column sm={3} md={3}>
            Date
          </Form.Label>
          <Col sm={8} md={8}>
            <DatePicker
              selected={values.encounter_date ? values.encounter_date : ""}
              onChange={(info) =>
                setActionDate(info, formikRef, "encounter_date")
              }
              name="encounter_date"
              className="form-control"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              autocomplete="off"
              dateFormat="dd/MMM/yyyy - h:mm aa"
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: "5px, 10px",
                },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: "viewport",
                },
              }}
            />
          </Col>
        </Form.Group>
      </>
    );
  };

  var COMMON_FORM_BLOCKS = [];
  var DYNAMIC_FORM_DATA = {};
  if (patient_record && patient_record.node && patient_record.node.formData) {
    if (
      patient_record.node.formData.commonFormBlocks &&
      patient_record.node.formData.commonFormBlocks.dynamicFormBlocks &&
      patient_record.node.formData.commonFormBlocks.dynamicFormBlocks.edges
        .length > 0
    ) {
      COMMON_FORM_BLOCKS =
        patient_record.node.formData.commonFormBlocks.dynamicFormBlocks.edges;
    }
    if (
      patient_record.node.formData.dynamicFormData &&
      patient_record.node.formData.dynamicFormData
    ) {
      DYNAMIC_FORM_DATA = patient_record.node.formData.dynamicFormData;
    }
  }

  const getDoctorSessionDetails = (session) => {
    if (selectedDoctor) {
      let selected_session = selectedDoctor.node.eventType.edges.find(
        (i) => i.node.id === session
      );

      if (selected_session) {
        return (
          <>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Location: {selected_session.node.eventLocation.title}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Type: {selected_session.node.eventType.name}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Duration: {selected_session.node.duration} Mins
            </Form.Control.Feedback>
          </>
        );
      }
    }
  };

  const handleDoctor = (event) => {
    formikRefReferral.current.setFieldValue("doctor", event.target.value);
    let act_doc = doctors_obj.doctors.edges.find(
      (i) => i.node.id === event.target.value
    );
    setSelectedDoctor(act_doc);
    formikRefReferral.current.setFieldValue("session", "");
    setSelectedSession(null);
    console.log("selectedSession", selectedSession);
  };

  const handleSession = (event) => {
    formikRefReferral.current.setFieldValue("session", event.target.value);
    let session_obj = selectedDoctor.node.eventType.edges.find(
      (i) => i.node.id === event.target.value
    );
    setSelectedSession(session_obj);
  };

  const getModalTitle = (modalType) => {
    if (modalType === "referral") {
      return "ADD REFERRAL";
    } else if (modalType === "intake_form") {
      return "ADD INTAKE FORM";
    } else if (modalType === "survey") {
      return "ADD SURVEY FORM";
    }
  };

  const referralForm = () => {
    return (
      <Formik
        onSubmit={onSubmitReferral}
        initialValues={initialValuesReferral}
        innerRef={formikRefReferral}
      >
        {({ handleSubmit, handleChange, values }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Row className="mb-5 mt-5">
              <Col xs={12} sm={12} md={12} className="mx-auto">
                <Form.Group as={Row}>
                  <Form.Label
                    column
                    sm={4}
                    md={3}
                    className=" text-right required "
                  >
                    Practitioner
                  </Form.Label>
                  <Col sm={8} md={8}>
                    <Form.Control
                      autoComplete="off"
                      as="select"
                      name="doctor"
                      value={values.doctor}
                      onChange={handleDoctor}
                      required
                    >
                      <option value="">Select Practitioner</option>
                      {doctors_obj.doctors &&
                        doctors_obj.doctors.edges.map((doctor) => {
                          return (
                            <option value={doctor.node.id} key={doctor.node.id}>
                              {doctor.node.firstName} {doctor.node.lastName}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label
                    column
                    sm={4}
                    md={3}
                    className="text-right required"
                  >
                    Practitioner Session
                  </Form.Label>
                  <Col sm={8} md={8}>
                    <Form.Control
                      autoComplete="off"
                      required
                      as="select"
                      name="session"
                      id="doctor-session"
                      value={values.session}
                      onChange={handleSession}
                    >
                      <option value="">Select Session</option>
                      {selectedDoctor
                        ? selectedDoctor.node.eventType.edges.map(
                            (eventtype) => {
                              return (
                                <option
                                  value={eventtype.node.id}
                                  key={eventtype.node.id}
                                >
                                  {eventtype.node.title.name}
                                </option>
                              );
                            }
                          )
                        : null}
                    </Form.Control>
                    {selectedDoctor && values.session
                      ? getDoctorSessionDetails(values.session)
                      : null}
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={4} md={3} className="text-right">
                    {"Description"}
                  </Form.Label>
                  <Col sm={8} md={8}>
                    <Form.Control
                      autoComplete="off"
                      as="textarea"
                      rows={5}
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>
                {/* <Form.Group as={Row} >
                  <Form.Label column sm={3} md={3} className="text-right required" >
                    Dateasdf
                  </Form.Label>
                  <Col sm={4} md={4}>
                    <Form.Control
                      autoComplete="off"
                      as="input"
                      type="number"
                      placeholder="Months"
                      name="months"
                      required
                      value={values.months}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col sm={4} md={4}>
                    <Form.Control
                      autoComplete="off"
                      as="input"
                      type="number"
                      placeholder="Weeks"
                      name="weeks"
                      required
                      value={values.weeks}
                      onChange={handleChange}
                    />
                  </Col> */}
                {/* <Col sm={8} md={8}>
                    <DatePicker
                      selected={values.recommended_date ? values.recommended_date : ''}
                      onChange={(info) => setActionDate(info, formikRefReferral, "recommended_date")}
                      name="recommended_date"
                      className="form-control "
                      showTimeSelect
                      required
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      autocomplete="off"
                      dateFormat="dd/MMM/yyyy - h:mm aa"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                  </Col> */}
                {/* </Form.Group> */}
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col
                xs={12}
                sm={12}
                md={12}
                className="d-flex flex-wrap justify-content-center justify-content-sm-between"
              >
                <Button
                  variant="primary"
                  size="md"
                  className="mb-1"
                  onClick={() => setAddReferralModal(false)}
                >
                  CANCEL
                </Button>
                <Button
                  variant="primary"
                  size="md"
                  className="ml-2 mr-2 mb-1"
                  type="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  };

  const onSubmitIntake = (values, { resetForm }) => {
    let val = {
      dynamicForm: values.dynamicForm,
      patient:
        patient_record && patient_record.node ? patient_record.node.id : null,
    };
    console.log("valvalval", val);

    createUpdateIntakeForm({ variables: val });
  };

  const addIntakeForm = () => {
    let initialValuesIntake = {
      dynamicForm: "",
      patient: patient_record.node.id,
    };
    let intake_forms =
      dynamic_forms_obj && dynamic_forms_obj.dynamicForms
        ? dynamic_forms_obj.dynamicForms.edges.filter(
            (i) => i.node.formType === "INTAKE_FORM"
          )
        : [];
    return (
      <Formik
        onSubmit={onSubmitIntake}
        initialValues={initialValuesIntake}
        innerRef={formikRefIntake}
      >
        {({ handleSubmit, handleChange, values }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Row className="mb-5 mt-5">
              <Col xs={12} sm={12} md={12} className="mx-auto">
                <Form.Group as={Row} className="text-right required">
                  <Form.Label column sm={4} md={3}>
                    Practitioner Session
                  </Form.Label>
                  <Col sm={8} md={8}>
                    <Form.Control
                      autoComplete="off"
                      required
                      as="select"
                      name="dynamicForm"
                      id="dynamicForm"
                      value={values.dynamicForm}
                      onChange={handleChange}
                    >
                      <option value="">Select Intake Form</option>
                      {intake_forms.length > 0
                        ? intake_forms.map((form) => {
                            return (
                              <option value={form.node.id} key={form.node.id}>
                                {form.node.name}
                              </option>
                            );
                          })
                        : null}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col
                xs={12}
                sm={12}
                md={12}
                className="d-flex flex-wrap justify-content-center justify-content-sm-between"
              >
                <Button
                  variant="primary"
                  size="md"
                  className="mb-1"
                  onClick={() => setAddReferralModal(false)}
                >
                  CANCEL
                </Button>
                <Button
                  variant="primary"
                  size="md"
                  className="ml-2 mr-2 mb-1"
                  type="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  };

  const onSubmitSurvey = (values, { resetForm }) => {
    let val = {
      dynamicForm: values.dynamicForm,
      patient:
        patient_record && patient_record.node ? patient_record.node.id : null,
    };

    createUpdateSurvey({ variables: val });
  };

  const addSurveyForm = () => {
    let initialValuesSurvey = {
      dynamicForm: "",
      patient: patient_record.node.id,
    };
    let intake_forms =
      dynamic_forms_obj && dynamic_forms_obj.dynamicForms
        ? dynamic_forms_obj.dynamicForms.edges.filter(
            (i) => i.node.formType === "SURVEY"
          )
        : [];
    return (
      <Formik onSubmit={onSubmitSurvey} initialValues={initialValuesSurvey}>
        {({ handleSubmit, handleChange, values }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Row className="mb-5 mt-5">
              <Col xs={12} sm={12} md={12} className="mx-auto">
                <Form.Group as={Row} className="text-right required">
                  <Form.Label column sm={4} md={3}>
                    Practitioner Session
                  </Form.Label>
                  <Col sm={8} md={8}>
                    <Form.Control
                      autoComplete="off"
                      required
                      as="select"
                      name="dynamicForm"
                      id="dynamicForm"
                      value={values.dynamicForm}
                      onChange={handleChange}
                    >
                      <option value="">Select Survey Form</option>
                      {intake_forms.length > 0
                        ? intake_forms.map((form) => {
                            return (
                              <option value={form.node.id} key={form.node.id}>
                                {form.node.name}
                              </option>
                            );
                          })
                        : null}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col
                xs={12}
                sm={12}
                md={12}
                className="d-flex flex-wrap justify-content-center justify-content-sm-between"
              >
                <Button
                  variant="primary"
                  size="md"
                  className="mb-1"
                  onClick={() => setAddReferralModal(false)}
                >
                  CANCEL
                </Button>
                <Button
                  variant="primary"
                  size="md"
                  className="ml-2 mr-2 mb-1"
                  type="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  };

  const getModalContents = (modalType) => {
    if (modalType === "referral") {
      return referralForm();
    } else if (modalType === "intake_form") {
      return addIntakeForm();
    } else if (modalType === "survey") {
      return addSurveyForm();
    }
  };

  const setEncounterNoteTooltip = (enc, notes, index, date) => {
    let child = (
      <Link
        to={{
          pathname: "/encounter/detail/" + enc.node.id,
          state: { vitalDataState },
        }}
        className="mb-0 encounter_item"
        key={index}
      >
        {date} {date ? "-" : ""} {enc.node.encounterType.name}
      </Link>
    );

    if (enc?.node?.notes?.edges[0]?.node?.text !== "+++" && enc?.node?.notes?.edges[0]?.node?.text) {
      return (
        <div>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                {notes.map((note, index) => {
                  return <div key={index}>{note.node.text}</div>;
                })}
              </Tooltip>
            }
          >
            {child}
          </OverlayTrigger>
        </div>
      );
    } else {
      return <div>{child}</div>;
    }
  };

  const upcomingAppointmentsArray =
    renderUpcomingAppointments(upcomingApointments);

  const getVitalsValues = (value) => {
    if (
      vitalDataState &&
      vitalDataState.edges &&
      vitalDataState.edges.length > 0
    ) {
      return vitalDataState.edges.map((vital, index) => {
        switch (value) {
          case "height":
            return (
              <td key={index} className="text-center">
                {vital.node.height}
              </td>
            );
          case "weight":
            return (
              <td key={index} className="text-center">
                {vital.node.weight}
              </td>
            );
          case "waistCircumference":
            return (
              <td key={index} className="text-center">
                {vital.node.waistCircumference}
              </td>
            );
          case "temperature":
            return (
              <td key={index} className="text-center">
                {vital.node.temperature}
              </td>
            );
          case "pulse":
            return (
              <td key={index} className="text-center">
                {vital.node.pulse}
              </td>
            );
          case "bp":
            return (
              <td key={index} className="text-center">
                {vital.node.bp}
              </td>
            );
          case "bmi":
            return (
              <td key={index} className="text-center">
                {vital.node.bmi}
              </td>
            );
          default:
            return null;
        }
      });
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (!addVitalsModal) {
      setIsEdit(false);
    }
  }, [addVitalsModal]);

  const renderVitals = () => {
    return (
      <Table striped bordered className="vitals-table">
        <thead>
          <tr>
            <th style={{ width: "160px" }}></th>
            {vitalDataState && vitalDataState.edges.length > 0
              ? vitalDataState.edges.map((vital, index) => {
                  return (
                    <th key={index} style={{ width: "160px" }}>
                      <div className="d-flex align-items-baseline justify-content-center">
                        {getDate(vital.node.created)}
                        <Button
                          variant="link"
                          className="py-0 px-0 ml-1 mr-0 d-inline"
                          onClick={() => editVital(vital)}
                        >
                          <i className="fa fa-edit"></i>
                        </Button>
                        <Button
                          variant="link"
                          className="py-0 px-0 ml-1 danger-color d-inline"
                          onClick={() => deleteVital(vital.node.id)}
                        >
                          <i className="fa fa-times"></i>
                        </Button>
                      </div>
                    </th>
                  );
                })
              : null}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Height</td>
            {getVitalsValues("height")}
          </tr>
          <tr>
            <td>Weight</td>
            {getVitalsValues("weight")}
          </tr>
          <tr>
            <td>Waist Circumference</td>
            {getVitalsValues("waistCircumference")}
          </tr>
          <tr>
            <td>Temperature</td>
            {getVitalsValues("temperature")}
          </tr>
          <tr>
            <td>Pulse</td>
            {getVitalsValues("pulse")}
          </tr>
          <tr>
            <td>BP</td>
            {getVitalsValues("bp")}
          </tr>
          <tr>
            <td>BMI</td>
            {getVitalsValues("bmi")}
          </tr>
        </tbody>
      </Table>
    );
  };

  useEffect(() => {
    if (patient_record?.node.formData) {
      let medicalInstancesToAdd = [];
      let medicalBlockStaticId =
        patient_record?.node.formData?.commonFormBlocks.dynamicFormBlocks.edges.find(
          (i) => i.node.formBlock.isMedication
        );
      if (medicalBlockStaticId?.node.formBlock.isMedication) {
        medicalBlockStaticId = medicalBlockStaticId?.node.formBlock.staticId;
        if (patient_record?.node.formData?.dynamicFormData) {
          let dynamicDatakeys = Object.keys(
            patient_record?.node.formData?.dynamicFormData
          );
          if (dynamicDatakeys?.length > 0) {
            let mainMedicalInstances = dynamicDatakeys.filter(
              (i) => i.indexOf("main_medication_") > -1
            );
            mainMedicalInstances.map((i) => {
              let enc_static_id = null;
              if (i.indexOf("main_medication") > -1) {
                enc_static_id = i.split("encounter_")[1];
                enc_static_id = enc_static_id.split("_block_")[0];
              }
              let inst = patient_record?.node.formData?.dynamicFormData[i];
              if (inst) {
                let new_form_block_instances = inst.form_block_instances.map(
                  (j) => {
                    let newObj = JSON.parse(JSON.stringify(j));
                    newObj["is_from_encounter"] = true;
                    newObj["enc_static_id"] = enc_static_id;
                    newObj["form_block_key"] = i;
                    return newObj;
                  }
                );
                medicalInstancesToAdd.push(...new_form_block_instances);
              }
            });
            if (medicalBlockStaticId) {
              let prevJsonData = patient_record?.node.formData?.data
                ? patient_record?.node.formData?.data
                : {};

              let jsonDataClone = JSON.parse(JSON.stringify(prevJsonData));
              let prevMedDataInstances = [];
              if (
                jsonDataClone.hasOwnProperty("block_" + medicalBlockStaticId)
              ) {
                prevMedDataInstances =
                  jsonDataClone["block_" + medicalBlockStaticId]
                    .form_block_instances;
              }
              if (medicalInstancesToAdd.length > 0) {
                prevMedDataInstances.push(...medicalInstancesToAdd);
              }
              if (
                jsonDataClone.hasOwnProperty("block_" + medicalBlockStaticId)
              ) {
                jsonDataClone["block_" + medicalBlockStaticId][
                  "form_block_instances"
                ] = prevMedDataInstances;
              } else {
                jsonDataClone["block_" + medicalBlockStaticId] = {
                  form_block_instances: prevMedDataInstances,
                };
              }
              let medicationFormBlock =
                patient_record.node.formData.commonFormBlocks.dynamicFormBlocks.edges.filter(
                  (i) => i.node.formBlock.isMedication
                );
              setJsonData(jsonDataClone);
              return;
            }
          }
        }
      }
      if (patient_record?.node.formData?.data) {
        setJsonData(patient_record?.node.formData?.data);
      }
    }
  }, [patient_record, COMMON_FORM_BLOCKS]);

  return (
    <Base
      title={
        patient_record && patient_record.node
          ? patient_record.node.firstName +
            " " +
            displayMiddleName(patient_record.node.middleName) +
            " " +
            patient_record.node.lastName +
            " Record"
          : "Patient Record"
      }
      showHeader={true}
      back="/patients"
      rightContainerClass={loading ? "page_loader_base" : ""}
      page_loading={loading}
      rightChild={
        patient_permission.includes("edit") ? (
          <Link to={"/edit/patient/" + identifier}>
            <Button variant="primary" onClick={setPatientInfo}>
              {" "}
              Edit Record {/* {patient_record?.node.id} */}
            </Button>
          </Link>
        ) : null
      }
    >
      {loading ? (
        <Preloader />
      ) : (
        <>
          {renderButtons()}
          <Row className="mt-5">
            <Col md={6} lg={4}>
              <h6 className="textGrey text-uppercase">
                <b> Basic Information </b>
              </h6>
              {loading ? <span> Loading...</span> : null}
              {patient_record ? (
                <Card>
                  <Card.Body>
                    <h6>
                      {" "}
                      Name:{" "}
                      {patient_record.node.firstName +
                        " " +
                        displayMiddleName(patient_record.node.middleName) +
                        " " +
                        patient_record.node.lastName}{" "}
                    </h6>
                    {patient_record.node.dob ? (
                      <h6> Age: {getPatientsAge(patient_record.node.dob)} </h6>
                    ) : null}
                    {patient_record.node.phone ? (
                      <h6> Phone: {patient_record.node.phone}</h6>
                    ) : null}
                    {patient_record.node.email ? (
                      <h6> Email: {patient_record.node.email}</h6>
                    ) : null}
                    {patient_record.node.gender ? (
                      <h6>
                        {" "}
                        Gender:{" "}
                        <span className="capitalize">
                          {" "}
                          {patient_record.node.gender.toLowerCase()}{" "}
                        </span>{" "}
                      </h6>
                    ) : null}
                    {patient_record.node.civilId ? (
                      <h6> Civil ID: {patient_record.node.civilId} </h6>
                    ) : null}
                    {patient_record.node.country &&
                    patient_record.node.country.name ? (
                      <h6> Country: {patient_record.node.country.name} </h6>
                    ) : null}
                    {patient_record.node.governorate &&
                    patient_record.node.governorate.name &&
                    patient_record.node.country &&
                    patient_record.node.country.code === "KW" ? (
                      <h6>
                        {" "}
                        Governorate: {patient_record.node.governorate.name}{" "}
                      </h6>
                    ) : null}
                    {patient_record.node.area &&
                    patient_record.node.area.name &&
                    patient_record.node.country &&
                    patient_record.node.country.code === "KW" ? (
                      <h6> Area: {patient_record.node.area.name} </h6>
                    ) : null}
                    {patient_record.node.address ? (
                      <h6> Address: {patient_record.node.address} </h6>
                    ) : null}
                    {patient_record.node.paymentContact ? (
                      <h6>
                        {" "}
                        Payment Contact: {patient_record.node.paymentContact}
                      </h6>
                    ) : (
                      assertNullableType
                    )}
                    {patient_record.node.paymentContactDetails ? (
                      <h6> Payment Contact Details: </h6>
                    ) : null}
                  </Card.Body>
                </Card>
              ) : null}
            </Col>
            <Col md={6} lg={4}>
              <h6 className="textGrey text-uppercase">
                <b> Upcoming Appointment </b>
              </h6>
              {upcomingApointments && upcomingApointments.length > 0 ? (
                <>
                  <div className="patient_app_container thinScrollBar">
                    {loading_upcoming ? (
                      <Preloader />
                    ) : upcomingAppointmentsArray.length === 0 ? (
                      "No Confirmed Upcoming Appointments"
                    ) : (
                      upcomingAppointmentsArray
                    )}
                    {(patient_record && patient_record.node) ||
                    totalCount > upcomingAppointmentsArray.length ? (
                      <Link
                        to={
                          "/patient/appointments/" +
                          patient_record.node.identifier
                        }
                      >
                        <Button variant="link" block className="back ">
                          View All Appointments [{totalCount}]
                        </Button>
                      </Link>
                    ) : null}
                  </div>
                </>
              ) : (
                "No Upcoming Confirmed Appointments"
              )}
            </Col>
            <Col md={6} lg={4} className="mr-auto">
              <h6 className="textGrey text-uppercase">
                <b> Referrals </b>
              </h6>
              {patient_record &&
              patient_record.node &&
              patient_record.node.referrals &&
              patient_record.node.referrals.edges.length > 0 &&
              referral_permissions &&
              referral_permissions.includes("view") ? (
                <>
                  <div>
                    {loading_upcoming ? (
                      <Preloader />
                    ) : (
                      renderReferrals(patient_record.node.referrals.edges)
                    )}
                    {/* <Link to={"/patients/referrals/" + patient_record.node.identifier} onClick={setPatientInfo}> */}
                    <Link
                      to={{
                        pathname:
                          "/patients/referrals/" +
                          patient_record.node.identifier,
                        state: { isClickedFromStaff: true },
                      }}
                      onClick={setPatientInfo}
                    >
                      {patient_record &&
                      patient_record.node &&
                      patient_record.node.referrals &&
                      patient_record.node.referrals.edges.length > 1 ? (
                        <Button variant="link" block className="back ">
                          View All Referrals [
                          {patient_record.node.referrals.edges.length}]
                        </Button>
                      ) : null}
                    </Link>
                  </div>
                </>
              ) : (
                "No Referrals"
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="d-flex align-items-baseline">
                <h6 className="textGrey text-uppercase">
                  <b>Vitals</b>
                </h6>
                <Button
                  variant=""
                  onClick={() => resetInitialValues()}
                  className="px-0 py-0"
                >
                  <i className="fa fa-plus-circle"></i>
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="overflow-auto">
            <Col>
              {vitalDataState && vitalDataState.edges.length > 0
                ? renderVitals()
                : "No vitals here yet"}
            </Col>
          </Row>
          <Row className="patient_record_forms my-4">
            {encounter_permission && encounter_permission.includes("view") ? (
              <Col md={6}>
                <div className="singleBlockContainer ">
                  <Row>
                    <Col
                      xs={7}
                      sm={7}
                      md={7}
                      className="text-left textBold textGrey singleBlockTitle col-form-label"
                    >
                      Encounters
                    </Col>
                    <Col
                      xs={5}
                      sm={5}
                      md={5}
                      className="d-flex justify-content-end align-items-center form_edit_buttons"
                    >
                      {encounter_permission &&
                      encounter_permission.includes("add") ? (
                        <Button
                          variant="link"
                          onClick={() => setAddEncounterModal(true)}
                        >
                          <i className="fa fa-plus-circle"></i>
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                  <div className="singleBlock ">
                    <div className="encounter_block thinScrollBar">
                      {encounter_permission &&
                      encounter_permission.includes("view") &&
                      patient_record &&
                      patient_record.node &&
                      patient_record.node.encounters.edges.length > 0
                        ? patient_record.node.encounters.edges.map(
                            (enc, index) => {
                              let date = enc.node.date
                                ? getDate(new Date(enc.node.date))
                                : "";
                              let notes = enc.node.notes.edges;
                              return (
                                <>
                                  {setEncounterNoteTooltip(
                                    enc,
                                    notes,
                                    index,
                                    date
                                  )}
                                </>
                              );
                            }
                          )
                        : "No Encounters"}
                    </div>
                  </div>
                </div>
              </Col>
            ) : null}
            {medical_record_permission &&
            medical_record_permission.includes("view") &&
            COMMON_FORM_BLOCKS &&
            COMMON_FORM_BLOCKS.length > 0
              ? COMMON_FORM_BLOCKS.map((blockNode, index) => {
                  let block = blockNode.node.formBlock;
                  let initialValues = {};
                  let forms = block.blockFormFields.edges;
                  forms.map((form) => {
                    let default_val = "";
                    if (form.node.formField.variant === "BOOLEAN") {
                      default_val = false;
                    }
                    return (initialValues[form.node.formField.fieldName] =
                      default_val);
                  });
                  let blockData = {
                    blockId: block.id,
                    title: block.name,
                    id: block.id,
                    initialValues: initialValues,
                    forms: forms,
                    staticId: block.staticId,
                    patientFormData: patient_record.node.formData.id,
                    json_data: jsonData,
                  };
                  return (
                    <Col md={6} key={index}>
                      <FormBlock
                        blockData={blockData}
                        is_patient_record={true}
                        DYNAMIC_FORM_DATA={DYNAMIC_FORM_DATA}
                        REQUEST_UPDATE={REQUEST_PATIENT}
                        REQUEST_PATIENT_UPDATE={REQUEST_PATIENT}
                        encounterIdList={encounterIdList}
                        medical_record_permission={medical_record_permission}
                      />
                    </Col>
                  );
                })
              : null}
          </Row>
        </>
      )}

      <CustomModals
        showModal={showModal}
        modalHeader={"LAB REQUEST"}
        setShowModal={setShowModal}
        dialogClassName="modal60h"
      >
        {patient_record && patient_record.node && patient_record.node.id ? (
          <LabsComponent
            patient_id={patient_record.node.id}
            setShowModal={setShowModal}
            showModal={showModal}
          />
        ) : null}
      </CustomModals>

      <CustomModals
        showModal={addVitalsModal}
        modalHeader={isEdit ? "EDIT VITALS" : "ADD VITALS"}
        setShowModal={setAddVitalsModal}
        dialogClassName="modal60h"
      >
        <Formik
          onSubmit={onSubmitVitals}
          initialValues={initialValuesForVital}
          innerRef={formikRefVitals}
        >
          {({ handleSubmit, handleChange, values }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Row className="mb-5 mt-5">
                <Col xs={12} sm={12} md={12}>
                  <Form.Group
                    as={Row}
                    className="justify-content-start align-items-center"
                  >
                    <Form.Label
                      column
                      sm={3}
                      md={3}
                      className="text-right textBold textGrey"
                    >
                      Height
                    </Form.Label>
                    <Col sm={6} md={7}>
                      <Form.Control
                        className="d-inline"
                        autoComplete="off"
                        as="input"
                        type="number"
                        name="height"
                        // required
                        value={values.height}
                        onChange={handleChange}
                      />
                    </Col>
                    <span>cm</span>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="justify-content-start align-items-center"
                  >
                    <Form.Label
                      column
                      sm={3}
                      md={3}
                      className="text-right textBold textGrey"
                    >
                      Weight
                    </Form.Label>
                    <Col sm={6} md={7}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        type="number"
                        name="weight"
                        // required
                        value={values.weight}
                        onChange={handleChange}
                      />
                    </Col>
                    <span>kg</span>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="justify-content-start align-items-center"
                  >
                    <Form.Label
                      column
                      sm={3}
                      md={3}
                      className="text-right textBold textGrey"
                    >
                      Waist Circumference
                    </Form.Label>
                    <Col sm={6} md={7}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        type="number"
                        name="waistCircumference"
                        // required
                        value={values.waistCircumference}
                        onChange={handleChange}
                      />
                    </Col>
                    <span>cm</span>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="justify-content-start align-items-center"
                  >
                    <Form.Label
                      column
                      sm={3}
                      md={3}
                      className="text-right textBold textGrey"
                    >
                      Temperature
                    </Form.Label>
                    <Col sm={6} md={7}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        type="number"
                        name="temperature"
                        // required
                        value={values.temperature}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="justify-content-start align-items-center"
                  >
                    <Form.Label
                      column
                      sm={3}
                      md={3}
                      className="text-right textBold textGrey"
                    >
                      BP
                    </Form.Label>
                    <Col sm={6} md={7}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        type="text"
                        name="bp"
                        // required
                        value={values.bp}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="justify-content-start align-items-center"
                  >
                    <Form.Label
                      column
                      sm={3}
                      md={3}
                      className="text-right textBold textGrey"
                    >
                      Pulse
                    </Form.Label>
                    <Col sm={6} md={7}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        type="text"
                        name="pulse"
                        // required
                        value={values.pulse}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  className="d-flex flex-wrap justify-content-center justify-content-sm-end"
                >
                  <Button
                    variant="primary"
                    size="md"
                    className="mb-1"
                    onClick={() => setAddVitalsModal(false)}
                  >
                    Cancel
                  </Button>
                  {isEdit ? (
                    <Button
                      variant="primary"
                      size="md"
                      className="ml-2 mr-2 mb-1"
                      type="submit"
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      size="md"
                      className="ml-2 mr-2 mb-1"
                      type="submit"
                    >
                      Add
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </CustomModals>

      <CustomModals
        showModal={addEncounterModal}
        modalHeader={"ADD ENCOUNTER"}
        setShowModal={setAddEncounterModal}
        dialogClassName="modal60h"
      >
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          innerRef={formikRef}
        >
          {({ handleSubmit, handleChange, values }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Row className="mb-5 mt-5">
                <Col xs={12} sm={12} md={12}>
                  <Form.Group as={Row} className="justify-content-start">
                    <Form.Label column sm={3} md={3} className="text-right">
                      Has Appointment
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Check
                        type="checkbox"
                        name="has_appointment"
                        value={values.has_appointment}
                        checked={values.has_appointment}
                        onChange={() =>
                          formikRef.current.setFieldValue(
                            "has_appointment",
                            !values.has_appointment
                          )
                        }
                      />
                    </Col>
                  </Form.Group>

                  {values.has_appointment ? (
                    // (patient_record && patient_record.node.appointment ? <Form.Group as={Row}>
                    encounter_events &&
                    encounter_events.events &&
                    encounter_events.events.edges ? (
                      <Form.Group as={Row}>
                        <Form.Label
                          column
                          sm={3}
                          md={3}
                          className="text-right required"
                        >
                          Appointment
                        </Form.Label>
                        <Col sm={8} md={8}>
                          <Form.Control
                            autoComplete="off"
                            as="select"
                            name="appointment"
                            onChange={handleChange}
                            required
                          >
                            <option value={""}> Select Appointment</option>
                            {/* {patient_record.node.appointment.edges.map((item, index) => {
                            return (
                              <option value={item.node.id} key={index}>
                                {item.node.location.title} - { getDate(new Date(item.node.start))} {getForamttedTime(new Date(item.node.start))}
                              </option>
                            );
                          })} */}
                            {encounter_events.events.edges.map(
                              (item, index) => {
                                return (
                                  <option value={item.node.id} key={index}>
                                    {item.node.location.title} -{" "}
                                    {getDate(new Date(item.node.start))}{" "}
                                    {getForamttedTime(
                                      new Date(item.node.start)
                                    )}
                                  </option>
                                );
                              }
                            )}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    ) : null
                  ) : (
                    renderEncounterForm(handleChange, values)
                  )}
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  className="d-flex flex-wrap justify-content-center justify-content-sm-between"
                >
                  <Button
                    variant="primary"
                    size="md"
                    className="mb-1"
                    onClick={() => setAddEncounterModal(false)}
                  >
                    CANCEL
                  </Button>
                  <Button
                    variant="primary"
                    size="md"
                    className="ml-2 mr-2 mb-1"
                    type="submit"
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </CustomModals>

      <CustomModals
        showModal={addReferralModal}
        modalHeader={getModalTitle(modalType)}
        setShowModal={setAddReferralModal}
        dialogClassName="modal60h"
      >
        {getModalContents(modalType)}
      </CustomModals>
    </Base>
  );
}
export default withRouter(PatientRecord);
