import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Table,Col } from 'react-bootstrap';
import { gql, useQuery } from '@apollo/client';
import Base from './base.js';
import Preloader from '../Common/Preloder/Preloader';
import {
  displayMiddleName
} from "../Common/helpers";

const DisplayIntakeForms = (props) => {
  const identifier = props.match.params.identifier;

  const REQUEST_INTAKE_FORMS = gql`
    query {
      intakeForms(patient_Identifier:"${identifier}") {
        edges {
          node {
            id
            created
            patient {
              firstName
              middleName
              lastName
              identifier
            }
            dynamicForm{
              name
              id
              formType
              formBlocks{
                edges{
                  node {
                    name
                    id
                    staticId
                    formFields{
                      edges{
                        node{
                          id
                          name
                          fieldName
                          variant
                          staticId
                          blockFormFields{
                            edges{
                              node{
                                formField{
                                  name                  
                                }
                                formBlock{
                                  id
                                }
                                required
                              }
                            }
                          }
                          defaultBooleanVariant
                          options{
                            edges{
                              node{
                                id
                                staticId                   
                                value
                                default
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            data
            submitted
          }
        }
      }
    }
  `;

  const { data, loading } = useQuery(REQUEST_INTAKE_FORMS, {
    onCompleted() {
      // console.log('intake forms', data);
    },
    onError: (e) => {
      // console.log('intake form error: ', e.errors);
    },
  });

  let firstName = data && data.intakeForms && data.intakeForms.edges.length > 0 ? data.intakeForms.edges[0].node.patient.firstName : 'Patient';
  let lastName = data && data.intakeForms && data.intakeForms.edges.length > 0 ? data.intakeForms.edges[0].node.patient.lastName : '';
  let middleName = data && data.intakeForms && data.intakeForms.edges.length > 0 ? displayMiddleName(data.intakeForms.edges[0].node.patient.middleName): '';

  return (
    <Base title={`${firstName} ${middleName} ${lastName} INTAKE FORM`} showHeader={true}>
      {loading ? (
        <Preloader />
      ) : (
        <Row>
          {data.intakeForms.edges && data.intakeForms.edges.length > 0 ? (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Submitted</th>
                    <th>Created</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {data.intakeForms.edges.map((intakeForm, index) => (
                    <tr key={index}>
                      <td className="align-middle patient-capitalize-first-letter">
                        {intakeForm.node.patient.firstName}{' '}
                        {displayMiddleName(intakeForm.node.patient.middleName)}
                        {intakeForm.node.patient.lastName}
                      </td>
                      <td>
                        {intakeForm.node.submitted
                          ? 'Submitted'
                          : 'Not submitted'}
                      </td>
                      <td>
                        {new Date(intakeForm.node.created).toLocaleDateString(
                          'en-GB'
                        )}
                      </td>
                      {intakeForm.node.submitted ? (
                        <td>
                          <Link
                            to={`/patient/intakeform/${intakeForm.node.id}`}
                          >
                            View
                          </Link>
                        </td>
                      ) : <td></td>}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <Col xs={12} md={6}> No Intake Forms for this patient</Col>
          )}
        </Row>
      )}
    </Base>
  );
};

export default withRouter(DisplayIntakeForms);
