import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export const getForamttedTime = (date) => {
  let typeDate = typeof date;
  if (typeDate === 'string') {
    date = new Date(date);
  }
  let hours = date.getHours();
  let suffix = hours >= 12 ? 'PM' : 'AM';
  let time =
    String(((hours + 11) % 12) + 1).padStart(2, '0') +
    ':' +
    String(date.getMinutes()).padStart(2, '0') +
    ' ' +
    suffix;
  return time;
};

export const getDate = (date) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  let today = new Date(date);
  let res = today.getDate();
  res += '/' + monthNames[today.getMonth()]?.toUpperCase();
  res += '/' + today.getFullYear();
  return res;
};


export const getDay = (index) => {
  let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[index];
};

export const sortByDate = (appointments) => {
  return appointments.slice().sort((a, b) => new Date(a.start) - new Date(b.start));
}

const CopyToClipBoardButton = (props) => {
  // This helper function expect textValue props
  // This copy the text to clipboard

  const copyIcon = <FontAwesomeIcon icon={faCopy} />
  const checkIcon = <FontAwesomeIcon icon={faCheckCircle} className="helpers-check-icon" />
  const failIcon = <FontAwesomeIcon icon={faTimesCircle} className="helpers-fail-icon" />
  const [copied, setCopied] = useState(copyIcon);
  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    try {
      await navigator.clipboard.writeText(props.textValue);
      setCopied(checkIcon);
      setLoading(false);
    } catch (err) {
      setCopied(failIcon);
      setLoading(false);
    }
  };



  return (
    // TODO: It's either we create button components or define our own classes
    <Button
      disabled={isLoading}
      onClick={!isLoading ? handleClick : null}
      size="sm"
      className=""
      variant={props.variant}
    >
      {copied}
    </Button>
  );
};


// Export reusable components
export { CopyToClipBoardButton };

export const success_options = {
  position: 'bottom-right',
  style: {
    backgroundColor: '#28A745',
    color: 'white',
    textAlign: 'center',
  },
};

export const error_options = {
  position: 'bottom-right',
  style: {
    backgroundColor: '#DC3546',
    color: 'white',
    textAlign: 'center',
  },
}
export const SNACK_DURATION = 2000;
export const PER_PAGE_COUNT = 20;
export const ERROR_MESSAGE = 'The system encountered an error. Please contact the administrator';

const actionFunc = (search_params, action, param) => {
  switch (action) {
    case "get":
      let a = search_params.get(param);
      let val = "";
      if (a) {
        val = a.toString();
      }
      return val;
    default:
      return null;
  }
}

export const urlActions = (url, action, param) => {
  var url1 = new URL(url);
  var query_string = url1 && url1.search ? url1.search : "";
  var search_params = new URLSearchParams(query_string);
  let actionResult = actionFunc(search_params, action, param);
  return actionResult;
}

export const paymentReason = {
  appointment: 'Appointment',
  report: 'Report',
  phone_call: 'Phone Call',
  others: 'Others',
}
export const getPaymentReasonLabel = (reason) => {
  return paymentReason[reason.toLowerCase()];
};

export const isSuperUserFunc = () => {
  let userDetails = localStorage.getItem('user');
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }
  var IS_SUPERUSER = false;
  IS_SUPERUSER = userDetails && userDetails.isSuperuser ? userDetails.isSuperuser : false;
  return IS_SUPERUSER;
}

export const getPermissionForAction = (tab, action, returnAll, all_user_permissions) => {
  var user_permissions = all_user_permissions ? all_user_permissions : localStorage.getItem('user_permissions');
  user_permissions = JSON.parse(user_permissions);
  const PERMISSIONS = user_permissions;
  var IS_SUPERUSER = isSuperUserFunc();

  // console.log("PERMISSIONS",PERMISSIONS);
  // IF USER IS SUPERUSER THEN ALWAYS return permissions for all actions if requested for list of permissions
  if (IS_SUPERUSER && returnAll) {
    return ["approved", "add", "delete", "approve", "view", "list", "edit"]
  }
  else if (IS_SUPERUSER) {
    return true; // IF USER IS SUPERUSER THEN ALWAYS return permissions true for action requested for any Tab
  }
  // IF USER is not superuser and requests to return all permissions for specific tab 
  if (returnAll && PERMISSIONS && PERMISSIONS[tab]) {
    return PERMISSIONS[tab];
  }
  else if (returnAll) {
    return [];
  }

  // IF USER is not superuser and requests to return  permissions for specific tab for specific action
  if (PERMISSIONS && PERMISSIONS[tab] && PERMISSIONS[tab].length > 0) {
    if (PERMISSIONS[tab].includes(action)) {
      return true;
    }
    return false;
  }
  return false;
}


export const createSlug = (text) => {
  let slugText = text.toString().toLowerCase().replace(/\s+/g, '-');
  return slugText;
};


export const isUserStaff = () => {
  if (localStorage.getItem('is_staff') === 'true') {
    return true;
  }
  else if (localStorage.getItem('is_staff') === 'false') {
    return false;
  }
}

export const daysInMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
  // Example July
  // daysInMonth(7,2009); // 31
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getPatientsAge = (patientDob) => {
  let now = new Date();
  let todayToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  let dobYear = new Date(patientDob).getFullYear()
  let dobMonth = new Date(patientDob).getMonth()
  let dobDay = new Date(patientDob).getDate()
  let dob = new Date(dobYear, dobMonth, dobDay)
  let dobnow = new Date(todayToday.getFullYear(), dob.getMonth(), dob.getDate());
  let age = todayToday.getFullYear() - dob.getFullYear();

  return todayToday < dobnow ? age - 1 : age;
}

export const getValidDateStringForGraphQL = (value) => {
  return value.getFullYear() + "-" + String((value.getMonth() + 1)).padStart(2, '0') + "-" + String(value.getDate()).padStart(2, '0')
}
export const removeEmpty = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => (v !== "" && v !== undefined && v !== null)));
}
export const generateUID = () => {
  //  generate the UID from two parts here 
  // to ensure the random number provide enough bits.
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-3);
  secondPart = ("000" + secondPart.toString(36)).slice(-3);
  return firstPart + secondPart;
}

export const replaceUnderscoreAndDashBySpace = (string) => {
  return string.replace(/[-_]/g, ' ');
}

export const getTotalRemaining = (paymentSources, price) => {
  let total = 0;
  if (paymentSources) {
    paymentSources.filter((paidSource) => {
      return paidSource.node.status === 'Paid'
    }).forEach((item, index) => {
      total += parseFloat(item.node.amountAllocated);
    })
  }
  // const paidSourcesArr = paymentSources ? paymentSources.filter((paidSource, index) => {
  //   return paidSource.node.status === 'Paid'
  // }).forEach((item, index) => {
  //   total += parseFloat(item.node.amountAllocated);
  // }) : null;

  return [total.toFixed(3), (price - total).toFixed(3)];
};

export const displayMiddleName=(middleName)=>{
  if(middleName){
    return middleName+" ";
  }
  return ""
}
// export const appColors = {
//   banana: '#fbd75d',
//   basil: '#51b749',
//   blueberry: '#5484ed',
//   flamingo: '#ff887c',
//   grape: '#dbadff',
//   graphite: '#e1e1e1',
//   lavender: '#a4bdfc',
//   peacock: '#46d6db',
//   sage: '#7ae7bf',
//   tangerine: '#ffb878',
//   tomato: '#dc2127',
// }