import { Row, Col,  Button, Form } from 'react-bootstrap';
import React, { useState, useEffect }  from 'react';
import Base from './base.js';
import {   withRouter, Link } from 'react-router-dom';
import { gql, useQuery,  } from '@apollo/client';
import { error_options,  SNACK_DURATION, ERROR_MESSAGE, getPermissionForAction, isUserStaff,  getDate, isSuperUserFunc, getForamttedTime,displayMiddleName } from '../Common/helpers';
import { useSnackbar } from 'react-simple-snackbar';
import Logo from '../assets/images/logo.png';
import Preloader from '../Common/Preloder/Preloader';
import Stamp from 'component/Stamp/Stamp';
import { useTranslation } from 'react-i18next';
var today = new Date();
today = getDate(today) + " " + getForamttedTime(today);


function RequestReportDetail(props) {
  const patient_record_id = props.match.params.id;
  console.log("patient_record_id", patient_record_id)
  const [openSnackbar] = useSnackbar(error_options);
  const isStaff = isUserStaff();
  const isSuperUser = isSuperUserFunc();
  let returnAllSectionPermissions = true;
  const patient_report_permission = getPermissionForAction('patient_report', '', returnAllSectionPermissions);
  const {t, i18n} = useTranslation();

  const [overrideName, setOverrideName] = useState('')

  const REQUEST_PATIENT_REPORTS = gql`
  query {
    patientReport(id:"${patient_record_id}"){

            id
            status
            reason
            created
            clinicalSummary
            treatmentRecommendation
            notes
            daignosis
            patient{
              firstName
              middleName
              lastName
              phone
              civilId
              id
              dob
              displayGender
              identifier
            }
            reportType{
              id
              name
              price
              description
            }
            languageEn
            doctor{
              id
              firstName
              lastName
            }
        }
  }
`;




  // GET REQUEST_PATIENT_REPORTS DATA
  const { data: patient_reports_obj = null, loading } = useQuery(
    REQUEST_PATIENT_REPORTS,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  let languageEn = patient_reports_obj?.patientReport?.languageEn
  console.log(languageEn);



  const printMedicalReport = () => {
    window.print();
  }

  const getButtons = () => {
    return (
      <div className="d-flex">
        <Button variant="primary" className='back' onClick={printMedicalReport} >
          {t("patientPaymentDetails.print")}{' '}
        </Button>
      </div>
    );
  };

  let title = languageEn ? 'Clinical Report' : 'تقرير اكلينيكي'
  let reportBy = languageEn ? 'Report prepared by' : 'تم تحظير هذا التقرير من قبل' 

  let nameTitle = languageEn ? 'Patient Name' : 'الاسم'
  let civilIdTitle = languageEn ? 'ID Number' : 'رقم الهوية'
  let phoneTitle = languageEn ? 'Phone' : 'الهاتف'

  let clinicalSummaryTitle = languageEn ? 'Clinical Summary' : 'الملخص ‏الإكلينيكي'
  let diagnosisTitle = languageEn ? 'Diagnosis' : 'التشخيص'
  let treatmentRecommendationTitle = languageEn ? 'Treatment Recommendations' : 'الخطة العلاجية'
  let notesTitle = languageEn ? 'Notes' : 'ملاحظات'

  let doctorLine1 = languageEn ? 'Mohammad Alsuwaidan MD, MPH, FRCPC, DipABPN' : 'الدكتور محمد السویدان'
  let doctorLine2 = languageEn ? 'Consultant Psychiatrist' : 'استشاري طب نفسي  '
  let doctorLine3 = languageEn ? 'Founder & Clinical Director - Alsuwaidan Clinic / MindWell' : 'المؤسس والمدير الطبي   '

  let languageClass = !languageEn ? 'arabic' : 'english'

  const getOverrideName = () => {
    let result;
    if (overrideName == '' || overrideName == ' ' || overrideName.trim() == '') {
      return;
    } else {
      result = overrideName;
    }
    return result;
  }
  

  const renderReportDetail = () => {
    return (
      <>
        {patient_reports_obj && patient_reports_obj.patientReport ? 
        <>
          <Row className={`category_box ${languageClass}`}>
          {/* <Row className="category_box"> */}
            <Col xs={12} sm={6} md={6}>
              {/* <div className='d-flex align-items-baseline'> */}
              <div>
                <h6 className="capitalize mr-1">
                  {nameTitle}:
                  <Link
                    className="d-inline-block ml-1 patient-name-print-mode-link"
                    to={`/patient/record/${patient_reports_obj.patientReport.patient.identifier}`}
                  >
                    <Button variant="link" className="p-0 patient-name-print-mode-btn">
                      {getOverrideName() ? getOverrideName() : 
                        patient_reports_obj.patientReport.patient.firstName + ' ' +
                        displayMiddleName(patient_reports_obj.patientReport.patient.middleName) + ' ' +
                        patient_reports_obj.patientReport.patient.lastName}
                    </Button>
                  </Link>
                </h6>
                <div className='d-flex align-items-baseline justify-items-space-between'>
                  {
                    isStaff?
                  <Form.Group className="text-right d-print-none mr-1 flex-grow-1">
                    <Form.Control
                      className='mr-1'
                      autoComplete="nope"
                      type="text"
                      onChange={(e) => setOverrideName(e.target.value)}
                      value={overrideName}
                      placeholder='Override Name'
                    />
                  </Form.Group>
                  :null
                  }
                </div>
              </div>
              <h6> {civilIdTitle}: {patient_reports_obj.patientReport.patient.civilId}</h6>
              <h6> {phoneTitle}: <div className="phone-direction">{patient_reports_obj.patientReport.patient.phone}</div></h6>
              
            </Col>
          </Row> 
          <Row className={`mb-3 ${languageClass}`}>
          {/* <Row className='mb-3'> */}
            <Col xs={12} sm={6} md={6}>
              <div className='mt-4'>
                <h6 className='mb-1'><b>{clinicalSummaryTitle}:</b></h6>
                {patient_reports_obj.patientReport.clinicalSummary}
              </div>
              <div className='mt-3'>
                <h6 className='mb-1'><b>{diagnosisTitle}:</b></h6>
                {patient_reports_obj.patientReport.daignosis}
              </div>
              <div className='mt-3'>
                <h6 className='mb-1'><b>{treatmentRecommendationTitle}:</b></h6>
                {patient_reports_obj.patientReport.treatmentRecommendation}
              </div>
              <div className='mt-3'>
                <h6 className='mb-1'><b>{notesTitle}:</b></h6>
                {patient_reports_obj.patientReport.notes}
              </div>
            </Col>
          </Row>
          <Row className={`${languageClass}`}>
            <Col>
              <div className='mt-3 print'>
                <h6 className='mb-1'><b>{reportBy}:</b></h6>
                {patient_reports_obj.patientReport.doctor.firstName} {patient_reports_obj.patientReport.doctor.lastName}
              </div>
            </Col>
          </Row>
        </>
        : null}
      </>
    )
  }

  const PrintReportDetailComponent = () => {
    return (
      <div className={`print prescriptions-print-page ${languageClass}`}>
        <div className="lab-request-header" style={{ height: 160 + 'px'}}>
          <Row className={`h100 ${languageClass}`}>
            <Col
              xs={6}
              sm={6}
              md={6}
              className="d-flex justify-content-start align-items-center"
            >
              <img alt={'Logo'} className="img-fluid printLogo" src={Logo} />
              <h4 className='ml-4'>{title}</h4>
            </Col>
            <Col
              xs={6}
              sm={6}
              md={6}
              className="d-flex justify-content-end align-items-center"
            >
              {today}
            </Col>
          </Row>
        </div>
        <div className="page-footer">
          Al Tijaria Tower - Floor 23 - Alsoor Street - Kuwait City, Telephone -
          +965 22055334
        </div>
        <table>
          <thead>
            <tr>
              <td>
                <div className="page-header-space" style={{ height: 160 + 'px'}}></div>
              </td>
            </tr>
          </thead>

          <tbody>{renderReportDetail()}</tbody>

          <tfoot>
            <tr>
              <td>
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
        <div className={`${languageClass}`}>
          <Stamp />
        </div>
          <div className={`${languageClass}`}>
            {doctorLine1}
          </div>
          <div className={`${languageClass}`}>
            {doctorLine2}
          </div>
          <div className={`${languageClass}`}>
            {doctorLine3}
          </div>
      </div>
    )
  }
  
  
  return (
    <>
      <Base title={t('Clinical Report Request')} isPatientPortal={!isStaff} 
      showHeader={true} 
      // rightChild={(isSuperUser && patient_report_permission && patient_report_permission.includes("view"))
      //  || isStaff ? getButtons() : null}
      rightChild={getButtons()}
       >

        {loading ? (
          <Preloader />
        ) : (
          <Row className="d-print-none">
            <Col md={12}>{renderReportDetail()}</Col>
          </Row>
        )}
      </Base>

      <PrintReportDetailComponent />
      
    </>
  );
}

export default withRouter(RequestReportDetail);