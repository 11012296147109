import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Table, Button } from 'react-bootstrap';
import Base from './base.js';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import Preloader from '../Common/Preloder/Preloader';
import { useSnackbar } from 'react-simple-snackbar';
import {  Link } from 'react-router-dom';
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getForamttedTime,
  getDate,
  displayMiddleName
} from '../Common/helpers.js';



export default function WaitingList() {
  const [openSnackbar] = useSnackbar(error_options);
  const [activeDetailID, setActiveDetailID] = useState(null);
  
  var waiting_list = [];

  const WAITING_LISTS = gql`
    query {
      listWaitinglist {
        edges {
          node {
            id
            name
            priority
            patients {
              totalCount
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `;
  const WAITING_DETAIL = gql`
    query waitingDetail($id: ID!) {
      waitinglist(id: $id) {
        id
        name
        priority
        patients {
          edges {
            node {
              id
              patient {
                firstName
                middleName
                lastName
                identifier
              }
              event {
                start
                description
                duration
                id
                shouldBeSeen
                doctor{
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  `;

  const DELETE_PATIENT_FROM_WAITING_LIST = gql`
    mutation deleteWaitinglistRecord($id: ID!){
      deleteWaitinglistRecord(id: $id){
        deleted
      }
    }
  `;

  useEffect(() => {
    if (waiting_list && waiting_list.length > 0 && activeDetailID === null) {
      let default_id = waiting_list.find((i) => i.node.patients.totalCount > 0);
      if (default_id && default_id.node.id) {
        setActiveDetailID(default_id.node.id);
        getWaitingListDetail({ variables: { id: default_id.node.id } });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waiting_list, activeDetailID]);

  const { data: waitingListObj = null, loading: listLoading } = useQuery(WAITING_LISTS, {
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
    fetchPolicy: 'network-only',
  });
  if (waitingListObj) {
    waiting_list = waitingListObj.listWaitinglist.edges;
  }

  const [getWaitingListDetail, { data: waitingDetail, loading: detailLoading }] = useLazyQuery(
    WAITING_DETAIL,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  const [deleteWaitinglistRecord] = useMutation(DELETE_PATIENT_FROM_WAITING_LIST, {
    onCompleted: ({ deleteWaitingListRecord }) => {
      console.log('deleteWaitingListRecord! ', deleteWaitingListRecord);
    },
    refetchQueries: [
      { query: WAITING_LISTS, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const handleSelect = (item) => {
    getWaitingListDetail({ variables: { id: item } });
  };

  const deleteFromWaiting = (id) => {
    let val = {};
    val["id"] = id;
    window.confirm('Are you sure you want to delete patient from waiting list?') &&
    deleteWaitinglistRecord({ variables: val });
  }
  

  const renderDetailTable = (waitingDetail) => {
    let patients =
      waitingDetail && waitingDetail.waitinglist ? waitingDetail.waitinglist.patients : [];
    if (detailLoading) {
      return <Preloader />;
    }
    if (patients.edges && patients.edges.length > 0) {
      return (
        <Table
          responsive
          className="table table-borderless waiting_table text-xs-left"
          style={{ minWidth: '450px' }}>
          <thead>
            <tr>
              {/* <th scope="col">#</th>
              <th scope="col">Patients Name</th>
              <th scope="col" style={{paddingLeft: 20}}>Next Appointment</th>
              <th scope="col">Duration</th> */}
              <th scope="col">#</th>
              <th scope="col">Patients Name</th>
              <th scope="col">File Number</th>
              <th scope="col">Practitioner </th>
              <th scope="col">Next Appointment</th>
              <th scope="col">Should be seen</th>
              <th scope="col">Duration</th>
              <th scope="col">Note</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>        
            {patients.edges.map((patientobj, index) => {
              let next_app = new Date(patientobj.node.event.start);
              let should_be_seen =patientobj.node.event.shouldBeSeen? new Date(patientobj.node.event.shouldBeSeen):null;
              if (patientobj) {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>
                  <Link to={`/patient/record/${patientobj.node.patient.identifier}`}>
                    {patientobj.node.patient.firstName} {displayMiddleName(patientobj.node.patient.middleName)}{patientobj.node.patient.lastName}
                   </Link>
                  </td>
                  <td>
                  <Link to={`/patient/record/${patientobj.node.patient.identifier}`}>
                  {patientobj.node.patient.identifier}
                   </Link>
                  </td>
                  <td>
                  <Link to={`/patient/record/${patientobj.node.patient.identifier}`}>
                    {patientobj.node.event.doctor.firstName}   {patientobj.node.event.doctor.lastName}
                   </Link>
                  </td>
                  <td>                 
                  <Link to={`/appointment/detail/${patientobj.node.event.id}`}>
                    <span className="mx-lg-2 textGrey">{getDate(next_app)}</span>{' '}
                    <span className="mx-lg-2 textGrey">{getForamttedTime(next_app)}</span>
                    </Link>
                  </td>
                  <td>                 
                  {should_be_seen?<Link to={`/appointment/detail/${patientobj.node.event.id}`}>
                    <span className="mx-lg-2 textGrey">{getDate(should_be_seen)}</span>{' '}
                    <span className="mx-lg-2 textGrey">{getForamttedTime(should_be_seen)}</span>
                    </Link>:"-"}
                  </td>
                  <td className="textGrey">{patientobj.node.event.duration} Mins</td>
                  <td className="textGrey">{patientobj.node.event.description} </td>
                  <td>
                    <Button variant="link" className="p-0" onClick={() => deleteFromWaiting(patientobj.node.id)}>
                      <i className="fa fa-times-circle wating-list-icon"></i>
                    </Button>
                  </td>
                </tr>
              ); 
              }
              else{
                return null;
              }
            }) 
          }        
          </tbody>
      </Table>
      );
    } else {
      return (
        <div className='textGrey mt-2'>No patients under waiting list.</div>
      )
    }
  };

  let waitingExists = waiting_list.find((i) => i.node.patients.totalCount > 0);
  return (
    <Base title={'Waiting List'} showHeader={true}>
      {listLoading ? <Preloader /> : null}
      {waitingExists ? (
          <Tabs
            defaultActiveKey={waitingExists.node.id}
            className="d-flex justify-content-sm-center"
            transition={false}
            id="noanim-tab-example"
            onSelect={handleSelect}>
            {waiting_list.map((item, index) => {
              if (item.node.patients.totalCount >= 0) {
                return (
                  <Tab
                    eventKey={item.node.id}
                    key={index}
                    title={
                      <div className="textPrimary waitingHighlights">
                        <span> {item.node.name} </span>
                        <span>
                          <b>{item.node.patients.totalCount} </b>
                        </span>
                      </div>
                    }>
                    {waitingDetail && item.node.patients.totalCount > 0 ? renderDetailTable(waitingDetail) : 
                      <div className='textGrey mt-2'>No patients under waiting list.</div>
                    }
                  </Tab>
                );
              } else {
                return null;
              }
            })}
          </Tabs>
      ) : (
        <div className='textGrey mt-2'>No patients under waiting list.</div>
      )}
    </Base>
  );
}
