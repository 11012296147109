import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";

function CountrySelect({ ...props }) {
  const [number, setNumber] = React.useState(null);
  const [countryCode, setCountryCode] = React.useState('99');

  React.useEffect(() => {
    setCountryCode(props.value.slice(1, 4));
    setNumber(props.value);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const rootChange = (value, data) => {
    props.onChange(value);
    if (data.dialCode !== countryCode) {
      setNumber(data.dialCode);
    }
  };

  return (
    <ReactPhoneInput
      {...props}
      country={'kw'}
      excludeCountries={['ca', 'us', 'ru', 'pr', 'do', 'kz']}
      value={number ? number : props.value}
      onChange={rootChange}
      inputExtraProps={{
        required: props.required,
        name: 'countrySelect',
        autoComplete: 'off',
      }}
      countryCodeEditable={false}
      enableSearchField={true}
    />
  );
}

export default CountrySelect;
