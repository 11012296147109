import React from 'react';
import { Col, Row, Button, Container } from 'react-bootstrap';
import Logo from '../assets/images/logo.png';
import { withRouter } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  urlActions,
  success_options
} from '../Common/helpers';

const VerifyAccount = (props) => {
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const VERIFY_ACCOUNT = gql`
    mutation verifyAccount($token:String!) {
      verifyAccount(
        input: {
          token:$token
        }
      ) {
          success
          errors
      }
    }
  `;

  // CREATE ENCOUNTER
  const [verifyAccount] = useMutation(VERIFY_ACCOUNT, {
    onCompleted: ({ verifyAccount }) => {
      console.log("verifyAccount",verifyAccount);
      if (verifyAccount.errors && verifyAccount.errors.nonFieldErrors && verifyAccount.errors.nonFieldErrors.length > 0) {
        let error_messages = verifyAccount.errors.nonFieldErrors;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error.message, [SNACK_DURATION]);
        }
      } else if (verifyAccount.success) {
        openSnackbarSuccess("Account Verified")
        props.history.push("/");
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });


  const verifyAccountFunc = () => {
    let verify_token = urlActions(window.location.href, 'get', 'verify_token');
    let val={
      token:verify_token
    }
    verifyAccount({variables:val});
    // if (verify_token) {
    //   client.mutate({
    //     mutation: gql`
    //     mutation verifyAccount{
    //       verifyAccount(
    //         input: {
    //           token:"${verify_token}"
    //         }
    //       ) {
    //           success
    //           errors
    //       }
    //     }`,
        
    //     update: (store ,{ data: { verifyAccount }}) => {
    //       console.log("verifyAccount",verifyAccount)
    //       // if (verifyAccount && verifyAccount.errors && verifyAccount.errors.length > 0) {
    //       //   let error_messages = verifyAccount.errors[0].messages;
    //       //   for (let i in error_messages) {
    //       //     let error = error_messages[i];
    //       //     openSnackbar(error, [SNACK_DURATION]);
    //       //   }
    //       // } else if (verifyAccount && verifyAccount.success) {
    //       //   openSnackbarSuccess("Account Verified")
    //       //   props.history.push("/");
    //       // }
    //     },
    //     onError: (error) => {
    //       console.log("error",error)
    //       openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    //     },
    //   })
    // }
  }
  return (

    <Container fluid>
      <Row className={"login_page varifyPage"}>
        <Col md="12" className={"login_page__logo d-flex flex-column align-items-center"}>
          <div className={"logotype navLogoColor"}>
            <img
              alt={"Logo"}
              className="img-fluid navLogo "
              src={Logo}
            />
          </div>
          <Row className="justify-content-center my-5">
            <Col xs={12} sm={12} md={12} >
              <Button variant="primary" block size="md" className="mr-5 btn-pg-low" onClick={verifyAccountFunc} >
                VERIFY ACCOUNT
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(VerifyAccount);
