
import React, { useState, useRef, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import CreateEventFormHoc from '../component/AppointmentDetails/CreateEventForm/CreateEventFormHoc';
import CalendarHoc from '../component/AppointmentDetails/Calendar/CalendarHoc';
import Base from './base.js';
import { gql, useLazyQuery } from '@apollo/client';
import { error_options, SNACK_DURATION, ERROR_MESSAGE, isUserStaff } from '../Common/helpers';
import { useSnackbar } from 'react-simple-snackbar';
import { eventsDataVar, calendarActiveMonthVar} from '../cache/cache.js';
import { useTranslation } from "react-i18next";


export default function AppointmentCreate() {
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [doctorLeavesList, setDoctorLeavesList] = useState(null);
  const fullCalendarRef = useRef();
  const [openSnackbar] = useSnackbar(error_options);
  const isStaff = isUserStaff();
  const { t } = useTranslation();

  const REQUEST_EVENTS = gql`
  query($doctor_identifier: String!, $start: DateTime!, $end: DateTime!) {
    events(doctor_Identifier: $doctor_identifier, start: $start, end: $end, active:true) {
      edges {
        node {
          title
          id
          status
          start
          end
          holdWaitingReplacement
          onlineLink
          doctor {
            id
            firstName
            identifier
            lastName
            eventType {
              edges {
                node {
                  id
                  duration
                  buffer
                }
              }
            }
          }
          doctorEventType{
            duration
            buffer
          }
          patient {
            id
            firstName
            middleName
            lastName
          }
        }
      }
    }
  }
`;


  const REQUEST_PLAIN_EVENTS = gql`
  query($doctor_identifier: String!, $start: DateTime!, $end: DateTime!) {
    plainEvents(doctor_Identifier: $doctor_identifier, start: $start, end: $end, active:true) {
      edges {
        node {
          start
          end
          id
          status
          doctorEventType{
            duration
            buffer
          }
          doctor {
            id
            firstName
            identifier
            lastName
            eventType {
              edges {
                node {
                  id
                  duration
                  buffer
                }
              }
            }
          }
        }
      }
    }
  }
`;

  var EVENT_REQUEST_QUERY = isStaff ? REQUEST_EVENTS : REQUEST_PLAIN_EVENTS;



  useEffect(() => {
    return function cleanup() {
      calendarActiveMonthVar(null);
    };
  }, []);


  const [GetEvents, { error, data: eventDisplay = null, variables }] = useLazyQuery(EVENT_REQUEST_QUERY, {
    fetchPolicy: "network-only",
    // pollInterval: 10000,
    onCompleted: (data) => {
      var events = [];
      if (isStaff && data && data.events && data.events.edges) {
        events = data.events.edges.map((evt) => {
          return evt.node;
        });
      }
      else if (!isStaff && data && data.plainEvents && data.plainEvents.edges) {
        events = data.plainEvents.edges.map((evt) => {
          return evt.node;
        });
      }
      eventsDataVar(events);

    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });
  return (
    <Base title={t('patientAppointmentCreate.appointmentDetails')} showHeader={true} isPatientPortal={!isStaff}>
      <Row>
        <Col xs={12} sm={12} md={4} className="mb-4">
          <CreateEventFormHoc
            setSelectedDoctor={setSelectedDoctor}
            selectedDoctor={selectedDoctor}
            setDoctorLeavesList={setDoctorLeavesList}
            doctorLeavesList={doctorLeavesList}
            fullCalendarRef={fullCalendarRef}
            setSelectedSession={setSelectedSession}
            selectedSession={selectedSession}
            GetEvents={GetEvents}
          />
        </Col>
        <Col xs={12} sm={12} md={8} className='mb-2'>
          <CalendarHoc
            selectedDoctor={selectedDoctor}
            doctorLeavesList={doctorLeavesList}
            fullCalendarRef={fullCalendarRef}
            selectedSession={selectedSession}
            GetEvents={GetEvents}
            eventDisplay={eventDisplay}
            error={error}
          />
        </Col>
      </Row>
    </Base>
  )
}