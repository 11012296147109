import React from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import Base from './base';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSmile} from "@fortawesome/free-solid-svg-icons";
import {
  // faRedo,
  // faClipboard,
  // faCalendar,
  faSmile,
  // faHandHoldingMedical,
  // faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  getDate,
  // getForamttedTime,
  // getPermissionForAction,
  // getPatientsAge,
} from '../Common/helpers';
// import { currentPageDataVar } from '../cache/cache.js';
import { useSnackbar } from 'react-simple-snackbar';
import { gql, useQuery } from '@apollo/client';
import Preloader from '../Common/Preloder/Preloader';
import { useTranslation } from "react-i18next";

const SurveyFormsList = () => {
  const [openSnackbar] = useSnackbar(error_options);
  const { t } = useTranslation();

  const REQUEST_SURVEY_FORMS = gql`
    query {
      surveys {
        edges {
          node {
            id
            created
            patient {
              firstName
              middleName
              lastName
            }
            data
            dynamicForm {
              name
              id
              formType
              formBlocks {
                edges {
                  node {
                    name
                    id
                    staticId
                    formFields {
                      edges {
                        node {
                          id
                          name
                          fieldName
                          variant
                          staticId
                          blockFormFields {
                            edges {
                              node {
                                formField {
                                  name
                                }
                                formBlock {
                                  id
                                }
                                required
                              }
                            }
                          }
                          defaultBooleanVariant
                          options {
                            edges {
                              node {
                                id
                                staticId
                                value
                                default
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { data: survey_forms_obj, loading: loadingSurvey } = useQuery(
    REQUEST_SURVEY_FORMS,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    }
  );

  const renderSurveyForms = () => {
    let finalSurveyFormsList = [];

    let survey_forms =
      survey_forms_obj &&
      survey_forms_obj.surveys &&
      survey_forms_obj.surveys.edges.length > 0
        ? survey_forms_obj.surveys.edges
        : [];

    let survey_forms_elem = survey_forms.map((form, index) => {
      return {
        link: '/patientPortal/surveyform/' + form.node.id,
        // onClickFunc: saveFormInfo,
        title: form.node.dynamicForm.name,
        passData: form,
        subTitle:  t('survey.subtitle'),
        buttonText: t('survey.fillForm'),
        icon: faSmile,
        backgroundClass: 'green',
      };
    });

    finalSurveyFormsList.push(...survey_forms_elem);

    return finalSurveyFormsList.map((item, index) => (
      <Col xs={12} sm={6} md={6} lg={4} key={index}>
        <Card>
          <Card.Body className="p-4">
          <div className="mb-2 text-uppercase"> {item.title}</div>
          <div className='textGrey'>
            <b>{getDate(item.passData.node.created)}</b>{' '}
            {/* <b>{getForamttedTime(new Date(item.passData.node.created))}</b> */}
          </div>
          <Link
            to={item.link}
            onClick={() =>
              item.onClickFunc && item.passData
                ? item.onClickFunc(item.passData)
                : ''
            }
          >
            <Button variant="primary" block className="back mt-4">
              {t('survey.fillForm')}
            </Button>
          </Link>
          </Card.Body>
        </Card>
        {/* <Link
          to={item.link}
          onClick={() =>
            item.onClickFunc && item.passData
              ? item.onClickFunc(item.passData)
              : ''
          }
        >
          <Card className={item.backgroundClass + ' patient-card'}>
            <Card.Body className="text-center patient-card-body">
              <div>
                <h6 className="text-center font-bold text-uppercase patient-card-title">{item.title}</h6>
                <FontAwesomeIcon icon={item.icon} className="icon_large mx-3 my-4" />
                <h4 className="mb-0 text-left"> <b> {item.date} </b> </h4>
                <p className="text-left text-uppercase font-bold">
                  {item.subTitle}
                </p>
              </div>
              <div className="text-center">
                
                <div className="mt-2 text_container">
                  <Button block variant="link" className="font-bold d-flex justify-content-end align-items-center">
                    {item.buttonText} <i className="fa fa-long-arrow-right ml-3"></i>
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Link> */}
        {/* <i className={"fa "+ item.icon + " mx-3 icon_large"}></i> */}
      </Col>
    ));
  };

  return (
    <Base
      title={'Survey Forms List'}
      showHeader={true}
      isPatientPortal={true}
      containerClass="mt-0"
    >
      {loadingSurvey ? <Preloader /> : <Row>{renderSurveyForms()}</Row>}
    </Base>
  );
};

export default SurveyFormsList;
