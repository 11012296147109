import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import Base from './base.js';
import { withRouter } from 'react-router-dom';
import {
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  getDate,
  isUserStaff,
  displayMiddleName
} from '../Common/helpers';
import { gql, useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import Preloader from '../Common/Preloder/Preloader';
import { Formik } from 'formik';
import DatePicker from 'react-datepicker';

import CustomModals from '../component/Modal/modal';
import PatientFileUploadModal from './patientsFileUploadModal';
import { useTranslation } from "react-i18next";

function PatientRecordFiles(props) {
  const isStaff = isUserStaff();
  const [showModal, setShowModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [allFiles, setAllFiles] = useState([]);
  const [date, setDate] = useState("");
  const [modifiedLt, setModifiedLt] = useState(new Date());
  const [modifiedGt, setModifiedGt] = useState(new Date("2002-04-01"));
  const id = props.match.params.id;
  const [openSnackbar] = useSnackbar(error_options);
  const formikRef = useRef();
  const { t } = useTranslation();

  const REQUEST_PATIENT = gql`
  query {
    patient(id:"${id}"){
          firstName
          middleName
          lastName
          id
          files(name_Icontains:"${fileName}", modified_Lt:"${modifiedLt.toISOString()}", modified_Gt:"${modifiedGt.toISOString()}"){
            edges{
              node{
                name
                id
                file
                created
                description
              }
            }
          }
        }
  }
`;

  const [getPatients, { data, loading }] = useLazyQuery(REQUEST_PATIENT, {
    fetchPolicy: 'network-only',
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  useEffect(() => {
    getPatients();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("data", data);
  const patient_record = data && data.patient ? data.patient : null;

  useEffect(() => {
    if (patient_record && patient_record.files.edges) {
      setAllFiles(patient_record.files.edges);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_record]);

  const onSubmit = (values, { resetForm }) => {
    if (values.fileName) {
      setFileName(values.fileName);
    }
    if (date) {
      let dateGt = new Date(date);
      let dateLt = new Date(date);
      dateLt.setDate(dateLt.getDate() + 1);
      setModifiedGt(new Date(dateGt));
      setModifiedLt(new Date(dateLt));
    }
    getPatients();
  };
  const resetFilter = (handleReset) => {
    setFileName("");
    handleReset();
    setDate("");
    setModifiedGt(new Date("2002-04-01"));
    setModifiedLt(new Date());
  }


  console.log("patient_record", patient_record);
  const initialValues = {
    fileName: '',
  }

  return (
    <Base isPatientPortal={!isStaff} title={patient_record ? t('patientRecordFiles.yourFiles') : 'Patient record files'} showHeader={true}
      rightChild={
        <Button variant="primary" className='mx-2' onClick={() => setShowModal(true)} >
          {t('patientRecordFiles.addFiles')}
        </Button>
      }>
      <Row className="payment-section">
        <Col md={12} lg={12} className="mr-auto">
          <Formik onSubmit={onSubmit} initialValues={initialValues} innerRef={formikRef}>
            {({ handleSubmit, handleChange, handleReset, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md={4} lg={6} xl={2} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label column sm={3} md={3} lg={3} className="text-right pr-0">
                        {t('patientRecordFiles.date')}
                      </Form.Label>
                      <Col sm={12} md={9} lg={9}>
                        <DatePicker
                          autoComplete="off"
                          selected={date}
                          onChange={(date) => setDate(date)}
                          className="form-control"
                          dateFormat="dd/MMM/yyyy"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={4} lg={6} xl={4} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label column sm={4} md={4} className="text-right pr-0">
                        <span className="">{t('patientRecordFiles.fileName')}</span>
                      </Form.Label>
                      <Col sm={8} md={8}>
                        <Form.Control
                          autoComplete="off"
                          as="input"
                          type="input"
                          name="fileName"
                          value={values.fileName}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={4} lg={6} xl={6} sm={12}>
                    <div className="d-flex justify-content-end submitPayment">
                      <Button type="button" variant="primary" className="mx-3" onClick={() => resetFilter(handleReset)}> {t('patientRecordFiles.reset')} </Button>
                      <Button type="submit"> {t('patientRecordFiles.submit')} </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
        <Col xs={12} md={12} >
          <Table responsive className=" table-borderless">
            <thead>
              <tr className="patient_table_row tableHeader">
                <th scope="col " className="text-uppercase"> {t('patientRecordFiles.created')}</th>
                <th scope="col " className="text-uppercase"> {t('patientRecordFiles.name')}</th>
                <th scope="col " className="text-uppercase"> {t('patientRecordFiles.description')}</th>
                <th scope="col " className="text-uppercase"> {t('patientRecordFiles.view')}</th>
              </tr>
            </thead>
            {loading ? <Preloader /> :
              <tbody>
                {allFiles && allFiles.length > 0 ? allFiles.map((file, index) => {
                  return (
                    <tr key={index} className="patient_table_row">
                      <td>{getDate(file.node.created)}</td>
                      <td>{file.node.name}</td>
                      <td>{file.node.description}</td>
                      <td>
                        <a href={file.node.file} target="_blank" rel="noreferrer" > {t('patientRecordFiles.view')} </a>
                      </td>
                    </tr>
                  )
                }) : <h6 className="p-3"> {t('patientRecordFiles.filesNotAdded')}  </h6>}
              </tbody>}
          </Table>
        </Col>
      </Row>
      <CustomModals dialogClassName="modal60h" showModal={showModal} modalHeader={t('patientRecordFiles.uploadPatientFiles')} setShowModal={setShowModal}>
        {patient_record && patient_record.id ? <PatientFileUploadModal
          patient_id={patient_record.id}
          setShowModal={setShowModal}
          setAllFiles={setAllFiles}
          showModal={showModal}
        /> : null}
      </CustomModals>
    </Base>
  );
}

export default withRouter(PatientRecordFiles);