import { Button, Row, Col, Form, Card } from 'react-bootstrap';
import React, { useState, useRef, useEffect } from 'react';
import CustomModals from '../Modal/modal';
import { Formik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import DatePicker from 'react-datepicker';
import { error_options, SNACK_DURATION, ERROR_MESSAGE, getForamttedTime, getDate, removeEmpty, getPermissionForAction } from '../../Common/helpers';
import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";


function FormBlock(props) {
  const { blockPrintData, blockData, REQUEST_UPDATE, is_patient_record,
    DYNAMIC_FORM_DATA, intake_form, survey_form, is_encounter, form_card,
    formBlockRef, encounterIdList, medical_record_permission, 
    submittedFormUpdate,hide_edit,REQUEST_PATIENT_UPDATE,
    setBlockData, is_prescription, medicationBlockForm, has_medication_block } = props;
  const [openSnackbar] = useSnackbar(error_options);
  const [showModal, setShowModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [editBlockInstance, setEditBlockInstance] = useState(null);
  
  const { t } = useTranslation();

  var formikRef = useRef();

  if (formBlockRef) {
    formikRef = formBlockRef;
  }

  const CREATE_UPDATE_FORM_BLOCK = gql`
  mutation createUpdateFormBlockField(
    $copyToMedication: Boolean
    $formBlock: ID!
    $relatedModel: ID!
    $jsonData:GenericScalar
    $instanceCode:String
  ) {
    createUpdateFormBlockField(
      input: {
        copyToMedication:$copyToMedication
        formBlock:$formBlock
        relatedModel:$relatedModel
        jsonData:$jsonData
        instanceCode:$instanceCode
      }
    ) {
      obj{
        date    
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const CREATE_UPDATE_INTAKE_BLOCK = gql`
  mutation createUpdateIntakeFormBlockField(
    $formBlock: ID!
    $relatedModel: ID!
    $jsonData:GenericScalar
    $instanceCode:String
  ) {
    createUpdateIntakeFormBlockField(
      input: {
        formBlock:$formBlock
        relatedModel:$relatedModel
        jsonData:$jsonData
        instanceCode:$instanceCode
      }
    ) {
      obj{
        data 
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const CREATE_UPDATE_SURVERY_BLOCK = gql`
  mutation createUpdateSurveyFormBlockField(
    $formBlock: ID!
    $relatedModel: ID!
    $jsonData:GenericScalar
    $instanceCode:String
  ) {
    createUpdateSurveyFormBlockField(
      input: {
        formBlock:$formBlock
        relatedModel:$relatedModel
        jsonData:$jsonData
        instanceCode:$instanceCode
      }
    ) {
      obj{
        data 
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const CREATE_UPDATE_PATIENT_FORM_BLOCK = gql`
  mutation createUpdatePatientFormBlockField(
    $formBlock: ID!
    $relatedModel: ID!
    $jsonData:GenericScalar
    $instanceCode:String
  ) {
    createUpdatePatientFormBlockField(
      input: {
        formBlock:$formBlock
        relatedModel:$relatedModel
        jsonData:$jsonData
        instanceCode:$instanceCode
      }
    ) {
      obj{
        data   
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const DELETE_MEDICATION_BLOCK_INSTANCE = gql`
  mutation deleteMedicationBlockInstance(
    $id:ID!
    $instanceCode:String!
    $formBlockKey:String!
  ) {
    deleteMedicationBlockInstance(
      id:$id
      instanceCode:$instanceCode
      formBlockKey:$formBlockKey
    ) {
      obj{
        data
      }
    }
  }
  `;

  const DELETE_FORM_INSTANCE = gql`
  mutation deleteFormBlockInstance(
    $id:ID!
    $instanceCode:String!
    $formBlockKey:String!
  ) {
    deleteFormBlockInstance(
      id:$id
      instanceCode:$instanceCode
      formBlockKey:$formBlockKey
    ) {
      obj{
        data
      }
    }
  }
  `;

  const DELETE_PATIENT_FORM_INSTANCE = gql`
  mutation deletePatientFormBlockInstance(
    $id:ID!
    $instanceCode:String!
    $formBlockKey:String!
  ) {
    deletePatientFormBlockInstance(
      id:$id
      instanceCode:$instanceCode
      formBlockKey:$formBlockKey
    ) {
      obj{
        data
      }
    }
  }
  `;


  // CREATE INTAKE FORM BLOCK
  const [createUpdateIntakeFormBlockField,] = useMutation(CREATE_UPDATE_INTAKE_BLOCK, {
    onCompleted: ({ createUpdateIntakeFormBlockField }) => {
      if (createUpdateIntakeFormBlockField.errors && createUpdateIntakeFormBlockField.errors.length > 0) {
        let error_messages = createUpdateIntakeFormBlockField.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (createUpdateIntakeFormBlockField.obj) {
        console.log("createUpdateIntakeFormBlockField BLOCK CREATED", createUpdateIntakeFormBlockField.obj);
        if (formikRef && formikRef.current) {
          let formikValues = formikRef.current.values;
          if (formikValues.closeModal === false) {
            formikRef.current.resetForm({values:getEmptyValues(initialValuesBlock)});
            setEditBlockInstance(null);
            setEnableEdit(false);
          }
          else {
            setShowModal(false);
          }
        }
        else {
          setShowModal(false);
        }

        if (form_card ) {
          if (submittedFormUpdate) {
            submittedFormUpdate(blockData.blockId);
          }
        }
        // props.history.push("/encounter/detail/"+createEncounter.obj.id);
      }
    },
    refetchQueries: [
      { query: REQUEST_UPDATE, fetchPolicy: 'network-only' }
    ],
    onError: (e) => {
      console.log("E", e)
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // CREATE SURVEY FORM BLOCK
  const [createUpdateSurveyFormBlockField] = useMutation(CREATE_UPDATE_SURVERY_BLOCK, {
    onCompleted: ({ createUpdateSurveyFormBlockField }) => {
      if (createUpdateSurveyFormBlockField.errors && createUpdateSurveyFormBlockField.errors.length > 0) {
        let error_messages = createUpdateSurveyFormBlockField.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (createUpdateSurveyFormBlockField.obj) {
        console.log("createUpdateSurveyFormBlockField BLOCK CREATED", createUpdateSurveyFormBlockField.obj);
        if (formikRef && formikRef.current) {
          let formikValues = formikRef.current.values;
          if (formikValues.closeModal === false) {
            formikRef.current.resetForm({values:getEmptyValues(initialValuesBlock)});
            setEditBlockInstance(null);
            setEnableEdit(false);
          }
          else {
            setShowModal(false);
          }
        }
        else {
          setShowModal(false);
        }

        if (form_card) {
          if (submittedFormUpdate) {
            submittedFormUpdate(blockData.blockId);
          }
        }
      }
    },
    refetchQueries: [
      { query: REQUEST_UPDATE, fetchPolicy: 'network-only' }
    ],
    onError: (e) => {
      console.log("E", e)
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });


  // CREATE ENCOUNTER FORM BLOCK
  const [createUpdateFormBlockField] = useMutation(CREATE_UPDATE_FORM_BLOCK, {
    onCompleted: ({ createUpdateFormBlockField }) => {
      console.log("createUpdateFormBlockField",createUpdateFormBlockField)
      if (createUpdateFormBlockField.errors && createUpdateFormBlockField.errors.length > 0) {
        let error_messages = createUpdateFormBlockField.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (createUpdateFormBlockField.obj) {
        console.log("ENCOUNTER BLOCK CREATED", createUpdateFormBlockField.obj);
        if (formikRef && formikRef.current) {
          let formikValues = formikRef.current.values;
          if (formikValues.closeModal === false) {
            formikRef.current.resetForm({values:getEmptyValues(initialValuesBlock)});
            clearDiagnosisFields();
            clearMedicalFields();
            setEditBlockInstance(null);
            setEnableEdit(false);
          }
          else {
            setShowModal(false);
          }
          if (formikValues.copyToMedication === true) {
            let medicationPostValues = {};
            let prescriptionFields = formikValues;
            prescriptionFields = removeEmpty(prescriptionFields);
            let prescriptionFieldsKeys = Object.keys(prescriptionFields);
            let medicationFields = medicationBlockForm.node.formBlock.blockFormFields.edges.map(fld => {
              return { fieldName: fld.node.formField.fieldName, isRequired: getIsRequired(fld.node) };
            });
            let medicationfieldName = medicationFields.find(i => i.fieldName.indexOf("medication") > -1);
            let otherRequiredMedKeys = medicationFields.map(i => {
              if (i.isRequired === true) {
                return i.fieldName
              }
              return null;
            });

            let medJsonData = {};
            otherRequiredMedKeys = otherRequiredMedKeys.filter(i => i !== medicationfieldName.fieldName && i !== null);
            // check if every required field in medicaiton block exists in prescription block
            let allFound = otherRequiredMedKeys.every(ai => prescriptionFieldsKeys.includes(ai));
            if (allFound) {
              if (medicationfieldName) {
                medicationfieldName = medicationfieldName.fieldName;
              }
              let prescriptionFieldName = blockData.forms.find(i => i.node.formField.fieldName.indexOf("prescription") > -1);
              if (prescriptionFieldName) {
                prescriptionFieldName = prescriptionFieldName.node.formField.fieldName;
              }
              medJsonData[medicationfieldName] = prescriptionFields[prescriptionFieldName];
              medicationFields = medicationFields.filter(i => i.fieldName !== medicationfieldName);
              medicationFields.map(i => {
                medJsonData[i.fieldName] = prescriptionFields[i.fieldName] !== undefined ? prescriptionFields[i.fieldName] : "";
                return null;
              })

              medicationPostValues["jsonData"] = JSON.stringify(medJsonData);
              medicationPostValues["formBlock"] = medicationBlockForm.node.formBlock.id;
              medicationPostValues["relatedModel"] = blockData.relatedModelId;
              let closeModal = formikValues.closeModal;
              callMedicalCopyMutation(medicationPostValues,closeModal);
            }
            else {
              alert("There are incompatible fields on the medication block. Please review the field names and try again.");
            }
          }
        }
        else {
          setShowModal(false);
        }
        // props.history.push("/encounter/detail/"+createEncounter.obj.id);
      }
    },
    refetchQueries: [
      { query: REQUEST_UPDATE, fetchPolicy: 'network-only' }
    ],
    onError: (e) => {
      console.log("EEEE", e)
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const callMedicalCopyMutation = (medicationPostValues,closeModal) => {
    formikRef.current.setFieldValue("copyToMedication", false); 
    formikRef.current.setFieldValue("closeModal", closeModal); 
    createUpdateFormBlockField({ variables: medicationPostValues });
  }

  // CREATE PATIENT FORM BLOCK
  const [createUpdatePatientFormBlockField] = useMutation(CREATE_UPDATE_PATIENT_FORM_BLOCK, {
    onCompleted: ({ createUpdatePatientFormBlockField }) => {
      if (createUpdatePatientFormBlockField.errors && createUpdatePatientFormBlockField.errors.length > 0) {
        let error_messages = createUpdatePatientFormBlockField.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (createUpdatePatientFormBlockField.obj) {
        console.log("ENCOUNTER BLOCK CREATED", createUpdatePatientFormBlockField.obj);
        if (formikRef && formikRef.current) {
          let formikValues = formikRef.current.values;
          if (formikValues.closeModal === false) {
            formikRef.current.resetForm({values:getEmptyValues(initialValuesBlock)});
            clearDiagnosisFields();
            clearMedicalFields();
            setEditBlockInstance(null);
            setEnableEdit(false);
          }
          else {
            setShowModal(false);
          }
        }
        else {
          setShowModal(false);
        }
        // props.history.push("/encounter/detail/"+createEncounter.obj.id);
      }
    },
    refetchQueries: [
      { query: REQUEST_UPDATE, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // delete form instance
  const [deleteMedicationBlockInstance] = useMutation(DELETE_MEDICATION_BLOCK_INSTANCE, {
    // onCompleted: ({ deleteFormBlockInstance }) => {
    //   if (deleteFormBlockInstance.errors && deleteFormBlockInstance.errors.length > 0) {
    //     let error_messages = deleteFormBlockInstance.errors[0].messages;
    //     for (let i in error_messages) {
    //       let error = error_messages[i];
    //       openSnackbar(error, [SNACK_DURATION]);
    //     }
    //   } else if (deleteFormBlockInstance.deleted) {
    //     console.log("deleteMedicationBlockInstance inst deleted");
    //   }
    // },
    refetchQueries: [
      { query: REQUEST_PATIENT_UPDATE, fetchPolicy: 'network-only' }
    ],
    onError: (e) => {
      console.log("eee",e)
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // delete form instance
  const [deleteFormBlockInstance] = useMutation(DELETE_FORM_INSTANCE, {
    onCompleted: ({ deleteFormBlockInstance }) => {
      if (deleteFormBlockInstance.errors && deleteFormBlockInstance.errors.length > 0) {
        let error_messages = deleteFormBlockInstance.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (deleteFormBlockInstance.deleted) {
        console.log("ENCOUNTER inst deleted");
      }
    },
    refetchQueries: [
      { query: REQUEST_UPDATE, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // delete patient form instance
  const [deletePatientFormBlockInstance] = useMutation(DELETE_PATIENT_FORM_INSTANCE, {
    onCompleted: ({ deletePatientFormBlockInstance }) => {
      if (deletePatientFormBlockInstance.errors && deletePatientFormBlockInstance.errors.length > 0) {
        let error_messages = deletePatientFormBlockInstance.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (deletePatientFormBlockInstance.deleted) {
        console.log("patient form  deleted");
      }
    },
    refetchQueries: [
      { query: REQUEST_UPDATE, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const getEmptyValues = (initialValuesBlock) => {
    const emptyObj = {};
    let formikValues = formikRef?.current?.values;
    for (let key in initialValuesBlock) {
      emptyObj[key] = '';
      if (key === 'copyToMedication') {
        emptyObj['copyToMedication'] = initialValuesBlock['copyToMedication'];
      }
    }
    if(formikValues?.closeModal){
      emptyObj['closeModal'] = formikValues.closeModal;
    }
    return emptyObj;
  }
  

  //BASED ON THE VARIANTS, IT RETURNS RESPECTIVE FIELDS
  const getComponent = (form, handleChange, values) => {
    switch (form.formField.variant) {
      case "DROPDOWN":
        return dynamicComp(form, handleChange, values, "select");
      case "CHAR":
        return dynamicComp(form, handleChange, values, "input");
      case "TEXT":
        return dynamicComp(form, handleChange, values, "textarea");
      case "CHOICES":
        return choicesGroupComp(form, handleChange, values, "radiogroup");
      case "RADIO":
        return choicesGroupComp(form, handleChange, values, "radiogroup");
      case "BOOLEAN":
        return booleanComponent(form, handleChange, values, "checkbox");
      case "CHECKBOX":
        return booleanComponent(form, handleChange, values, "checkbox", true);
      case "DATE":
        return dateComponent(form, handleChange, values, "dateselect");
      case "DIAGNOSIS":
        return diagnosisComponent(form, handleChange, values, "diagnosis");
      case "MEDICATION":
        return medicationComponent(form, handleChange, values, "diagnosis");
      case "PRESCRIPTION":
        return medicationComponent(form, handleChange, values, "diagnosis");
      default:
        return null;
    }
  }

  const clearDiagnosisFields = () => {
    try {
      var diagnosisFrame = document.getElementById('diagnosisFrame');
      if (diagnosisFrame) {
        var diagnosisInnerDoc = diagnosisFrame.contentDocument || diagnosisFrame.contentWindow.document;
        let selectedEntity = diagnosisInnerDoc.getElementById("paste-selectedEntity");
        selectedEntity.value = "";
      }
    }
    catch{}
  }

  const clearMedicalFields = () => {
    try {
      var medicalFrame = document.getElementById('medicalFrame');
      if (medicalFrame) {
        var medicalInnerDoc = medicalFrame.contentDocument || medicalFrame.contentWindow.document;
        let rxterms = medicalInnerDoc.getElementById("rxterms");
        rxterms.value = "";
        let drug_strengths = medicalInnerDoc.getElementById("drug_strengths");
        drug_strengths.value = "";
      }
    }
    catch{}
  }

  // GET IS REQUIRED FROM BACKEND THROUGH MODEL TO USE FOR FRONTEND VALIDATION
  const getIsRequired = (current_form) => {
    if (current_form) {
      return current_form.required;
    }
    else {
      return false;
    }
  }






  const getWidth = (current_form_width) => {
    if (current_form_width) {
      let percent = parseInt(current_form_width) / 100;
      return percent * 12;
    }
    else {
      return 12;
    }
  }



  // DYNAMIC FIELD USED FOR INPUT,TEXTAREA, SELECT
  const dynamicComp = (current_form, handleChange, values, variant) => {
    let form_slug = current_form.formField.fieldName;
    let isRequired = getIsRequired(current_form);
    return (<Form.Control
      autoComplete="off"
      as={variant}
      name={form_slug}
      rows={variant === "textarea" ? 4 : 1}
      placeholder={t("forms.enter") + " " + current_form.formField.name}
      value={values[form_slug]}
      onChange={handleChange}
      required={isRequired}
    >
      {variant && variant === "select" && current_form && current_form.formField.options.edges ?
        <>
          <option value={""} > {t("forms.select")} {current_form.formField.name}</option>
          {current_form.formField.options.edges.map((i_node, index) => {
            let i = i_node.node;
            return <option value={i.staticId} key={index} >{i.value} </option>
          })}
        </>
        : null}
    </Form.Control>)
  }

  const choiceGroupSelect = (form_slug,options, i) => {
    formikRef.current.setFieldValue(form_slug, i.staticId);
  }
  //CHOICES FEILD
  const choicesGroupComp = (current_form, handleChange, values, variant) => {
    let form_slug = current_form.formField.fieldName;
    // let isRequired = getIsRequired(current_form);
    return (
      <Row>
        <Col md={12}>
          {variant && variant === "radiogroup" && current_form && current_form.formField.options.edges ? current_form.formField.options.edges.map((i_node, index) => {
            let i = i_node.node;
            let scoreClass="score-"+i.scoreValue;
            return (
              <div key={index} className={"input-radio btn btn-sm button_group " + (values[form_slug] && i.staticId && parseInt(values[form_slug]) === parseInt(i.staticId) ? (" btn-primary "+scoreClass) : " btn-outline-primary ")}> <input type="radio" name={form_slug} id={"radio_" + i.staticId}
                value={values[form_slug] === parseInt(i.staticId) ? true : false}
                checked={values[form_slug] === parseInt(i.staticId) ? true : false} onClick={() => choiceGroupSelect(form_slug,current_form.formField.options, i)} /> <label className="mb-0 px-2 d-flex justify-content-between" htmlFor={"radio_" + i.staticId}><span>{i.value}</span> <span>{i.scoreValue ? "(+" + i.scoreValue+")":""}</span></label></div>
            )
          }) : null}
        </Col>
      </Row>
    )
  }

  const onChangeMultiSelect = (form_slug, values, newSelect) => {
    let arr = values[form_slug] ? values[form_slug] : [];
    if (arr.includes(newSelect.node.staticId)) {
      arr = arr.filter(i => i !== newSelect.node.staticId);
    }
    else {
      arr.push(newSelect.node.staticId);
    }
    formikRef.current.setFieldValue(form_slug, arr);
  }


  const booleanComponent = (current_form, handleChange, values, variant, multiselect) => {
    let form_slug = current_form.formField.fieldName;
    let isRequired = getIsRequired(current_form);
    if (current_form.formField.variant === "BOOLEAN") {
      return (
        <div className='create-new-patient-checkboxes'>
          <Form.Check
            type={variant}
            name={form_slug}
            required={isRequired}
            value={values[form_slug] ? values[form_slug] : false}
            checked={values[form_slug]}
            onChange={() => formikRef.current.setFieldValue(form_slug, !values[form_slug])}
          />
          <Form.Label className={isRequired ? "required" : ""}>
            {current_form.formField.name}
          </Form.Label>
        </div>
      )
    } else if (current_form.variant === "CHECKBOX" && current_form && current_form.formField.options.edges.length > 0) {
      return current_form.formField.options.edges.map((option, index) => {
        return (
          <>
            <div className='create-new-patient-checkboxes' key={index}>
              <Form.Check
                type={variant}
                name={option.staticId}
                required={isRequired}
                value={values[form_slug].includes(option.node.staticId) ? true : false}
                checked={values[form_slug].includes(option.node.staticId) ? true : false}
                onChange={() => onChangeMultiSelect(form_slug, values, option)}
              />
              <Form.Label className={isRequired ? "required" : ""}>
                {option.node.value}
              </Form.Label>
            </div>
          </>
        )
      })
    }
  }

  const dateComponent = (current_form, handleChange, values, variant) => {
    let form_slug = current_form.formField.fieldName;
    let isRequired = getIsRequired(current_form);
    return (
      <DatePicker
        showMonthDropdown
        showWeekNumbers
        showYearDropdown
        required={isRequired}
        autoComplete="off"
        selected={values[form_slug]!=="None" ? values[form_slug] : ""}
        onChange={(date) => formikRef.current.setFieldValue(form_slug, date)}
        className="form-control"
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="time"
        dateFormat="dd/MMM/yyyy - h:mm aa"
        popperModifiers={{
          offset: {
            enabled: true,
            offset: '5px, 10px',
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: 'viewport',
          },
        }}
      />
    )
  }

  const diagnosisComponent = (current_form, handleChange, values, variant) => {
    let editBlockInstanceJSON = JSON.stringify(editBlockInstance);
    return (
      <iframe className='diagnosis-iframe medicationBlock' title="medication" src={process.env.PUBLIC_URL + "/diagnosis.html?fieldName=" + current_form.formField.fieldName + "&editBlockInstanceJSON=" + editBlockInstanceJSON} id="diagnosisFrame" frameBorder="0" width="100%" />
    )
  }

  const medicationComponent = (current_form, handleChange, values, variant) => {
    let editBlockInstanceJSON = JSON.stringify(editBlockInstance);
    return (
      <iframe title="medication" src={process.env.PUBLIC_URL + "/medication.html?fieldName=" + current_form.formField.fieldName + "&editBlockInstanceJSON=" + editBlockInstanceJSON} id="medicalFrame" className="medicationBlock" frameBorder="0" width="100%" />
    )
  }


  useEffect(() => {
    if(showModal && !enableEdit){
      if(formikRef?.current){
        formikRef.current.resetForm({values:getEmptyValues(initialValuesBlock)});
      }
    }
    else if (!showModal) {
      setEnableEdit(false);
      setEditBlockInstance(null);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);


  // THIS IS WHERE EACH FORM FIELD IS RENDERED
  const renderForms = (handleChange, values) => {
    if (blockData && blockData.forms.length > 0) {
      return blockData.forms.map((current_form_node, index) => {
        let current_form = current_form_node.node;
        let isRequired = getIsRequired(current_form);
        let width = getWidth(current_form.width);

        return (
          <Col md={width} key={index} className="mb-3">
            <Form.Group className="mb-0">
              <Form.Label className={"text-left textBold textGrey " + (isRequired ? "required" : "")}>
                {current_form.formField.name}
              </Form.Label>
              {getComponent(current_form, handleChange, values)}
            </Form.Group>
          </Col>
        )
      })
    }
  }

  //LISTENER FOR ON SELECT ON DIAGNOSIS AND MEDICATION BLOCK 
  window.addEventListener("message", function (event) {
    if (event.origin === window.location.origin) {
      if (formikRef && formikRef.current) {
        if (event.data.event_id === "diagnosis") {
          formikRef.current.setFieldValue(event.data.fieldName, event.data.final_val + ", " + event.data.final_val_text);
        }
        else if (event.data.event_id === "medicataion") {
          let drugValue = event.data.drugFieldFinal;

          if (!drugValue) {
            alert('Enter a medication.')
          }
          
          if (event.data.drug_strengthsFinal) {
            drugValue += ", Strength: " + event.data.drug_strengthsFinal
          }
          formikRef.current.setFieldValue(event.data.fieldName, drugValue);
        }
      }
    }
  });

  // BASED ON DIFFERENT CONDITIONS, ONSUBMIT POSTS TO DIFFERENT ENDPOINTS
  const onSubmit = (values, { resetForm }) => {

    // TODO: CHECK if VALUES contains a FIELD PRESCRIPTION.,
    // YOu can check by seeing if VALUES has KEY 'prescription', If so, DO PRESCRIPTION VALIDATION ON VALUES
    // Prescription validation is either MONTH or WEEK should have a value, not both and not neither.

    console.log("values",values);
    
    // WORKING there is no such property as prescription, thats why this "if" never triggered
    if (values.hasOwnProperty('months') || values.hasOwnProperty('weeks')) {
      if (!values['months'] && !values['weeks']) {
        alert('Add weeks or months, please.');
        return;
      } else if (values['months'] && values['weeks']) {
        alert('Choose either weeks or months, please.');
        return;
      }
      if (isNaN(Number(values['months'])) || isNaN(Number(values['weeks']))) {
        alert('Only numbers are allowed for weeks and months.');
        return;
      }
    }

    let medKey = '';
    for (let key in values) {
      if (key.startsWith('medication')) {
        medKey = key;
        break;
      }
    }
    
    if (values.hasOwnProperty('unlisted-medication')) {
      if (values['unlisted-medication'] && values[medKey]) {
        alert('Enter either a medication or unlisted medication, not both.');
        return;
      } else if (!values['unlisted-medication'] && !values[medKey]) {
        alert('Enter either a medication or unlisted medication.');
        return;
      }
    }

    
    let copyToMedication = values["copyToMedication"];
    // let valuesToSend = JSON.parse(JSON.stringify(values));
    let valuesToSend = Object.assign({}, values)
    console.log('valuesToSend', valuesToSend)
    delete valuesToSend["copyToMedication"];
    let jsonData = JSON.stringify(valuesToSend);
    let val = {};
    val["jsonData"] = jsonData;
    val["formBlock"] = blockData.id;
    if (editBlockInstance) {
      val["instanceCode"] = editBlockInstance.form_block_intance_id;
    }
    
    if (is_patient_record) {
      val["relatedModel"] = blockData.patientFormData;
      createUpdatePatientFormBlockField({ variables: val });
    }
    else {
      val["relatedModel"] = blockData.relatedModelId;
      if (is_encounter) {
        if (copyToMedication && is_prescription) {
          val["copyToMedication"] = copyToMedication;
        }
        createUpdateFormBlockField({ variables: val });
      }
      else if (intake_form) {
        createUpdateIntakeFormBlockField({ variables: val });
      }
      else if (survey_form) {
        createUpdateSurveyFormBlockField({ variables: val });
      }
    }

  }

  // track onSubmit, check the structure of val and reproduce it here
  const approveFunc = (block, blockData) => {
    // console.log(block)
    let val = {};
    let jsonData = {}
    for (const item of block.form_block_instances[0].form_block_fields) {
      jsonData[item.form_field_name] = item.form_field_value_id ? item.form_field_value_id : item.form_field_value; 
    }
    val["jsonData"] = JSON.stringify(jsonData);
    val["formBlock"] = blockData.id;
    val["instanceCode"] = block.form_block_instances[0].form_block_intance_id;
    if (is_patient_record) {
      val["relatedModel"] = blockData.patientFormData;
      createUpdatePatientFormBlockField({ variables: val });
    }
    else {
      val["relatedModel"] = blockData.relatedModelId;
      createUpdateFormBlockField({ variables: val });
    }
  }

  const getEncounterRelay=(encounterStaticId)=>{
    let enc = encounterIdList?.find(i => i.staticId === encounterStaticId);
    if(enc){
      return enc.relayId;
    }
  }

  // FUNCTION USED TO POPULATE THE FORM BLOCK INSTANCE
  const get_dynamic_values = (block_static_id) => {
    let keys = Object.keys(DYNAMIC_FORM_DATA);
    let dynamic_blk_id = keys.filter(i => (i.indexOf(block_static_id) > -1));
    let res = []
    if (dynamic_blk_id && dynamic_blk_id.length > 0) {
      res = dynamic_blk_id.map(blk => {
        var enc_static_id = blk.split("_")[1];
        let encRelayId = getEncounterRelay(enc_static_id);
        let dynamicBlkObj = {
          form_block_id: DYNAMIC_FORM_DATA[blk].form_block_id,
          form_block_instances: DYNAMIC_FORM_DATA[blk].form_block_instances,
          form_block_name: DYNAMIC_FORM_DATA[blk].form_block_name,
          form_block_name_ar: DYNAMIC_FORM_DATA[blk].form_block_name_ar,
          encounterRelay: encRelayId,
        };
        return dynamicBlkObj;
      })
    }
    return res;
  }

  var prev_val = [];
  var initialValuesBlock = blockData.initialValues;

  initialValuesBlock['copyToMedication'] = false;

  if(is_prescription){
    initialValuesBlock['copyToMedication'] = true;
  }

  var block_static_id = "block_" + blockData.staticId;
  var block_json = blockData && blockData.json_data ? blockData.json_data[block_static_id] : null;
  
  
  // FUNC THAT SETS THE FORM FIELD VALUES

  const getInstanceValues = (form_block_instancesArr, is_dynamic_patient, encounterRelay) => {
    return (form_block_instancesArr.map((item, index) => {
      let encounterIdToRedirect= encounterRelay;
      if(item.is_from_encounter && item.enc_static_id){
         encounterIdToRedirect= getEncounterRelay(item.enc_static_id);
      }
      let block_data = [];
      for (let i = 0; i < item.form_block_fields.length; i++) {
        let fld = item.form_block_fields[i];
        // IF ENABLE EDIT THIS PART OF CODE DOES THE INITIALIZATION OF FORM BLOCKS 
        if (enableEdit && editBlockInstance && editBlockInstance.form_block_intance_id === item.form_block_intance_id) {
          initialValuesBlock[fld.form_field_name] = fld.form_field_value;
          if (fld.form_field_value.indexOf("+00:") > -1 && fld.form_field_value.indexOf("-") > -1) {
            let date_fld = new Date(fld.form_field_value);
            initialValuesBlock[fld.form_field_name] = date_fld;
          }
          else if (typeof (fld.form_field_value_id) === "string" && fld.form_field_value_id.indexOf(",") > -1) {
            let fld_val_arr = fld.form_field_value_id.split(",");
            // fld_val_arr=fld_val_arr.map(item=>parseInt(item));
            initialValuesBlock[fld.form_field_name] = fld_val_arr;
          }
          else if (fld.form_field_value_id) {
            initialValuesBlock[fld.form_field_name] = fld.form_field_value_id;
          }
        }

        let fld_val = fld.form_field_value;
        if (fld_val.indexOf("+00:") > -1 && fld_val.indexOf("-") > -1) {
          let date_fld = new Date(fld_val);
          date_fld = getDate(date_fld) + " " + getForamttedTime(date_fld);
          fld_val = date_fld;
        }
        // TODO push values for display
        if (fld_val) {
          block_data.push(fld.form_field_label + ": " + fld_val);
        }
        // block_data.push(fld_val);
      }
      return ({ block_data: block_data, form_block_intance_id: item.form_block_intance_id, is_dynamic_patient: is_dynamic_patient, created_date: item.form_block_created_date, encounterRelay: encounterIdToRedirect ? encounterIdToRedirect : null , is_from_encounter:item.is_from_encounter});
    }))
  }

  //PREV VAL IS PREVIOUS VALUES THAT NEEDS TO BE DISPLAY ON FORM BLOCK GREY DIV
  if (block_json && block_json.form_block_instances) {
    
    prev_val = getInstanceValues(block_json.form_block_instances, false);
  }
  if (block_static_id) {
    var dynamic_values = DYNAMIC_FORM_DATA ? get_dynamic_values(block_static_id) : null;
   
    if (dynamic_values && dynamic_values.length > 0) {
      let dynamic_val_Arr = dynamic_values.map(dyn => {
        return getInstanceValues(dyn.form_block_instances, true, dyn.encounterRelay);
      })

      var merged_dyn_arr = [].concat.apply([], dynamic_val_Arr);
      prev_val.push(...merged_dyn_arr);
      prev_val.sort((a, b) => a.created_date - b.created_date); // sort by date
    }
  }


  useEffect(() => {
    if (form_card && prev_val && prev_val.length > 0) {
      editEntries(prev_val[0])
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev_val, form_card]);


  const renderPreviousValues = () => {
    let blockInstances = block_json && block_json.form_block_instances ? block_json.form_block_instances : [];
    return blockInstances.map((block, index) => {
      const filteredFields = block.form_block_fields.filter(item => item.form_field_value);
      return (<div>
        {filteredFields.map((fld, index) => {
          return (<span key={index}>{fld.form_field_label}: {fld.form_field_value}{index === filteredFields.length - 1 ? null : ", "}</span>);
        })}
      </div>)
    })
  }

  const editEntries = (block_item_data, deleteEntry) => {

    let block_instance = block_json && block_json.form_block_instances.find(i => i.form_block_intance_id === block_item_data.form_block_intance_id);
    setEditBlockInstance(block_instance);
    if (deleteEntry) {
      // console.log("block_instance",block_instance)
      if (window.confirm("Delete Entry?")) {
        let val = {};
        val["instanceCode"] = block_instance.form_block_intance_id;
        val["formBlockKey"] = "block_" + blockData.staticId;
        if (is_patient_record && block_instance.is_from_encounter) {
          val["id"] = blockData.patientFormData;
          val["formBlockKey"] = block_instance.form_block_key;
          deleteMedicationBlockInstance({ variables: val });
        }
        else if (is_patient_record && !block_instance.is_from_encounter) {
          val["id"] = blockData.patientFormData;
          deletePatientFormBlockInstance({ variables: val });
        }
        else {
            val["id"] = blockData.relatedModelId;
          deleteFormBlockInstance({ variables: val });
        }
      }
    }
    else {
      setShowModal(true);
      setEnableEdit(true);
    }
  }
  const goToEncounterDetail = (item) => {
    window.open(window.location.origin + "/encounter/detail/" + item.encounterRelay, '_blank');
  }

  const addAnotherInstance = () => {
    // creating/setting closeModal formik feild value to false, that will be used in onSubmit 
    formikRef.current.setFieldValue("closeModal", false); 
    formikRef.current.handleSubmit();
  }

  const printPrescriptions = (currentBlock) => {
    if (blockPrintData) {
      setBlockData(null);
    }
    setTimeout(() => {
      setBlockData(parseInt(currentBlock.staticId));
    }, 100);
    // setPrintPrescription(true);
    // window.print();
  }

  const showPrint = () => {
    if (blockData.title.toLowerCase().indexOf('prescription') >= 0) {
      return (
        <Button
          className='p-1'
          variant="link"
          onClick={() => printPrescriptions(blockData)}
          disabled={block_json && block_json.form_block_instances.length > 0 ? false : true}
        >
          <i className="fa fa-print"></i>
        </Button>
      )
    }
  }

  const openModal = () => {
    setShowModal(true);
    setEditBlockInstance(null)
    setEnableEdit(false)
  }



  return (
    <>
      {form_card ? null : <Form.Group as={Row}>
        <Form.Label column xs={7} sm={7} md={7} className="text-left text-uppercase textBold textGrey">
          {blockData.title}
        </Form.Label>
        <Col xs={5} sm={5} md={5} className="d-flex justify-content-end align-items-center form_edit_buttons">
          {medical_record_permission.includes("add") ?
            <div className='d-flex align-items-center'>
              {block_json?.requires_approval && getPermissionForAction('medical_record', 'approve') ?
                <Button className='approveBlockBtn' variant='primary' onClick={() => approveFunc(block_json, blockData)}>
                  <span> Approve </span>
                </Button>
                : null}
              {!block_json?.requires_approval ? showPrint(blockData.id) : null}
              <Button variant="link" onClick={openModal} className='p-1 ml-1'>
                <i className="fa fa-plus-circle"></i>
              </Button>
            </div> : null}

        </Col>
        <Col sm={12} md={12}>
          <div className="dynamic_block_display_container">
            <div className="dynamic_block_display form_edit_buttons thinScrollBar">
              {/* {console.log("prev_val",prev_val)} */}
              {prev_val && prev_val.map((item, index) => {
                let filterData = item.block_data.filter(item => item);
                return (
                  <div className="block_item_container" key={index}>
                    {item.is_dynamic_patient ?
                      <div className="block_item_edit d-flex">
                        {medical_record_permission.includes("edit") ? <Button variant="link" className="px-0 " onClick={() => goToEncounterDetail(item)}>
                          <i className="ml-2 fa fa-external-link"></i>
                        </Button> : null}
                      </div>
                      :
                      medical_record_permission.includes("edit") || medical_record_permission.includes("delete") ? <div className="block_item_edit d-flex">
                        {!intake_form && !survey_form && medical_record_permission.includes("delete") ? <Button variant="link" className="danger-color" onClick={() => editEntries(item, true)}>
                          <i className="fa fa-times"></i>
                        </Button> : null}
                        {medical_record_permission.includes("edit") && !item.is_from_encounter ? 
                        <Button variant="link" className="px-0 " onClick={() => editEntries(item)}>
                          <i className="fa fa-edit"></i>
                        </Button> : null}
                      </div> : null
                    }
                    {/* if instance is from encounter,its click should take user to encounter detail page */}
                    <div className={"block_item print-prescription"}>
                      {filterData.join(", ")}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Col>
        <CustomModals
          showModal={showModal}
          modalHeader={blockData.title}
          setShowModal={setShowModal}
          dialogClassName="modal60h" 
          contentClassName='modals-from-formBlock'
        >
          <Formik onSubmit={onSubmit} 
            initialValues={initialValuesBlock} 
            enableReinitialize 
            innerRef={formikRef}
          >
            {({ handleChange, values, handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="w100">
                <div className="mb-2">
                  <Form.Label className="text-left textBold textGrey">
                    Previous Values:
                  </Form.Label>
                  {renderPreviousValues()}
                </div>
                <Row>
                  {renderForms(handleChange, values)}
                </Row>
                <Row className=" mt-4 justify-content-center justify-content-sm-end">
                  <Col className="d-flex justify-content-center justify-content-sm-end">
                    {is_prescription ? <Form.Group className="d-flex p-0 m-0 align-items-baseline">
                      <Form.Check
                        type="checkbox"
                        name="copyToMedication"
                        onChange={handleChange}
                        // disabled={!has_medication_block}
                        value={values.copyToMedication}
                        checked={values.copyToMedication}
                        className="d-inline-block require-payment-checkbox"
                      />
                      <Form.Label className="d-inline-block p-0 ml-1">Copy to Medications</Form.Label>
                    </Form.Group> : null}
                    <Button variant="primary" size="md" className="mx-2" type="button" onClick={() => {
                      setShowModal(false);
                      // setEditBlockInstance(null);
                    }}>
                      Cancel
                    </Button>
                    <Button variant="primary" size="md" className="mx-2" type="submit">
                      Save
                    </Button>
                    <Button variant="primary" size="md" className="mx-2" type="button" onClick={addAnotherInstance} >
                      Add Another
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </CustomModals>
      </Form.Group>}
      {
        form_card ? <Col md={12}>
          <Card className="p-3">
            <Card.Body>
              <Card.Title>
                {blockData.title}
              </Card.Title>
              <Formik onSubmit={onSubmit} 
                initialValues={initialValuesBlock} 
                enableReinitialize innerRef={formikRef}
              >
                {({ handleChange, values, handleSubmit }) => (
                  <Form onSubmit={handleSubmit} className="w100">
                    <Row>
                      {renderForms(handleChange, values)}
                    </Row>
                    <Row className={" mt-4 justify-content-center justify-content-sm-end " + form_card ? " d-none " : ""}>
                      <Col className="d-flex justify-content-center justify-content-sm-end">
                        <Button variant="primary" size="md" className="mx-2" type="button" onClick={() => setShowModal(false)}>
                          Cancel
                        </Button>
                        <Button variant="primary" size="md" className="mx-2" type="submit" id={"submit_" + blockData.blockId} >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col> : null
      }
    </>
  )
}

export default withRouter(FormBlock);