import React, { useRef, useEffect, useState } from 'react';
import { Row, Col, Button, Form, Container } from 'react-bootstrap';
import Base from './base.js';
import { Formik } from 'formik';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import { error_options, SNACK_DURATION, ERROR_MESSAGE, urlActions, capitalizeFirstLetter, getValidDateStringForGraphQL,displayMiddleName } from '../Common/helpers.js';
import FileUpload from '../component/upload';
import DatePicker from 'react-datepicker';
import { useTranslation } from "react-i18next";
import CountrySelect from '../Views/countrySelect';



const RegistrationCompleteForm = (props) => {
  const [openSnackbar] = useSnackbar(error_options);
  const formikRef = useRef();
  const [currentImage, setCurrentImage] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [focus, setFocus] = useState(null);
  const { t } = useTranslation();
  let profileEdit = urlActions(window.location.href, 'get', 'profileEdit');
  const GET_COUNTRIES = gql`
  query{
    countries
  } 
`;

  const GET_GOVERNORATES = gql`
  query{
    governorates{
      edges{
        node{
          id
          name
        }
      }
    }
  } 
`;

  const GET_AREAS = gql`
  query areas($governorate: ID!) {
    areas(governorate_Id:$governorate){
      totalCount
      edges{
        node{
          id
          name
        }
      }
    }
  } 
`;


  const UPDATE_PATIENT = gql`
  mutation createUpdateSelfPatient(
    $firstName: String!
    $middleName: String!
    $lastName: String!
    $phone: String!
    $dob: Date!
    $gender:String!
    $email:String!
    $civil_id:String
    $doctor:ID
    $address:String
    $governorate:ID
    $country:String!
    $area:ID
    $diagnosis:String
    $referred_by:String
    $medication:String
  ) {
    createUpdateSelfPatient(
      input: { firstName: $firstName,  middleName: $middleName, lastName: $lastName, phone: $phone, dob: $dob, gender:$gender, civilId:$civil_id,doctor:$doctor, address:$address,governorate:$governorate, country:$country, area:$area,impressionDiagnosis:$diagnosis,medication:$medication, referredBy:$referred_by, email:$email }
    ) {
      obj {
        firstName
        lastName
        email
        identifier
        phone
        id
        profileComplete
        doctor{
          identifier
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

  const SELECTED_PATIENT_DATA = gql`
    query receivePatientData {
      dataPatient @client
    }
  `;

  const [UpdatePatient] = useMutation(UPDATE_PATIENT, {
    onCompleted: ({ createUpdateSelfPatient }) => {
      if (createUpdateSelfPatient.errors.length === 0) {
        console.log("createUpdateSelfPatient", createUpdateSelfPatient);
        if (createUpdateSelfPatient && createUpdateSelfPatient.obj && createUpdateSelfPatient.obj.profileComplete) {
          props.history.push("/patientPortal/dashboard");
        }
      } else {
        let alert_msg = '';
        createUpdateSelfPatient.errors.map((error) => {
          alert_msg += error.messages.join(' ') + ' ';
          openSnackbar(alert_msg, [50000]);
          return null;
        });
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const { data: countriesData = null } = useQuery(GET_COUNTRIES);
  const { data: governoratesData = null } = useQuery(GET_GOVERNORATES);
  const [getAreasForGov, { data: areasData = [] }] = useLazyQuery(GET_AREAS, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });
  const { data: patientData = [] } = useQuery(SELECTED_PATIENT_DATA);
  const onChangeCountry = (e) => {
    formikRef.current.setFieldValue('country', e.target.value);
  }

  useEffect(() => {
    if (patientData && patientData.dataPatient) {
      if (patientData.dataPatient.governorate && patientData.dataPatient.governorate.id) {
        let val = {
          governorate: patientData.dataPatient.governorate.id
        }
        getAreasForGov({ variables: val });
      }
      if (patientData.dataPatient.idFile) {
        setCurrentImage(patientData.dataPatient.idFile);
      }
      if (patientData.dataPatient.dob) {
        setStartDate(new Date(patientData.dataPatient.dob));
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData])


  const onChangeGovernorate = (e) => {
    formikRef.current.setFieldValue('governorate', e.target.value);
    let gov = governoratesData.governorates.edges.find(i => i.node.id === e.target.value);
    if (gov) {
      let gov_id = gov.node.id;
      let val = {
        governorate: gov_id
      }
      getAreasForGov({ variables: val });
    }
  }

  const onChangeArea = (e) => {
    formikRef.current.setFieldValue('area', e.target.value);
  }


  let initialValues = {
    firstName: '',
    lastName: '',
    middleName: '',
    dob: '',
    gender: '',
    civil_id: '',
    email: '',
    phone: '',
    address: '',
    governorate: '',
  };
  if (patientData && patientData.dataPatient) {
    initialValues['firstName'] = patientData.dataPatient.firstName;
    initialValues['middleName'] = displayMiddleName(patientData.dataPatient.middleName);
    initialValues['lastName'] = patientData.dataPatient.lastName;
    initialValues['dob'] = new Date(patientData.dataPatient.dob);
    initialValues['phone'] = patientData.dataPatient.phone;
    initialValues['email'] = patientData.dataPatient.email;
    initialValues['gender'] = String(patientData.dataPatient.displayGender).toLowerCase();
    initialValues['civil_id'] = patientData.dataPatient.civilId;
    initialValues['address'] = patientData.dataPatient.address;
    initialValues['id'] = patientData.dataPatient.id ? patientData.dataPatient.id : '';
    initialValues['file_number'] = patientData.dataPatient.identifier;
    initialValues['country'] = patientData.dataPatient.country ? patientData.dataPatient.country.code : '';
    initialValues['governorate'] = patientData.dataPatient.governorate ? patientData.dataPatient.governorate.id : '';
    initialValues['area'] = patientData.dataPatient.area ? patientData.dataPatient.area.id : '';
  }

  const onSubmit = (values) => {
    let val = {
      firstName: capitalizeFirstLetter(values.firstName),
      middleName: capitalizeFirstLetter(values.middleName),
      lastName: capitalizeFirstLetter(values.lastName),
      phone: values.phone,
      dob: getValidDateStringForGraphQL(startDate),
      email: values.email,
      gender: values.gender,
      civil_id: values.civil_id,
      address: values.address,
      country: values.country,
      profile_complete: false,
      identifier: values.file_number,
      id: values.id
    };
    if (val.country === "KW") {
      val["governorate"] = values.governorate;
      val["area"] = values.area;
    }
    let variables = val;
    console.log('variables', variables);
    UpdatePatient({ variables: variables });
  };

  // var patientId = patientData && patientData.dataPatient && patientData.dataPatient.id ? patientData.dataPatient.id : null; 
  var patientId = "UGF0aWVudE5vZGU6Mw==";


  const onChangeEnglishInput = (e) => {
    let value = e.target.value;
    let fieldName = e.target.name;
    // value = value.replace(/[^A-Za-z]/ig, '');
    formikRef.current.setFieldValue(fieldName, value);
  }

  const updateFocus = (data) => {
		if (data) {
			setFocus(data)
		}
	};

  const handleSignupPhoneChange = (value,) => {
		let num = "+" + value;
		formikRef.current.setFieldValue('phone', num);
		// setPhoneNumber(num);
	};

  return (
    <Base title={profileEdit && profileEdit === 'true' ? t('patientRegForm.profileEdit') : "REGISTRATION FORM"} showHeader={true} isPatientPortal={true} >
      <Col xs={12} sm={12} md={12} lg={12} xl={11}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          innerRef={formikRef}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            handleBlur,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Container>
                <Row className="text-center">
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}

                  >
                    <Form.Group as={Row} className="text-right">
                      <Form.Label xs={12} column sm={4} md={5} className="required">
                        {t('patientRegForm.patientFirstName')}
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <Form.Control
                          required
                          autoComplete="nope"
                          type="text"
                          name="firstName"
                          onBlur={handleBlur}
                          onChange={onChangeEnglishInput}
                          value={values.firstName || ''}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="text-right">
                      <Form.Label xs={12} column sm={4} md={5} className="required">
                        {t('patientRegForm.patientMiddleName')}
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <Form.Control
                          required
                          autoComplete="nope"
                          type="text"
                          name="middleName"
                          onBlur={handleBlur}
                          onChange={onChangeEnglishInput}
                          value={values.middleName || ''}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="text-right">
                      <Form.Label xs={12} column sm={4} md={5} className="required">
                        {t('patientRegForm.patientLastName')}
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <Form.Control
                          required
                          autoComplete="nope"
                          type="text"
                          name="lastName"
                          onBlur={handleBlur}
                          onChange={onChangeEnglishInput}
                          value={values.lastName || ''}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="text-right">
                      <Form.Label column xs={12} sm={4} md={5} className="required">
                        {t('patientRegForm.dateOfBirth')}
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        {/* <Form.Control
                          type="number"
                          autoComplete="age"
                          required
                          onBlur={handleBlur}
                          name="age"
                          onChange={handleChange}
                          value={values.age || ''}
                        /> */}
                        <DatePicker
                          name="dob"
                          autoComplete="off"
                          showMonthDropdown
                          showYearDropdown
                          selected={startDate ? startDate : ''}
                          onChange={(date) => setStartDate(date)}
                          className="form-control"
                          dateFormat="dd/MMM/yyyy"
                          maxDate={new Date()}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.dob}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="text-right">
                      <Form.Label column xs={12} sm={4} md={5} className="required">
                        {t('patientRegForm.gender')}
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <Form.Control
                          onBlur={handleBlur}
                          as="select"
                          name="gender"
                          value={values.gender}
                          required
                          onChange={handleChange}
                        >
                          <option value="">{t('patientRegForm.selectGender')}</option>
                          <option value={'female'}>{t('patientRegForm.female')}</option>
                          <option value={'male'}>{t('patientRegForm.male')}</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="text-right">
                      <Form.Label column xs={12} sm={4} md={5} className="required">
                        {t('patientRegForm.idNumber')}
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <Form.Control
                          type="text"
                          onBlur={handleBlur}
                          name="civil_id"
                          required
                          autoComplete="civil_id"
                          onChange={handleChange}
                          value={values.civil_id || ''}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="text-right">
                      <Form.Label column xs={12} sm={4} md={5} className="id-label">
                        {/* QUESTION translation? */}
                        {/* {t('patientRegForm.id')} */}
                        <span className='required'>{t('patientRegForm.id')}</span>
                        <small>{t('patientRegForm.click')}</small>
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        {patientId ?
                          <FileUpload patientId={patientId} currentImage={currentImage} setCurrentImage={setCurrentImage} />
                          : null}
                      </Col>
                    </Form.Group>

                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}

                  >
                    <Form.Group as={Row} className="text-right">
                      <Form.Label xs={12} column sm={4} md={5} className="required">
                        {t('patientRegForm.email')}
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <Form.Control
                          required
                          autoComplete="email"
                          type="email"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email || ''}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column xs={12} sm={4} md={5} className="required text-right">
                        {t('patientRegForm.phoneNumber')}
                      </Form.Label>
                      <Col xs={12} sm={8} md={7} className="text-left">
                        <CountrySelect
                          required
                          value={values.phone || ''}
                          onChange={handleSignupPhoneChange}
                          type="text"
                          name="phone"
                          onFocus={() => updateFocus("phone")}
                          onBlur={() => updateFocus("blur")}
                          placeholder={focus === "phone" ? "" : t('Account.phone')}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    {countriesData ? <Form.Group as={Row} className="text-right">
                      <Form.Label column xs={12} sm={4} md={5} className="required">
                        {t('patientRegForm.country')}
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <Form.Control
                          onBlur={handleBlur}
                          as="select"
                          name="country"
                          value={values.country}
                          required
                          onChange={onChangeCountry}>
                          <option value="">{t('patientRegForm.selectCountry')}</option>
                          {Object.entries(countriesData.countries).map(([key, value], index) => {
                            return (
                              <option value={key} key={index}>
                                {value}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group> : null}
                    {governoratesData && governoratesData.governorates && governoratesData.governorates.edges && values.country === "KW" ? <Form.Group as={Row} className="text-right">
                      <Form.Label column xs={12} sm={4} md={5} className="required">
                        {t('patientRegForm.governorate')}
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <Form.Control
                          onBlur={handleBlur}
                          as="select"
                          name="governorate"
                          value={values.governorate}
                          required
                          onChange={onChangeGovernorate}>
                          <option value="">{t('patientRegForm.selectGovernorate')}</option>
                          {governoratesData.governorates.edges.map((item, index) => {
                            return (
                              <option value={item.node.id} key={index}>
                                {item.node.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group> : null}
                    {areasData && areasData.areas && areasData.areas.edges && values.country === "KW" ? <Form.Group as={Row} className="text-right">
                      <Form.Label column xs={12} sm={4} md={5} className="required">
                        {t('patientRegForm.area')}
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <Form.Control
                          onBlur={handleBlur}
                          as="select"
                          name="area"
                          value={values.area}
                          required
                          onChange={onChangeArea}>
                          <option value="">{t('patientRegForm.selectArea')}</option>
                          {areasData.areas.edges.map((item, index) => {
                            return (
                              <option value={item.node.id} key={index}>
                                {item.node.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group> : null}

                    <Form.Group as={Row} className="text-right">
                      <Form.Label column xs={12} sm={4} md={5} className="required">
                        {t('patientRegForm.address')}
                      </Form.Label>
                      <Col xs={12} sm={8} md={7}>
                        <Form.Control
                          type="text"
                          onBlur={handleBlur}
                          name="address"
                          required
                          autoComplete="address"
                          onChange={handleChange}
                          value={values.address || ''}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.address}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group as={Row}></Form.Group>
                <Row className="pull-right">
                  <Button
                    type="submit"
                    variant="primary"
                    size="md"
                    active
                    name="appointment"
                    className="my-3"
                  >
                    {t('patientRegForm.submit')}
                  </Button>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      </Col>
    </Base>
  );
};

export default RegistrationCompleteForm;
