import React, { useEffect, useState } from 'react';
// import style from './Navigation.module.css';
import { Col, Row, Button } from 'react-bootstrap';
import LogoutButton from './LogoutButton/LogoutButton';
import { withRouter, Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { dataPatientInVar, appLanguageVar, myAppointmentDetailsVar } from '../../cache/cache.js';
import { useTranslation } from "react-i18next";
import { isUserStaff, getPermissionForAction, error_options, SNACK_DURATION, ERROR_MESSAGE, } from '../../Common/helpers';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import { useSnackbar } from 'react-simple-snackbar';
import Badge from '../../Views/Badge';


const Navigation = (props) => {
  const { location } = props;
  const { pathname } = location;
  const { i18n, t } = useTranslation();
  const appLanguage = useReactiveVar(appLanguageVar);
  const myAppointmentDetails = useReactiveVar(myAppointmentDetailsVar);
  const isStaff = isUserStaff();
  const [dateStart] = useState(new Date());
  const [badgeForDashboard, setBadgeForDashboard] = useState();
  const [badgeForAppointments, setBadgeForAppointments] = useState();
  const [badgeForReportRequests, setBadgeForReportRequests] = useState();
  const [patientsBadgeForReportRequests, setPatientsBadgeForReportRequests] = useState();
  const [badgeForReferrals, setBadgeForReferrals] = useState();
  const [badgeForLeaveRequests, setBadgeForLeaveRequests] = useState();
  const [openSnackbar] = useSnackbar(error_options);

  const [badgeForStaffDashboard, setBadgeForStaffDashboard] = useState();
  const [badgeForStaffApprovals, setBadgeForStaffApprovals] = useState();
  const [badgeForStaffApproved, setBadgeForStaffApproved] = useState();
  const [badgeForPatientReportsForApproval, setBadgeForPatientReportsForApproval] = useState();



  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;

  const REQUEST_ACCOUNT_DETAILS = gql`
  query {
    me{
     id
     isStaff
     patient{
       firstName
       middleName
       lastName
       id
       idFile
       identifier
       dob
       phone
       email
       civilId
       address
       displayGender
       referrals{
        edges{
          node{
            title
            description
            id
            recommendedDate
            referredDoctor{
              firstName
              lastName
              id
              identifier
              eventType {
                edges {
                  node {
                    id
                    title{
                      name
                    }
                    duration
                    buffer
                    price
                    eventType {
                      id
                      name
                      color{
                        hexCode
                        name
                        colorId
                      }
                    }
                    eventLocation {
                      id
                      title
                    }
                  }
                }
              }
              recurringEvents{
                edges {
                  node {
                    startDate
                    startTime
                    endTime
                    title
                    description
                    everyday
                    days
                    dateUntil
                  }
                }
              }
              availability {
                edges {
                  node {
                    id
                    day
                    shifts{
                      edges {
                        node {
                          startTime
                          endTime
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
            dynamicForms{
              edges{
                node{
                  name
                  id
                  formType
                }
              }
            }
            referredDoctorSession{
              id
              title{
                name
              }
              duration
              price
              eventLocation{
                title
              }
              eventType{
                name
              }
            }
            createdAppointment{
              start
            }
          }
        }
      }
       country{
         code
         name
       }
       governorate{
         id
         name
       }
       area{
         id
         name
       }
       profileComplete
     }
    }
   }
`;

const REQUEST_ALL_EVENTS_FOR_BADGE = gql`
  query($start: DateTime) {
    events(start: $start, orderby: "start", first: 3, status: "confirmed", active: true) {
      edges {
        node {
          id
          start
          end
          patient{
            firstName
            middleName
            lastName
          }
        }
      }
    }
  }
`;

const REQUEST_PATIENT_REPORTS = gql`
  query {
    patientReports{
      totalCount
      edges {
        node {
          status
        }
      }    
    }
  }
`;

const REQUEST_PATIENT_REPORTS_FOR_BADGE = gql`
  query {
    doctorPatientReports(status: "paid"){
      totalCount
    }
  }
`;

const REQUEST_FORM_BLOCK_APPROVALS_FOR_BADGE = gql`
    query {
      formBlockApprovals {
        totalCount
      }
    }  
  `;

const REQUEST_APPROVED_BLOCKS = gql`
  query {
    approvedBlockNotifications(first: 3){
      totalCount
      edges {
        node {
            id
            created
          encounter {
            id
            doctor {
              firstName
              lastName
            }
          }
          patient{
            id
            firstName
            middleName
            lastName
          }
        }
      }
    }
  }
`;

const REQUEST_MEDICAL_REPORTS = gql`
  query {
    patientReportsForApproval{
      totalCount
    }
  }
`;

const LEAVE_REQUESTS_FOR_BADGE = gql`
query {
  leaveRequests(status: "pending"){
    totalCount
  }
}
`;

const { data: medical_reports_obj = null } = useQuery(
  REQUEST_MEDICAL_REPORTS,
  {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setBadgeForPatientReportsForApproval(data?.patientReportsForApproval?.totalCount)
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  },
);

  const { data: approvedBlocks = null } = useQuery(
    REQUEST_APPROVED_BLOCKS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setBadgeForStaffApproved(data?.approvedBlockNotifications?.totalCount)
      },
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  const { data: formBlockApprovalsObj = null } = useQuery(
    REQUEST_FORM_BLOCK_APPROVALS_FOR_BADGE,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setBadgeForStaffApprovals(data?.formBlockApprovals?.totalCount)
      },
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  const { data } = useQuery(USER_DETAILS);

  var userDetails = data && data.userDetails ? data.userDetails : null;
  if (userDetails && userDetails.indexOf('username') > -1) {
    userDetails = JSON.parse(userDetails);
  }

  const { data: accountDetails, } = useQuery(REQUEST_ACCOUNT_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setBadgeForReferrals(data?.me?.patient?.referrals?.edges?.length)
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  var accDetails = accountDetails;
  if (accDetails && accDetails.me && accDetails.me.patient && !accDetails.me.isStaff) {
    dataPatientInVar(accDetails.me.patient);
  }

  useEffect(() => {
    if (accDetails && accDetails.me && accDetails.me.patient && !accDetails.me.isStaff && !accDetails.me.patient.profileComplete) {
      if (pathname !== "/registration/complete/form") {
        props.history.push("/registration/complete/form");
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, accDetails]);


  const getDate = () => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    let today = new Date();
    let res = days[today.getDay()];
    res += ' ' + today.getDate();
    res += '/' + monthNames[today.getMonth()].toUpperCase();
    res += '/' + today.getFullYear();
    return res;
  };

  let default_permissions = ['dashboard'];
  let permissions = ['patient', 'appointment', 'account', 'manual_payment', 'waiting_list', 'change_pass', 'patient_report'];

  const REQUEST_USER_PERMISSION = gql`
  query receiveDate {
    userPermissions @client
  }
`;
  var { data: user_permissions } = useQuery(
    REQUEST_USER_PERMISSION
  );

  const {allEventsForBadge, allEventsForBadgeLoading} = useQuery(REQUEST_ALL_EVENTS_FOR_BADGE, {
    fetchPolicy: "network-only",
    variables: { start: dateStart },
    onCompleted: (data) => {
      setBadgeForAppointments(data?.events?.edges?.length)
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  })

  const { data: patient_reports_obj = null } = useQuery(
    REQUEST_PATIENT_REPORTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        // we show only !PENDING requests in requestReport component,
        // same is here to match numbers in badges
        const badgeResult = data?.patientReports?.edges?.filter(item => {
          return item.node.status !== "PENDING";
        })
        // setPatientsBadgeForReportRequests(data?.patientReports?.edges?.length)
        setPatientsBadgeForReportRequests(badgeResult?.length)
      },
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  const { data: patient_reports_obj_for_badge = null } = useQuery(
    REQUEST_PATIENT_REPORTS_FOR_BADGE,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setBadgeForReportRequests(data?.doctorPatientReports?.totalCount)
      },
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  const { data: leave_requests_for_badge = null } = useQuery(
    LEAVE_REQUESTS_FOR_BADGE,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setBadgeForLeaveRequests(data?.leaveRequests?.totalCount)
      },
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  useEffect(() => {
    setBadgeForDashboard(badgeForAppointments + badgeForReferrals);
  }, [badgeForAppointments, badgeForReferrals])

  useEffect(() => {
    // console.log('badgeForReportRequests', badgeForReportRequests)
    // console.log('badgeForStaffApprovals', badgeForStaffApprovals)
    // console.log('badgeForStaffApproved', badgeForStaffApproved)
    // console.log('badgeForLeaveRequests', badgeForLeaveRequests)
    // console.log('badgeForAppointments', badgeForAppointments)
    // console.log('badgeForPatientReportsForApproval', badgeForPatientReportsForApproval)
    
    setBadgeForStaffDashboard(badgeForReportRequests + badgeForStaffApprovals + badgeForStaffApproved + badgeForLeaveRequests + badgeForAppointments + badgeForPatientReportsForApproval);
  }, [badgeForReportRequests, badgeForStaffApprovals, badgeForStaffApproved, badgeForLeaveRequests, badgeForAppointments, badgeForPatientReportsForApproval])

  permissions = permissions.filter(i => getPermissionForAction(i, 'list', false, user_permissions.userPermissions) === true);
  permissions.push(...default_permissions);
  const has_HR_permission = getPermissionForAction("leave_request", 'approve');

  if (has_HR_permission) {
    permissions.push('leave_request');
  }
  else {
    permissions.push('leave_request_view');
  }

  let navigation_links = [
    {
      key: 'dashboard',
      slug: '/dashboard',
      title: 'Dashboard',
      badge: badgeForStaffDashboard
    },
    {
      key: 'patient',
      slug: '/patients',
      title: 'Patients',
    },
    {
      key: 'appointment',
      slug: '/appointments',
      title: 'Appointments',
    },
    {
      key: 'waiting_list',
      slug: '/waiting/list',
      title: 'Waiting List',
    },
    {
      key: 'manual_payment',
      slug: '/manual/payment',
      title: 'Manual Payment',
    },
    {
      key: 'leave_request',
      slug: '/manage/leave/requests',
      title: 'Manage Leave Request',
    },
    {
      key: 'leave_request_view',
      slug: '/leaves',
      title: 'Your Leave Request',
    },
    {
      key: "patient_report",
      slug: "/patient/report/request",
      title: " Report Requests "
    },
    {
      key: 'account',
      slug: '/accounts',
      title: 'Transactions',
    },
    {
      key: 'change_pass',
      slug: '/change/password',
      title: 'Change Password',
    },
    {
      key: 'settings',
      slug: '/settings',
      title: 'Settings',
    },
  ]
  if (props.isPatientPortal) {
    permissions = ["patientDashboard", "patientAppointment", "change_pass", "report_request", "files", "patientsInfo"];
    navigation_links = [
      {
        key: "patientDashboard",
        slug: "/patientPortal/dashboard",
        title: t('patientNavigation.dashboard'),
        badge: badgeForDashboard
      },
      {
        key: "patientAppointment",
        slug: "/patientPortal/appointments",
        title: t('patientNavigation.appointments'),
        badge: badgeForAppointments
      },
      {
        key: "report_request",
        slug: "/patientPortal/report/request",
        title: t('patientNavigation.reportRequests'),
        badge: patientsBadgeForReportRequests
      },
      {
        key: 'change_pass',
        slug: '/change/password',
        title: t('patientNavigation.changePassword'),
      },
      {
        key: "patientsInfo",
        slug: "/registration/complete/form?profileEdit=true",
        title: t('patientNavigation.patientsInfo')
      },
    ]
    if (userDetails && userDetails.patient && userDetails.patient.id) {
      navigation_links.push(
        {
          key: "files",
          slug: "/patientPortal/files/" + userDetails.patient.id,
          title: t('patientNavigation.reportFiles'),
        },
      )
    }
  }
  const toggleNav = (open) => {
    if (open) {
      document.getElementById('mySidenav').style.width = '250px';
      document.getElementById('nav_overlay').classList.remove('d-none');
    } else {
      document.getElementById('mySidenav').style.width = '0';
      document.getElementById('nav_overlay').classList.add('d-none');
    }
  };
  const renderNavContents = () => {
    return (
      <>
        <div className={" w100 px-2 " + (accDetails && accDetails.me && accDetails.me.patient && !accDetails.me.patient.profileComplete ? " pointerEventsNone " : "")}>
          <div className="d-md-none userdetails px-3 mb-4">
            <EllipsisWithTooltip placement="bottom">
              <h6 className="nav-email-overlay-mobile">{userDetails ? userDetails.email : 'User'}</h6>
            </EllipsisWithTooltip>
            <Link to={props.isPatientPortal ? "/patientPortal/dashboard" : "/dashboard"}>
              <img alt={'Logo'} className="img-fluid navLogo" src={Logo} />
            </Link>
            {/* <h4 className="textPrimary capitalize">
              {userDetails ? userDetails.username : 'User'}
            </h4> */}
          </div>
          {navigation_links.map((link, index) => {
            if (permissions.includes(link.key)) {
              return (
                <Link to={link.slug} key={index} className='nav-link-with-badge'>
                  {' '}
                  <Button className="text-left text-uppercase navigation-btn py-1" variant="link">
                    {link.title}
                  </Button>
                  {/* {link.badge && badgeForDashboard && badgeForReferrals && badgeForAppointments ?  */}
                  {link.badge ? 
                    <Badge badge={link.badge} /> 
                  : null}
                </Link>
              );
            } else {
              return null;
            }
          })}
        </div>
        <LogoutButton />
      </>
    );
  };




  const changeAppLangauge = (lang) => {
    // setLangChangeLoader(true);
    setTimeout(() => {
      i18n.changeLanguage(lang);
      if (lang === "ar") {
        document.body.lang = "ar";
        document.body.dir = "rtl";
      } else {
        document.body.lang = "en";
        document.body.dir = "ltr";
      }
      localStorage.setItem('appLanguage', lang);
      appLanguageVar(lang);
      setTimeout(() => {
        window.location = window.location.href;
      }, 500);
    }, 200);

  };

  return (
    <>
      <div className="mobile_nav w100 justify-content-between align-items-center">
        <span onClick={() => toggleNav(true)} className="flex1">
          <i className={'fa fa-bars menu_icon '} aria-hidden="true"></i>{' '}
        </span>
        {/* <Link to="/dashboard" className="d-flex justify-content-center flex1">
          <img alt={'Logo'} className="img-fluid navLogo" src={Logo} />
        </Link> */}
        <h6 className="today_date mb-0 flex1 nav-date">{getDate()}</h6>
        <div id="nav_overlay" onClick={() => toggleNav()} className=" d-none"></div>
      </div>
      <Row className={"flex4 user_details" + (accDetails && accDetails.me && accDetails.me.patient && !accDetails.me.patient.profileComplete ? " pointerEventsNone " : "")}>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            {!isStaff ? <div>
              {appLanguage === "ar" ?
                <h6 className="language mt-3 text-right pointer" onClick={() => changeAppLangauge("en")}> English </h6>
                :
                <h6 className="language mt-3 text-right  pointer" onClick={() => changeAppLangauge("ar")} > العربية </h6>
              }
            </div> : null}
            <h6 className="today_date mt-3 text-right">{getDate()}</h6>
          </div>
          <div className="text-light mt-2 hide-sm">
            <EllipsisWithTooltip placement="bottom">
              {userDetails ? userDetails.email : 'User'}
            </EllipsisWithTooltip>
          </div>          
          <Link to={props.isPatientPortal ? "/patientPortal/dashboard" : "/dashboard"}>
            <img alt={'Logo'} className="img-fluid navLogo" src={Logo} />
          </Link>
        </Col>
      </Row>
      <Row className="flex6 mt-3 tabs">{renderNavContents()}</Row>
      <div id="mySidenav" className={(isStaff?"" :" patient_nav " )+ " sidenav "}>
        <div className="closebtn" onClick={() => toggleNav()}>
          <i className={'fa fa-times '} aria-hidden="true"></i>{' '}
        </div>
        {renderNavContents()}
      </div>
    </>
  );
};

export default withRouter(Navigation);