import React from 'react';
// import style from './LogoutButton.module.css';
import { useApolloClient } from '@apollo/client';
import { isLoggedInVar, userDetailsVar, userPermissionsVar } from "../../../cache/cache";
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { dataPatientInVar, appLanguageVar } from '../../../cache/cache.js';
import { useTranslation } from "react-i18next";
const LogoutButton = (props) => {

  const {  t,i18n } = useTranslation();
  const client = useApolloClient();
  const handleLogout = () => {
    const { history } = props;
    client.cache.evict({ fieldName: "me" });
    client.cache.gc();
    client.clearStore();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("is_staff");
    localStorage.removeItem("user_permissions");
    isLoggedInVar(false);
    userDetailsVar(null);
    userPermissionsVar(null);
    dataPatientInVar(null);
    history.push("/");
  };

  return (
    <>
      <Button variant="link" className="logout-btn d-flex justify-content-start text-uppercase" onClick={handleLogout} >  <i className="fa fa-sign-out mx-2 " aria-hidden="true"></i> {t('patientLogoutButton.logout')}</Button>
    </>
  );
};

export default withRouter(LogoutButton);
