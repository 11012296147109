import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import LoginForm from './LoginForm/LoginForm';
import Logo from '../../assets/images/logo.png';
import PasswordReset from "./passwordReset"
import ForgotPassword from "./forgotPassword"
import RegisterForm from './RegisterForm';
import { useTranslation } from "react-i18next";
import { appLanguageVar } from "../../cache/cache";
import { useReactiveVar } from '@apollo/client';
import Preloader from '../../Common/Preloder/Preloader';


const LoginPage = (props) => {
  const { location } = props;
  const { pathname } = location;
  const [displayForm, setDisplayForm] = useState("login");
  const [langChangeLoader, setLangChangeLoader] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const appLanguage = useReactiveVar(appLanguageVar);


  useEffect(() => {
    if (pathname === "/password-reset/") {
      setDisplayForm("passwordReset");
    }
    if (pathname === "/forgot/password") {
      setDisplayForm("forgotPassword");
    }
    if (pathname === "/register") {
      setDisplayForm("Register");
    }
  }, [pathname]);



  const renderForm = () => {
    if (pathname === "/password-reset/") {
      return <PasswordReset />
    }
    else if (pathname === "/forgot/password") {
      return <ForgotPassword />
    }
    else if (pathname === "/register") {
      return <RegisterForm />
    }
    else {
      return <LoginForm setLangChangeLoader={setLangChangeLoader} />
    }
  }

  const setHeader = () => {
    if (displayForm === "login") {
      return t('patientLogin.login');
    } else if (displayForm === "forgotPassword") {
      return t('forgotPassword.resetPassword');
    } else {
      return t('patientLogin.register');
    }
  }


  const changeAppLangauge = (lang) => {
    setLangChangeLoader(true);
    i18n.changeLanguage(lang);
    if (lang === "ar") {
      document.body.lang = "ar";
      document.body.dir = "rtl";
      appLanguageVar(lang);
      localStorage.setItem('appLanguage', lang);
    } else {
      document.body.lang = "en";
      document.body.dir = "ltr";
    }
    appLanguageVar(lang);
    localStorage.setItem('appLanguage', lang);
    setTimeout(() => {
      window.location = window.location.href;
    }, 500);

  };

  return (
    langChangeLoader ? <Preloader /> :
      <Container fluid>
        <Row className={"login_page"}>
          <Col md="6" className={"login_page__logo "}>
            <div className={"logotype"}>
              <img
                alt={"Logo"}
                className="img-fluid navLogo"
                src={Logo}
              />
            </div>
          </Col>
          <Col md="6" className={"login_page__form"}>
            <div className="language_change">
              {appLanguage === "ar" ?
                <span className="text-light pointer" onClick={() => changeAppLangauge("en")} > English </span>
                :
                <span className="text-light pointer" onClick={() => changeAppLangauge("ar")} > العربية </span>
              }    
            </div>
            <Row>
              <Col md="12" className="mx-auto">
                <div className={"logotype mobileLogo"}>
                  <img
                    alt={"Logo"}
                    className="img-fluid navLogo"
                    src={Logo}
                  />
                </div>
                <Row>
                  <Col md="12">
                    {/* <h3 className={'text-white text-center mb-5 mt-2'}><b>{displayForm==="login"?" Staff Portal":" Reset Password" }</b></h3> */}
                    <h3 className={'text-white text-center mb-5 mt-2 login-header'}><b>{setHeader()}</b></h3>
                  </Col>
                  <Col md="12 mt-5" className='login-page-render-form'>
                    {renderForm()}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
  );
};

export default LoginPage;
