import { Row, Col, Card, Button } from 'react-bootstrap';
import Base from './base.js';
import { withRouter } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  success_options,
  capitalizeFirstLetter,
  // getPermissionForAction,
  getDate
} from '../Common/helpers';
import Preloader from '../Common/Preloder/Preloader';
import { getPermissionForAction } from '../Common/helpers';



function LeaveDetail(props) {
  const leave_id = props.match.params.id;
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  
  const leaveRequest_permission = getPermissionForAction('leave_request', '', true);
  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;
  const { data } = useQuery(USER_DETAILS);

  const userDetails = data && data.userDetails ? JSON.parse(data.userDetails) : null;
  const REQUEST_LEAVE_DETAIL = gql`
    query {
        leaveRequest(id:"${leave_id}"){  
          id
          startDate 
          endDate
          totalDays
          status
          leaveType
          created
          description
          user {
            firstName
            lastName
            id
            username
            email
            phone
            hr{
              position{
                name
              }
              department{
                name
              }
              numberAnnualLeaves
              consumedSickLeaves
              remainingAnnualLeaves
              conferenceLeaves
              remainingConferenceLeaves
            }
            
          }
        }
    }
  `;


  // GET leave detail
  const { data: leave_detail_obj = null, loading } = useQuery(
    REQUEST_LEAVE_DETAIL,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );


  // # this will return a list of values in order
  // # 'SICK_FULL_PAY': (6, _("Sick days full pay")),
  // # 'SICK_THREE_QUARTERS_PAY': (6, _("Sick days 3/4 pay")),
  // # 'SICK_HALF_PAY': (6, _("Sick days 1/2 pay")),
  // # 'SICK_ONE_FOURTH_PAY': (6, _("Sick days 1/4 pay")),
  // # 'SICK_NO_PAY': (6, _("Sick days no pay")),

  const REQUEST_SETTINGS = gql`
      query {
        settings
      }
  `;

  // GET leave detail
  const { data: settings_obj = null } = useQuery(
    REQUEST_SETTINGS,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  const APPROVE_LEAVE_REQUEST = gql`
  mutation approvalLeaveRequest(
    $status:String!
    $id:ID!
    $user:ID!
    $startDate:Date!
    $endDate:Date!
    $leaveType:String!
    $description:String!
  ) {
    approvalLeaveRequest(
      input: {    
      id:$id
      user: $user
      startDate: $startDate
      endDate: $endDate
      leaveType: $leaveType
      description: $description
      status: $status }
    ) {
      obj{
        created
        id
        startDate
        endDate
        leaveType
        status
      }
       errors {
        field
        messages
      }
    }
  }
`;

  const CANCEL_LEAVE_REQUEST = gql`
  mutation cancelLeaveRequest(
    $id:ID!
  ) {
    cancelLeaveRequest(
      id:$id
    ) {
      obj{
        created
        id
        startDate
        endDate
        leaveType
        status
      }
    }
  }
  `;

  const [approvalLeaveRequest] = useMutation(APPROVE_LEAVE_REQUEST, {
    onCompleted: ({ approvalLeaveRequest }) => {
      if (approvalLeaveRequest?.errors.length === 0) {
        let status = approvalLeaveRequest?.obj.status;
        openSnackbarSuccess('Leave Request ' + status, [SNACK_DURATION]);
      } else {
        let alert_msg = '';
        approvalLeaveRequest?.errors.map((error) => {
          alert_msg += error.messages.join(' ') + ' ';
          openSnackbar(alert_msg, [50000]);
          return null;
        });
      }
    },
    refetchQueries: [
      { query: REQUEST_LEAVE_DETAIL, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const [cancelLeaveRequest] = useMutation(CANCEL_LEAVE_REQUEST, {
    onCompleted: ({ cancelLeaveRequest }) => {
      console.log("cancelLeaveRequest", cancelLeaveRequest);
      if (cancelLeaveRequest?.obj) {
        let status = cancelLeaveRequest?.obj.status;
        openSnackbarSuccess('Leave Request ' + status, [SNACK_DURATION]);
      } else {
        let alert_msg = '';
        cancelLeaveRequest?.errors.map((error) => {
          alert_msg += error.messages.join(' ') + ' ';
          openSnackbar(alert_msg, [50000]);
          return null;
        });
      }
    },
    refetchQueries: [
      { query: REQUEST_LEAVE_DETAIL, fetchPolicy: 'network-only' }
    ],
    onError: (e) => {
      console.log("eeeeee", e)
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const approveRequest = () => {
    let val = {
      id: leave_detail_obj?.leaveRequest?.id,
      user: leave_detail_obj?.leaveRequest?.user?.id,
      startDate: leave_detail_obj?.leaveRequest?.startDate,
      endDate: leave_detail_obj?.leaveRequest?.endDate,
      leaveType: leave_detail_obj?.leaveRequest?.leaveType.toLowerCase(),
      description: leave_detail_obj?.leaveRequest?.description,
      status: "approved",
    }
    console.log("valvalval", val);
    approvalLeaveRequest({ variables: val });
  }

  const denyRequest = () => {
    let val = {
      id: leave_detail_obj?.leaveRequest?.id,
      user: leave_detail_obj?.leaveRequest?.user?.id,
      startDate: leave_detail_obj?.leaveRequest?.startDate,
      endDate: leave_detail_obj?.leaveRequest?.endDate,
      leaveType: leave_detail_obj?.leaveRequest?.leaveType.toLowerCase(),
      description: leave_detail_obj?.leaveRequest?.description,
      status: "unapproved",
    }

    approvalLeaveRequest({ variables: val });
  }

  const cancelLeaveRequestFunc = () => {
    if (window.confirm("Delete Leave Request?",)) {
      let val = {};
      val["id"] = leave_detail_obj?.leaveRequest?.id;
      cancelLeaveRequest({ variables: val });
    }
  }



  const sickLeaveQuota = leave_detail_obj && leave_detail_obj?.leaveRequest ? Math.ceil((leave_detail_obj?.leaveRequest?.user?.hr?.consumedSickLeaves) / 6) : 0;

  const rightChildButtons = () => {
    if (leave_detail_obj && leave_detail_obj?.leaveRequest && leave_detail_obj?.leaveRequest?.status === "PENDING" && userDetails && leave_detail_obj?.leaveRequest?.user?.username === userDetails.username) {
      return (
        <Button variant="link" className="danger-color" onClick={cancelLeaveRequestFunc} > <b> Delete Leave Request </b> </Button>
      )
    }
  }
  return (
    <Base title={'Leave'} showHeader={true} rightChild={rightChildButtons()} >
      {loading ? <Preloader /> :
        <>
          <Row>
            <Col xs={12} md={3} className="d-flex-column-str mb-4">
              <span className="textPrimaryBold">{leave_detail_obj?.leaveRequest?.user?.firstName} {leave_detail_obj?.leaveRequest?.user?.lastName} </span>
              <span>Email: {leave_detail_obj?.leaveRequest?.user?.email} </span>
              <span>Phone: {leave_detail_obj?.leaveRequest?.user?.phone} </span>
              <span className="textPrimaryBold pt-3">HR</span>
              <span>Name: {leave_detail_obj?.leaveRequest?.user?.hr?.position.name} </span>
              <span>Department: {leave_detail_obj?.leaveRequest?.user?.hr?.department && leave_detail_obj?.leaveRequest?.user?.hr?.department.name} </span>
            </Col>
            <Col md={3} className="ml-auto" >
              <Card >
                <Card.Body className="d-flex align-items-center justify-content-center">
                  <div>
                    <h6 className="text-center text-uppercase"> ANNUAL LEAVE </h6>
                    <h6 className="textPrimaryBold text-center">{leave_detail_obj?.leaveRequest?.user?.hr?.remainingAnnualLeaves}</h6>
                    <h6 className="text-center"> Days Remaining </h6>
                  </div>
                </Card.Body>
              </Card>
              {/* WORKING conferenceLeaves, remainingConference ??? */}
              {leave_detail_obj?.leaveRequest?.user?.hr?.conferenceLeaves > 0 ? 
                <Card >
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    <div>
                      <h6 className="text-center text-uppercase"> CONFERENCE LEAVE </h6>
                      <h6 className="textPrimaryBold text-center">{leave_detail_obj?.leaveRequest?.user?.hr?.remainingConferenceLeaves}</h6>
                      <h6 className="text-center"> Days Remaining </h6>
                    </div>
                  </Card.Body>
                </Card>
              : null}
            </Col>
            <Col md={3}>
              {settings_obj && settings_obj.settings ? <Card>
                <Card.Body>
                  <h6 className="text-center text-uppercase"> SICK LEAVE </h6>
                  <h6 className="text-center "> Consumed:<span className="textPrimaryBold "> {leave_detail_obj?.leaveRequest?.user?.hr?.consumedSickLeaves} </span></h6>
                  <h6 className={"text-center " + (sickLeaveQuota === 1 || sickLeaveQuota === 0 ? " border_primary " : " ")}>Full Pay: <span className="textPrimaryBold ">{settings_obj.settings.sick_full_pay} </span></h6>
                  <h6 className={"text-center" + (sickLeaveQuota === 2 ? " border_primary " : " ")}>3/4 Pay: <span className="textPrimaryBold ">{settings_obj.settings.sick_three_quaters_pay} </span></h6>
                  <h6 className={"text-center" + (sickLeaveQuota === 3 ? " border_primary " : " ")}>1/2 Pay: <span className="textPrimaryBold ">{settings_obj.settings.sick_half_pay} </span></h6>
                  <h6 className={"text-center" + (sickLeaveQuota === 4 ? " border_primary " : " ")}>1/4 Pay: <span className="textPrimaryBold ">{settings_obj.settings.sick_one_fourth_pay} </span></h6>
                  <h6 className={"text-center" + (sickLeaveQuota === 5 ? " border_primary " : " ")}>No Pay: <span className="textPrimaryBold ">{settings_obj.settings.sick_no_pay} </span></h6>
                </Card.Body>
              </Card> : null}
            </Col>
            <Col className="py-3" md={12}>
              <h6 className="textPrimaryBold" > <b> Leave Details </b></h6>
              <h6> From: {getDate(leave_detail_obj?.leaveRequest?.startDate)} To: {getDate(leave_detail_obj?.leaveRequest?.endDate)}</h6>
              <h6 > Leave Type: <b> {capitalizeFirstLetter(leave_detail_obj?.leaveRequest?.leaveType.toLowerCase())} </b> </h6>
              <h6 > Status: <b> {leave_detail_obj?.leaveRequest?.status} </b> </h6>
              <p> Description: {leave_detail_obj?.leaveRequest?.description} </p>

            </Col>
          </Row>
          {leave_detail_obj && leave_detail_obj?.leaveRequest && 
          leave_detail_obj?.leaveRequest?.status === "PENDING" &&
          leaveRequest_permission.indexOf("approve") >= 0 ? 
          <Row className={'button_block mb-2'} >
            <Col className="justify-content-center d-flex mt-2" xs={{ order: 'last' }}>
              <Button variant="primary" size="md" active onClick={denyRequest}>
                Deny
              </Button>
            </Col>
            <Col className="justify-content-center d-flex mt-2" xs={{ order: 'first' }}>
              <Button variant="primary" size="md" active onClick={approveRequest}>
                Approve
              </Button>
            </Col>
          </Row> : null}
        </>
      }
    </Base>
  );
}

export default withRouter(LeaveDetail);