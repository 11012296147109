import React, { useRef, useEffect } from 'react';
import { gql, useMutation, useQuery ,useLazyQuery} from '@apollo/client';
import CreateNewPatient from './CreateNewPatient';
import { dataPatientInVar } from '../../cache/cache.js';
import Base from '../base.js';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar';
import { error_options, success_options, SNACK_DURATION, ERROR_MESSAGE } from '../../Common/helpers.js';

const CREATE_PATIENT = gql`
  mutation createUpdatePatient(
    $firstName: String!
    $middleName: String!
    $lastName: String!
    $phone: String!
    $dob: Date!
    $gender:String!
    $email:String!
    $civil_id:String
    $doctor:ID!
    $address:String
    $governorate:ID
    $country:String!
    $area:ID
    $referred_by:String
    $diagnosis:String
    $medication:String
  ) {
    createUpdatePatient(
      input: { firstName: $firstName,middleName: $middleName, lastName: $lastName, phone: $phone, dob: $dob, gender:$gender, civilId:$civil_id,doctor:$doctor, address:$address,governorate:$governorate, country:$country, area:$area,
        referredBy:$referred_by,impressionDiagnosis:$diagnosis,medication:$medication,email:$email}
    ) {
      obj {
        firstName
        middleName
        lastName
        email
        identifier
        phone
        id
        doctor{
          identifier
          id
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

const UPDATE_PATIENT = gql`
  mutation createUpdatePatient(
    $firstName: String!
    $lastName: String!
    $middleName: String!
    $phone: String!
    $dob: Date!
    $gender:String!
    $email:String!
    $civil_id:String
    $doctor:ID!
    $address:String
    $governorate:ID
    $country:String!
    $area:ID
    $diagnosis:String
    $referred_by:String
    $medication:String
    $id: ID!
    $identifier:String
  ) {
    createUpdatePatient(
      input: { firstName: $firstName,middleName: $middleName, lastName: $lastName, phone: $phone, dob:$dob, gender:$gender, civilId:$civil_id,doctor:$doctor, address:$address,governorate:$governorate, country:$country, area:$area,impressionDiagnosis:$diagnosis,medication:$medication,id: $id,identifier:$identifier, referredBy:$referred_by, email:$email }
    ) {
      obj {
        firstName
        lastName
        email
        identifier
        phone
        id
        doctor{
          identifier
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

const GET_COUNTRIES = gql`
  query{
    countries
  } 
`;

const GET_GOVERNORATES = gql`
  query{
    governorates{
      edges{
        node{
          id
          name
        }
      }
    }
  } 
`;

const GET_AREAS = gql`
  query areas($governorate: ID!) {
    areas(governorate_Id:$governorate){
      totalCount
      edges{
        node{
          id
          name
        }
      }
    }
  } 
`;



const CreateNewPatientHoc = (props) => {
  const formikRef = useRef();
  const { history } = props;
  const { data: countriesData = null } = useQuery(GET_COUNTRIES);
  const { data: governoratesData = null } = useQuery(GET_GOVERNORATES);
  const [getAreasForGov, { data: areasData = [] }] = useLazyQuery(GET_AREAS, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  let selected_button = document.activeElement.name;


  useEffect(() => {
    return function cleanup() {
      localStorage.setItem('appointment_flow', false);
    };
  }, []);

  const [createUpdatePatient] = useMutation(CREATE_PATIENT, {
    onCompleted: ({ createUpdatePatient }) => {
      var is_appointment_flow = localStorage.getItem('appointment_flow') === 'true' ? true : false;
      if (createUpdatePatient.errors.length === 0) {
        dataPatientInVar(createUpdatePatient.obj);
        openSnackbarSuccess('New Patient Created', [SNACK_DURATION]);
        formikRef.current.handleReset();
        if (is_appointment_flow) {
          dataPatientInVar(createUpdatePatient.obj);
          history.push('/create/appointment');
        }
      }
      if (selected_button === 'appointment' && createUpdatePatient.errors.length === 0) {
        openSnackbarSuccess('New Patient Created', [SNACK_DURATION]);
        dataPatientInVar(createUpdatePatient.obj);
        history.push('/create/appointment');
      } else {
        let alert_msg = '';
        createUpdatePatient.errors.map((error) => {
          alert_msg += error.messages.join(' ') + ' ';
          openSnackbar(alert_msg, [50000]);
          return null;
        });
        if (createUpdatePatient.obj) {
          history.push('/patient/record/'+createUpdatePatient.obj.identifier);
        }
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const [UpdatePatient] = useMutation(UPDATE_PATIENT, {
    onCompleted: ({ createUpdatePatient }) => {
      if (createUpdatePatient.errors.length === 0) {
        dataPatientInVar(createUpdatePatient.obj);
        openSnackbarSuccess(' Patient Updated', [SNACK_DURATION]);
        history.push('/patient/record/' + createUpdatePatient.obj.identifier);
      } else {
        let alert_msg = '';
        createUpdatePatient.errors.map((error) => {
          alert_msg += error.messages.join(' ') + ' ';
          openSnackbar(alert_msg, [50000]);
          return null;
        });
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const { location } = props;
  const { pathname } = location;

  let is_edit = pathname.indexOf('/edit/patient') > -1 ? true : false;

  return (
    <Base title={is_edit ? 'Edit Patient' : 'Create Patient'} showHeader={true}>
      <CreateNewPatient
        createUpdatePatient={createUpdatePatient}
        UpdatePatient={UpdatePatient}
        is_edit={is_edit}
        formikRef={formikRef}
        countriesData={countriesData}
        governoratesData={governoratesData}
        areasData={areasData}
        getAreasForGov={getAreasForGov}
      />
    </Base>
  );
};

export default withRouter(CreateNewPatientHoc);
