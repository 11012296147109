import { Row, Col, Button, Table } from 'react-bootstrap';
import React, { useState } from 'react';
import Base from './base.js';
import { Link, withRouter } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { error_options, success_options, SNACK_DURATION, ERROR_MESSAGE, getPermissionForAction, isUserStaff, getDate, urlActions,displayMiddleName } from '../Common/helpers';
import { useSnackbar } from 'react-simple-snackbar';
import { useTranslation } from "react-i18next";
import Preloader from '../Common/Preloder/Preloader';

function RequestReport(props) {
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  let patientId = urlActions(window.location.href, 'get', 'patientId');
  const isStaff = isUserStaff();
  let returnAllSectionPermissions = true;
  const patient_report_permission = getPermissionForAction('patient_report', '', returnAllSectionPermissions);
  const { t } = useTranslation();

  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;

  const { data } = useQuery(USER_DETAILS);

  var userDetails = data && data.userDetails ? data.userDetails : null;

  if (userDetails && userDetails.indexOf('username') > -1) {
    userDetails = JSON.parse(userDetails);
  }

  console.log("userDetailsuserDetails",userDetails);

  const REQUEST_PATIENT_REPORTS = gql`
  query {
    patientReports{
      edges {
        node {
            id
            status
            reason
            created
            clinicalSummary
            treatmentRecommendation
            notes
            daignosis
            patient{
              firstName
              middleName
              lastName
              id
            }
            reportType{
              id
              name
              price
              description
            }
            languageEn
            doctor{
              id
              firstName
              lastName
            }
        }
      }
    }
  }
`;

  const REQUEST_PATIENT_REPORTS_BY_DOC = gql`
  query {
    doctorPatientReports{
      edges {
        node {
            id
            status
            reason
            created
            clinicalSummary
            treatmentRecommendation
            notes
            daignosis
            patient{
              firstName
              middleName
              lastName
              id
            }
            reportType{
              id
              name
              price
              description
            }
            languageEn
            doctor{
              id
              firstName
              lastName
            }
        }
      }
    }
  }
`;


  const REQUEST_SINGLE_PATIENT_REPORTS = gql`
  query {
    patientReports(patient:"${patientId}"){
      edges {
        node {
            id
            status
            reason
            created
            clinicalSummary
            treatmentRecommendation
            notes
            daignosis
            patient{
              firstName
              middleName
              lastName
              id
            }
            reportType{
              id
              name
              price
              description
            }
            languageEn
            doctor{
              id
              firstName
              lastName
            }
        }
      }
    }
  }
`;

  const DELETE_PATIENT_REPORT = gql`
mutation deletePatientReport(
  $id:ID!
) {
  deletePatientReport(
    id:$id
  ) {
    deleted
  }
}
`;

  const REQ_PATIENT_REPORT = patientId ? REQUEST_SINGLE_PATIENT_REPORTS : REQUEST_PATIENT_REPORTS;
  const REQ_REPORT = isStaff && userDetails.doctor && userDetails.doctor.id ? REQUEST_PATIENT_REPORTS_BY_DOC : REQ_PATIENT_REPORT;
  // delete form instance
  const [deletePatientReport] = useMutation(DELETE_PATIENT_REPORT, {
    onCompleted: ({ deletePatientReport }) => {
      if (deletePatientReport.errors && deletePatientReport.errors.length > 0) {
        let error_messages = deletePatientReport.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (deletePatientReport.deleted) {
        openSnackbarSuccess("Patient Report Request Deleted");
      }
    },
    refetchQueries: [
      { query: REQ_REPORT, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });




  // GET REQUEST_PATIENT_REPORTS DATA
  const { data: patient_reports_obj = null } = useQuery(
    REQ_REPORT,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );


  const getButtons = () => {
    return (
      <div className="d-flex">
        <Link to={"/patientPortal/report/request/create"}>
          <Button variant="primary" className='back'>
            {' '}
            <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i> {t('patientrequestReport.createReportRequest')}{' '}
          </Button>
        </Link>
      </div>
    );
  };


  const deleteReportFunc = (reportReq) => {
    let val = {
      id: reportReq.node.id
    }
    if (window.confirm("Delete Report Request?",)) {
      deletePatientReport({ variables: val });
      console.log("valvalval", val);
    }
  }

  var patient_reports_data = null;
  if (isStaff && patient_reports_obj && patient_reports_obj.doctorPatientReports) {
    patient_reports_data = patient_reports_obj.doctorPatientReports;
  }
  else if (patient_reports_obj && patient_reports_obj.patientReports) {
    patient_reports_data = patient_reports_obj.patientReports;
  }

  return (
    <Base title={t('patientrequestReport.clinicalReportRequest')} rightChild={!isStaff ? getButtons() : null} isPatientPortal={!isStaff} showHeader={true}>
      <Row>
        <Col sm={12} md={12}>
          <h6 className="mt-4 textGrey"> <b>  {t('patientrequestReport.requestedClinicalReports')} </b> </h6>
          {patient_reports_data && patient_reports_data.edges && patient_reports_data.edges.length > 0 ? <Table responsive>
            <thead>
              <tr>
                {isStaff ? <th>Patient</th> : null}
                <th>{t('patientrequestReport.submitted')}</th>
                {/* <th>Language</th> */}
                <th>{t('patientrequestReport.reportType')}</th>
                <th>{t('patientrequestReport.reportDescription')}</th>
                <th>{t('patientrequestReport.language')}</th>
                {/* <th>Price</th> */}
                {/* <th>Reason</th> */}
                <th>{t('patientrequestReport.doctor')}</th>
                {/* <th>Status</th> */}
                <th>{t('patientrequestReport.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {patient_reports_data.edges.map((reportReq, index) => {
                if (reportReq.node.status !== "PENDING") {
                  console.log("reportReq",reportReq)
                  return (
                    <tr key={index}>
                      {isStaff ? <td> <Link to={(isStaff ? "/patient/report/detail/" : "/patientPortal/report/detail/") + reportReq.node.id}>  {reportReq.node.patient.firstName} {displayMiddleName(reportReq.node.patient.middleName)}{reportReq.node.patient.lastName} </Link> </td> : null}
                      <td className="align-middle patient-capitalize-first-letter">
                        {/* <Link to={(isStaff ? "/patient/report/detail/" : "/patientPortal/report/detail/") + reportReq.node.id}> {getDate(new Date(reportReq.node.created))} </Link> */}
                        {getDate(new Date(reportReq.node.created))}
                      </td>
                      {/* <td> {reportReq.node.languageEn ? "EN" : "AR"} </td> */}
                      <td> {reportReq.node.reportType.name}</td>
                      <td> {reportReq.node.reportType.description} </td>
                      <td> {reportReq.node.languageEn? "EN":"AR"}</td>
                      {/* <td> {reportReq.node.reportType.price} </td> */}
                      {/* <td> {reportReq.node.reason} </td> */}
                      <td> {reportReq.node.doctor.firstName} {reportReq.node.doctor.lastName} </td>
                      {/* <td> {reportReq.node.status} </td> */}
                      <td>
                        {reportReq.node.status !== "COMPLETED" ?
                          isStaff ? 
                            <div className="block_item_edit d-flex">
                              {(isStaff && patient_report_permission && patient_report_permission.includes("edit")) || !isStaff ?
                                <Link to={(isStaff ? "/patient/report/request/update/" + reportReq.node.id + "?doctorFill=true" : "/patientPortal/report/request/update/") + reportReq.node.id}> <Button variant="link" className="px-0 " >
                                  <i className="fa fa-edit"></i>
                                </Button></Link> : null}
                              {(isStaff && patient_report_permission && patient_report_permission.includes("delete")) || !isStaff ?
                                <Button variant="link" className="danger-color" onClick={() => deleteReportFunc(reportReq)} >
                                  <i className="fa fa-times"></i>
                                </Button> : null}
                            </div> 
                            : null
                        : 
                        <Link to={(isStaff ? "/patient/report/detail/" : "/patientPortal/report/detail/") + reportReq.node.id}> {t("patientRecordFiles.view")} </Link>}
                      </td>
                    </tr>
                  )
                }
                return null;
              })}
            </tbody>
          </Table> : t('patientrequestReport.noClinicalReportsRequested')}
          {/* </Table> : <Preloader />} */}
        </Col>
      </Row>
    </Base>
  );
}

export default withRouter(RequestReport);