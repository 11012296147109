import { Row, Col, Form, Button } from 'react-bootstrap';
import { useRef, useState, useEffect } from 'react';
import Base from './base.js';
import CalendarHoc from '../component/AppointmentDetails/Calendar/CalendarHoc';
import Preloader from '../Common/Preloder/Preloader';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { Formik } from 'formik';
import { withRouter, Link } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar';
import { error_options, SNACK_DURATION, ERROR_MESSAGE, getPermissionForAction, isUserStaff ,displayMiddleName} from '../Common/helpers';
import { myAppointmentDetailsVar,appFromCalVar } from '../cache/cache';
import SelectPatientModal from './SearchForPatient/selectPatientModal';
import { eventsDataVar } from '../cache/cache.js';
import { useTranslation } from "react-i18next";
import { useReactiveVar } from '@apollo/client';
import { appLanguageVar } from "../cache/cache";

export const REQUEST_DOCTOR = gql`
  query {
    doctors {
      edges {
        node {
          firstName
          lastName
          id
          email
          identifier
          recurringEvents{
            edges {
              node {
                startDate
                startTime
                endTime
                title
                description
                everyday
                days
                dateUntil
              }
            }
          }
          eventType {
            edges {
              node {
                id
                title{
                  name
                }
                duration
                buffer
                price
                eventType {
                  id
                  name
                }
                eventLocation {
                  id
                  title
                }
              }
            }
          }
          availability {
            edges {
              node {
                id
                day
                shifts {
                  edges {
                    node {
                      startTime
                      endTime
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MY_APPOINTMENT_DETAILS = gql`
  query {
    myAppointmentDetails @client
  }
`;
const REQUEST_LOCATION = gql`
query {
  listEventLocation {
    edges {
      node {
        title
        id
      }
    }
  }
}
`;
const REQUEST_EVENTS = gql`
  query($doctor_Identifier: String, $search: String, $status:String, $active:Boolean!, $location:ID, $start: DateTime, $end: DateTime,) {
    events(doctor_Identifier: $doctor_Identifier,search: $search,status:$status,active:$active, location_Id_Icontains:$location, start:$start, end:$end) {
      edges {
        node {
          title
          id
          status
          start
          end
          eventType{
            id
            name
            color{
              hexCode
              name
              colorId
            }
          }
          doctor {
            id
            firstName
            lastName
            identifier
          }
          patient {
            id
            firstName
            middleName
            lastName
            identifier
          }
        }
      }
    }
  }
`;

function MyAppointments() {
  const USER_DETAILS = gql`
  query receiveDate {
    userDetails @client
    }
  `;

  const { t } = useTranslation();
  const { data } = useQuery(USER_DETAILS);
  const fullCalendarRef = useRef();
  var userDetails = data.userDetails;
  if (userDetails && userDetails.indexOf('username') > -1) {
    userDetails = JSON.parse(userDetails);
  }
  const isStaff = isUserStaff();
  const [openSnackbar] = useSnackbar(error_options);
  const formikRef = useRef();
  const searchPatientRef = useRef();
  const [selectedPatientFullName, setSelectedPatientFullName] = useState('');
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  // const [calendarActiveMonth, setCalendarActiveMonth] = useState(1);
  const [dateStart, setDateStart] = useState(new Date());
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [selectedDoctorIdentifier, setSelectedDoctorIdentifier] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [active, setActive] = useState(!isStaff?true:false);
  const [pageLoading, setPageLoading] = useState(true);
  const [firstTime, setFirstTime] = useState(true);
  const appLanguage = useReactiveVar(appLanguageVar);
  const appFromCal = useReactiveVar(appFromCalVar);
  const [patientDataObj, setPatientDataObj] = useState(null);

  useEffect(() => {
    if (dateEnd === null) {
      let dt = new Date();
      dt.setDate(dt.getDate() + 30);
      setDateEnd(dt);
      // setCalendarActiveMonth(dt.getMonth());
    }
    GetEvents();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    if(fullCalendarRef.current){
      let calendarView = fullCalendarRef.current.getApi().view;
      if(calendarView){
        let dtSt = new Date(calendarView.activeStart);
        setDateStart(dtSt);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullCalendarRef.current]);

  

  
  const { loading: myAppointmentDetailsLoading } = useQuery(MY_APPOINTMENT_DETAILS, {
    onCompleted: ({ myAppointmentDetails }) => {
      if (
        myAppointmentDetails.selectedDoctorIdentifier ||
        myAppointmentDetails.selectedPatientFullName || myAppointmentDetails.status
      ) {
        setSelectedDoctorIdentifier(myAppointmentDetails.selectedDoctorIdentifier);
        setSelectedPatientFullName(myAppointmentDetails.selectedPatientFullName);
        formikRef.current.setFieldValue('patient', myAppointmentDetails.selectedPatientFullName);
        formikRef.current.setFieldValue('doctor', myAppointmentDetails.selectedDoctorIdentifier);
        setSelectedStatus(myAppointmentDetails.selectedStatus);
        if (myAppointmentDetails.selectedStatus === "active") {
          setActive(true);
        }
        else {
          setActive(false);
        }

        let variables={};
        let dataToPass = appFromCal?appFromCal:{};
        if(myAppointmentDetails?.patientDataObj){
          setPatientDataObj(myAppointmentDetails.patientDataObj);
          dataToPass["patientData"]=myAppointmentDetails.patientDataObj;
          variables["patient_Identifier"]=myAppointmentDetails.patientDataObj.identifier;
        }
        if(myAppointmentDetails?.selectedDoctor){
          setSelectedDoctor(myAppointmentDetails.selectedDoctor);
          dataToPass["doctor"]=myAppointmentDetails.selectedDoctor;
          variables["doctor_identifier"]= myAppointmentDetails.selectedDoctorIdentifier;
        }
        appFromCalVar(dataToPass);

        let calendarView = fullCalendarRef?.current?.getApi()?.view;
        if(calendarView){
          let dtSt = new Date(calendarView.activeStart);
          let dtEn = new Date(dtSt);
          dtEn.setDate(dtEn.getDate()+30);
          if(dtSt && dtEn){
            variables["start"]= dtSt;
            variables["end"]= dtEn;
          }
          GetEvents({
            variables:variables,
          });
        }
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });
  const { data: doctorsList } = useQuery(REQUEST_DOCTOR, {
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });


  // const getEventColor = (event) => {
  //   // console.log('event', event)
  //   if (event.status === "PENDING") {
  //     return appColors.grape;
  //   } else if (event.status === "CANCELLED") {
  //     return appColors.tomato;
  //   } else if (event.status === "PAID" || event.status === "CONFIRMED") {
  //     return appColors.sage;
  //   }
  // }

  const [GetEvents, { data: eventsList, loading }] = useLazyQuery(REQUEST_EVENTS, {
    fetchPolicy: "network-only",
    variables: { doctor_Identifier: selectedDoctorIdentifier, search: selectedPatientFullName, status: selectedStatus, active: active, location: selectedLocation, start: dateStart, end: dateEnd },
    notifyOnNetworkStatusChange: true,
    // pollInterval: 10000,
    onCompleted: (data) => {
      var events = [];
      if (data && data.events && data.events.edges) {
        events = data.events.edges.map((evt) => {
          let val = JSON.parse(JSON.stringify(evt.node));
          // let color = getEventColor(evt.node);
          val["backgroundColor"] = val.eventType.color?.hexCode;
          val["borderColor"] = val.eventType.color?.hexCode;
          // val["backgroundColor"] = color;
          // val["borderColor"] = color;
          return val;
        });
      }
      var displayEventsList = events;
      if (displayEventsList && userDetails && userDetails.canViewAppointments && isStaff) {
        let canViewAppointments = userDetails.canViewAppointments.edges.map(doc => {
          return doc.node.identifier;
        })
        displayEventsList = displayEventsList.filter(i => {
          /* FIXME:  two issues here, 
          
          1. we are checking TOO late (the appointments should have not 
             been requested from the backend or should have been filtered out. 
          2. (Backend) How can there be appointments without a doctor?   
          3. I forgot, but there's a third thing wrong with this.
          */

          try {
            return canViewAppointments.includes(i.doctor.identifier) === true
          } catch {
            return false;
          }

        }
        );
        eventsDataVar(displayEventsList);
      }
      else if (!isStaff && displayEventsList) {
        eventsDataVar(displayEventsList);
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const doctors = doctorsList && doctorsList.doctors ? doctorsList.doctors.edges : [];

  const onSelectPatient = (patientData) => {
    formikRef.current.setFieldValue('patient', `${patientData.firstName} ${displayMiddleName(patientData.middleName)}${patientData.lastName}`);
    setSelectedPatientFullName(`${patientData.firstName} ${displayMiddleName(patientData.middleName)}${patientData.lastName}`);
    setPatientDataObj(patientData);
    let dataToPass=appFromCal?appFromCal:{};
    dataToPass["patientData"]=patientData;
    appFromCalVar(dataToPass);

  };

  const { data: location = [] } = useQuery(REQUEST_LOCATION, {
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });


  const onSelectDoctor = (event) => {
    formikRef.current.setFieldValue('doctor', event.target.value);
    setSelectedDoctorIdentifier(event.target.value);
    if (!event.target.value) {
      formikRef.current.setFieldValue('patient', '');
      setSelectedPatientFullName('');
      setPatientDataObj(null);
      GetEvents({variables:{}});
      myAppointmentDetailsVar({});
    }
    let docObj = doctors.find(i=>(event.target.value && i.node.identifier===event.target.value));
    let dataToPass=appFromCal?appFromCal:{};
    if(docObj){
      setSelectedDoctor(docObj);
      dataToPass["doctor"]=docObj;
    }
    else{
      setSelectedDoctor(null);
      dataToPass["doctor"]=null;
    }
    appFromCalVar(dataToPass);
  };

  useEffect(() => {
    if (selectedPatientFullName || selectedDoctorIdentifier) {
      myAppointmentDetailsVar({
        selectedDoctorIdentifier,
        selectedPatientFullName,
        selectedStatus,
        selectedDoctor,
        patientDataObj
      });
    }
  }, [selectedPatientFullName,doctors,selectedDoctorIdentifier,selectedDoctor, selectedStatus,patientDataObj]);


  useEffect(() => {
    if (active) {
      if (isStaff) {
        setSelectedStatus("active");
      }
    }
  }, [active]);


  // Create a USEEFFECT that will check if the eventslist is done loading, and set PAGE_LOADER to FALSE, only for the first time.
  useEffect(() => {
    if (!loading && firstTime) {
      setPageLoading(false)
      setFirstTime(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const initialValues = {
    doctor: '',
    patient: '',
    status: '',
  }

  const handleChangeStatus = (e) => {
    setSelectedStatus(e.target.value);
    if (e.target.value === "active") {
      setActive(true);
    }
    else {
      setActive(false);
    }
  }
  // Event Status 
  const eventStatus = {
    pending: "Pending",
    confirmed: "Confirmed",
    no_show: "No Show",
    cancelled: "Cancelled"
  }
  var is_my_appointments = false;
  var has_add_permission = getPermissionForAction("appointment", "add");


  const createNewApp = () => {
    localStorage.setItem('appointment_flow', true);
  }

  const resetPatient = () => {
    setSelectedPatientFullName('');
    setPatientDataObj(null);
    let dataToPass = appFromCal?appFromCal:{};
    dataToPass["patientData"]=null;
    appFromCalVar(dataToPass);
    formikRef.current.setFieldValue('patient', "");
  }
  return (
    <Base title={is_my_appointments ? " My Appointments " : t('patientAppointments.appointments')} showHeader={true}
      isPatientPortal={!isStaff}
      rightChild={
        <Link to={has_add_permission && isStaff ? '/search/patient' : '/patientPortal/create/appointment'}>
          {!isStaff || (has_add_permission && isStaff) ? <Button variant="primary" className="back" onClick={createNewApp}>
            {' '}
            <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i> {t('patientAppointments.newAppointment')} {' '}
          </Button> : null}
        </Link>
      }
    >
      <Row className="mt-3">
        <Col xs={12} sm={12} md={12} lg={11} xl={10} className="mx-auto mb-2" style={{ minHeight: '114vh' }}>
          <Formik enableReinitialize initialValues={initialValues} innerRef={formikRef}>
            {({ values, handleChange }) => (
              <Form autoComplete="off" className="mb-4">
                <Row className="filter-block">
                  {!is_my_appointments ? <Col md={12} xl={4} className={appLanguage==="en"? "ml-auto":"mr-auto"}>
                    <Form.Group as={Row} className="text-left text-xl-right">
                      <Form.Label column sm={3} md={3} xl={4} >
                        {t('patientAppointments.practitioner')}
                      </Form.Label>
                      <Col sm={12} md={9} xl={8}>
                        <Form.Control
                          autoComplete="off"
                          as="select"
                          name="doctor"
                          value={values.doctor}
                          onChange={onSelectDoctor}>
                          <option value="">{t('patientAppointments.selectPractitioner')}</option>
                          {doctors.map((doctor) => {
                            return (
                              <option value={doctor.node.identifier} key={doctor.node.id}>
                                {doctor.node.firstName} {doctor.node.lastName}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col> : null}
                  {isStaff ? <Col md={12} xl={4}>
                    <Form.Group as={Row} className="text-left text-xl-right">
                      <Form.Label column sm={3} md={3} xl={4}>
                        {t('patientAppointments.patient')}
                      </Form.Label>
                      <Col sm={12} md={9} xl={8} ref={searchPatientRef}>
                        <Form.Control
                          placeholder="Select Patient"
                          autoComplete="off"
                          type="text"
                          name="patient"
                          // disabled={is_my_appointments?false: (selectedDoctorIdentifier ? false : true)}
                          value={values.patient}
                          onChange={handleChange}
                          className="mr-sm-2 pr-0 float-left pr-5"
                        />
                        <span
                          onClick={() =>
                            setShowPatientSearch(true)
                          }
                          className="search-icon">
                          <i className="fa fa-search"></i>
                        </span>
                      </Col>
                      <Col md={12} className="d-flex justify-content-end">
                        <Button variant="link" className="back fontSmall" onClick={resetPatient} >
                          Clear Patient
                        </Button>
                      </Col>
                    </Form.Group>
                  </Col> : null}
                  <Col md={12} xl={4} >
                    {isStaff ? <Form.Group as={Row} className="text-left text-xl-right">
                      <Form.Label column sm={3} md={3} xl={4}  >
                        Status
                      </Form.Label>
                      <Col sm={12} md={9} xl={8}>
                        <Form.Control
                          autoComplete="off"
                          as="select"
                          name="status"
                          // disabled={is_my_appointments?false: (selectedDoctorIdentifier ? false : true)}
                          value={selectedStatus}
                          onChange={handleChangeStatus}>
                          <option value="">Select Status</option>
                          {Object.entries(eventStatus).map(([key, value], index) => {
                            return (
                              <option value={key} key={index}>
                                {value}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group> :
                      <Form.Group as={Row} className="text-left text-xl-right">
                        <Form.Label column sm={3} md={3} xl={4}  >
                          {t('patientAppointments.location')}
                        </Form.Label>
                        <Col sm={12} md={9} xl={8}>
                          <Form.Control
                            autoComplete="off"
                            as="select"
                            name="location"
                            // disabled={is_my_appointments?false: (selectedDoctorIdentifier ? false : true)}
                            value={selectedLocation ? selectedLocation.id : ""}
                            onChange={(e) => setSelectedLocation(e.target.value)}>
                            <option value="">{t('patientAppointments.selectLocation')}</option>
                            {location && location.listEventLocation ? location.listEventLocation.edges.map((item, index) => {
                              return (
                                <option value={item.node.id} key={index}>
                                  {item.node.title}
                                </option>
                              );
                            }) : null}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    }
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          {/* <Col md={4} className="d-flex justify-content-center align-items-center" xs={{ order: 'first' }} >
              <h3> Appointments </h3>
            </Col>
            <Col md={4} className="d-flex justify-content-end align-items-center" md={{order: 'last'}}>
            </Col> */}

          {
            pageLoading ? <Preloader />
              :
              <CalendarHoc myAppointments={true} eventsList={eventsList} loading={loading} fullCalendarRef={fullCalendarRef} GetEvents={GetEvents} selectedDoctor={selectedDoctor} />

          }

        </Col>
      </Row>
      <SelectPatientModal
        showPatientSearch={showPatientSearch}
        setShowPatientSearch={setShowPatientSearch}
        onSelectPatientFunc={onSelectPatient}
      />
    </Base>
  );
}
export default withRouter(MyAppointments);
