import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import CustomModals from '../Modal/modal';
import { gql, useQuery } from '@apollo/client';
import { getPatientsAge,displayMiddleName } from '../../Common/helpers';

const NotesBlock = (props) => {
  const [activeTemplate, setActiveTemplate] = useState(0);
  const [textAreaValue, setTextAreaValue] = useState('');
  const textAreaRef = useRef();

  const {
    setShowModalNotes,
    showModalNotes,
    is_modal,
    patient,
    previousNotes,
    setNotesValue,
    notesValue,
    encounternote_permission
  } = props;

  let firstName = patient ? patient.firstName : '';
  let middleName = patient ? displayMiddleName(patient.middleName) : '';
  let lastName = patient ? patient.lastName : '';
  let age = patient ? getPatientsAge(patient.dob) : '';
  let number = patient ? patient.phone : '';
  // let email = patient ? patient.email : '';
  let gender = patient ? patient.gender : '';

  useEffect(() => {
    if (previousNotes) {
      let prevNotes = previousNotes.edges && previousNotes.edges[0] ? previousNotes.edges[0].node.text:"";
      if(notesValue.length < prevNotes.length){
        setTextAreaValue(prevNotes);
        if (prevNotes === '+++') {
          setTextAreaValue('');
        }
      }
    }
  }, [previousNotes, notesValue]);


  useEffect(() => {
    setNotesValue(textAreaValue)
  }, [textAreaValue, setNotesValue]);

  useEffect(() => {
    if(notesValue){
      setTextAreaValue(notesValue);
    }
  }, [notesValue]);

  // useEffect(() => {
  //   if(notesValue){
  //     setTextAreaValue(notesValue);
  //   }
  // }, []);
  

  let templates = [];

  const REQUEST_TEMPLATES = gql`
  query {
    noteTemplates{
      edges {
        node {
            id
            title
            text
        }
      }
    }
  }
`;


const { data, loading } = useQuery(REQUEST_TEMPLATES, {
  fetchPolicy: 'network-only',
  // variables: {
  //   start: startDate,
  // },
  onCompleted() {
    // Set state here?
    // console.log(data);
  },
  onError: () => {
    // openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);

  },
});




const variables = [
  {"label":"__age__", "value":age}, 
  {"label": "__firstname__", "value":firstName}, 
  {"label": "__middleName__", "value":middleName}, 
  {"label":"__lastname__", "value": lastName},
  {"label":"__number__", "value": number},
  {"label":"__gender__", "value": gender} 
]


if (!loading) {
  templates =data && data.noteTemplates? data.noteTemplates.edges.map((template) => {
    
    let processedText = template.node.text;

    for (var item in variables) {
      processedText = processedText.replace(variables[item].label, variables[item].value)
    }
    
    
    return {
      "title": template.node.title,
      "text": processedText
    };
  }):[];
  
}

  const addTemplate = () => {
    setShowModalNotes(false);
    setTextAreaValue(
      (prevTextValue) => {
        return !prevTextValue ? (prevTextValue += templates[activeTemplate].text) : (prevTextValue += '\n\n' + templates[activeTemplate].text)  
      }
    );
  };

  const handleChange = (e) => {
    setTextAreaValue(e.target.value);
  };

  const handleTabKey = (e) => {
    // TODO troubleshoot functionality
    if (e.code === 'Tab') {
      console.log('tab key')
      e.preventDefault();

      let positionsArr = [];
      let lastIndex = -1;
      while ((lastIndex = textAreaValue.indexOf('___', lastIndex + 1)) !== -1) {
        positionsArr.push(lastIndex);
      }
      let pos;
      for (let i = 0; i <= positionsArr.length - 1; i++) {
        if (e.target.selectionEnd <= positionsArr[i]) {
          pos = positionsArr[i];
          break;
        } else {
          pos = positionsArr[0];
        }
      }
      textAreaRef.current.setSelectionRange(pos, pos + 3);
    }
  };



  return (
    <>
      <Form.Group as={Row}>
        {!is_modal ? (
          <Form.Label
            column
            xs={7}
            sm={7}
            md={7}
            className="text-left textBold textGrey"
          >
            NOTES
          </Form.Label>
        ) : null}
        {!is_modal ? (
          <Col xs={5} sm={5} md={5} className="text-xs-right d-flex justify-content-end align-items-start">
            {encounternote_permission && encounternote_permission.includes("add")? <Button
              variant="link"
              href="#"
              className="d-flex justify-content-end px-0 icon-black add_button"
              onClick={() => setShowModalNotes(true)}
            >
              <i className="fa fa-plus-circle mt-2"></i>
            </Button>:null}
          </Col>
        ) : null}
      </Form.Group>
      <Col sm={12} md={12} className="notes-textarea-container">
        <Form.Control
          className="notes-textarea"
          ref={textAreaRef}
          autoComplete="off"
          as="textarea"
          rows={4}
          name="note"
          value={textAreaValue}
          onChange={handleChange}
          onKeyDown={handleTabKey}
        />
      </Col>
      <CustomModals
        dialogClassName="modal60h" 
        showModal={showModalNotes}
        modalHeader={'CHOOSE TEMPLATE'}
        setShowModal={setShowModalNotes}
        contentClassName={" notes-modal"}
      >
        <Row className="mb-5 mt-5 tab-template-container">
          <Col sm={4} md={4} xs={12} className="tab-title">
            {templates.map((template, index) => {
              return (
                <div key={index}>
                  <h3
                    className={`tablinks ${index === activeTemplate ? 'active' : ''
                      }`}
                    onClick={(e) => setActiveTemplate(+e.target.dataset.index)}
                    data-index={index}
                  >
                    {template.title}
                  </h3>
                </div>
              );
            })}
          </Col>
          <Col sm={8} md={8} xs={12} className="tab-content">
            <div className="tabcontent">
              {templates[activeTemplate] && templates[activeTemplate].text}
            </div>
          </Col>
        </Row>
        <Row className="mb-3 mt-5">
          <Col className="d-flex justify-content-center justify-content-sm-end">
            <Button variant="primary" size="md" onClick={()=>setShowModalNotes(false)}>
              CANCEL
            </Button>
            <Button
              variant="primary"
              size="md"
              className="ml-3"
              onClick={addTemplate}
            >
              ADD
            </Button>
          </Col>
        </Row>
      </CustomModals>
    </>
  );
};

export default withRouter(NotesBlock);
