import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Button, Form, Table } from 'react-bootstrap';
import Base from './base.js';
import { withRouter } from 'react-router-dom';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getDate,
  getForamttedTime,
  success_options,
  getPatientsAge,
  displayMiddleName
  // getPermissionForAction,
} from '../Common/helpers';
import Preloader from '../Common/Preloder/Preloader';
import CustomModals from '../component/Modal/modal';
import LabsComponent from "./Labs/LabsComponent";
import Logo from '../assets/images/logo.png';
import { useDropzone } from "react-dropzone";

var today = new Date();
today = getDate(today) + " " + getForamttedTime(today);


function LabRequestDetail(props) {
  const [showModal, setShowModal] = useState(false);
  const [labInitialValues, setLabInitialValues] = useState(null);
  const [openSnackbarSuccess] = useSnackbar(success_options);

  const lab_req_id = props.match.params.id;

  const [openSnackbar] = useSnackbar(error_options);

  const REQUEST_LAB_REQUEST_DETAIL = gql`
  query {
    labRequest(id:"${lab_req_id}"){
      id
      file
      patient{
        phone
        civilId
        id
        firstName
        middleName
        lastName
        identifier
        dob
        displayGender
        impressionDiagnosis
      }
      searchText
      description
      usedTemplate {
        id
        staticId
        testCategories {
          edges {
            node {
              id
              name
              staticId
              structure
              testType
              parent {
                name
              }
              children {
                edges {
                  node {
                    id
                    name
                    staticId
                    labTests {
                      edges {
                        node {
                          staticId
                          name
                          id
                        }
                      }
                    }
                  }
                }
              }
              labTests {
                edges {
                  node {
                    staticId
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      groupTests {
        edges {
          node {
            name
            id
            staticId
            testType
            labTests {
              edges {
                node {
                  staticId
                  name
                  id
                }
              }
            }
          }
        }
      }
      specificTests {
        edges {
          node {
            name
            id
            staticId
          }
        }
      }
      testResults {
        edges {
          node {
            specificTest {
              name
              id
              staticId
            }
            category{
              name
              id
              testType
            }
            result
          }
        }
      }
    }
  }
`;


  const DELETE_LAB_REQUEST = gql`  
    mutation deleteLabRequest($id:ID!){
      deleteLabRequest(id:$id){
        deleted
      }
    } 
    `;


  const UPLOAD_MUTATION = gql`
    mutation updateLabFile($file: Upload!, $id:ID!) {
      updateLabFile( file: $file,id:$id) {
        obj{
          file
        }
      }
    }
  `;
  // pass in the UPLOAD_MUTATION mutation we created earlier.
  const [uploadFile] = useMutation(UPLOAD_MUTATION, {
    onCompleted({ updateLabFile }) {
      console.log("updateLabFile", updateLabFile);
      openSnackbarSuccess("Result file uploaded");
      updateDetailPage();
    },
    onError: (e) => {
      console.log("eeeee", e);
      // openSnackbarError(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // QUESTION what is the difference - useLazyQuery and then useEffect and useQuery
  // and no useEffect?
  const [getLabRequestDetail, { data, loading }] = useLazyQuery(REQUEST_LAB_REQUEST_DETAIL, {
    fetchPolicy: 'network-only',
    onError: (e) => {
      console.log('REQUEST_LAB_REQUEST_DETAIL  error: ', e.errors);
    },
  });

  useEffect(() => {
    getLabRequestDetail();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDetailPage = () => { // used to pass in lab component that updates lab request detail after edit
    getLabRequestDetail();
  }

  // DELETE_ LAB REQ
  const [deleteLabRequest] = useMutation(DELETE_LAB_REQUEST, {
    onCompleted: ({ deleteLabRequest }) => {
      console.log("deleteLabRequest", deleteLabRequest);
      props.history.push("/patient/labrequests/" + labInitialValues.patientIdentifier)
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });


  var labRequest = data && data.labRequest ? data.labRequest : null;

  let patient_name = labRequest && labRequest.patient ? labRequest.patient.firstName + " " + displayMiddleName(labRequest.patient.middleName) +labRequest.patient.lastName : "";

  const deleteRefFunc = () => {
    if (window.confirm("Delete Referral?",)) {
      let val = {};
      val["id"] = labRequest.id;
      console.log("VALL", val)
      deleteLabRequest({ variables: val });
    }
  }



  useEffect(() => {
    let initVal = {};
    if (labRequest) {
      initVal["id"] = labRequest.id;
      initVal["description"] = labRequest.description;
      initVal["selectedTemplate"] = {
        node: labRequest.usedTemplate
      };
      let specificTestReqArr = labRequest.specificTests.edges.map(test => {
        return test.node.staticId;
      })
      let profileTestReqArr = labRequest.groupTests.edges.map(test => {
        return test.node.staticId;
      })
      initVal["specificTestReqArr"] = specificTestReqArr;
      initVal["profileTestReqArr"] = profileTestReqArr;
      initVal["patientIdentifier"] = labRequest.patient.identifier;
      setLabInitialValues(initVal);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labRequest]);

  const editLabRequest = () => {
    setShowModal(true);
  }

  const renderTemplateTwo = () => {
    let testNormalCategoryArr = labRequest && labRequest.usedTemplate.testCategories && 
    labRequest.usedTemplate.testCategories.edges ? 
    labRequest.usedTemplate.testCategories.edges.filter(i => i.node.testType === "NORMAL") : 
    [];
    let testProfileCategoryArr = labRequest && labRequest.usedTemplate.testCategories && 
    labRequest.usedTemplate.testCategories.edges ? 
    labRequest.usedTemplate.testCategories.edges.filter(i => i.node.testType === "PROFILE") : 
    [];

    let groupTestsUsed = [];
      labRequest && 
      labRequest.groupTests && 
      labRequest.groupTests.edges.map((item, index) => {
        let labTests = item.node.labTests.edges.map((item, index) => {
          return {
            name: item.node.name,
            staticId: item.node.staticId,
            id: item.node.id,
            __typename: "SpecificTestNode"
          };
          
        })
        groupTestsUsed = [...groupTestsUsed, ...labTests]; 
        return null;
      })
    
    if (testProfileCategoryArr.length > 0) {
      testProfileCategoryArr = testProfileCategoryArr.filter(i => i.node.structure !== "child");
    }
    return (
      <div className="template_two">
        <div className="category_box mb-4">
          <h6 className='notes-title'><b> Notes / Comments:  </b></h6>
          <p className='profiles-comments'> {labRequest && labRequest.description ? labRequest.description : " - "}</p>
        </div>
        <div className='mb-4'>
          <h6 className='notes-title'><b>Tests</b></h6>
        </div>
        <div className="category_section">
          <Row className="tests-container">
            {testNormalCategoryArr && testNormalCategoryArr.length > 0 ? testNormalCategoryArr.map((normalCat, index) => {
              let lab_tests = normalCat.node.labTests.edges;
              let children = normalCat.node.children.edges;
              let has_active_test = false;

              if (labInitialValues && 
                labInitialValues.specificTestReqArr && 
                lab_tests
                  .find(i => {
                    let exist = groupTestsUsed.filter(item => item.staticId === i.node.staticId);
                    return ( 
                        labInitialValues.specificTestReqArr.includes(i.node.staticId) &&
                        exist.length === 0
                      )
                    })) {
                has_active_test = true;
              }
              if (labInitialValues && labInitialValues.specificTestReqArr && children) {
                children.map(child => {
                  let child_lab_test = child.node.labTests.edges;
                  if (child_lab_test
                    .find(i => {
                      let exist = groupTestsUsed.filter(item => item.staticId === i.node.staticId);
                      return ( 
                          labInitialValues.specificTestReqArr.includes(i.node.staticId) &&
                          exist.length === 0
                        )
                      })) {
                    has_active_test = true;
                  }
                  return null;
                })
              }
              
              return (
                has_active_test ?
                  <Col xs={4} sm={6} md={6} lg={4} className="active-tests mb-3" key={index}>
                    <div className="category_box">
                      <h6 className="group_title"><b>{normalCat.node.name}</b></h6>
                      {lab_tests.length > 0 ?
                        lab_tests.map((lab, index) => {
                          if (labInitialValues && labInitialValues.specificTestReqArr.includes(lab.node.staticId)) {
                            return (
                              <div key={index} className='create-new-patient-checkboxes'>
                                <Form.Label className="">
                                  {lab.node.name}
                                </Form.Label>
                              </div>)
                          }
                          return null;
                        })
                        : null}
                      {children.length > 0 ?
                        children.map((child, index) => {
                          let child_lab_test = child.node.labTests.edges;
                          return (<div key={index}>
                            {child_lab_test.length > 0 ?
                              child_lab_test.map((child_lab, index) => {
                                if (labInitialValues && labInitialValues.specificTestReqArr.includes(child_lab.node.staticId)) {
                                  return (
                                    <div key={index} className='create-new-patient-checkboxes my-1'>
                                      <Form.Label>
                                        {child_lab.node.name}
                                      </Form.Label>
                                    </div>)
                                }
                                return null;

                              })
                              : null}
                          </div>)
                        })
                        : null}
                    </div>
                  </Col>
                  : null
              )
            }) : null}
          </Row>
        </div>
        {
          labRequest && 
          labRequest.groupTests && 
          labRequest.groupTests.edges.length > 0 ?
          <Row className="mx-0 mb-5">
          <Col xs={12} md={12} className="category_box_profiles  px-0 mt-2">
            <div className="category_section">
              <Table responsive>
                <thead>
                  <tr>
                    <th className='profiles-th'>Profiles</th>
                    <th className='profiles-th'>Profile Brief Description</th>
                  </tr>
                </thead>
                <tbody>
                  
                    {labRequest && 
                    labRequest.groupTests && 
                    labRequest.groupTests.edges.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td xs={4} md={4} className='create-new-patient-checkboxes'>
                              <span>{item.node.name}</span>
                            </td>
                            <td xs={8} md={8}>
                              {item && item.node && item.node.labTests && item.node.labTests.edges.map((lab, index) => {
                                return (
                                  <span key={index}>
                                    {lab.node.name}{index === item.node.labTests.edges.length - 1 ? "" : ", "} 
                                  </span>
                                )
                              })}
                            </td>
                          </tr>
                        )})}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row> : null
        }

        <CustomModals showModal={showModal} modalHeader={'LAB REQUEST'} setShowModal={setShowModal} dialogClassName="modal60h" >
          <LabsComponent
            patient_id={labRequest && labRequest.patient ? labRequest.patient.id : ""}
            setShowModal={setShowModal}
            showModal={showModal}
            updateDetailPage={updateDetailPage}
            labInitialValues={labInitialValues}
          />
        </CustomModals>
      </div>
    )
  }

  const printLabRequest = () => {
    window.print();
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      // select the first file from the Array of files
      const file = acceptedFiles[0];
      // use the uploadFile variable created earlier
      if (file && labRequest) {
        let val = {
          file: file,
          id: labRequest.id
        }
        console.log("val", val);
        uploadFile({
          // use the variables option so that you can pass in the file we got above
          variables: { ...val },
          onCompleted: () => { console.log("done") },
        });
      }
      else {
        console.log("NO File")
      }
    },
    // pass in uploadFile as a dependency
    [uploadFile, labRequest]
  );


  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });


  const viewLabReqResult = () => {
    window.open(labRequest.file, '_blank');
  }

  return (
    <>
    <Base page_loading={loading} title={patient_name ? patient_name + ' LAB REQUEST' : 'LAB REQUEST'} showHeader={true}
      rightChild={(
        <div className="d-flex flex-column d-print-none">
          <div className="d-flex justify-content-end">
            <Button variant="primary" className='mx-2' onClick={printLabRequest} >
            <b> Print </b>
          </Button>
            <Button variant="primary" className='mx-2' onClick={editLabRequest}>
              {' '}
              <b> Edit </b>{' '}
            </Button>
            <Button variant="danger" className='encounter-detail-btn mx-2' onClick={deleteRefFunc} >
              {' '}
              <b> Delete </b>{' '}
            </Button>
          </div>
          {labRequest ? <div className="d-flex mt-3  justify-content-end">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button variant="primary" className='mx-2' >
                {' '}
                <b>{labRequest.file ? " Upload new Result" : " Upload Result "}</b>{' '}
              </Button>
            </div>
            {labRequest.file ? <Button variant="primary" className='mx-2' onClick={viewLabReqResult} >
              {' '}
              <b> View Result </b>{' '}
            </Button> : null}
          </div> : null}
        </div>
      )
      }>
        {
          loading ? <Preloader /> :
            <Row className='d-print-none'>
              <Col md={12}>
                {renderTemplateTwo()}
              </Col>
            </Row>
        }
      </Base>
      
        <>
          <div className='print'>
            <div className='lab-request-header'>
              <Row className='h100'>
                <Col xs={6} sm={6} md={6} className='d-flex justify-content-start align-items-center'>
                  <img
                    alt={"Logo"}
                    className="img-fluid printLogo"
                    src={Logo}
                    />
                </Col>
                <Col xs={6} sm={6} md={6} className='d-flex justify-content-end align-items-center'>
                  {today}
                </Col>
              </Row>
            </div>
            <div className="lab-request-footer d-flex justify-content-center align-items-center">
              Al Tijaria Tower - Floor 23 - Alsoor Street - Kuwait City,Telephone - +965 22055334
            </div>
            
            <table  className='lab-request-table'>
              <thead>
                <tr>
                  <td>
                    <div className="lab-request-header-space"></div>
                  </td>
                </tr>
              </thead>
              <tbody className='lab-request-tbody'>
                <tr>
                  <td>
                    <div className='page'>
                      {labRequest? <Row>
                        <Col xs={6} className="lab-request-patient">
                          <h6 className="capitalize" > Patient Name: {labRequest.patient.firstName} {displayMiddleName(labRequest.patient.middleName)} {labRequest.patient.lastName} </h6>
                          <h6 className="capitalize" > Civil ID: {labRequest.patient.civilId} </h6>
                          <h6 className="capitalize" > Phone: {labRequest.patient.phone} </h6>
                          <h6 className="capitalize" > Sex: {labRequest.patient.displayGender} </h6>
                          <h6 className="capitalize" > Age: {getPatientsAge(labRequest.patient.dob)} </h6>
                        </Col>
                        <Col xs={6} className="lab-request-patient">
                        </Col>
                        <Col xs={12} sm={12} md={12} className='mt-3'>              
                          <h5 className="category_title text-center " > <b>  Lab Test Request </b> </h5>
                        </Col>
                      </Row>:null}
                      <Row>
                        <Col xs={12} md={12}>
                          {renderTemplateTwo()}
                        </Col>
                      </Row>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <div className="lab-request-footer-space"></div>
                  </td>
                </tr>
              </tfoot>
            </table>
  
          </div>
          </>
          
    </>

  );
}
export default withRouter(LabRequestDetail);
