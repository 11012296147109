import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Figure, Card } from 'react-bootstrap';
import { gql, useQuery, useMutation } from '@apollo/client';
import Base from './base.js';
import Preloader from '../Common/Preloder/Preloader';
import { useSnackbar } from 'react-simple-snackbar';
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getPermissionForAction,
  getDay,
  getDate,
  getForamttedTime, 
  displayMiddleName
  // isUserStaff
} from '../Common/helpers';
import UpcomingAppointmentCard from '../component/upcomingAppointmentCard';
import Badge from './Badge';



function Dashboard(props) {
  const [approvedEncounter, setApprovedEncounter] = useState();
  const [badgeForReportRequests, setBadgeForReportRequests] = useState();
  const [badgeForMedicalReports, setBadgeForMedicalReports] = useState();
  const [badgeForApproved, setBadgeForApproved] = useState();
  const [badgeForApprovals, setBadgeForApprovals] = useState();
  const [badgeForLeaveRequests, setBadgeForLeaveRequests] = useState();
  const [badgeForUpcomingAppointments, setBadgeForUpcomingAppointments] = useState();

  const [upcomingAppointments, setUpcomingAppointments] = useState();

  const LEAVE_REQUESTS = gql`
    query {
      leaveRequests(first: 3, status: "pending") {
        totalCount
        edges {
          node {
            id
            startDate
            endDate
            totalDays
            status
            user {
              firstName
              lastName
              phone
              hr {
                position {
                  name
                }
                department {
                  name
                }
                numberAnnualLeaves
              }
            }
          }
        }
      }
    }
  `;

  const REQUEST_EVENTS = gql`
    query($start: DateTime!) {
      events(start: $start, orderby: "start", first: 3, status: "confirmed", active: true) {
        edges {
          node {
            title
            id
            displayStatus
            status
            start
            end
            patient {
              firstName
              lastName
              middleName
              identifier
            }
            doctor{
              identifier
            }
            eventType {
              name
            }
          }
        }
      }
    }
  `;
  // const REQUEST_EVENTS_FOR_BADGE = gql`
  //   query($start: DateTime, $active:Boolean!) {
  //     events(start: $start, active:$active) {
  //     # events(start: $start, orderby: "start", first: 4, status: "confirmed", active: true) {
  //       totalCount
  //       edges {
  //         node {
  //           start
  //         }
  //       }
  //     }
  //   }
  // `;

  const USER_DETAILS = gql`
  query receiveDate {
    userDetails @client
    }
  `;


  const REQUEST_PATIENT_REPORTS = gql`
  query {
    doctorPatientReports(first: 3,status:"paid"){
      totalCount
      edges {
        node {
          id
          created
          reportType{
            name
          }
          patient{
            firstName
            middleName
            lastName
            id
          }
        }
      }
    }
  }
`;

const REQUEST_MEDICAL_REPORTS = gql`
  query {
    patientReportsForApproval{
      totalCount
      edges {
        node {
            status
            id
            reason
            displayStatus
            reportType {
              name
            }
            patient {
              firstName
              middleName
              lastName
            }
        }
      }
    }
  }
`;
  const REQUEST_FORM_BLOCK_APPROVALS = gql`
    query {
      formBlockApprovals
      # (
        # offset: 1
        # before: ""
        # after: ""
        # first: 3
        # last: 3
        # patient_SearchText: ""
        # patient_SearchText_Icontains: ""
        # patient_SearchText_Istartswith: ""
      # ) 
      {
        totalCount
        edges {
          node {
            created
            modified
            patient {
              firstName
              middleName
              lastName
              identifier
            }
            encounter {
              id
              date
              doctor {
                firstName
                lastName
              }
            }
            formBlock {
              name
              created
            }
          }
        }
      }
    }  
  `;

  const REQUEST_APPROVED_BLOCKS = gql`
    query {
      approvedBlockNotifications(first: 3){
        totalCount
        edges {
          node {
              id
              created
            encounter {
              id
              doctor {
                firstName
                lastName
              }
            }
            patient{
              id
              firstName
              middleName
              lastName
            }
          }
        }
      }
    }
  `;

const DELETE_APPROVED_BLOCK = gql`
  mutation deleteBlockApprovedNotification($id: ID!){
    deleteBlockApprovedNotification(id:$id) {
      deleted
    }
  }
`;


  // GET REQUEST_PATIENT_REPORTS DATA
  const { data: patient_reports_obj = null } = useQuery(
    REQUEST_PATIENT_REPORTS,
    {
      fetchPolicy: 'network-only',
      // onCompleted: (data) => {
      //   console.log(data)
      //   // const badgeResult = data?.doctorPatientReports?.edges?.filter(item => {
      //   //   return item.node.status !== "COMPLETED";
      //   // })
      //   // console.log(badgeResult)
      //   setBadgeForReportRequests(data?.doctorPatientReports?.totalCount)
      // },
      onCompleted: (data) => {
        setBadgeForReportRequests(data?.doctorPatientReports?.totalCount)
      },
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );
  
  const { data: medical_reports_obj = null } = useQuery(
    REQUEST_MEDICAL_REPORTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setBadgeForMedicalReports(data?.patientReportsForApproval?.totalCount)
      },
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  const { data: formBlockApprovalsObj = null } = useQuery(
    REQUEST_FORM_BLOCK_APPROVALS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setBadgeForApprovals(data?.formBlockApprovals?.totalCount)
      },
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  const { data: approvedBlocks = null } = useQuery(
    REQUEST_APPROVED_BLOCKS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setBadgeForApproved(data?.approvedBlockNotifications?.totalCount)
      },
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  const { data: userDetailsData } = useQuery(USER_DETAILS);

  var userDetails = userDetailsData ? userDetailsData.userDetails : null;
  if (userDetails && userDetails.indexOf('username') > -1) {
    userDetails = JSON.parse(userDetails);
  }

  const [deleteBlockApprovedNotification] = useMutation(DELETE_APPROVED_BLOCK, {
    onCompleted: ({ deleteBlockApprovedNotification }) => {
      if (deleteBlockApprovedNotification.errors && deleteBlockApprovedNotification.errors.length > 0) {
        let error_messages = deleteBlockApprovedNotification.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (deleteBlockApprovedNotification.deleted) {
        props.history.push(`/encounter/detail/${approvedEncounter}`);
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  let startDate = new Date();
  startDate = startDate.toISOString().split('.')[0];
  const [openSnackbar] = useSnackbar(error_options);
  const { data, loading } = useQuery(REQUEST_EVENTS, {
    fetchPolicy: 'network-only',
    variables: {
      start: startDate,
    },
    onCompleted(data) {
      let upApp = data?.events?.edges?.map((evt) => {
        return evt.node
      })
      setUpcomingAppointments(upApp);
      setBadgeForUpcomingAppointments(data?.events?.totalCount)
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // const { dataForBadge, dataForBadgeLoading } = useQuery(REQUEST_EVENTS_FOR_BADGE, {
  //   fetchPolicy: 'network-only',
  //   variables: { active: true, start: startDate },
  //   onCompleted: (data) => {
  //     setBadgeForUpcomingAppointments(data?.events?.totalCount)
  //   },
  //   onError: () => {
  //     openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
  //   },
  // });

  const { data: leaveRequests, loading: leaveRequestsLoading } = useQuery(LEAVE_REQUESTS, {
    fetchPolicy: 'network-only',
    variables: {
      start: startDate,
    },
    onCompleted(data) {
      setBadgeForLeaveRequests(data?.leaveRequests?.totalCount)
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // let upcoming_appointments = [];
  // if (data) {
  //   upcoming_appointments = data.events.edges.map((evt) => {
  //     return evt.node;
  //   });
  //   // upcoming_appointments = sortByDate(upcoming_appointments);
  // }



  const has_app_permission_view = getPermissionForAction(
    'appointment',
    'list',
  );

  const renderUpcomingAppointments = (displayEventsList) => {
    return displayEventsList.map((event, index) => {
      // let date = new Date(event.start);
      return (
        <Link
          to={'/appointment/detail/' + event.id}
          className="dark_link"
          key={index}
        >
          <UpcomingAppointmentCard patient_record={event.patient} event={event} extraClasses="w100" />
        </Link>
      );
    });
  };

  const REQUEST_USER_PERMISSION = gql`
  query receiveDate {
    userPermissions @client
  }
`;
  var { data: user_permissions } = useQuery(
    REQUEST_USER_PERMISSION
  );

  let userPermissions = user_permissions ? JSON.parse(user_permissions.userPermissions) : null;
  let userLeaveRequest = userPermissions ? userPermissions.leave_request : '';

  const leaveRequestsArr = leaveRequests && leaveRequests.leaveRequests && leaveRequests.leaveRequests.edges ? leaveRequests.leaveRequests.edges : [];

  const renderLeaveRequests = () => {
    return leaveRequestsArr.map((leaveRequest, index) => {
      let startDate = new Date(leaveRequest.node.startDate);
      let endDate = new Date(leaveRequest.node.endDate);
      return (
        <Link
          to={'leave/request/' + leaveRequest.node.id}
          className="dark_link"
          key={index}
        >
          <Card className=''>
            <Card.Body>
              <span>{`${leaveRequest.node.user.firstName} ${leaveRequest.node.user.lastName}`}</span>
              <div className="d-flex  justify-content-between mt-3">
                <div>
                  From: <span> {getDay(startDate.getDay())} </span>
                  <span>{getDate(startDate)}</span>
                </div>
                <div>
                  To: <span> {getDay(endDate.getDay())} </span>
                  <span>{getDate(endDate)}</span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Link>
      )
    }
    )
  }

  const renderPatientReportRequests = () => {
    return patient_reports_obj.doctorPatientReports.edges.map((report, index) => {
      let Requested = new Date(report.node.created);
      return (
        <Link
          to={'/patient/report/request/update/' + report.node.id + "?doctorFill=true"}
          className="dark_link"
          key={index}
        >
          {/* get date here! */}
          <Card className=''>
            <Card.Body>
              <span>{`${report.node.patient.firstName} ${displayMiddleName(report.node.patient.middleName)}${report.node.patient.lastName}`}</span>
              <div className="d-flex  justify-content-between mt-3">
                <div>
                  Requested On: <span> {getDay(Requested.getDay())} </span><br/>
                  <span>{getDate(Requested)}</span>
                </div>
                <div>
                  Report Type: <span> {report.node.reportType.name} </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Link>
      )
    }
    )
  }

  const renderMedicalReportRequests = () => {
    const medArr = medical_reports_obj?.patientReportsForApproval?.edges;
    const formApprovalsArr = formBlockApprovalsObj?.formBlockApprovals?.edges;
    // let arr = medical_reports_obj?.patientReportsForApproval?.edges.concat(formBlockApprovalsObj?.formBlockApprovals?.edges);
    const arr = medArr?.concat(formApprovalsArr ? formApprovalsArr : null);
    // setMedicalReportRequestsLength(arr.length)

    return arr?.slice(0, 3).map((report, index) => {
      let Requested = new Date(report?.node?.created);
      if (report?.node) {
        if (report.node.reportType) {
          return (
            <Link
              to={'/patient/report/request/update/' + report.node.id + "?doctorFill=true"}
              className="dark_link"
              key={index}
            >
              <Card className=''>
                <Card.Body>
                  <span>{`${report.node.patient.firstName} ${displayMiddleName(report.node.patient.middleName)}${report.node.patient.lastName}`}</span>
                  <div className="d-flex  justify-content-between mt-3">
                    <div>
                      Requested On: <span> {getDay(Requested.getDay())} {getDate(Requested)}</span>
                    </div>
                    <div>
                      Report Type: <span> {report?.node?.reportType?.name} </span>
                    </div>
                  </div>
                    </Card.Body>
              </Card>
            </Link>
          )
        } else {
          return (
            <Link
              to={{
                pathname: 
                  report.node.encounter
                    ? `/encounter/detail/${report.node.encounter.id}`
                    : `/patient/record/${report.node.patient.identifier}`,
                state: { patientIdentifier: report.node.patient.identifier }
              }}
              className="dark_link"
              key={index}
            >
              <Card>
                <Card.Body>
                  <div>{report.node.formBlock.name}</div>
                  <div className='mt-3'> Patient: {report.node.patient.firstName} {report.node.patient.lastName}</div>
                  <div>Practitioner: {report.node.encounter ? `${report.node.encounter.doctor.firstName} ${report.node.encounter.doctor.lastName}` : null}</div>
                  <div>
                    <span>{getDate(report.node.encounter && report.node.encounter.date ? report.node.encounter.date : report.node.formBlock.created)}</span>{' '}
                    <span>{getForamttedTime(report.node.encounter && report.node.encounter.date ? report.node.encounter.date : report.node.formBlock.created)}</span>
                  </div>
                </Card.Body>
          </Card>
        </Link>
      )
        }
      }

    })
    }  

    

  // const renderFormBlocksForApprovement = () => {
  //   return formBlockApprovalsObj?.formBlockApprovals?.edges?.map((formBlockApproval, index) => {
  //     return (
  //       <Link
  //         to={{
  //           pathname: 
  //             formBlockApproval.node.encounter
  //               ? `/encounter/detail/${formBlockApproval.node.encounter.id}`
  //               : `/patient/record/${formBlockApproval.node.patient.identifier}`,
  //           state: { patientIdentifier: formBlockApproval.node.patient.identifier }
  //         }}
  //         className="dark_link"
  //         key={index}
  //       >
  //         <Card>
  //           <Card.Body>
  //             <div>{formBlockApproval.node.formBlock.name}</div>
  //             <div className='mt-3'> Patient: {formBlockApproval.node.patient.firstName} {formBlockApproval.node.patient.lastName}</div>
  //             <div>Practitioner: {formBlockApproval.node.encounter ? `${formBlockApproval.node.encounter.doctor.firstName} ${formBlockApproval.node.encounter.doctor.lastName}` : null}</div>
  //             <div>
  //               <span>{getDate(formBlockApproval.node.encounter && formBlockApproval.node.encounter.date ? formBlockApproval.node.encounter.date : formBlockApproval.node.formBlock.created)}</span>{' '}
  //               <span>{getForamttedTime(formBlockApproval.node.encounter && formBlockApproval.node.encounter.date ? formBlockApproval.node.encounter.date : formBlockApproval.node.formBlock.created)}</span>
  //             </div>
  //           </Card.Body>
  //         </Card>
  //       </Link>
  //     )
  //   })
  // }

  const removeApprovedBlock = (block) => {
    deleteBlockApprovedNotification({ variables: { id: block.node.id } });
    setApprovedEncounter(block.node.encounter.id)
  }
  

  const renderApprovedBlocks = () => {
    return approvedBlocks.approvedBlockNotifications.edges.map((block, index) => {
      // let Requested = new Date(block.node.created);
      return (
        <Card className='card-approved-block' key={index} onClick={() => removeApprovedBlock(block)}>
          <Card.Body>
            <span>{`${block.node.patient.firstName} ${displayMiddleName(block.node.patient.middleName)}${block.node.patient.lastName}`}</span>
            <div className="mt-3">
              <div>
                Created: <span> {getDate(new Date(block.node.created))} </span>
              </div>
              <div>
                Practitioner: <span> {block.node.encounter.doctor.firstName} {block.node.encounter.doctor.lastName} </span>
              </div>
            </div>
          </Card.Body>
        </Card>
      )})
    }

  const renderGridContent = () => {
    let data = [
      {
        link: '/appointments',
        key: "appointment",
        title: 'Appointments',
        subTitle: 'View appointment and create new appointment',
      },
      {
        link: '/manual/payment',
        title: 'Send payment',
        key: "manual_payment",
        subTitle: 'Manual Payments',
      },
      // {
      //   link: "#",
      //   title: "Lab test and imaging",
      //   subTitle: "Require lab work, test or imaging"
      // },
      {
        link: '/waiting/list',
        key: 'waiting_list',
        title: 'Waiting list',
        subTitle: 'View patients on waiting list',
      },
      {
        link: '/patients',
        key: 'patient',
        title: 'patient records',
        subTitle: 'View and edit patient records',
      },
      {
        link: "/leaves",
        key: "leave_request",
        title: " Annual and sick leave",
        subTitle: "Remaining vacation days, sick days and request leaves"
      },
    ];
    // if(patient_reports_obj && patient_reports_obj.doctorPatientReports && patient_reports_obj.doctorPatientReports.edges.length > 0){
    //   data.push(
    //     {
    //       link: "/patient/report/request",
    //       key:"patient_report",
    //       title: " Clinical Report Requests",
    //       subTitle: "Enter Clinical report requested by patient"
    //     },
    //   )
    // }
    data = data.filter(i => getPermissionForAction(i.key, 'list', false, user_permissions.userPermissions) || getPermissionForAction(i.key, 'add', false, user_permissions.userPermissions));

    return data.map((item, index) => (
      <Col xs={12} md={6} lg={6} key={index}>
        <Link to={item.link}>
          <Figure className="centered-figure mb-0 align-items-baseline">
            <div className="icon-image-container mb-4">
              <Figure.Image className="img-fluid" src={item.icon} />
            </div>
            <Figure.Caption>
              <h4 className="grid-title text-left">{item.title}</h4>
              <p className="grid-sub-title text-left">{item.subTitle}</p>
            </Figure.Caption>
          </Figure>
        </Link>
      </Col>
    ));
  };

  let canViewAppointments = userDetails && userDetails.canViewAppointments && userDetails.canViewAppointments.edges ? userDetails.canViewAppointments.edges.map(doc => {
    return doc.node.identifier;
  }) : null;
  let displayEventsList = upcomingAppointments?.filter(i => {
    /* FIXME:  two issues here, 
    
    1. we are checking TOO late (the appointments should have not 
       been requested from the backend or should have been filtered out. 
    2. (Backend) How can there be appointments without a doctor?   
    3. I forgot, but there's a third thing wrong with this.
    */

    try {
      return canViewAppointments.includes(i.doctor.identifier) === true
    } catch {
      return false;
    }

  });

  console.log('approvedBlocks', approvedBlocks)

  return (
    <Base title={'Dashboard'} showHeader={true} isDashboard={true} rightContainerClass={loading ? "page_loader_base" : ""}>
      {/* Content */}
      {loading || leaveRequestsLoading ? <Preloader /> :
        <>
          <Row>
            {(displayEventsList &&
              displayEventsList.length > 0 &&
              has_app_permission_view
              ) ? (
              <Col sm={12} md={6} lg={4} xl={4} className="mb-4">
                <div className='d-flex align-items-baseline justify-content-center'>
                  <h6 className='text-center textGrey mr-1'> <b> Upcoming Appointments </b></h6><Badge badge={badgeForUpcomingAppointments} />
                </div>
                {renderUpcomingAppointments(displayEventsList.slice(0, 3))}
                {displayEventsList.length > 3 ? 
                  <Link to={'/appointments'}>
                    <Button variant="link" block className="back ">
                      View All Appointments
                    </Button>
                  </Link>
                : null}
              </Col>
            ) : (
              <Col sm={12} md={6} lg={4} xl={4} className="mb-4">
                <div className='d-flex align-items-baseline justify-content-center'>
                  <h6 className='text-center textGrey mr-1'><b>Upcoming Appointments</b></h6>
                </div>
                <p className='text-center'>No Items For Upcoming Appointments</p>
              </Col>
            )}
            {getPermissionForAction('leave_request', "approve") ? (
              <Col sm={12} md={6} lg={4} xl={4} className="mb-4">
                <div className='d-flex align-items-baseline justify-content-center'>
                  <h6 className='text-center textGrey mr-1'><b> Leave Requests</b></h6><Badge badge={badgeForLeaveRequests} />
                </div>
                {renderLeaveRequests()}
                {leaveRequests?.leaveRequests?.totalCount > 3 ?
                  <Link to={'/manage/leave/requests'}>
                    <Button variant="link" block className="back ">
                      View All Leave Requests
                    </Button>
                  </Link>
                : null}
              </Col>
            ) : (
              <Col sm={12} md={6} lg={4} xl={4} className="mb-4">
                <div className='d-flex align-items-baseline justify-content-center'>
                  <h6 className='text-center textGrey mr-1'><b>Leave Requests</b></h6>
                </div>
                <p className='text-center'>No Items For Leave Requests</p>
              </Col>
            )}
            {getPermissionForAction("patient_report", "add") && patient_reports_obj && patient_reports_obj.doctorPatientReports && patient_reports_obj.doctorPatientReports.edges.length > 0 ? (
              <Col sm={12} md={6} lg={4} xl={4} className="mb-4">
                <div className='d-flex align-items-baseline justify-content-center'>
                  <h6 className='text-center textGrey mr-1'><b>Patient Report Request</b></h6><Badge badge={badgeForReportRequests} />
                </div>
                {renderPatientReportRequests()}
                {patient_reports_obj?.doctorPatientReports?.totalCount > 3 ?
                  <Link to={'/patient/report/request'}>
                    <Button variant="link" block className="back">
                      View All Report Requests
                    </Button>
                  </Link>
                : null}
              </Col>
            ) : (
              <Col sm={12} md={6} lg={4} xl={4} className="mb-4">
                <div className='d-flex align-items-baseline justify-content-center'>
                  <h6 className='text-center textGrey mr-1'><b>Patient Report Request</b></h6>
                </div>
                <p className='text-center'>No Items For Patient Report Request</p>
              </Col>
            )}
            {/* {getPermissionForAction("patient_report", "approve") && patient_reports_obj?.doctorPatientReports?.edges?.length > 0 ? ( */}
            {getPermissionForAction("patient_report", "approve") || getPermissionForAction("medical_record", "approve") ? (
              <Col sm={12} md={6} lg={4} xl={4} className="mb-4">
                <div className='d-flex align-items-baseline justify-content-center'>
                  <h6 className='text-center textGrey mr-1'><b>Pending Approvals</b></h6><Badge badge={badgeForApprovals + badgeForMedicalReports} />
                </div>
                {/* QUESTION do we need this permission? */}
                {getPermissionForAction("patient_report", "approve") ? 
                <>
                  {renderMedicalReportRequests()} 
                  {medical_reports_obj?.patientReportsForApproval?.edges?.length + formBlockApprovalsObj?.formBlockApprovals?.edges.length > 3 ? 
                  <Link to={'/patient/blocksforapprovemenet'}>
                    <Button variant="link" block className="back ">
                      View All Approvals
                    </Button>
                  </Link> 
                  : null}
                </>
                : null}
                {/* {getPermissionForAction("medical_record", "approve") ? 
                <>
                {renderFormBlocksForApprovement()} 
                {formBlockApprovalsObj?.formBlockApprovals?.edges?.length > 3 ? 
                  <Link to={'/patient/blocksforapprovemenet'}>
                    <Button variant="link" block className="back ">
                      View All Form Blocks
                    </Button>
                  </Link> : null}
                </>
                : null} */}
                {medical_reports_obj?.patientReportsForApproval?.edges?.length === 0 && formBlockApprovalsObj?.formBlockApprovals?.edges?.length === 0 ? 
                  <div className='text-center'>No Items For Approvals</div>
                : null}
              </Col>
            ) : null}
            {/* {getPermissionForAction("medical_record", "approve") && formBlockApprovalsObj?.formBlockApprovals?.edges.length > 0 ? 
              <Col sm={12} md={6} lg={4} xl={4} className="mb-4">
                <div className='d-flex align-items-baseline justify-content-center'>
                  <h6 className='text-center textGrey mr-1'><b>Form Blocks For Approval</b></h6><Badge badge={badgeForApprovals} />
                </div>
                {renderFormBlocksForApprovement()}
                <Link to={'/patient/blocksforapprovemenet'}>
                  <Button variant="link" block className="back ">
                    View All Form Blocks
                  </Button>
                </Link>
              </Col>  
            : null} */}
            {approvedBlocks?.approvedBlockNotifications?.edges.length > 0 ? 
              <Col sm={12} md={6} lg={4} xl={4} className="mb-4">
                <div className='d-flex align-items-baseline justify-content-center'>
                  <h6 className='text-center textGrey mr-1'><b>Approved</b></h6><Badge badge={badgeForApproved} />
                </div>
                {renderApprovedBlocks()}
                <Link to={'/patient/approvedblocks'}>
                  <Button variant="link" block className="back ">
                    View All Approved Form Blocks
                  </Button>
                </Link>
              </Col>  
            : (
              <Col sm={12} md={6} lg={4} xl={4} className="mb-4">
                <div className='d-flex align-items-baseline justify-content-center'>
                  <h6 className='text-center textGrey mr-1'><b>Approved</b></h6>
                </div>
                <p className='text-center'>No Items For Approved</p>
              </Col>
            )}
          </Row>
          {/* <Row className="justify-content-center mt-3 pb-5">
            <Col xs={12} md={10}>
             <Row>{renderGridContent()}</Row> 
            </Col>
          </Row> */}
        </>}
    </Base>
  );
}
export default Dashboard;