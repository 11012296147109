import React, { useState, useRef, useEffect } from 'react';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import CreateEventForm from './CreateEventForm';
import Preloader from '../../../Common/Preloder/Preloader';
import { useSnackbar } from 'react-simple-snackbar';
import { error_options, success_options, SNACK_DURATION, ERROR_MESSAGE, isUserStaff,displayMiddleName } from "../../../Common/helpers.js";
import { withRouter } from 'react-router-dom';
import { dataPatientInVar, dateEventInVar } from '../../../cache/cache';

const CREATE_EVENT = gql`
  mutation createEvent(
    $doctor: ID!
    $patient: ID!
    $start: DateTime!
    $title: String!
    $description: String!
    $doctorEventType: ID!
    $require_payment: Boolean!
    $discount: Float
    $waitinglist:ID
    $referral:ID
    $shouldBeSeen:DateTime
    # $onlineLink: String
  ) {
    createEvent(
      input: {
        doctor: $doctor
        patient: $patient
        start: $start
        title: $title
        description:$description
        doctorEventType:$doctorEventType
        requirePayment:$require_payment
        discount:$discount
        waitinglist:$waitinglist
        referral:$referral
        shouldBeSeen:$shouldBeSeen
        # onlineLink:$onlineLink
      }
    ) {
      errors {
        field
        messages
      }
      obj {
        start
        end
        title
        description
        id
        patient{
          id
          identifier
          firstName
          middleName
          lastName
        }
        paymentSources{
          edges{
            node{
              paymentLink
            }
          }
        }
      }
    }
  }
`;

export const REQUEST_DOCTOR = gql`
  query {
    doctors(form:true) {
      edges {
        node {
          firstName
          lastName
          id
          email
          identifier
          eventType {
            edges {
              node {
                id
                title{
                  name
                }
                duration
                buffer
                price
                notification{
                  downPaymentNotificationCutoff
                }
                eventType {
                  id
                  name
                  color{
                    hexCode
                    name
                    colorId
                  }
                }
                eventLocation {
                  id
                  title
                }
                availability{
                  edges {
                    node{
                      day
                      shifts{
                        edges{
                           node {
                            startTime
                            endTime
                          }
                        }
                      }
                    }
                  }                
                }
              }
            }
          }
          recurringEvents{
            edges {
              node {
                startDate
                startTime
                endTime
                title
                description
                everyday
                days
                dateUntil
              }
            }
          }
          availability {
            edges {
              node {
                id
                day
                shifts{
                  edges {
                    node {
                      startTime
                      endTime
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const REQUEST_DOCTOR_LEAVES = gql`
query leaves($doctor_identifier: String!){
  leaves(doctor_Identifier_Icontains: $doctor_identifier) {
    edges {
      node {
        id
        date
      }
    }
  }
}
`;

export const WAITING_LISTS = gql`
query {
  listWaitinglist{
   edges{
     node{
       id
       name
       priority
     }
   }
  }
 }
`;

export const SERVICE_LIST = gql`
query {
  services{
    edges {
      node {
          id
          name
          doctorServices{
            edges{
              node {
                id
                title {
                  name
                }
                doctor {
                  firstName
                  lastName
                  id
                  email
                  identifier
                  eventType {
                    edges {
                      node {
                        id
                        title{
                          name
                        }
                        duration
                        notification{
                          downPaymentNotificationCutoff
                        }
                        buffer
                        price
                        eventType {
                          id
                          name
                          color{
                            hexCode
                            name
                            colorId
                          }
                        }
                        eventLocation {
                          id
                          title
                        }
                        availability{
                          edges {
                            node{
                              day
                              shifts{
                                edges{
                                   node {
                                    startTime
                                    endTime
                                  }
                                }
                              }
                            }
                          }                
                        }
                      }
                    }
                  }
                  recurringEvents{
                    edges {
                      node {
                        startDate
                        startTime
                        endTime
                        title
                        description
                        everyday
                        days
                        dateUntil
                      }
                    }
                  }
                  availability {
                    edges {
                      node {
                        id
                        day
                        shifts{
                          edges {
                            node {
                              startTime
                              endTime
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                
                }
              }
            }
          }
      }
    }
  }
}
`;

export const REQUEST_LOCATION = gql`
  query {
    listEventLocation {
      edges {
        node {
          title
          id
        }
      }
    }
  }
`;

export const REQUEST_EVENT_TYPE = gql`
  query {
    listEventType {
      edges {
        node {
          title
          id
        }
      }
    }
  }
`;

const SELECTED_PATIENT_DATA = gql`
  query receivePatientData {
    dataPatient @client
  }
`;

const REFERRAL_OBJECT = gql`
  query receiveReferralData {
    referralObj @client
  }
`;

const SELECTED_DATE_EVENT = gql`
  query receiveDate {
    dateEvent @client
  }
`;

export const REQUEST_PATIENT = gql`
  query GetPatients($searchTerm: String) {
    patients(searchText_Icontains:$searchTerm) {
      edges {
        node {
          firstName
          middleName
          lastName
          id
          email
          identifier
          phone
        }
      }
    }
  }
`;
export const REQUEST_DOCTORS_FROM_SERVICE = gql`
    query GetPatients($eventType_Title: ID){
      doctors(eventType_Title:$eventType_Title) {
        edges {
          node {
            firstName
            lastName
            id
            email
            identifier
            eventType {
              edges {
                node {
                  id
                  title{
                    name
                  }
                  duration
                  buffer
                  price
                  notification{
                    downPaymentNotificationCutoff
                  }
                  eventType {
                    id
                    name
                    color{
                      hexCode
                      name
                      colorId
                    }
                  }
                  eventLocation {
                    id
                    title
                  }
                  availability{
                    edges {
                      node{
                        day
                        shifts{
                          edges{
                             node {
                              startTime
                              endTime
                            }
                          }
                        }
                      }
                    }                
                  }
                }
              }
            }
            recurringEvents{
              edges {
                node {
                  startDate
                  startTime
                  endTime
                  title
                  description
                  everyday
                  days
                  dateUntil
                }
              }
            }
            availability {
              edges {
                node {
                  id
                  day
                  shifts{
                    edges {
                      node {
                        startTime
                        endTime
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
`;
const CALENDAR_SLOTS = gql`
query receiveDate {
  calendarSlots @client
}
`;

const CreateEventFormHoc = (props) => {
  const { setSelectedDoctor, selectedDoctor, setDoctorLeavesList, fullCalendarRef, setSelectedSession, selectedSession, history, GetEvents } = props;
  const { data: patientDataOfSearchPage = null } = useQuery(SELECTED_PATIENT_DATA);
  const { data: referralObjectData = null } = useQuery(REFERRAL_OBJECT);
  const { data: selectedDateEvent = [] } = useQuery(SELECTED_DATE_EVENT);
  const { data: calendarData = null } = useQuery(CALENDAR_SLOTS);
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const formikRef = useRef();
  const isStaff = isUserStaff();
  const [doctorsListFromService, setDoctorsListFromService] = useState({});
  const [sessionName, setSessionName] = useState(null);
  // const [getDoctorsListFromService, { data: doctorsListFromService }] = useLazyQuery(REQUEST_DOCTORS_FROM_SERVICE, {
  //   onCompleted: (data) => {
  //     console.log(data)
  //   },

  //   onError: () => {
  //     openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
  //   },
  // });

  const getDoctorsListFromService = (data=null) => {
    console.log(data);
    if (data?.name) {
      setSessionName(data.name)
      let reduced_list = data?.doctorServices?.edges.map((item, index)=> {
        return {node: item.node.doctor}
      })
      let tempDoctors = {doctors: {edges: []}}
      tempDoctors.doctors["edges"] = [...reduced_list];
      setDoctorsListFromService(
        tempDoctors
      )
    }
  }
  const { data: doctors = [], loading: loadingDoctors } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log(data)
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });
  const { data: location = [], loading: loadingLocation } = useQuery(REQUEST_LOCATION, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });
  const { data: eventType = [], loading: loadingEventType } = useQuery(REQUEST_EVENT_TYPE, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const { data: waitingListObj = null } = useQuery(WAITING_LISTS, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const { data: servicesListObj = null } = useQuery(SERVICE_LIST, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });


  const [getPatients, { data: patientList }] = useLazyQuery(REQUEST_PATIENT, {
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const [doctorLeaves] = useLazyQuery(REQUEST_DOCTOR_LEAVES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setDoctorLeavesList(data)
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  var waitingList = [];
  if (waitingListObj) {
    waitingList = waitingListObj.listWaitinglist.edges;
  }

  var serviceList = [];
  if (servicesListObj) {
    serviceList = servicesListObj.services.edges;
  }

  const [createEvent] = useMutation(CREATE_EVENT, {
    onCompleted: ({ createEvent, variables }) => {
      if (createEvent && createEvent.obj && createEvent.obj.patient) {
        let alert_msg = "Appointment created for " + createEvent.obj.patient.firstName + " " +displayMiddleName(createEvent.obj.patient.middleName)+ createEvent.obj.patient.lastName;
        let sms_error_msg = " but SMS was not sent.";
        if (createEvent.errors && createEvent.errors.length === 1) {
          let sms_error = createEvent.errors[0].messages[0];
          if (sms_error.indexOf("Insufficient") > -1) {
            alert_msg = alert_msg + sms_error_msg;
          }
        }
        let patient_url = '/patient/record/' + createEvent.obj.patient.identifier;;
        if (isStaff) {
          history.push(patient_url);
        } else {
          window.location.href = createEvent.obj.paymentSources.edges[0].node.paymentLink;
        }
        openSnackbarSuccess(alert_msg, [SNACK_DURATION]);
        if (formikRef && formikRef.current) {
          formikRef.current.handleReset();
        }
        setSelectedPatient([]);
        dataPatientInVar(null);
        dateEventInVar([]);
      }
      else if (createEvent.errors && createEvent.errors.length > 0) {
        var alert_msg = "";
        createEvent.errors.map(error => {
          let messagesArr = error.messages;
          if (messagesArr) {
            alert_msg += messagesArr.join(" ");
          }
          return null
        })
        openSnackbar(alert_msg, [SNACK_DURATION]);
      }
    },
    onError: (error) => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);

    },
  });

  useEffect(() => {
    // returned function will be called on component unmount 

    // let eventformref=formikRef?formikRef.current:null;
    return () => {
      setSelectedPatient(null);
      dataPatientInVar(null);
      dateEventInVar([]);
    }
  }, [])

  if (loadingDoctors) return <Preloader />;
  if (loadingLocation) return <Preloader />;
  if (loadingEventType) return <Preloader />;

  return (
    <CreateEventForm
      createEvent={createEvent}
      patientList={patientList}
      doctorList={!isStaff ? doctorsListFromService : doctors}
      locationList={location}
      eventType={eventType}
      patientDataOfSearchPage={patientDataOfSearchPage}
      selectedDateEvent={selectedDateEvent}
      getPatients={getPatients}
      setSelectedDoctor={setSelectedDoctor}
      selectedDoctor={selectedDoctor}
      doctorLeaves={doctorLeaves}
      fullCalendarRef={fullCalendarRef}
      setSelectedSession={setSelectedSession}
      setSelectedPatient={setSelectedPatient}
      selectedPatient={selectedPatient}
      selectedSession={selectedSession}
      formikRef={formikRef}
      waitingList={waitingList}
      calendarData={calendarData}
      GetEvents={GetEvents}
      serviceList={serviceList}
      getDoctorsListFromService={getDoctorsListFromService}
      referralObjectData={referralObjectData}
      sessionName={sessionName}
    />
  );
};

export default withRouter(CreateEventFormHoc);
