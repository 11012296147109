import { Row, Col, Form, Button, Container, } from 'react-bootstrap';
import React, { useState, useRef, useEffect } from 'react';
import Base from './base.js';
import { withRouter, Link } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { error_options, success_options, SNACK_DURATION, ERROR_MESSAGE, getPermissionForAction, isUserStaff, urlActions, isSuperUserFunc,displayMiddleName } from '../Common/helpers';
import { useSnackbar } from 'react-simple-snackbar';
import { Formik } from 'formik';
import Preloader from '../Common/Preloder/Preloader';
import { useTranslation } from "react-i18next";


function RequestReportCreate(props) {
  const patient_record_id = props.match.params.id;
  const isUpdate = patient_record_id ? true : false;
  let doctorFill = urlActions(window.location.href, 'get', 'doctorFill');
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const formikRef = useRef();
  const [fillRepEdit, setFillRepEdit] = useState(false);
  const [editSingleReport, setEditSingleReport] = useState(null);
  const isStaff = isUserStaff();
  const isSuperUser = isSuperUserFunc();
  const returnAllSectionPermissions = true;
  const patient_report_permission = getPermissionForAction('patient_report', '', returnAllSectionPermissions);
  const { t } = useTranslation();


  const CREATE_UPDATE_PATIENT_REPORT = gql`
  mutation createUpdatePatientReport(
    $id: ID
    $doctor:ID!
    $reason:String!
    $reportType:ID!
    $languageEn:Boolean!
  ) {
    createUpdatePatientReport(
      input: {id:$id, doctor:$doctor,reason:$reason,languageEn:$languageEn, reportType:$reportType  }
    ) {
      obj {
        created
        id
        reason
        paymentSources{
          edges{
            node{
              paymentLink
              id
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

  const DOCTOR_UPDATE_PATIENT_REPORT = gql`
  mutation doctorUpdatePatientReport(
    $id: ID!
    $clinicalSummary:String!
    $treatmentRecommendation:String!
    $notes:String
    $daignosis:String!
  ) {
    doctorUpdatePatientReport(
      input: {id:$id, clinicalSummary:$clinicalSummary,treatmentRecommendation:$treatmentRecommendation,notes:$notes, daignosis:$daignosis  }
    ) {
      obj {
        created
        id
        reason
        paymentSources{
          edges{
            node{
              paymentLink
              id
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

  const REQUEST_DOCTOR = gql`
  query {
    doctors(forReport:true) {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

  const REQUEST_PATIENT_REPORTS = gql`
  query {
    patientReport(id:"${patient_record_id}"){
            id
            status
            reason
            created
            clinicalSummary
            treatmentRecommendation
            notes
            daignosis
            patient{
              firstName
              middleName
              lastName
              id
              identifier
            }
            reportType{
              id
              name
              price
              description
            }
            languageEn
            doctor{
              id
              firstName
              lastName
            }
        }
  }
`;



  const REQUEST_REPORT_TYPES = gql`
  query {
    reportTypes{
      edges {
        node {
            id
            name
            description
            price
        }
      }
    }
  }
`;

  const DELETE_PATIENT_REPORT = gql`
    mutation deletePatientReport($id:ID!) {
      deletePatientReport(id:$id) {
        deleted
      }
    }
`;

const APPROVE_PATIENT_REPORT = gql`
  mutation approvePatientReport (
    $id: ID!
    $clinicalSummary: String!
    $treatmentRecommendation: String!
    $notes: String
    $daignosis: String!
  ) {
    approvePatientReport (input: {
      id: $id, 
      clinicalSummary: $clinicalSummary,
      treatmentRecommendation: $treatmentRecommendation,
      notes: $notes, 
      daignosis: $daignosis
    }) {
      obj {
        created
        id
        reason
        reportType {
          name
          description
        }
      }
      errors {
        field
        messages
      }
    }    
  }
`;


  // delete form instance
  const [deletePatientReport] = useMutation(DELETE_PATIENT_REPORT, {
    onCompleted: ({ deletePatientReport }) => {
      if (deletePatientReport.errors && deletePatientReport.errors.length > 0) {
        let error_messages = deletePatientReport.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (deletePatientReport.deleted) {
        openSnackbarSuccess("Patient Report Request Deleted");
        props.history.goBack();
      }
    },
    refetchQueries: [
      { query: REQUEST_PATIENT_REPORTS, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const [approvePatientReport] = useMutation(APPROVE_PATIENT_REPORT, {
    onCompleted: ({ approvePatientReport }) => {
      if (approvePatientReport.errors && approvePatientReport.errors.length > 0) {
        let error_messages = approvePatientReport.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (approvePatientReport.deleted) {
        openSnackbarSuccess("Patient Report Request Deleted");
        props.history.goBack();
      } else {
        openSnackbarSuccess("Patient Report Approved");
        props.history.goBack();

      }
    },
    refetchQueries: [
      { query: REQUEST_PATIENT_REPORTS, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // GET DOCTOR DATA
  const { data: doctors_obj = null } = useQuery(
    REQUEST_DOCTOR,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  // GET REQUEST_REPORT_TYPES DATA
  const { data: report_type_obj = null } = useQuery(
    REQUEST_REPORT_TYPES,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  // GET REQUEST_PATIENT_REPORTS DATA
  const { data: patient_reports_obj = null, loading } = useQuery(
    REQUEST_PATIENT_REPORTS,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );
  console.log("patient_reports_obj", patient_reports_obj);


  const onSubmit = (values, { resetForm }) => {
    let val = values;
    val["languageEn"] = !values.languageAr;

    if (patient_reports_obj && patient_reports_obj.patientReport) {
      val["id"] = patient_reports_obj.patientReport.id;
    //   val["clinicalSummary"] = values.clinicalSummary;
    //   val["treatmentRecommendation"] = values.treatmentRecommendation;
    //   val["notes"] = values.notes;
    //   val["daignosis"] = values.daignosis;
    }
    console.log("editSingleReport", editSingleReport);
    if (isUpdate && doctorFill) {
      console.log("doctorUpdatePatientReport", val);
      doctorUpdatePatientReport({ variables: val });
    }
    else {
      console.log("createUpdatePatientReporvalt", val);
      createUpdatePatientReport({ variables: val });
    }
  };


  const [createUpdatePatientReport] = useMutation(CREATE_UPDATE_PATIENT_REPORT, {
    onCompleted: ({ createUpdatePatientReport }) => {
      if (createUpdatePatientReport.errors.length === 0) {
        if (isUpdate) {
          setEditSingleReport(null);
          props.history.goBack();
        }
        else if (createUpdatePatientReport.obj.paymentSources.edges.length > 0) {
          let paymentLink = createUpdatePatientReport.obj.paymentSources.edges[0].node.paymentLink;
          window.location = paymentLink;
        }
        else{
          let alert_msg = 'Cannot redirect to Payment Page. Please contact Administrator';
          openSnackbar(alert_msg, [SNACK_DURATION]);
        }
      } else {
        let alert_msg = '';
        createUpdatePatientReport.errors.map((error) => {
          alert_msg += error.messages.join(' ') + ' ';
          openSnackbar(alert_msg, [SNACK_DURATION]);
          return null;
        });
      }
    },
    refetchQueries: [
      { query: REQUEST_PATIENT_REPORTS, fetchPolicy: 'network-only' }
    ],
    onError: (e) => {
      console.log("eee", e)
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const [doctorUpdatePatientReport] = useMutation(DOCTOR_UPDATE_PATIENT_REPORT, {
    onCompleted: ({ doctorUpdatePatientReport }) => {
      if (doctorUpdatePatientReport.errors.length === 0) {
        console.log("doctorUpdatePatientReport", doctorUpdatePatientReport);

        if (editSingleReport) {
          setEditSingleReport(null);
        }
        openSnackbarSuccess("Patient Report Filled");
        props.history.goBack();
      } else {
        let alert_msg = '';
        doctorUpdatePatientReport.errors.map((error) => {
          alert_msg += error.messages.join(' ') + ' ';
          openSnackbar(alert_msg, [50000]);
          return null;
        });
      }
    },
    refetchQueries: [
      { query: REQUEST_PATIENT_REPORTS, fetchPolicy: 'network-only' }
    ],
    onError: (e) => {
      console.log("eee", e)
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const initialValues = {
    doctor: '',
    reason: '',
    languageEn: true,
    reportType: "",
    clinicalSummary: "",
    treatmentRecommendation: "",
    notes: "",
    daignosis: "",
  };

  if (patient_reports_obj && patient_reports_obj.patientReport) {
    initialValues["doctor"] = patient_reports_obj.patientReport.doctor.id;
    initialValues["reportType"] = patient_reports_obj.patientReport.reportType.id;
    initialValues["reason"] = patient_reports_obj.patientReport.reason;
    initialValues["languageAr"] = !patient_reports_obj.patientReport.languageEn;
  }


  if (patient_reports_obj && patient_reports_obj.patientReport && fillRepEdit) {
    initialValues["clinicalSummary"] = patient_reports_obj.patientReport.clinicalSummary;
    initialValues["treatmentRecommendation"] = patient_reports_obj.patientReport.treatmentRecommendation;
    initialValues["notes"] = patient_reports_obj.patientReport.notes;
    initialValues["daignosis"] = patient_reports_obj.patientReport.daignosis;
  }



  useEffect(() => {
    if (isUpdate && patient_reports_obj && patient_reports_obj.patientReport) {
      setEditSingleReport(patient_reports_obj.patientReport);
    }
    if (doctorFill) {
      setFillRepEdit(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate, doctorFill]);

  const getReportTypeDetails = (report, values) => {
    if (report && report_type_obj) {
      let selected_report_type = report_type_obj.reportTypes.edges.find((i) => i.node.id === report);
      if (selected_report_type) {
        return (
          <>
            {!isStaff ? 
              <Form.Control.Feedback type="valid" className="doctor_session">
                Price: {selected_report_type.node.price}
              </Form.Control.Feedback>
            : null}
            <Form.Control.Feedback type="valid" className="doctor_session">
              Description: {selected_report_type.node.description}
            </Form.Control.Feedback>
          </>
        );
      }
    }
  };


  const deleteReportFunc = () => {
    if (patient_reports_obj && patient_reports_obj.patientReport) {
      let val = {
        id: patient_reports_obj.patientReport.id
      }
      if (window.confirm("Delete Report Request?",)) {
        deletePatientReport({ variables: val });
        console.log("valvalval", val);
        props.history.goBack();
      }

    }
  }
  
  const approveReportFunc = () => {
    if (patient_reports_obj && patient_reports_obj.patientReport) {
      let val = {
        id: patient_reports_obj.patientReport.id,
        clinicalSummary: formikRef.current.values.clinicalSummary,
        treatmentRecommendation: formikRef.current.values.treatmentRecommendation,
        notes: formikRef.current.values.notes,
        daignosis: formikRef.current.values.daignosis,
      }
      if (window.confirm("Approve Report Request?",)) {
        approvePatientReport({ variables: val });
      }

    }
  }

  const changeLanguage = (values) => {
    formikRef.current.setFieldValue("languageAr", !values.languageAr)
  }

  console.log("patient_reports_obj", patient_reports_obj);
  return (
    <Base title={'Clinical Report Request'}
      isPatientPortal={!isStaff} showHeader={true}>
      {loading ? <Preloader /> :
        <Row>
          <Col xs={12} xl={12}>
            {patient_reports_obj && patient_reports_obj.patientReport ? <Form.Group as={Row}>
              <Form.Label column sm={4} md={3} className="text-right pr-0">
                <span className="">Patient Name: </span>
              </Form.Label>
              <Col sm={8} md={8}>
                <Link to={`/patient/record/${patient_reports_obj.patientReport.patient.identifier}`}>
                  <Button variant='link' style={{ display: 'inline-block' }}>
                    {patient_reports_obj.patientReport.patient.firstName + " " + displayMiddleName(patient_reports_obj.patientReport.patient.middleName) + patient_reports_obj.patientReport.patient.lastName}
                  </Button>
                </Link>
              </Col>
            </Form.Group> : null}
            <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize innerRef={formikRef}>
              {({ handleSubmit, handleBlur, handleChange, values }) => (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <Container>
                    {report_type_obj && report_type_obj.reportTypes ?
                      <Form.Group as={Row}>
                        <Form.Label column sm={4} md={3} className="text-right pr-0 required">
                          <span className="">{t('patientReportRequest.reportType')} </span>
                        </Form.Label>
                        <Col sm={8} md={8}>
                          <Form.Control
                            autoComplete="off"
                            as="select"
                            name="reportType"
                            disabled={doctorFill ? true : false}
                            value={values.reportType}
                            onChange={handleChange}
                            required>
                            <option value="">{t('patientReportRequest.selectReportType')}</option>
                            {report_type_obj.reportTypes.edges.map((type, index) => {
                              return (
                                <option value={type.node.id} key={index}>
                                  {type.node.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                          {getReportTypeDetails(values.reportType, values)}
                        </Col>
                      </Form.Group>
                      : null}
                    {/* {doctors_obj && doctors_obj.doctors && report_type_obj? <Form.Group as={Row}>
                      <Form.Label column sm={4} md={3} className="text-right pr-0 required">
                        <span className="">{t('patientReportRequest.reportType')} </span>
                      </Form.Label>
                      <Col sm={8} md={8}>
                        <Form.Control
                          autoComplete="off"
                          as="select"
                          name="reportType"
                          disabled={doctorFill ? true : false}
                          value={values.reportType}
                          name="doctor"
                          value={values.doctor}
                          disabled={doctorFill ? true : false}
                          onChange={handleChange}
                          required>
                          <option value="">{t('patientReportRequest.selectReportType')}</option>
                          {report_type_obj.reportTypes.edges.map((type, index) => {
                            return (
                              <option value={type.node.id} key={index}>
                                {type.node.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        {getReportTypeDetails(values.reportType, values)}
                      </Col>
                    </Form.Group>
                    : null} */}
                    {doctors_obj && doctors_obj.doctors ? <Form.Group as={Row}>
                      <Form.Label column sm={4} md={3} className="text-right pr-0 required">
                        <span className="">{t('patientReportRequest.doctor')}</span>
                      </Form.Label>
                      <Col sm={8} md={8}>
                        <Form.Control
                          autoComplete="off"
                          as="select"
                          name="doctor"
                          value={values.doctor}
                          disabled={doctorFill ? true : false}
                          onChange={handleChange}
                          required>
                          <option value="">{t('patientReportRequest.selectDoctor')}</option>
                          {doctors_obj && doctors_obj.doctors.edges.map((doctor, index) => {
                            return (
                              <option value={doctor.node.id} key={index}>
                                {doctor.node.firstName} {doctor.node.lastName}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group> : null}
                    <Form.Group as={Row}>
                      <Form.Label column sm={4} md={3} className="text-right pr-0 required">
                        <span className=""> {t('patientReportRequest.reportInArabic')} </span>
                      </Form.Label>
                      <Col sm={8} md={8} className="d-flex align-items-center">
                        <Form.Check
                          type="checkbox"
                          name="languageAr"
                          value={values.languageAr}
                          disabled={doctorFill ? true : false}
                          checked={values.languageAr}
                          onChange={() => changeLanguage(values)}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} >
                      <Form.Label column sm={12} md={3} className="text-right pr-0 required">
                        <span className="">{t('patientReportRequest.reason')}</span>
                      </Form.Label>
                      <Col sm={12} md={8}>
                        <Form.Control
                          autoComplete="off"
                          as="textarea"
                          rows={5}
                          onBlur={handleBlur}
                          name="reason"
                          value={values.reason}
                          disabled={doctorFill ? true : false}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                    </Form.Group>
                    {doctorFill ? <>
                      <Form.Group as={Row} >
                        <Form.Label column sm={12} md={3} className="text-right pr-0 required">
                          <span className="">Clinical Summary</span>
                        </Form.Label>
                        <Col sm={12} md={8}>
                          <Form.Control
                            autoComplete="off"
                            as="textarea"
                            rows={2}
                            onBlur={handleBlur}
                            name="clinicalSummary"
                            value={values.clinicalSummary}
                            onChange={handleChange}
                            required
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} >
                        <Form.Label column sm={12} md={3} className="text-right pr-0 required">
                          <span className="">Diagnosis</span>
                        </Form.Label>
                        <Col sm={12} md={8}>
                          <Form.Control
                            autoComplete="off"
                            as="textarea"
                            rows={2}
                            onBlur={handleBlur}
                            name="daignosis"
                            value={values.daignosis}
                            onChange={handleChange}
                            required
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} >
                        <Form.Label column sm={12} md={3} className="text-right pr-0 required">
                          <span className="">Treatment Recommendation</span>
                        </Form.Label>
                        <Col sm={12} md={8}>
                          <Form.Control
                            autoComplete="off"
                            as="textarea"
                            rows={2}
                            onBlur={handleBlur}
                            name="treatmentRecommendation"
                            value={values.treatmentRecommendation}
                            onChange={handleChange}
                            required
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} >
                        <Form.Label column sm={12} md={3} className="text-right pr-0 ">
                          <span className="">Notes</span>
                        </Form.Label>
                        <Col sm={12} md={8}>
                          <Form.Control
                            autoComplete="off"
                            as="textarea"
                            rows={2}
                            onBlur={handleBlur}
                            name="notes"
                            value={values.notes}
                            onChange={handleChange}

                          />
                        </Col>
                      </Form.Group>
                    </> : null}
                    <div className="d-flex justify-content-end mt-4 mb-4">
                      {
                        ((isSuperUser ||
                          patient_report_permission &&
                          patient_report_permission.includes("delete"))) &&
                          patient_reports_obj &&
                          patient_reports_obj.patientReport ?
                            <Button variant="danger" className='back mr-2' onClick={deleteReportFunc}>
                              Delete
                            </Button> : null
                      }
                      {
                        ((isSuperUser ||
                          patient_report_permission &&
                          patient_report_permission.includes("approve"))) &&
                          patient_reports_obj &&
                          patient_reports_obj.patientReport ?
                            <Button variant="primary" className='back mr-2' onClick={approveReportFunc}>
                              Approve
                            </Button> : 
                            <Button variant="primary" type="submit" className='back mr-2'>
                              {t('patientReportRequest.submit')}
                            </Button>
                      } 
                      <Button variant="primary" className="back mr-2" onClick={() => props.history.goBack()}>
                        {t('patientReportRequest.cancel')}
                      </Button>
                    </div>
                  </Container>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      }</Base>
  );
}

export default withRouter(RequestReportCreate);