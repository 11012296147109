import { Row, Col, Form, Card, Button, Table } from 'react-bootstrap';
import Base from './base.js';
import { useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { gql, useQuery, useLazyQuery, useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import { error_options, SNACK_DURATION, ERROR_MESSAGE, getDate,displayMiddleName } from '../Common/helpers';
import SelectPatientModal from './SearchForPatient/selectPatientModal';
import { urlBackend } from "../index";
import Preloader from '../Common/Preloder/Preloader';
import { Link } from 'react-router-dom';
import { accountsFilterVar } from '../cache/cache.js';
import { useTranslation } from "react-i18next";

export const REQUEST_DOCTOR = gql`
  query {
    doctors {
      edges {
        node {
          firstName
          lastName
          arabicName
          id
          email
          identifier
          doctorId
        }
      }
    }
  }
`;


const REQUEST_PAYMENTS_FILTER = gql`
query($start:DateTime, $end:DateTime, $doctor:String, $patient:String, $amount:Float)  {
  payments(
    start: $start, end: $end, 
    appointment_Doctor_Identifier: $doctor, 
    patient_Identifier: $patient,
    amount_Gte:$amount
    )
  {
    totalCount   
    edges{
      node{
        created
        id
        patient{
          identifier
          firstName
          middleName
          lastName
          email
          phone
        }
        displayPaymentType
        amount
        currency
        notes
        sources{
          edges{
            node{
              reference
            }
          }
        }
        appointment{
          created
          start
          id
          identifier
          doctor{
            identifier
            firstName
            lastName
            arabicName
          }
        }
      }
    }
  }
}
`;

export default function Payments(props) {
  const [getPaymentsFilter, { data: paymentsObjFilter = null, loading }] = useLazyQuery(REQUEST_PAYMENTS_FILTER);
  const patient_record = props.location.state ? props.location.state.patient_record : null;
  const identifier = patient_record ? patient_record.node.identifier : null;
  const searchPatientRef = useRef();
  const formikRef = useRef();
  const [openSnackbar] = useSnackbar(error_options);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  const [exportLink, setExportLink] = useState(urlBackend + "admin/payment/paymentmodel/export/?paid__exact=1");
  const [defaultLink, setDefaultLink] = useState(urlBackend + "admin/payment/paymentmodel/export/?paid__exact=1")
  const accountsFilter = useReactiveVar(accountsFilterVar);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    console.log("accountsFilter", accountsFilter);
    if (accountsFilter) {
      if (accountsFilter.doctorIdentifier) {
        formikRef.current.setFieldValue('doctor', accountsFilter.doctorIdentifier);
      }
      if (accountsFilter.amount) {
        formikRef.current.setFieldValue('amount', accountsFilter.amount);
      }
      if (accountsFilter.patient) {
        formikRef.current.setFieldValue('patient', `${accountsFilter.patient.firstName} ${displayMiddleName(accountsFilter.patient.middleName)}${accountsFilter.patient.lastName}`);
        setSelectedPatient(accountsFilter.patient)
      }
      if (accountsFilter.startDate) {
        setStartDate(accountsFilter.startDate);
      }
      if (accountsFilter.endDate) {
        setEndDate(accountsFilter.endDate);
      }
      if (Object.keys(accountsFilter).length > 0) {
        formikRef.current.handleSubmit();
        setTimeout(() => {
          accountsFilterVar(null);
        }, 500);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(patient_record) {
      setDefaultLink(urlBackend + "admin/payment/paymentmodel/export/?paid__exact=1&patient__id__exact=" + patient_record.node.patientId);
      setExportLink(urlBackend + "admin/payment/paymentmodel/export/?paid__exact=1&patient__id__exact=" + patient_record.node.patientId);
    }
  }, [patient_record])


  const { data: doctorsList } = useQuery(REQUEST_DOCTOR, {
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const doctors = doctorsList && doctorsList.doctors ? doctorsList.doctors.edges : [];

  const onSelectPatient = (patientData) => {
    formikRef.current.setFieldValue('patient', `${patientData.firstName} ${displayMiddleName(patientData.middleName)}${patientData.lastName}`);
    setSelectedPatient(patientData);
  };


  const onSubmit = (values) => {
    // since we have date variable in the filter query we need to pass a valid date
    //  hence creating dummy dates that will cover all dates incase date filter is not seleted
    // let defaultStart= new Date("2002/02/02");
    // let defaultEnd= new Date("2052/02/02");
    let startDateStr = "";
    let endDateStr = "";

    if (startDate) {
      let startDateObj = new Date(startDate);
      startDateStr = new Date(startDateObj.getTime() - startDateObj.getTimezoneOffset() * 60000).toISOString();

    }
    if (endDate) {
      let endDateObj = new Date(endDate);
      endDateStr = new Date(endDateObj.getTime() - endDateObj.getTimezoneOffset() * 60000).toISOString();
    }

    let variables = {};
    let doctorId = "";
    let patientId = "";
    let export_link = defaultLink;
    if (values.doctor) {
      variables["doctor"] = values.doctor;
      let doctorIdObj = doctors.find(i => i.node.identifier === values.doctor);
      if (doctorIdObj) {
        doctorId = doctorIdObj.node.doctorId;
        export_link = export_link + "&appointment__doctor__id__exact=" + doctorId;
      }
    }
    if (patient_record) {
      variables["patient"] = patient_record ? patient_record.node.identifier : '';
      patientId = patient_record.node.patientId;
    }
    if (selectedPatient) {
      variables["patient"] = selectedPatient ? selectedPatient.identifier : '';
      patientId = selectedPatient.patientId;
      export_link = export_link + "&patient__id__exact=" + patientId;
    }
    if (startDateStr) {
      variables["start"] = startDateStr;
      export_link = export_link + "&actual_date__range__gte=" + startDateStr.substring(0, 10);
    }
    if (endDateStr) {
      variables["end"] = endDateStr;
      export_link = export_link + "&actual_date__range__lte=" + endDateStr.substring(0, 10);
    }
    if (values.amount) {
      variables["amount"] = values.amount;
    }
    console.log("variables", variables);
    setExportLink(export_link);
    getPaymentsFilter({
      variables: variables
    })
  };

  const initialValues = {
    doctor: '',
    patient: '',
    paidby: '',
  };



  /** 
   * FORMIK NOTES:
   * Formik uses { handleSubmit, handleChange, handleBlur, values } inbuilt functions and onChnage={handleChange} 
   * makes sure that values object in submit will have the changed/updated values for input
   * 
   * if we need to add custom functions onChange then similar to onChange={handleSearch} for patient we can use custom function but 
   * make sure to set the formik value of that field using formikRef.current.setFieldValue('patient', event.target.value);
   * formikRef is the innerRef given to formik component   * 
   * 
   */
  const goToDetailPage = () => {
    if (selectedPatient || formikRef.current.values.doctor ||formikRef.current.values.amount || startDate || endDate) {
      accountsFilterVar({
        patient: selectedPatient,
        doctorIdentifier: formikRef.current.values.doctor,
        startDate: startDate,
        endDate: endDate,
        amount:formikRef.current.values.amount
      });
    }
    else {
      accountsFilterVar(null);
    }
  }

  const renderList = (paymentListObj) => {
    let payment_list = paymentListObj && paymentListObj.payments ? paymentListObj.payments.edges : [];
    if (payment_list.length > 0) {
      return payment_list.map((itemNode, index) => {
        let item = itemNode.node;
        let date = new Date(item.created);
        let app_date = item.appointment ? item.appointment.start : null;
        if (app_date) {
          app_date = new Date(app_date);
          app_date = getDate(app_date);
        }
        return (
          <tr key={index} className="patient_table_row">
            <td>
              <Link to={"/accounts/payment/detail/" + item.id} onClick={goToDetailPage} className='d-inline-block'>
                <Button variant="link" className="p-0 mr-0">
                  {item.appointment ? item.appointment.identifier : item.sources.edges[0].node.reference}
                </Button>
              </Link>
            </td>
            <td>{getDate(date)}</td>
            <td >{item.appointment ? 
            i18n.language == 'en'? 
            item.appointment?.doctor.firstName + " " + item.appointment?.doctor.lastName : "-"
            :item.appointment?.doctor.arabicName
            }</td>
            {!patient_record ? 
              <td>{item.patient ? item.patient.firstName+ " " + displayMiddleName(item.patient.middleName) + item.patient.lastName : ""}</td>
            : null}
            {item.patient ? <td >{item.patient.email}{item.patient.email ? <br /> : null} {item.patient.phone}</td> : <td></td>}
            <td >{item.amount} {item.currency}</td>
          </tr>
        )
      })
    }
    else {
      return (
        <tr >
          <td>{t("No payment data available")}</td>
        </tr>
      )
    }
  }

  const resetFilter = () => {
    if (formikRef && formikRef.current) {
      formikRef.current.handleReset();
      setSelectedPatient(null);
      setStartDate('');
      setEndDate('');
    }
    accountsFilterVar(null);
    getPaymentsFilter();
  }

  useEffect(() => {
    if (identifier) {
      let variables = {};
      variables['patient'] = identifier;
      getPaymentsFilter({
        variables: variables
      })
    } else {
      getPaymentsFilter();
    }
  }, [identifier])

  return (
    <Base title={`${patient_record ? `${patient_record.node.firstName} ${displayMiddleName(patient_record.node.middleName)}${patient_record.node.lastName} Payment Records` : 'Accounts'}`} showHeader={true}>
      <Row className="payment-section">
        <Col md={12} lg={12} className='mr-auto'>
          <Formik onSubmit={onSubmit} initialValues={initialValues} innerRef={formikRef}>
            {({ handleSubmit, handleChange, handleBlur, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className='justify-content-end'>
                  <Col md={12} lg={6} xl={2} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label column sm={3} md={3} lg={3} className="text-right pr-0">
                        From
                    </Form.Label>
                      <Col sm={12} md={9} lg={9}>
                        <DatePicker
                          autoComplete="off"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="form-control"
                          dateFormat="dd/MMM/yyyy"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6} xl={2} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label column sm={3} md={3} lg={3} className="text-right pr-0">
                        To
                    </Form.Label>
                      <Col sm={12} md={9} lg={9}>
                        <DatePicker
                          autoComplete="off"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          className="form-control"
                          dateFormat="dd/MMM/yyyy"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6} xl={4} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label column sm={3} md={3} lg={3} className="text-right pr-0">
                        Practitioner
                      </Form.Label>
                      <Col sm={12} md={9} lg={9}>
                        <Form.Control
                          autoComplete="off"
                          onBlur={handleBlur}
                          as="select"
                          name="doctor"
                          value={values.doctor}
                          onChange={handleChange}>
                          <option value="">Select Practitioner</option>
                          {doctors.map((doctor) => {
                            return (
                              <option value={doctor.node.identifier} key={doctor.node.id}>
                                {doctor.node.firstName} {doctor.node.lastName}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6} xl={4} sm={12} className={`${patient_record ? 'd-none' : 'd-block'}`}>
                    <Form.Group as={Row}>
                      <Form.Label column sm={3} md={3} lg={3} className="text-right pr-0">
                        Patient
                      </Form.Label>
                      <Col
                        sm={12} md={9} lg={9}
                        className="payment-section__patient-search"
                        ref={searchPatientRef}>
                        <Form.Control
                          autoComplete="off"
                          placeholder="Select Patient"
                          type="text"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="patient"
                          value={values.patient}
                          className="mr-sm-2 pr-0 float-left pr-5"
                        />
                        <span onClick={() => setShowPatientSearch(true)} className="search-icon">
                          <i className="fa fa-search"></i>
                        </span>
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6} xl={4} sm={12} className="ml-auto">
                    <Form.Group as={Row}>
                      <Form.Label column sm={3} md={3} lg={3} className="text-right pr-0">
                        Amount
                      </Form.Label>
                      <Col
                        sm={12} md={9} lg={9}>
                        <Form.Control
                          autoComplete="off"
                          placeholder="Enter Amount"
                          type="text"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="amount"
                          value={values.amount}
                          className="mr-sm-2 pr-0 float-left pr-5"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6} xl={4} sm={12}>
                    <div className={`d-flex justify-content-end submitPayment ${patient_record ? 'pt-3' : ''}`}>
                      <Button type="button" variant="primary" onClick={() => window.open(exportLink, "_blank")}> Export </Button>
                      <Button type="button" variant="primary" className="mx-3" onClick={resetFilter}> Reset </Button>
                      <Button type="submit" variant="primary"> Submit </Button>
                      {/* <Button className='ml-3' variant="primary" onClick={printPayment}> Print </Button> */}
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
        <Col md={12} className="mb-4">
          {loading ?
            <Preloader />
            : <Card className="paymentListCard">
              <Card.Body className="p-0">
                <Table responsive className=" table-borderless">
                  <thead>
                    <tr className="patient_table_row tableHeader">
                      <th scope="col " className="text-uppercase">Transaction ID</th>
                      <th scope="col " className="text-uppercase">Date</th>
                      <th scope="col " className="text-uppercase">Staff</th>
                      {!patient_record ? 
                        <th scope="col " className="text-uppercase">Patient </th>
                      : null}
                      <th scope="col " className="text-uppercase">Contact Information</th>
                      <th scope="col " className="text-uppercase">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentsObjFilter ? renderList(paymentsObjFilter) : null}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>}
        </Col>
      </Row>
      <SelectPatientModal showPatientSearch={showPatientSearch} setShowPatientSearch={setShowPatientSearch} onSelectPatientFunc={onSelectPatient} />
    </Base>
  );
}
