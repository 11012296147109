import {useEffect} from 'react';
import { useApolloClient } from '@apollo/client';
import { gql, useMutation,useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import { error_options ,SNACK_DURATION,ERROR_MESSAGE} from "./Common/helpers.js";
import { isLoggedInVar ,dataPatientInVar, userDetailsVar,userPermissionsVar} from "./cache/cache";
import { withRouter } from 'react-router-dom';

const VerifyToken = (props) => {
  const token = localStorage.getItem('token');
  const [openSnackbar ] = useSnackbar(error_options);
  const client = useApolloClient();

  const VERIFY_TOKEN = gql`
  mutation verifyToken($token: String!) {
    verifyToken(
      input: {
        token:$token 
      }
    )
    {
      success,
      errors
    }
  }
`;



  const handleLogout = () => {
    const { history } = props;
    client.cache.evict({ fieldName: "me" });
    client.cache.gc();
    client.resetStore()
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("is_staff");
    localStorage.removeItem("user_permissions");
    isLoggedInVar(false);
    userDetailsVar(null);
    userPermissionsVar(null);
    dataPatientInVar(null);
    history.push("/");
  };

  const REQUEST_USER_PERMISSION = gql`
    query {
      userPermission
    }
  `;

  const [GetUserPermissions] = useLazyQuery(REQUEST_USER_PERMISSION, {
    fetchPolicy:"network-only",
    onCompleted({ userPermission }) {  
      
      var user_permissions = {};
      // localStorage.setItem('user_permissions', JSON.stringify(userPermission));
      if (userPermission) {
        let userprofile = userPermission.filter(i => (i.indexOf("userprofile.") > -1 )&& i.indexOf("_can_") > -1);
        let accountant = userPermission.filter(i => (i.indexOf("userprofile.as_accountant") > -1 ));
        let clinician = userPermission.filter(i => (i.indexOf("userprofile.as_clinician") > -1 ));
        // let receptionist = userPermission.filter(i => (i.indexOf("userprofile.as_receptionist") > -1 ));
        let patientRecord = userPermission.filter(i => (i.indexOf("patientrecord.") > -1 ));         
        // let medical_record = userPermission.filter(i => (i.indexOf("medical_record") > -1 ));  

        
        userprofile = userprofile.map(i => {
          return i.split('.')[1]
        })
        let userPerm = userprofile.map(i => {
          let str = "_can_";
          let key = "";
          let val = "";
          if (i.indexOf(str) > -1) {
            key = i.split(str)[0];
            val = i.split(str)[1];
            return {
              key: key,
              value: val
            }
          }
          return null
        })
        let group = userPerm.reduce((r, a) => {
          r[a.key] = [...r[a.key] || [], a.value];
          return r;
        }, {});
        user_permissions=group;

        if(accountant.length>0){
          user_permissions["manual_payment"]=["add", "view", "delete", "list", "edit"];
          user_permissions["account"]=["add", "view", "delete", "list", "edit"];
        }
        if(clinician.length>0){
          user_permissions["waiting_list"]=["add", "view", "delete", "list", "edit"];
        }
        if(patientRecord.length>0){
         let patientRecordPerms = patientRecord.map(i => {
            return i.split('.')[1]
          })
          let recordPerms = patientRecordPerms.map(i => {
            let str = "_";
            let key = "";
            let val = "";
            if (i.indexOf(str) > -1) {
              key = i.split(str)[0];
              val = i.split(str)[1];
              return {
                key:val,
                value:key
              }
            }
            return null;
          })
          let recordPermsgroup = recordPerms.reduce((r, a) => {
            r[a.key] = [...r[a.key] || [], a.value];
            return r;
          }, {});
          console.log("recordPermsgroup",recordPermsgroup);
          user_permissions["encounter"]= recordPermsgroup["encounter"];
          user_permissions["encounternote"]= recordPermsgroup["encounternote"];
        }
      }
      localStorage.setItem('user_permissions', JSON.stringify(user_permissions));
      userPermissionsVar(JSON.stringify(user_permissions));
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  

  const [verifyToken] = useMutation(VERIFY_TOKEN, {
  onCompleted({ verifyToken }) {    
      if(!verifyToken.success){
        let errors = verifyToken.errors.nonFieldErrors;
        for(let i in errors){
          let error=errors[i];
          openSnackbar(error.message,[SNACK_DURATION]);
        }
        handleLogout();
      }
      else{
        GetUserPermissions();
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  useEffect(() => {
    if(token){
      verifyToken({variables:{token:token}});
    }
  }, [token,verifyToken]);
  
  return null;
  
};

export default withRouter(VerifyToken);
