import React, { useRef } from 'react';
import style from './LoginForm/LoginForm.module.css';
import { Button, Form, Col, Row } from 'react-bootstrap';
import { gql, useMutation } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar';
import { isLoggedInVar, userDetailsVar } from '../../cache/cache';
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
} from '../../Common/helpers.js';
import { Formik } from 'formik';
import { useTranslation } from "react-i18next";

const RegisterForm = (props) => {
  const [openSnackbarError] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const formikRef = useRef();
  const { t } = useTranslation();

  const REGISTER = gql`
    mutation register(
      $firstName: String!
      $lastName: String!
      $middleName:String!
      $email: String!
      $password1: String!
      $password2: String!
      $username: String!
    ) {
      register(
        input: {
          firstName: $firstName
          middleName: $middleName
          lastName: $lastName
          email: $email
          password1: $password1
          password2: $password2
          username: $username
        }
      ) {
        success
        errors
        token
        refreshToken
      }
    }
  `;

  const [register, { loading }] = useMutation(REGISTER, {
    onCompleted({ register }) {
      if (register.success) {
        const { history } = props;
        openSnackbarSuccess('Registered');
        localStorage.setItem('token', register.token);
        userDetailsVar(JSON.stringify(register.token));
        isLoggedInVar(true);
        if (
          props.location &&
          props.location.state &&
          props.location.state.from
        ) {
          history.push(props.location.state.from.pathname);
        } else {
          history.push('/manage/intake/forms/form');
        }
      } else {
        let errors = register.errors;
        let nonFieldErrors = errors.nonFieldErrors;
        let emailErrors = errors.email;
        let password1Errors = errors.password1;
        let password2Errors = errors.password2;
        let firstNameErrors = errors.firstName;
        let middleNameErrors = errors.middleName;
        let lastNameErrors = errors.lastName;
        let usernameErrors = errors.username;
        for (let i in nonFieldErrors) {
          let e = nonFieldErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in emailErrors) {
          let e = emailErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in firstNameErrors) {
          let e = firstNameErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in lastNameErrors) {
          let e = lastNameErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in middleNameErrors) {
          let e = middleNameErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in usernameErrors) {
          let e = usernameErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in password1Errors) {
          let e = password1Errors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in password2Errors) {
          let e = password2Errors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
      }
    },
    onError: () => {
      openSnackbarError(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const initialValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    username: '',
    email: '',
    password1: '',
    password2: '',
  };

  const onSubmit = (values) => {
    register({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: values.middleName,
        username: values.username,
        email: values.email,
        password1: values.password1,
        password2: values.password2,
      },
    });
  };

  // const formik = useFormik({
  //   initialValues,
  //   onSubmit,
  //   formikRef
  // });

  const onChangeEnglishInput = (e) => {
    let value = e.target.value;
    let fieldName = e.target.name;
    // value = value.replace(/[^A-Za-z]/ig, '');
    formikRef.current.setFieldValue(fieldName, value);
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} innerRef={formikRef}>
      {({ handleSubmit, handleChange, values, handleReset }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row}>
            <Form.Label column sm={4} className={'text-white text-right'}>
              {t('patientRegister.firstName')}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="firstName"
                onChange={onChangeEnglishInput}
                value={values.firstName}
                autoComplete="given-name"
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4} className={'text-white text-right'}>
            {t('patientRegister.middleName')}
          </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="middleName"
                onChange={onChangeEnglishInput}
                value={values.middleName}
                autoComplete="family-name"
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4} className={'text-white text-right'}>
            {t('patientRegister.lastName')}
          </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="lastName"
                onChange={onChangeEnglishInput}
                value={values.lastName}
                autoComplete="family-name"
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4} className={'text-white text-right'}>
            {t('patientRegister.username')}
          </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="username"
                onChange={handleChange}
                value={values.username}
                autoComplete="username"
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4} className={'text-white text-right'}>
            {t('patientRegister.email')}
          </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="email"
                name="email"
                onChange={handleChange}
                value={values.email}
                autoComplete="email"
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4} className={'text-white text-right'}>
            {t('patientRegister.password')}
          </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="password"
                name="password1"
                onChange={handleChange}
                value={values.password1}
                autoComplete="new-password"
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={4} className={'text-white text-right'}>
            {t('patientRegister.confirmPassword')}
          </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="password"
                name="password2"
                onChange={handleChange}
                value={values.password2}
                autoComplete="new-password"
                required
              />
            </Col>
          </Form.Group>
          <Button
            variant="link"
            block
            className="forgot_password"
            onClick={() => props.history.push('/')}
          >
            {t('patientRegister.backToLogin')}
         </Button>
          <Form.Group as={Row}></Form.Group>
          <Form.Group as={Row}>
            <Col className={style.login_form__submit}>
              <Button
                disabled={loading}
                className="login_form__button"
                type="submit"
              // onClick={() => props.history.push('/manage/intake/forms/form')}
              >
                {loading ? 'Loading ...' : 
              t('patientRegister.submit')}
              </Button>
            </Col>
          </Form.Group>
        </Form>)}
    </Formik>
  );
};

export default withRouter(RegisterForm);
