import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { cache } from './cache/cache'
import './styles.scss';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { setContext } from '@apollo/client/link/context';
import { BrowserRouter } from "react-router-dom";
import { createUploadLink } from "apollo-upload-client";

// import i18n 
import "./locales/i18n.js";


const localBe = 'http://localhost:8000/';
const devBe = 'https://clinic.hypertext.site/';

export var urlBackend = devBe;

const enableSentry = false; // variable for sentry, true in master 
const local = false; // change this true if need to have local backend 

if (local) {
  urlBackend = localBe;
}
export const httpLink = createUploadLink({
  uri: urlBackend + 'graphql/',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  // headers['Accept-Language'] = 'ar'
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
      "Accept-Language": localStorage.getItem('appLanguage')
    }
  }
});

const corsOptions = {
  credentials: false
};

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  cors: corsOptions,
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  }
});


if (enableSentry) {
  Sentry.init({
    dsn: "https://00b45350515d4ff9a329f22b8f44eb12@o346322.ingest.sentry.io/5601175",
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(

  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
  ,

  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
