import React, { useState } from 'react';
import style from './LoginForm.module.css';
import { Button, Form, Col, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { isLoggedInVar, userDetailsVar } from '../../../cache/cache.js';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar';
import { error_options, SNACK_DURATION, ERROR_MESSAGE } from '../../../Common/helpers.js';
import { useReactiveVar } from '@apollo/client';
import { appLanguageVar } from "../../../cache/cache";
import { useTranslation } from "react-i18next"



const LoginForm = (props) => {
  const { setLangChangeLoader } = props;
  const [openSnackbarError] = useSnackbar(error_options);
  const appLanguage = useReactiveVar(appLanguageVar);
  const [isUsername, setIsUsername] = useState(false);
  const { t } = useTranslation();

  const [submitToken, setSubmitToken] = useState(false);

  const [emailValue, setEmailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  //=======================LOGIN==========================//

  const LOGIN_EMAIL = gql`
    mutation tokenAuth($email: String!, $password: String!, $otpToken: String) {
      tokenAuth(input: { email: $email, password: $password, otpToken: $otpToken }) {
        success
        errors
        unarchiving
        token
        refreshToken
        unarchiving
        user {
          id
          email
          username
          isStaff
          verified
          pk
          isSuperuser
          canEditAppointments{
            edges{
              node{
                identifier
              }
            }
          }
          canViewAppointments{
            edges{
              node{
                identifier
              }
            }
          }
          doctor{
            id
          }
          patient{
            firstName
            middleName
            lastName
            id
            identifier
            email
            civilId
            displayGender
            phone
            address
            profileComplete
          }
        }
      }
    }
  `;

  const LOGIN_USERNAME = gql`
    mutation tokenAuth($username: String!, $password: String!, $otpToken: String) {
      tokenAuth(input: { username: $username, password: $password, otpToken: $otpToken }) {
        success
        errors
        unarchiving
        token
        refreshToken
        unarchiving
        user {
          id
          username
          isStaff
          pk
          canEditAppointments{
            edges{
              node{
                identifier
              }
            }
          }
          canViewAppointments{
            edges{
              node{
                identifier
              }
            }
          }
          patient{
            firstName
            middleName
            lastName
            id
            identifier
            email
            civilId
            displayGender
            phone
            address
            profileComplete
          }
        }
      }
    }
  `;

  const LOGIN = isUsername ? LOGIN_USERNAME : LOGIN_EMAIL;
  const [tokenAuth, { loading }] = useMutation(LOGIN, {
    onCompleted({ tokenAuth }) {
      console.log("tokenAuth", tokenAuth);
      if (tokenAuth.success === false) {
        let errors = tokenAuth.errors.nonFieldErrors;
        for (let i in errors) {
          let error = errors[i];
          openSnackbarError(error.message, [SNACK_DURATION]);
        }
      } else if (tokenAuth.success === true) {
        const { history } = props;
        localStorage.setItem('token', tokenAuth.token);
        localStorage.setItem('user', JSON.stringify(tokenAuth.user));
        userDetailsVar(JSON.stringify(tokenAuth.user));
        isLoggedInVar(true);
        console.log("tokenAuth.isStaff", tokenAuth);
        if (tokenAuth.user.isStaff) {
          localStorage.setItem('is_staff', true);
          if (appLanguage === "ar") {
            setLangChangeLoader(true);
            document.body.lang = "en";
            document.body.dir = "ltr";
            localStorage.setItem('appLanguage', "en");
            setTimeout(() => {
              window.location = window.location.href;
            }, 500);
          }
          if (props.location && props.location.state && props.location.state.from) {
            history.push(props.location.state.from.pathname);
          }
          else {
            history.push('/dashboard');
          }
        }
        else if (!tokenAuth.user.isStaff) {
          if (tokenAuth.user.patient && tokenAuth.user.patient.profileComplete) {
            if (props.location && props.location.state && props.location.state.from) {
              history.push(props.location.state.from.pathname);
            }
            else {
              history.push('/patientPortal/dashboard');
            }
          }
          else {
            history.push('/registration/complete/form');
          }
          localStorage.setItem('is_staff', false);
        }
      }
    },
    onError: (e) => {
      console.log("Error", e)
      setSubmitToken(false)
      openSnackbarError(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const initialValues = {
    email: '',
    password: '',
    otpToken: '',
  };

  const onSubmit = (values, { resetForm }) => {
    if (!values.email && !emailValue) {
      alert('Email required.');
      return;
    }
    if (!values.password && !passwordValue) {
      alert('Password required.');
      return;
    }
    
    let token = {};
    if (values.otpToken) {
      token = {
        otpToken: values.otpToken
      }
    }
    
    if (window.location.hostname.startsWith('patients') || submitToken) {
      if (emailValue.includes('@')) {
        // tokenAuth({ variables: { email: values.email, password: values.password, ...token } })
        tokenAuth({ variables: { email: emailValue, password: passwordValue, ...token } })
      } else {
        setIsUsername(true);
  
        setTimeout(() => {
          // tokenAuth({ variables: { username: values.email, password: values.password, ...token } })
          tokenAuth({ variables: { email: emailValue, password: passwordValue, ...token } })
        }, 300);
      }
    } else {
      setSubmitToken(true)
    }
    

    resetForm({ values: '' });
  };

  // const validate = (values) => {
  //   let errors = {};
  //   if (!values.username) {
  //     errors.username = 'Please enter a email.';
  //   }
  //   else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)){
  //     errors.username = 'Please enter a valid email.';
  //   }
  //   if (!values.password) {
  //     errors.password = 'Please enter a password.';
  //   }
  //   return errors;
  // };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const handleChangeEmail = (event) => {
    setEmailValue(event.target.value)
  }

  const handleChangePassword = (event) => {
    setPasswordValue(event.target.value)
  }
  

  return (
    <Form onSubmit={formik.handleSubmit} >
      <Col xs={12} md={11} lg={11}>
        <div className={!submitToken ? '' : 'd-none'}>
          <Form.Group as={Row} className="mb-4">
            <Form.Label column xs={12} sm={3} className={'text-white text-right'}>
            {t('patientLogin.email')}
                </Form.Label>
            <Col xs={12} sm={9}>
              <Form.Control
                type="text"
                name="email"
                // onChange={formik.handleChange}
                // value={formik.values.email}
                onChange={handleChangeEmail}
                value={emailValue}
                autoComplete="username"
                // required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column xs={12} sm={3} className={'text-white text-right'}>
              {t('patientLogin.password')}
                </Form.Label>
            <Col xs={12} sm={9}>
              <Form.Control
                autoComplete="new-password"
                type="password"
                name="password"
                // onChange={formik.handleChange}
                // value={formik.values.password}
                onChange={handleChangePassword}
                value={passwordValue}
                // required
              />
            </Col>
          </Form.Group>
          <Button
            variant="link"
            onClick={() => props.history.push('/register')}
            block
            className="forgot_password">
            {' '}
                {t('patientLogin.register')}
              </Button>
          <Button
            variant="link"
            onClick={() => props.history.push('/forgot/password')}
            block
            className="forgot_password">
            {' '}
            {t('patientLogin.forgotPassword')}
              </Button>
          <Form.Group as={Row}></Form.Group>
          </div>
          <div className={submitToken ? '' : 'd-none'}>
         <Form.Group as={Row} className="mb-4">
          <Form.Label column xs={12} sm={3} className={"text-white text-right"}>
            {t("patientLogin.otpForm")}
          </Form.Label>
          <Col xs={12} sm={9}>
            <Form.Control
              type="text"
              name="otpToken"
              onChange={formik.handleChange}
              value={formik.values.otpToken}
              autoComplete="username"
              // required
            />
          </Col>
        </Form.Group>
        </div>

        <Form.Group as={Row}>
          <Col className={style.login_form__submit}>
            <Button disabled={loading} className="login_form__button" type="submit">
              {loading ? 'Loading ...' : 
              t('patientLogin.submit')}
            </Button>
          </Col>
        </Form.Group>
      </Col>
    </Form>
  );
};

export default withRouter(LoginForm);
