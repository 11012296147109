import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { isUserStaff } from '../Common/helpers';
import drag from "../assets/images/drag.jpg";

const FileUpload = (props) => {
  const isStaff = isUserStaff();
  const { currentImage, setCurrentImage } = props;
  const allowedEndings = ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.webp'];

  const UploadMutation = gql`
    mutation updatePatientSelfFile($file: Upload!) {
      updatePatientSelfFile( file: $file) {
        obj{
          idFile
        }
      }
    }
  `;

  const UPDATE_PATIENT_FILE = gql`
    mutation updatePatientFile ($id: ID!, $file: Upload!) {
      updatePatientFile (id: $id, file: $file) {
        obj {
          idFile
        }
      }
    }
  `;

  // pass in the UploadMutation mutation we created earlier.
  const [uploadFile] = useMutation(UploadMutation, {
    onCompleted({ updatePatientSelfFile }) {
      console.log("updatePatientSelfFile", updatePatientSelfFile);
      setCurrentImage(updatePatientSelfFile.obj.idFile);
    },
    onError: (e) => {
      console.log("eeeee", e.errors);
      // openSnackbarError(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const [updatePatientFile, { loading }] = useMutation(UPDATE_PATIENT_FILE, {
    onCompleted({ updatePatientFile }) {
      console.log('updatePatientFile', updatePatientFile);
    },
    onError: (e) => {
      console.log('updatePatientFile error: ', e.errors);
    }
  })

  const onDrop = useCallback(
    (acceptedFiles) => {
      // select the first file from the Array of files
      const file = acceptedFiles[0];
      // use the uploadFile variable created earlier
      if (file) {
        let val = {
          file: file
        }
        console.log("val", val);
        if (isStaff) {
          updatePatientFile({
            variables: { ...val, id: props.patientId },
            onCompleted: () => { console.log('updatePatientFile done') }
          })
        } else {
          uploadFile({
            // use the variables option so that you can pass in the file we got above
            variables: { ...val },
            onCompleted: () => { console.log("done") },
          });
        }

      }
      else {
        console.log("NO File")
      }
    },
    // pass in uploadFile as a dependency
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadFile]
  );


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const checkEnding = (string) => {
    let isEndingGood = false;
    let index = string.lastIndexOf('.');
    let stringEnding = string.slice(index);
    allowedEndings.forEach((item, index) => {
      if (item === stringEnding) {
        isEndingGood = true;
      } 
    })
    return isEndingGood;
  }

  return (
    <>
      <div {...getRootProps()} className={`dropzone ${isDragActive && "isActive"}`}>
        <input {...getInputProps()} />
        {currentImage ?
          checkEnding(currentImage) ? 
          <div className="text-left">
            <div className={loading ? "opacityhalf" : "idFileWrapper"}>
              <img src={currentImage} alt="" className={""} />
            </div>
          </div>
          : 
          <div className="text-center">
            <div className={loading ? "opacityhalf" : "idFileWrapper"}>
              <i className="fa fa-file-image-o upload-icon"></i><br />
              <small>Your file preview is unavailable</small>  
            </div>
          </div>
           :
          <div className="text-left">
            {isDragActive ? <p>Drop the files here ...</p> : <div className="dragPic"><img src={drag} alt=""  /></div>}
          </div>
        }
      </div>
    </>
  );
};
export default FileUpload;