import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Base from './base.js';
import { withRouter, Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import {
  getDate,
  getForamttedTime,
  displayMiddleName
  // getPermissionForAction,
} from '../Common/helpers';
import Preloader from '../Common/Preloder/Preloader';
import Logo from '../assets/images/logo.png';

var today = new Date();
today = getDate(today) + ' ' + getForamttedTime(today);

const PaymentDetailPrintPage = (props) => {
  const paymentId = props.match.params.id;

  const REQUEST_PAYMENT_DETAIL = gql`
    query {
      payment(id:"${paymentId}") {
        created
        amount
        currency
        notes
        displayPaymentType
        sources{
          edges{
            node{
              reference
              paymentType
              sourceType{
                name
                code
              }
            }
          }
        }
        patient{
          firstName
          middleName
          lastName
          identifier
          displayGender
          civilId
          phone
        }
        appointment{
          created
          start
          id
          doctor{
            firstName
            lastName
          }
          payments{
            edges{
              node{
                sources{
                  edges{
                    node{
                      amountDebited
                      status
                      reference
                      id
                      currency
                      paymentType
                      sourceType{
                        name
                        code
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { data, loading } = useQuery(REQUEST_PAYMENT_DETAIL, {
    fetchPolicy: 'network-only',
    onError: (e) => {
      console.log('REQUEST_PAYMENT_DETAIL error: ', e.errors);
    },
  });

  const paymentDetailData = data && data.payment ? data.payment : null;

  console.log('paymentDetailData!', paymentDetailData);

  const printPaymentDetail = () => {
    window.print();
  };

  const renderPaymentDetail = () => {
    const appointmentPayments =
      paymentDetailData &&
        paymentDetailData.appointment &&
        paymentDetailData.appointment.payments
        ? paymentDetailData.appointment.payments.edges
        : null;

    const sources = appointmentPayments
      ? appointmentPayments.map((item, index) => {
        return item.node.sources.edges;
      })
      : null;
    console.log('sources!', sources);
    return (
      <tr className="page-table-cell">
        <td className="page-table-cell">
          <div className="page">
            <Row>
              {paymentDetailData && paymentDetailData.patient ? (
                <Col xs={12}>
                  <h6 className="capitalize">
                    Patient Name:
                    <Link
                      className="d-inline-block ml-1 patient-name-print-mode-link"
                      to={`/patient/record/${paymentDetailData.patient.identifier}`}
                    >
                      <Button variant="link" className="p-0 patient-name-print-mode-btn">
                        {paymentDetailData.patient.firstName}{' '}
                        {displayMiddleName(paymentDetailData.patient.middleName)}
                        {paymentDetailData.patient.lastName}
                      </Button>
                    </Link>
                  </h6>
                  <h6 className="capitalize">
                    Phone: {paymentDetailData.patient.phone}
                  </h6>
                  <h6 className="capitalize">
                    Civil ID: {paymentDetailData.patient.civilId}
                  </h6>
                </Col>
              ) : null}
              {paymentDetailData && paymentDetailData.appointment ? (
                <Col xs={12}>
                  <h6 className="capitalize">
                    Appointment Date:{' '}
                    {getDate(paymentDetailData.appointment.start)}{' '}
                    {getForamttedTime(paymentDetailData.appointment.start)}
                  </h6>
                  <h6 className="capitalize">
                    Practitioner:{' '}
                    {paymentDetailData.appointment.doctor.firstName}{' '}
                    {paymentDetailData.appointment.doctor.lastName}
                  </h6>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col xs={12}>
                <h6 className="mb-0 mt-3">
                  <b>Payments:</b>
                </h6>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="">
                {paymentDetailData && paymentDetailData.appointment ? (
                  // here goes appointment
                  <>
                    {sources
                      ? sources.map((itemNode, index) => {
                        return (
                          <div key={index} className="payment-container">
                            {itemNode.map((item, index) => {

                              return item.node.status === 'Paid' ? (
                                <div key={index} className="payment-block">
                                  <h6 className="capitalize">
                                    Amount:{' '}
                                    {item.node.amountDebited}{' '}
                                    {item.node.currency}
                                  </h6>
                                  <h6 className="capitalize">
                                    Transaction Date:{' '}
                                    {getDate(paymentDetailData.created)}{' '}
                                    {getForamttedTime(paymentDetailData.appointment.start)}
                                  </h6>
                                  <h6 className="capitalize">
                                    Reference Number:{' '}
                                    {item.node.reference}
                                  </h6>
                                  {/* <h6 className="capitalize">
                                        Transaction ID: {item.node.id}
                                      </h6> */}
                                  {item.node.sourceType ? 
                                    <>
                                    {item.node.sourceType.code === 'cash' ?
                                      <h6 className="capitalize">
                                        Payment Type: {item.node.sourceType.name}
                                      </h6>
                                      :
                                      <h6 className="capitalize">
                                        Payment Type: {item.node.sourceType.name}{' '}(TAP)
                                      </h6>}
                                    </>
                                  : null}
                                </div>
                              ) : null;

                            })}
                          </div>
                        );
                      })
                      : null}
                  </>
                ) : (
                  // here goes payment if no appointment
                  <>
                    <div className="payment-container">
                      <div className="payment-block">
                        <h6 className="capitalize">
                          Amount:{' '}
                          {paymentDetailData ? paymentDetailData.amount : null}{' '}
                          {paymentDetailData ? paymentDetailData.currency : null}
                        </h6>
                        <h6 className="capitalize">
                          Transaction Date:{' '}
                          {paymentDetailData && paymentDetailData.created ? getDate(paymentDetailData.created) : null}{' '}
                          {paymentDetailData && paymentDetailData.created ? getForamttedTime(paymentDetailData.created) : null}
                        </h6>
                        <h6 className="capitalize">
                          Reference Number:{' '}
                          {paymentDetailData ? paymentDetailData.sources.edges[0].node.reference : null}
                        </h6>
                        { paymentDetailData && paymentDetailData.sources.edges[0].node.sourceType ? 
                          <>
                          {paymentDetailData && paymentDetailData.sources.edges[0].node.sourceType.code === 'cash' ?
                            <h6 className="capitalize">
                              Payment Type: {paymentDetailData ? paymentDetailData.sources.edges[0].node.sourceType.name : null}
                            </h6>
                            :
                            <h6 className="capitalize">
                              Payment Type: {paymentDetailData ? paymentDetailData.sources.edges[0].node.sourceType.name : null}{' '}(TAP)
                            </h6>}
                          </>
                        : null }
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </td>
      </tr>
    );
  };

  const PrintPaymentComponent = () => {
    return (
      <div className="print prescriptions-print-page">
        <div className="lab-request-header">
          <Row className="h100">
            <Col
              xs={6}
              sm={6}
              md={6}
              className="d-flex justify-content-start align-items-center"
            >
              <img alt={'Logo'} className="img-fluid printLogo" src={Logo} />
            </Col>
            <Col
              xs={6}
              sm={6}
              md={6}
              className="d-flex justify-content-end align-items-center"
            >
              {today}
            </Col>
          </Row>
        </div>

        <div className="page-footer">
          Al Tijaria Tower - Floor 23 - Alsoor Street - Kuwait City, Telephone -
          +965 22055334
        </div>

        <table>
          <thead>
            <tr>
              <td>
                <div className="page-header-space"></div>
              </td>
            </tr>
          </thead>

          <tbody>{renderPaymentDetail()}</tbody>

          <tfoot>
            <tr>
              <td>
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  return (
    <>
      <Base
        title={'Payment Detail'}
        showHeader={true}
        rightChild={
          <div className="d-flex flex-column d-print-none">
            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                className="mx-2"
                onClick={printPaymentDetail}
              >
                <b>Print</b>
              </Button>
            </div>
          </div>
        }
      >
        {loading ? (
          <Preloader />
        ) : (
          <Row className="d-print-none">
            <Col md={12}>{renderPaymentDetail()}</Col>
          </Row>
        )}
      </Base>

      <PrintPaymentComponent />
    </>
  );
};

export default withRouter(PaymentDetailPrintPage);
