import Base from './base.js';
import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Form, Table, Button, Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { SNACK_DURATION, ERROR_MESSAGE, error_options, getDate, success_options, PER_PAGE_COUNT, urlActions } from '../Common/helpers';
import { Formik } from 'formik';
import { useSnackbar } from 'react-simple-snackbar';
import { Link } from 'react-router-dom';


function ManageLeaveRequests(props) {
  const { history } = props;
  const formikRef = useRef();
  const [startDate, setStartDate] = useState('');
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    let page = urlActions(window.location.href, 'get', 'page');
    if (page) {
      setActivePage(parseInt(page));
    }
  }, []);

  const REQUEST_USERS = gql`
  query {
    users{
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
  `;


  const REQUEST_LEAVES = gql`  
    query ($user:ID, $created:DateTime) {
      leaveRequests(
        user:$user,
        created_Date:$created,
        first:${PER_PAGE_COUNT}, offset:${PER_PAGE_COUNT * (activePage - 1)
    }
      ){
        totalCount
        edges {
          node {
              id
              startDate 
              endDate
              totalDays
              status
              leaveType
              created
              description
              user {
                firstName
                lastName
                phone
                id
                hr{
                  position{
                    name
                  }
                  department{
                    name
                  }
                  numberAnnualLeaves
                }
                
              }
          }
        }
      }
    }
  `;

  const { data: userList } = useQuery(REQUEST_USERS, {
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const [getLeavesFilter, { data: leavesObjFilter = null }] = useLazyQuery(REQUEST_LEAVES);
  const users = userList && userList.users ? userList.users.edges : [];



  const APPROVE_LEAVE_REQUEST = gql`
  mutation approvalLeaveRequest(
    $status:String!
    $id:ID!
    $user:ID!
    $startDate:Date!
    $endDate:Date!
    $leaveType:String!
    $description:String!
  ) {
    approvalLeaveRequest(
      input: {    
      id:$id
      user: $user
      startDate: $startDate
      endDate: $endDate
      leaveType: $leaveType
      description: $description
      status: $status }
    ) {
      obj{
        created
        id
        startDate
        endDate
        leaveType
        status
      }
       errors {
        field
        messages
      }
    }
  }
`;


  const [approvalLeaveRequest] = useMutation(APPROVE_LEAVE_REQUEST, {
    onCompleted: ({ approvalLeaveRequest }) => {
      console.log("approvalLeaveRequest", approvalLeaveRequest);
      if (approvalLeaveRequest.errors.length === 0) {
        let status = approvalLeaveRequest.obj.status;
        openSnackbarSuccess('Leave Request ' + status, [SNACK_DURATION]);
      } else {
        let alert_msg = '';
        approvalLeaveRequest.errors.map((error) => {
          alert_msg += error.messages.join(' ') + ' ';
          openSnackbar(alert_msg, [50000]);
          return null;
        });
      }
    },
    refetchQueries: [
      { query: REQUEST_LEAVES, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const approveRequest = (leave_detail_obj) => {
    let val = {
      id: leave_detail_obj.id,
      user: leave_detail_obj.user.id,
      startDate: leave_detail_obj.startDate,
      endDate: leave_detail_obj.endDate,
      leaveType: leave_detail_obj.leaveType.toLowerCase(),
      description: leave_detail_obj.description,
      status: "approved",
    }

    console.log("valvalval", val)
    approvalLeaveRequest({ variables: val });
  }

  const denyRequest = (leave_detail_obj) => {
    let val = {
      id: leave_detail_obj.id,
      user: leave_detail_obj.user.id,
      startDate: leave_detail_obj.startDate,
      endDate: leave_detail_obj.endDate,
      leaveType: leave_detail_obj.leaveType.toLowerCase(),
      description: leave_detail_obj.description,
      status: "unapproved",
    }
    approvalLeaveRequest({ variables: val });
  }

  useEffect(() => {
    getLeavesFilter();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    user: '',
    startDate: '',
  };

  const onSubmit = (values) => {
    console.log("values", values);
    let createdDate = startDate;

    let val = {};
    if (values.user) {
      val["user"] = values.user
    }
    if (createdDate) {
      createdDate.setHours(3);
      val["created"] = createdDate;
    }
    console.log("ValValValValVal", val);
    getLeavesFilter({ variables: val })
  }



  const handlePageChange = (number) => {
    setActivePage(number);
    history.push('/manage/leave/requests?page=' + number);
  };


  const renderPages = () => {
    let total_pages = leavesObjFilter && leavesObjFilter.leaveRequests && leavesObjFilter.leaveRequests.totalCount ? Math.ceil(leavesObjFilter.leaveRequests.totalCount / PER_PAGE_COUNT) : 1;
    let items = [];
    for (let number = 1; number <= total_pages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>,
      );
    }
    return items;
  }

  const resetFilter = () => {
    if (formikRef && formikRef.current) {
      formikRef.current.handleReset();
      setStartDate('');
    }
    getLeavesFilter();
  }

  console.log(leavesObjFilter)

  const getStatus = (status) => {
    if (status === "UNAPPROVED") {
      return "DENIED";
    } else {
      return status;
    }
  }
  

  return (
    <Base title={'MANAGE LEAVE REQUESTS'} showHeader={true}>
      <Formik onSubmit={onSubmit} initialValues={initialValues} innerRef={formikRef}>
        {({ handleSubmit, handleChange, handleBlur, values }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-2 justify-content-end">
              <Col xs={12} sm={6} md={6} lg={3} className="ml-auto">
                <Form.Group as={Row}>
                  <Form.Label column sm={3} md={3} lg={4} className="text-right pr-0">
                    Staff
                  </Form.Label>
                  <Col sm={12} md={9} lg={8}>
                    <Form.Control
                      autoComplete="off"
                      onBlur={handleBlur}
                      as="select"
                      name="user"
                      value={values.user}
                      onChange={handleChange}>
                      <option value="">Select Staff</option>
                      {users.map((user) => {
                        return (
                          <option value={user.node.id} key={user.node.id}>
                            {user.node.firstName} {user.node.lastName}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={6} lg={3}>
                <Form.Group as={Row}>
                  <Form.Label column sm={3} md={3} lg={3} className="text-right pr-0">
                    Date
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <DatePicker
                      autoComplete="off"
                      name="startDate"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="form-control"
                      dateFormat="dd/MMM/yyyy"
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={3} lg={2} style={{display: "flex"}}>
                <Button type="submit"> Submit </Button>
                <Button className="ml-3" onClick={resetFilter}> Reset </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <Row>
        <Table responsive className=" table-borderless">
          <thead>
            <tr className="patient_table_row tableHeader">
              <th scope="col " className="text-uppercase">
                Name
              </th>
              <th scope="col " className="text-uppercase">
                DATE OF REQUEST
              </th>
              <th scope="col " className="text-uppercase">
                LEAVE TYPE
              </th>
              <th scope="col " className="text-uppercase">
                START DATE
              </th>
              <th scope="col " className="text-uppercase">
                END DATE
              </th>
              <th scope="col " className="text-uppercase text-center">
                APPROVE/DENY
              </th>
            </tr>
          </thead>
          <tbody>
            {leavesObjFilter && leavesObjFilter.leaveRequests && leavesObjFilter.leaveRequests.edges.length > 0 ?
              leavesObjFilter.leaveRequests.edges.map((leave, index) => {
                let leaveObj = leave.node;
                return (<tr key={index} className="patient_table_row">
                  <td> <Link to={"/leave/request/" + leaveObj.id}> {leaveObj.user.firstName} {leaveObj.user.lastName} </Link> </td>
                  <td>{getDate(new Date(leaveObj.created))}</td>
                  <td>{leaveObj.leaveType}</td>
                  <td>{getDate(new Date(leaveObj.startDate))}</td>
                  <td>{getDate(new Date(leaveObj.endDate))}</td>
                  {leaveObj.status === "PENDING" ? <td className="icon">
                    <Button variant="link" className="py-0"> <FontAwesomeIcon icon={faCheckCircle} onClick={() => approveRequest(leaveObj)} /> </Button>
                    <Button variant="link" className="py-0"> <FontAwesomeIcon icon={faTimesCircle} onClick={() => denyRequest(leaveObj)} /> </Button>
                  </td> : <td className="text-center py-4"> <span >  {getStatus(leaveObj.status)} </span></td>}
                </tr>)
              })
              : <tr >
                <td>No Leaves Requested</td>
              </tr>}
          </tbody>
        </Table>
      </Row>
      {leavesObjFilter && leavesObjFilter.leaveRequests && leavesObjFilter.leaveRequests.totalCount > PER_PAGE_COUNT ? <div className="d-flex justify-content-center pt-5">
        <Pagination>{renderPages()}</Pagination>
      </div> : null}
    </Base >
  );
}
export default ManageLeaveRequests;
