import { Button, Container, Row, Col, Form, Modal, DropdownButton, Dropdown, Table } from 'react-bootstrap';
import { Formik } from 'formik';
import Base from './base.js';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useSnackbar } from 'react-simple-snackbar';
import {
  error_options, SNACK_DURATION, ERROR_MESSAGE, getDate, getForamttedTime,
  getPermissionForAction, getPatientsAge, generateUID,displayMiddleName
} from '../Common/helpers';
import FormBlock from "../component/DynamicFormBlock/formBlock";
import Notes from "../component/DynamicFormBlock/notes";
import CustomModals from '../component/Modal/modal';
import Logo from '../assets/images/logo.png';
import PatientFileUploadModal from './patientsFileUploadModal';
import BlackWhiteLogo from '../assets/images/logo.png';
import LabsComponent from "./Labs/LabsComponent";
import Stamp from 'component/Stamp/Stamp'

var today = new Date();
today = getDate(today) + " " + getForamttedTime(today);

function EncounterDetails(props) {

  const identifier = props.match.params.encounterId;
  // const patientId = props.match.params.patientId;
  const [openSnackbar] = useSnackbar(error_options);
  const formikRef = useRef();
  const formikRefNotes = useRef();
  const formikRefPrint = useRef();
  const [showModalNotes, setShowModalNotes] = useState(false);
  const [showFilesModal, setShowFilesModal] = useState(false);
  const [activeNotesID, setActiveNotesID] = useState(null);
  const [doctorSession, setDoctorSession] = useState(null);
  const [followUpmonths, setFollowUpMonths] = useState(null);
  const [followUpWeeks, setFollowUpWeeks] = useState(null);
  const [requestedForms, setRequestedForms] = useState([]);
  const [refFormElemList, setRefFormElemList] = useState({ keys: [] });
  const [refFormElemValues, setRefFormElemValues] = useState({ values: [] });
  const [editNoteTitleObj, setEditNoteTitle] = useState(false);
  const [expandNotesModal, setExpandNotesModal] = useState(false);
  const [patientIdentifier, setPatientIdentifier] = useState(null);
  const [printEncounterModal, setPrintEncounterModal] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [selectedDoctorSeenBy, setSelectedDoctorSeenBy] = useState([]);
  const [openCheckboxId, setOpenCheckboxId] = useState(null);
  const [blockPrintInstances, setBlockPrintInstances] = useState([]);
  const [initialValuesPrint, setInitialValuesPrint] = useState({});
  const [encounterDataValues, setEncounterDataValues] = useState(null);
  const [notesValue, setNotesValue] = useState('');
  let returnAllSectionPermissions = true;
  const referral_permissions = getPermissionForAction('referral', '', returnAllSectionPermissions);
  const encounter_permission = getPermissionForAction('encounter', '', returnAllSectionPermissions);
  const encounternote_permission = getPermissionForAction('encounternote', '', returnAllSectionPermissions);
  const [blockPrintData, setBlockData] = useState(null);
  const lab_request_permission = getPermissionForAction('lab_request', '', returnAllSectionPermissions);
  const [showModal, setShowModal] = useState(false);
  const [vitalDataState, setVitalDataState] = useState({edges: []});
  const [patientId, setPatientId] = useState(null);
  const [mainMedicalBlockPatient, setMainMedicalBlockPatient] = useState(null);

  if(encounter_permission.includes("change")){
    encounter_permission.push("edit");
  }
  
  useEffect(() => {
    if (!printEncounterModal) {
      setOpenCheckboxId(null);
      setInitialValuesPrint({});
      setBlockPrintInstances([]);
    }
  }, [printEncounterModal]);

  const REQUEST_ENCOUNTER_TYPES = gql`
  query {
    encounterTypes{
      edges {
        node {
            id
           name
          dynamicForm{
            id
            name
          }
        }
      }
    }
  }
`;

  const REQUEST_DYNAMIC_FORMS = gql`
  query {
    dynamicForms(formType:"intake_form"){
      edges {
        node {
          staticId
           id
          name
        }
      }
    }
  }
`;

  const REQUEST_LOCATION = gql`
  query {
    listEventLocation {
      edges {
        node {
          title
          id
        }
      }
    }
  }
`;
  const REQUEST_DOCTOR = gql`
  query {
    doctors {
      edges {
        node {
          firstName
          lastName
          identifier
          id
          eventType {
            edges {
              node {
                id
                title{
                  name
                }
                duration
                buffer
                price
                eventType {
                  id
                  name
                }
                eventLocation {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

  const REQUEST_ENCOUNTER = gql`
  query {
    encounter(id:"${identifier}"){
      id
      data
      date
      patient{
        id
        identifier
        firstName
        middleName
        lastName
        phone
        email
        gender
        displayGender
        dob
        civilId
     }
     doctor{
      id
      firstName
      lastName
      eventType {
        edges {
          node {
            id
            title{
              name
            }
            duration
            buffer
            price
            eventType {
              id
              name
            }
            eventLocation {
              id
              title
            }
          }
        }
      }
    }
    notes{
      edges{
        node{
          name
          text
          id
        }
      }
    }
    facility{
      title
      id
    }
    referrals{
      edges{
        node{
          title
          id
          followUp
          dynamicForms{
            edges{
              node{
                name
                id
                formType
              }
            }
          }
          encounter{
            date
          }
          description
          referredDoctor{
            firstName
            lastName
            id
            identifier
            eventType {
              edges {
                node {
                  id
                  title{
                    name
                  }
                  duration
                  buffer
                  price
                  eventType {
                    id
                    name
                    color{
                      hexCode
                      name
                      colorId
                    }
                  }
                  eventLocation {
                    id
                    title
                  }
                }
              }
            }
            recurringEvents{
              edges {
                node {
                  startDate
                  startTime
                  endTime
                  title
                  description
                  everyday
                  days
                  dateUntil
                }
              }
            }
            availability {
              edges {
                node {
                  id
                  day
                  shifts{
                    edges {
                      node {
                        startTime
                        endTime
                        id
                      }
                    }
                  }
                }
              }
            }
          }
          patient {
            firstName
            middleName
            lastName
            id
            identifier
          }
          referredDoctorSession{
            id
            title{
              name
            }
            duration
            buffer
            price
            eventType {
              id
              name
              color{
                hexCode
                name
                colorId
              }
            }
            eventLocation {
              id
              title
            }
          }
          createdAppointment{
            start
          }
        }
      }
    }
    appointment{
      id
      identifier
      start
      intakeForms{
        edges{
          node{
            id
            dynamicForm{
              name
            }
          }
        }
      }
      doctorEventType{
        id
        eventType{
          name
        }
      }
    }
      encounterType{
        id
        name
        dynamicForm {
          name
          id
          formType
          dynamicFormBlocks {
            edges {
              node {
                id
                formBlock {
                  name
                  id
                  staticId
                  blockFormFields {
                    edges {
                      node {
                        formField {
                          id
                          name
                          fieldName
                          variant
                          staticId
                          defaultBooleanVariant
                          options {
                            edges {
                              node {
                                id
                                staticId
                                value
                                default
                              }
                            }
                          }
                        }
                        formBlock {
                          id
                        }
                        required
                        width
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
     }
   }
`;
  const CREATE_UPDATE_ENCOUNTER_NOTES = gql`
  mutation createUpdateEncounterNote(
    $text: String!
    $name: String!
    $encounter: ID!
    $id:ID
  ) {
    createUpdateEncounterNote(
      input: {
        text:$text
        name:$name
        encounter:$encounter
        id:$id
      }
    ) {
      obj{
        id  
        name
        text        
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const UPDATE_ENCOUNTER = gql`
  mutation updateEncounter(
    $patient: ID!
    $doctor: ID!
    $facility: ID!
    $date:DateTime
    $id:ID!
  ) {
    updateEncounter(
      input: {
        patient:$patient
        doctor:$doctor
        facility:$facility
        date:$date
        id:$id
      }
    ) {
      obj{
        id   
        date    
        appointment{
          doctorEventType{
            id
            eventType{
              name
            }
          }
        }   
       }
       errors {
         field
         messages
       }
    }
  }
`;

  const DELETE_ENCOUNTER = gql`
    mutation deleteEncounter($id: ID!){
      deleteEncounter(id:$id) {
        deleted
      }
    }
  `;


  const CREATE_UPDATE_REFERRAL = gql`
    mutation createUpdateReferral(
      $patient: ID!
      $doctor: ID!
      $doctor_session: ID!
      $encounter: ID!
      $title: String
      $description: String
      $dynamicForms: [ID]
      $followUp:Boolean
      $recommendedDate: DateTime
    ) {
      createUpdateReferral(
        input: {
          patient:$patient
          referredDoctor:$doctor
          referredDoctorSession:$doctor_session
          encounter:$encounter
          title:$title
          description:$description
          dynamicForms:$dynamicForms
          followUp:$followUp
          recommendedDate:$recommendedDate
        }
      ) {
        obj{
          id
          created
          modified
           title
           description        
         }
         errors {
           field
           messages
         }
      }
    }
  `;

  const REQUEST_PATIENT_VITAL = gql`
  query {
    patients(identifier:"${patientId}"){
      edges {
        node {
          formData{
            id
            data
            dynamicFormData
            commonFormBlocks {
              dynamicFormBlocks {
                edges {
                  node {
                    id
                    formBlock {
                      name
                      id
                      staticId
                      isMedication
                      blockFormFields {
                        edges {
                          node {
                            formField {
                              id
                              name
                              fieldName
                              variant
                              staticId
                              defaultBooleanVariant
                              options {
                                edges {
                                  node {
                                    id
                                    staticId
                                    value
                                    default
                                  }
                                }
                              }
                            }
                            formBlock {
                              id
                            }
                            required
                            width
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          
          vitals{
            edges{
              node{
                id
                created
                modified
                bmi
                bp
                height
                weight
                waistCircumference
                pulse
                temperature
              }
            }
          }
        }
      }
    }
  }
  `;

  useEffect(() => {
    if (showModalNotes) {
      let notes_expand = document.getElementsByClassName("modal-90w");
      if (notes_expand && notes_expand.length > 0) {
        notes_expand[0].classList.add('opacityhalf');
      }
    }
    else {
      let notes_expand = document.getElementsByClassName("modal-90w");
      if (notes_expand && notes_expand.length > 0) {
        notes_expand[0].classList.remove('opacityhalf');
      }
    }
  }, [showModalNotes]);



  const updatePatientMedicalData=(patient_record) => {
    if(patient_record?.node.formData){
      let medicalInstancesToAdd = [];
      let medicalBlockStaticId=patient_record?.node.formData?.commonFormBlocks.dynamicFormBlocks.edges.find(i=>i.node.formBlock.isMedication);
      if(medicalBlockStaticId?.node.formBlock.isMedication){
        medicalBlockStaticId = medicalBlockStaticId?.node.formBlock.staticId;
        if(patient_record?.node.formData?.dynamicFormData){
          let dynamicDatakeys = Object.keys(patient_record?.node.formData?.dynamicFormData);
          if(dynamicDatakeys?.length>0){
            let mainMedicalInstances=dynamicDatakeys.filter(i=>(i.indexOf("main_medication_")>-1));
            mainMedicalInstances.map(i=>{
              let enc_static_id=null;
              if(i.indexOf("main_medication")>-1){
                enc_static_id=i.split("encounter_")[1];
                enc_static_id=enc_static_id.split("_block_")[0];
              }
              let inst = patient_record?.node.formData?.dynamicFormData[i];  
              if(inst){
                let new_form_block_instances = inst.form_block_instances.map(j=>{
                  let newObj = JSON.parse(JSON.stringify(j));
                  newObj["is_from_encounter"]=true;
                  newObj["enc_static_id"]=enc_static_id;
                  newObj["form_block_key"]=i;
                  return newObj;
                });
                medicalInstancesToAdd.push(...new_form_block_instances);
              }   
            })
            if(medicalBlockStaticId){
              let prevJsonData=patient_record?.node.formData?.data?patient_record?.node.formData?.data:{};
              
              let jsonDataClone = JSON.parse(JSON.stringify(prevJsonData));
              let prevMedDataInstances=[];
              if(jsonDataClone.hasOwnProperty("block_"+medicalBlockStaticId)){
                prevMedDataInstances=jsonDataClone["block_"+medicalBlockStaticId].form_block_instances;
              }
              if(medicalInstancesToAdd.length>0 ){
                prevMedDataInstances.push(...medicalInstancesToAdd);
              }
              if(jsonDataClone.hasOwnProperty("block_"+medicalBlockStaticId)){
                jsonDataClone["block_"+medicalBlockStaticId]["form_block_instances"]=prevMedDataInstances;
              }
              else{
                jsonDataClone["block_"+medicalBlockStaticId]={
                  form_block_instances:prevMedDataInstances
                }
              }
              let medicationFormBlock = patient_record.node.formData.commonFormBlocks.dynamicFormBlocks.edges.filter(i=>i.node.formBlock.isMedication);
              setMainMedicalBlockPatient({medicationFormBlock:medicationFormBlock,jsonDataMedical:jsonDataClone,patientFormData:patient_record.node.formData.id});
              return 
            }
          }
        }
      }
    }
  }


  const [getVitals, { data: dataPatientVital}] = useLazyQuery(REQUEST_PATIENT_VITAL, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange:true,
    onCompleted: ({patients}) => {
      console.log("dataPatientVitaluseLazyQuery",patients)
      if (patients?.edges[0]?.node?.vitals) {
        setVitalDataState(patients?.edges[0].node.vitals)
      }
      const patient_record = patients?.edges[0];
      console.log("REQUEST_PATIENT_VITAL",patient_record)
      updatePatientMedicalData(patient_record);
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });
  
  const patient_record = dataPatientVital?.patients?.edges[0];

  useEffect(() => {
    if (props.location.state?.vitalDataState) {
      setVitalDataState(props.location.state.vitalDataState)
    } 
    if(patientId){
      getVitals();
    }
  }, [patientId])
  
  const { data } = useQuery(REQUEST_ENCOUNTER, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange:true,
    onCompleted: ({encounter}) => {
      if(!patientId){
        setPatientId(encounter.patient.identifier);
      }
      else{
        getVitals();        
      }
      setShowModalNotes(false);
    },
    refetchQueries: [
      { query: REQUEST_PATIENT_VITAL, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // GET ENCOUNTER_TYPES DATA
  const { data: encounter_types_obj = null } = useQuery(
    REQUEST_ENCOUNTER_TYPES,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  // GET ENCOUNTER_TYPES DATA
  const { data: dynamic_forms_obj = null } = useQuery(
    REQUEST_DYNAMIC_FORMS,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );


  // GET REQUEST_LOCATION DATA
  const { data: locations_obj = null } = useQuery(
    REQUEST_LOCATION,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  // GET DOCTOR DATA
  const { data: doctors_obj = null } = useQuery(
    REQUEST_DOCTOR,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );


  // CREATE ENCOUNTER NOTES
  const [createUpdateEncounterNote] = useMutation(CREATE_UPDATE_ENCOUNTER_NOTES, {
    onCompleted: ({ createUpdateEncounterNote }) => {
      if (createUpdateEncounterNote.errors && createUpdateEncounterNote.errors.length > 0) {
        let error_messages = createUpdateEncounterNote.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (createUpdateEncounterNote.obj) {
        console.log("ENCOUNTER NOTES CREATED", createUpdateEncounterNote.obj);
        setTimeout(() => {
          setShowModalNotes(false);
          setActiveNotesID(null);
          setEditNoteTitle(null);
        }, 300);
        if (encounterDataValues) {
          onSubmit(encounterDataValues);
        }

        if (data && data?.encounter) {
          let docEventTypeId = null;
          if (data?.encounter?.appointment) {
            docEventTypeId = data.encounter.appointment.doctorEventType.id;
          }
          else {
            docEventTypeId = doctorSession;
          }
          if (docEventTypeId) {
            if (followUpmonths || followUpWeeks) {
              onSubmitReferral(docEventTypeId);
            }
          }
        }
        if (refFormElemValues.values.length > 0) {
          onSubmitInlineRef();
        }
        // props.history.push("/encounter/detail/"+createEncounter.obj.id);
      }
    },
    onError: (e) => {
      console.log("EEEE", e);
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // CREATE ENCOUNTER
  const [updateEncounter] = useMutation(UPDATE_ENCOUNTER, {
    onCompleted: ({ updateEncounter }) => {
      if (updateEncounter.errors && updateEncounter.errors.length > 0) {
        let error_messages = updateEncounter.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (updateEncounter.obj) {
        console.log("ENCOUNTER CREATED", updateEncounter.obj);
        props.history.push("/patient/record/" + data.encounter.patient.identifier);
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // DELETE_ENCOUNTER ENCOUNTER
  const [deleteEncounter] = useMutation(DELETE_ENCOUNTER, {
    onCompleted: ({ deleteEncounter }) => {
      if (deleteEncounter.errors && deleteEncounter.errors.length > 0) {
        let error_messages = deleteEncounter.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (deleteEncounter.deleted) {
        props.history.push("/patient/record/" + patientIdentifier);
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // CREATE REFERRAL
  const [createUpdateReferral] = useMutation(CREATE_UPDATE_REFERRAL, {
    onCompleted: ({ createUpdateReferral }) => {
      if (createUpdateReferral.errors && createUpdateReferral.errors.length > 0) {
        let error_messages = createUpdateReferral.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } 
    },
    refetchQueries: [
      { query: REQUEST_ENCOUNTER, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const onSubmit = (values) => {
    let val = {
      doctor: values.doctor,
      facility: values.facility,
      id: data.encounter.id,
      date: values.encounter_date ? values.encounter_date.toISOString() : "",
      patient: data.encounter.patient.id,
    };
    console.log("val", val);
    updateEncounter({ variables: val });
  };

  const onSubmitNotes = (values) => {
    let notes_obj = data.encounter.notes.edges[0];
    let notes_id = null;
    if (notes_obj) {
      notes_id = notes_obj.node.id;
    }
    let val = {
      // make possible to set empty notes
      text: values.notes_text ? values.notes_text : "+++", 
      name: "",
      encounter: data.encounter.id,
      encounterDataValues: values
    };
    if (notes_id) {
      val["id"] = notes_id;
    }

    console.log("val", val);
    createUpdateEncounterNote({ variables: val });
  };

  const setEncounterDate = (info) => {
    formikRef.current.setFieldValue('encounter_date', info);
  }

  var initialValues = {
    doctor: "",
    encounter_type: "",
    facility: "",
    has_appointment: false,
    encounter_date: new Date(),
  };
  if (data && data.encounter) {
    var selected_doc = doctors_obj && doctors_obj.doctors && doctors_obj.doctors.edges.length > 0 ? doctors_obj.doctors.edges.find(i => i.node.id === data.encounter.doctor.id) : "";
    let selected_enc_type = encounter_types_obj && encounter_types_obj.encounterTypes && encounter_types_obj.encounterTypes.edges.length > 0 ? encounter_types_obj.encounterTypes.edges.find(i => i.node.id === data.encounter.encounterType.id) : "";

    let selected_facility = locations_obj && locations_obj.listEventLocation && locations_obj.listEventLocation.edges.length > 0 ? locations_obj.listEventLocation.edges.find(i => i.node.id === data.encounter.facility.id) : "";

    initialValues['doctor'] = selected_doc ? selected_doc.node.id : "";
    initialValues['encounter_type'] = selected_enc_type ? selected_enc_type.node.id : "";
    initialValues['facility'] = selected_facility ? selected_facility.node.id : "";
    initialValues['notes'] = data.encounter.notes ? data.encounter.notes.edges : [];
    initialValues['encounter_date'] = data && data.encounter && data.encounter.date ? new Date(data.encounter.date) : new Date();
  }

  // // To set the selected doctor object since encounter already has a doctor
  useEffect(() => {
    console.log("selected_doc",selected_doc);
    if(selected_doc){
      setSelectedDoctorSeenBy(selected_doc);
    }
  }, [selected_doc]);

  let initialValuesNotes = {}
  if (editNoteTitleObj) {
    initialValuesNotes["notes_name"] = editNoteTitleObj.name;
    initialValuesNotes["notes_text"] = editNoteTitleObj.text;
  }

  const onSubmitInlineRef = () => {
    refFormElemValues.values.map(refItem => {
      let val = {
        doctor: refItem.doctor,
        doctor_session: refItem.session,
        patient: data && data.encounter ? data.encounter.patient.id : null,
        encounter: data && data.encounter ? data.encounter.id : null,
        recommendedDate: null
      };
      console.log("variables", val)
      createUpdateReferral({ variables: val });
      return null;
    });
  }

  const onSubmitReferral = (docEventTypeId) => {
    let val = {
      doctor: data && data.encounter ? data.encounter.doctor.id : null,
      doctor_session: docEventTypeId,
      patient: data && data.encounter ? data.encounter.patient.id : null,
      encounter: data && data.encounter ? data.encounter.id : null,
    };
    if (followUpmonths || followUpWeeks) {
      let recommended_date = new Date();
      if (followUpmonths) {
        recommended_date = recommended_date.setMonth(recommended_date.getMonth() + parseInt(followUpmonths));
      }
      if (followUpWeeks) {
        recommended_date.setDate(recommended_date.getDate() + (parseInt(followUpWeeks) * 7));
      }
      val["recommendedDate"] = new Date(recommended_date);
      if (requestedForms) {
        val["dynamicForms"] = requestedForms;
        val["followUp"] = true;
        createUpdateReferral({ variables: val });
      }
    }

  };

  const onSubmitSingleNote = (values) => {
    let obj_keys = Object.keys(values);
    let id = obj_keys.find(i => i.indexOf("single_note") > -1);
    if (id) {
      id = id.split("single_note")[1];
    }
    let val = {
      text: values["single_note" + id],
      name: values.notes_name,
      encounter: data.encounter.id,
      id: id,
    };
    console.log("val", val);
    createUpdateEncounterNote({ variables: val });
  }


  const onSubmitPrint = (values) => {
    console.log("values", values);
    setBlockPrintInstances(values);
    setBlockData(null);
    setTimeout(() => {
      setBlockData(-1);
    }, 100);
    // window.print();
  }

  var DYNAMIC_FORM = null;
  if (data && data.encounter && data.encounter.encounterType && data.encounter.encounterType && data.encounter.encounterType.dynamicForm) {
    DYNAMIC_FORM = data.encounter.encounterType.dynamicForm;
  }

  const deleteEncounterCall = () => {
    setPatientIdentifier(data.encounter.patient.identifier);
    if (window.confirm("Delete Encounter?")) {
      deleteEncounter({ variables: { id: data.encounter.id } });
    }
  }

  const getDoctorSessionDetails = (session) => {
    if (selectedDoctorSeenBy) {
      let selected_session = selectedDoctorSeenBy.node.eventType.edges.find((i) => i.node.id === session);
      if (selected_session) {
        return (
          <>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Location: {selected_session.node.eventLocation.title}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Type: {selected_session.node.eventType.name}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Duration: {selected_session.node.duration} Mins
            </Form.Control.Feedback>
          </>
        );
      }
    }
  };


  const handleDoctor = (event, elId) => {
    let formVal = {};
    formVal["doctor"] = event.target.value;
    formVal["id"] = elId;
    setRefFormElemValues(refFormElemValues => ({ values: [...refFormElemValues.values.filter(i => i.id !== elId), formVal] }));
    let act_doc = doctors_obj.doctors.edges.find((i) => i.node.id === event.target.value);
    let selectedDoc = selectedDoctor.filter(i => i.id !== elId);
    selectedDoc.push({ id: elId, doctor: act_doc, session: null });
    setSelectedDoctor(selectedDoc);
  };


  const handleSession = (event, elId) => {
    let formVal = {};
    let currRef = refFormElemValues.values.find(i => i.id === elId);
    formVal["session"] = event.target.value;
    formVal["doctor"] = currRef.doctor;
    formVal["description"] = currRef.description;
    formVal["showDescription"] = currRef.showDescription;
    formVal["id"] = elId;
    setRefFormElemValues(refFormElemValues => ({ values: [...refFormElemValues.values.filter(i => i.id !== elId), formVal] }));
  };

  const getButtons = () => {
    return (
      <div className="d-flex encounter-button-container">
          {lab_request_permission.includes("add") ? 
            <Button onClick={() => setShowModal(true)} className='ml-1'>
              Add Lab Request
            </Button> : null}
          <Button variant="primary" className='ml-1' onClick={() => setShowFilesModal(true)} >
            Add Files
          </Button>
          {notesValue || (data && data.encounter && data.encounter.data) ? <Button variant="primary" className='encounter-detail-btn' onClick={() => setPrintEncounterModal(true)}>
            Print{' '}
          </Button> : null}
          {encounter_permission && encounter_permission.includes("delete") ? <Button variant="danger" className='encounter-detail-btn' onClick={deleteEncounterCall} >
            {' '}
            Delete{' '}
          </Button> : null}
      </div>
    );
  };


  const onPrintAll = (values) => {
    DYNAMIC_FORM.dynamicFormBlocks.edges.map((blockNode, index) => {
      let block = blockNode.node.formBlock;
      let json_data = data.encounter.data ? data.encounter.data : null;
      let block_json = json_data ? json_data["block_" + block.staticId] : null;
      if (block_json) {
        handleChangeBlocks(block, block_json, values);
      }
      formikRefPrint.current.setFieldValue("notes", true);
      return null;
    });
  }

  const onClearPrint = () => {
    setInitialValuesPrint({});
  }

  const viewAllInstance = (block_json) => {
    if (block_json && openCheckboxId !== block_json.form_block_id) {
      setOpenCheckboxId(block_json.form_block_id);
    }
    else {
      setOpenCheckboxId(null);
    }
  }


  const notesSaveCall = () => {
    let values_new = {
      notes_name: "some title",
      notes_text: notesValue
    }
    onSubmitNotes(values_new);
  }

  const saveNotesFunc = (values) => {
    setEncounterDataValues(values);
    if (!followUpmonths && !followUpWeeks) {
      window.confirm('Are you sure you want to continue without a follow up?') &&
        notesSaveCall();
    } else {
      notesSaveCall();
    }
  }

  const handleSelectNotes = (values) => {
    formikRefPrint.current.setFieldValue("notes", !values["notes"]);
  }

  // WORKING fix "check all" in modal
  // check values initialValuesPrint
  const handleChangeBlocks = (block, block_json, values) => {
    let obj = initialValuesPrint;
    let blockId = block.staticId;

    if (block_json.requires_approval) {
      return;
    } 
    
    if (block_json) {
      block_json.form_block_instances.map(blk => {
        handleInstanceSelect(block, blk, values);
        return null;
      })
    }
    if (blockId in obj) {
      if (obj[blockId]) {
        obj[blockId] = false;
        formikRefPrint.current.setFieldValue(blockId, false);
        setInitialValuesPrint(obj);
      }
      else {
        obj[blockId] = true;
        formikRefPrint.current.setFieldValue(blockId, true);
        setInitialValuesPrint(obj);
      }
    }
    else {
      obj[blockId] = true;
      formikRefPrint.current.setFieldValue(blockId, true);
      setInitialValuesPrint(obj);
    }
  }

  const handleInstanceSelect = (block, inst, values) => {
    let arr = blockPrintInstances;
    if (values && values[inst.form_block_intance_id]) {
      setBlockPrintInstances(arr);
      // arr = arr.filter(i => i.form_block_intance_id !== inst.form_block_intance_id);
      formikRefPrint.current.setFieldValue(inst.form_block_intance_id, false);
    }
    else {
      // let blk_ist_obj = {
      //   block_static_id: block.staticId,
      //   form_block_intance_id: inst.form_block_intance_id
      // }
      formikRefPrint.current.setFieldValue(inst.form_block_intance_id, true);
      // arr.push(blk_ist_obj);
      setBlockPrintInstances(arr);
    }
  }

  const saveBigModal = () => {
    setExpandNotesModal(false);
    notesSaveCall();
  }

  const dynamicFormSelect = (e) => {
    let staticId = e.target.name;
    let reqForms = requestedForms;
    if (reqForms.includes(staticId)) {
      reqForms = reqForms.filter(i => i !== staticId);
      setRequestedForms(reqForms);
    }
    else {
      reqForms.push(staticId);
      setRequestedForms(reqForms);
    }
  }

  const addRefForms = () => {
    setRefFormElemList(refFormElemList => ({ keys: [...refFormElemList.keys, generateUID()] })); // giving unique ids to each form so we can use this to save each doctor and session id.
  }

  const removeRefForms = (i) => {
    let values = refFormElemList.keys;
    values.splice(i, 1);
    setRefFormElemList({ keys: values });
  }

  const getShowDescription = (elId) => {
    let currRef = refFormElemValues.values.find(i => i.id === elId);
    if (currRef) {
      return currRef.showDescription;
    }
    return false;
  }

  const changeRefDescription = (e, elId) => {
    let descVal = e.target.value;
    let formVal = {};
    let currRef = refFormElemValues.values.find(i => i.id === elId);
    if (currRef) {
      formVal["doctor"] = currRef.doctor;
      formVal["session"] = currRef.session;
      formVal["description"] = descVal;
      formVal["id"] = elId;
      formVal["showDescription"] = true;
    }
    else {
      formVal["id"] = elId;
      formVal["showDescription"] = true;
      formVal["description"] = descVal;
    }
    setRefFormElemValues(refFormElemValues => ({ values: [...refFormElemValues.values.filter(i => i.id !== elId), formVal] }));
  }

  const showDescriptionCall = (elId) => {
    let formVal = {};
    let currRef = refFormElemValues.values.find(i => i.id === elId);
    if (currRef) {
      formVal["doctor"] = currRef.doctor;
      formVal["session"] = currRef.session;
      formVal["description"] = currRef.description;
      formVal["showDescription"] = currRef.showDescription ? false : true;
      formVal["id"] = elId;
    }
    else {
      formVal["showDescription"] = true;
      formVal["id"] = elId;
    }
    setRefFormElemValues(refFormElemValues => ({ values: [...refFormElemValues.values.filter(i => i.id !== elId), formVal] }));
  }

  const getRefForms = () => {
    return refFormElemList.keys.map((elId, index) => {
      return (
        <Row className="my-2 pt-3" style={{ background: '#e9ecef', borderRadius: 10 }}>
          <Col xs={12} sm={12} md={12} className="mx-auto ">
          <Row>
            <Form.Label column sm={4} md={3}>
              Refer To:
            </Form.Label>
          </Row>
            
            <Form.Group as={Row} className="mb-1">
              <Form.Label column sm={4} md={3} className="required">
                Practitioner
              </Form.Label>
              <Col sm={8} md={7} >
                <Form.Control
                  autoComplete="off"
                  as="select"
                  name="doctor"
                  value={refFormElemValues.values.find(i => i.id === elId) ? refFormElemValues.values.find(i => i.id === elId).doctor : ''}
                  onChange={(event) => handleDoctor(event, elId)}
                  required>
                  <option value="">Select Practitioner</option>
                  {doctors_obj && doctors_obj.doctors &&
                    doctors_obj.doctors.edges.map((doctor) => {
                      return (
                        <option value={doctor.node.id} key={doctor.node.id}>
                          {doctor.node.firstName} {doctor.node.lastName}
                        </option>
                      );
                    })}
                </Form.Control>
              </Col>
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} className="mx-auto ">
            <Form.Group as={Row} className="mb-1" >
              <Form.Label column sm={4} md={3} className="required">
                Session Type
              </Form.Label>
              <Col sm={8} md={7}>
                <Form.Control
                  autoComplete="off"
                  required
                  as="select"
                  name="session"
                  id="doctor-session"
                  value={refFormElemValues.values.find(i => i.id === elId) ? refFormElemValues.values.find(i => i.id === elId).session : ''}
                  onChange={(event) => handleSession(event, elId)}>
                  <option value="">Select Session</option>
                  {selectedDoctor.length > 0 && selectedDoctor.find(i => i.id === elId)
                    ? selectedDoctor.find(i => i.id === elId).doctor.node.eventType.edges.map((eventtype) => {
                      return (
                        <option value={eventtype.node.id} key={eventtype.node.id}>
                          {eventtype.node.title.name}
                        </option>
                      );
                    })
                    : null}
                </Form.Control>
              </Col>
            </Form.Group>
          </Col>
          <Col md={6}>
            {getShowDescription(elId) ? <Form.Group as={Row}>
              <Form.Label column sm={4} md={5} className="text-right">
                {"Description"}
              </Form.Label>
              <Col sm={8} md={7}>
                <Form.Control
                  autoComplete="off"
                  as="textarea"
                  rows={2}
                  name="description"
                  value={refFormElemValues.values.find(i => i.id === elId) ? refFormElemValues.values.find(i => i.id === elId).description : ''}
                  onChange={(e) => changeRefDescription(e, elId)}
                />
              </Col>
            </Form.Group> : null}
          </Col>
          <Col md={6} className="d-flex align-items-start justify-content-end pb-3">
            <div className="removeRef">
              <Button onClick={() => showDescriptionCall(elId)} className=" py-0" variant="link" >{getShowDescription(elId) ? "Hide Description" : "Add Description"} </Button>
              <Button onClick={() => removeRefForms(index)} className="red p-0" variant="link" > Remove </Button>
            </div>
          </Col>
        </Row>
      )
    })
  }

  useEffect(() => {
    if (blockPrintData) {
      setTimeout(() => { window.print() }, 700)
    }
  }, [blockPrintData])

  const RenderPrescriptionTable = ({ fullData, target }) => {
    let prevName = null;
    return fullData[target].form_block_instances.map((item, index) => {
      // console.log(item)
      let display = null;
      let medication = item.form_block_fields.filter((item) => {
        return item.form_field_variant === 'prescription' | item.form_field_variant === 'medication';
      })
      let months = item.form_block_fields.filter((item) => {
        return item.form_field_name === 'months';
      })
      let weeks = item.form_block_fields.filter((item) => {
        return item.form_field_name === 'weeks';
      })
      let takeEvery = item.form_block_fields.filter((item) => {
        return item.form_field_name === 'take-every';
      })
      let timing = item.form_block_fields.filter((item) => {
        return item.form_field_name === 'timing';
      })
      let food = item.form_block_fields.filter((item) => {
        return item.form_field_name === 'food';
      })
      let unlistedMedication = item.form_block_fields.filter((item) => {
        return item.form_field_name === "unlisted-medication";
      })
      let unlistedStrength = item.form_block_fields.filter((item) => {
        return item.form_field_name === "strength";
      })


      let [medName, medStrength] = medication[0].form_field_value.split(', Strength: ');

      medStrength = unlistedMedication[0]?.form_field_value && unlistedStrength[0]?.form_field_value ? unlistedStrength[0]?.form_field_value : medStrength;
      
      if (!prevName) {
        display = medName;
      } else if (prevName === medName) {
        display = null;
      } else {
        display = unlistedMedication[0]?.form_field_value && unlistedStrength[0]?.form_field_value ? unlistedMedication[0]?.form_field_value : medName;
      }
      prevName = medName;

      let month = months[0]?.form_field_value;
      let week = weeks[0]?.form_field_value;
      let medDuration = null;
      if (month) {
        // if month > 1 add month's'
        medDuration = 'Months ' + month;
      } else {
        medDuration = 'Weeks: '+ week;
      }
      //Lithium carbonate (Oral Pill),  600 mg Cap, Take every: 24 hours, Timing: Bed Time, With Food, Months: 2

      return (
        // <tr className='prescription-tr'>
        //   <td>{display}</td>
        //   <td>{medStrength}</td>
        //   <td>{medDuration}</td>
        //   <td>Take every: {takeEvery[0].form_field_value}</td>
        //   <td>{food[0].form_field_value}</td>
        //   <td>Timing: {timing[0].form_field_value}</td>
        // </tr>
        <div className="mb-2">
          <span className="textBold">{display ? display + ', ' : <span style={{ marginLeft: 100 + 'px' }}>&nbsp;</span>}</span>
          <span>{medStrength}, </span>
          <span>Take every: {takeEvery[0].form_field_value}, </span>
          <span>{food[0].form_field_value}, </span>
          <span>Timing: {timing[0].form_field_value}, </span>
          <span>{medDuration}</span>
        </div>
      )
    })

  }


  const renderPrescriptionPrint = () => {
    let fullData = data && data.encounter ? data.encounter.data : null;
    let target = `block_${blockPrintData}`;
    return (
      <>
        <div className="page-header" style={{ textAlign: 'center' }}>
          <div className='header-doctor-eng' style={{ flex: '1 1 0px' }}>
            <h4><b>Dr. Mohammad Alsuwaidan</b></h4>
            Consultant Psychiatrist
          </div>
          <div className='logo-container' style={{ flex: '1 1 0px' }}>
            <img src={BlackWhiteLogo} alt='logo' className='print-logo' />
            <span>وصـفـة طـبـیـة  </span>
          </div>
          <div className='header-doctor-ar' style={{ flex: '1 1 0px' }}>
            <h4><b>د. محمد السويدان</b></h4>
            استشاري طب نفسي
          </div>
        </div>
        <div className="page-footer">
          Al Tijaria Tower - Floor 23 - Alsoor Street - Kuwait City,Telephone - +965 22055334
        </div>
        <div className='mt-5'>
         <div className='mx-2 encounter-print-patient-date'>
              {getDate(new Date(data.encounter.date))}
            </div>
            <div className='patient-date-container'>
              <div>Date:</div>
              <div className='text-field-line'></div>
              <div>:التاريخ </div>
            </div>

         
          <div className='mt-2'>
            <div>
            <div className='mx-2 encounter-print-patient-name'>
              {data.encounter.patient.firstName} {displayMiddleName(data.encounter.patient.middleName)}{data.encounter.patient.lastName}
            </div>
            <div className='patient-name-container'>
              <div>Name: </div>
              <div className='text-field-line'></div>
              <div>:الإسم </div>
            </div>
          </div>
          </div>
          <div className='mt-2'>
            <div className='mx-2 encounter-print-patient-id'>
            {data.encounter.patient.civilId}
            </div>
            <div className='patient-id-container'>
              <div>ID Number:</div>
              <div className='text-field-line'></div>
              <div>:رقم الهوية </div>
            </div>
          </div>
          <div className='mt-2'>
            <div className='mx-2 encounter-print-patient-phone'>
            {data.encounter.patient.phone}
            </div>
            <div className='patient-phone-container'>
              <div>Phone Number:</div>
              <div className='text-field-line'></div>
              <div>:رقم الهاتف </div>
            </div>
          </div>
        </div>
        <div className='mt-2 patient-date-container'>
          <b>Rx.</b>
        </div>

        <table style={{ width: "95%", margin: "auto" }}>
          <thead>

          </thead>
          <tbody>
            <tr>
              <td>
                <RenderPrescriptionTable fullData={fullData} target={target} />
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ marginTop: 10 + 'px', textAlign: 'center' }}>
          <Stamp />
        </div>
      </>
    )
  }



  const renderEncounterPrint = () => {
    return (<Container fluid className="print main-container">
      <Row className='page-header'>
        <Col xs={6} md={6} sm={6}>
          <img
            alt={"Logo"}
            className="img-fluid printLogo"
            src={Logo}
          />
        </Col>
        <Col xs={6} md={6} sm={6} className='today-container encounter-print-page'>
          <div>{today}</div>
        </Col>
      </Row>
      <Row className="py-2">
        <Col>
          <Row>
            <Col className='d-flex align-items-center encounter-print-page'>
              <div className="text-capitalize mr-1 ml-2" > Patient Name:</div>
              <div>{data.encounter.patient.firstName} {displayMiddleName(data.encounter.patient.middleName)}{data.encounter.patient.lastName}</div>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex align-items-center encounter-print-page'>
              <div className="text-capitalize mr-1 ml-2">Age:</div>
              <div>{getPatientsAge(data.encounter.patient.dob)}</div>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex align-items-center encounter-print-page'>
              <div className="text-capitalize mr-1 ml-2">Sex:</div>
              <div>{data.encounter.patient.displayGender}</div>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex align-items-center encounter-print-page'>
              <div className="text-capitalize mr-1 ml-2">Practitioner:</div>
              <div>{data.encounter.doctor.firstName} {data.encounter.doctor.lastName}</div>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex align-items-center encounter-print-page'>
              <div className="text-capitalize mr-1 ml-2">Date:</div>
              <div>{getDate(new Date(data.encounter.date))}</div>
            </Col>
          </Row>
        </Col>
      </Row>
      {DYNAMIC_FORM && DYNAMIC_FORM.dynamicFormBlocks ? DYNAMIC_FORM.dynamicFormBlocks.edges.map((blockNode, index) => {
        let block = blockNode.node.formBlock;
        let json_data = data.encounter.data ? data.encounter.data : null;
        var block_json = json_data ? json_data["block_" + block.staticId] : null;
        if (initialValuesPrint[block.staticId]) {
          return (
            <div key={index} className="py-2">
              <div className='encounter-block-title pl-2'> <b> {block.name} </b> </div>
              {block_json ? block_json.form_block_instances.map(json_item => {
                const filteredJsonItem = json_item.form_block_fields.filter(item => item.form_field_value);
                if (blockPrintInstances[json_item.form_block_intance_id]) {
                  return (<div>
                    {filteredJsonItem.map((block_fld, index) => {
                      return (
                        <span key={index} className='pl-2'>{block_fld.form_field_label}: {block_fld.form_field_value}{index === filteredJsonItem.length - 1 ? null : ", "}</span>
                      );
                    })}
                  </div>
                  )
                }
                return null
              }) : null}
            </div>
          )
        }
        // return (<div>
        //   {filteredFields.map((fld, index)=>{
        //     return (<span key={index}>{fld.form_field_label}: {fld.form_field_value}{index===filteredFields.length-1? null:", "}</span>);
        //   })}
        // </div>)
        else {
          return null;
        }
      }) : null}
      {blockPrintInstances["notes"] ?
        <div className="py-2">
          <div className='encounter-block-title pl-2'> <b> Notes </b> </div>
          <div className='pl-2' style={{ whiteSpace: 'pre-wrap' }}>{notesValue}</div>
        </div>
        : null}
      {/* <hr className="mt-5" /> */}
      <div className='page-footer'>
        Al Tijaria Tower - Floor 23 - Alsoor Street - Kuwait City,Telephone - +965 22055334
    </div>
    </Container>
    )
  }

  const PrintComponent = () => {
    return (
      <div className='print prescriptions-print-page'>
        <table>
          <thead>
            <tr>
              <td>
                <div className="page-header-space"></div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr className="page-table-cell">
              <td className="page-table-cell">
                {data && data.encounter && blockPrintData ?
                  blockPrintData < 0 ?
                    renderEncounterPrint() : renderPrescriptionPrint()
                  : null}
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    )
  }

  const handleDoctorChange=(e)=>{
    formikRef.current.setFieldValue('doctor', e.target.value);
   let doc_obj= doctors_obj.doctors.edges.find(i => i.node.id === e.target.value);
   setSelectedDoctorSeenBy(doc_obj);
  }

  const getVitalsValues = (value) => {
    if (vitalDataState && vitalDataState.edges && vitalDataState.edges.length > 0) {
      return vitalDataState.edges.map((vital, index) => {
        switch (value) {
          case 'height':
            return (
              <td key={index} className='text-center'>{vital.node.height}</td>
            )
          case 'weight':
            return (
              <td key={index} className='text-center'>{vital.node.weight}</td>
            ) 
          case 'waistCircumference':
            return (
              <td key={index} className='text-center'>{vital.node.waistCircumference}</td>
            )
          case 'temperature':
            return (
              <td key={index} className='text-center'>{vital.node.temperature}</td>
            )
          case 'pulse':
            return (
              <td key={index} className='text-center'>{vital.node.pulse}</td>
            )
          case 'bp':
            return (
              <td key={index} className='text-center'>{vital.node.bp}</td>
            )
          case 'bmi':
            return (
              <td key={index} className='text-center'>{vital.node.bmi}</td>
            )
          default:
            return null
        }
      })
    } else {
      return null;
    }
  }

  const renderVitals = () => {
    return (
      <Table striped bordered className='vitals-table'>
        <thead>
          <tr>
            <th style={{ width: '160px' }}></th>
            {vitalDataState && vitalDataState.edges.length > 0 ? vitalDataState.edges.map((vital, index) => {
              return (
                <th key={index} style={{ width: '160px' }}>
                  <div className='d-flex align-items-baseline justify-content-center'>
                    {getDate(vital.node.created)}
                  </div>
                </th>
              )
            }) : null}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Height</td>
            {getVitalsValues('height')}
          </tr>
          <tr>
            <td>Weight</td>
            {getVitalsValues('weight')}
          </tr>
          <tr>
            <td>Waist Circumference</td>
            {getVitalsValues('waistCircumference')}
          </tr>
          <tr>
            <td>Temperature</td>
            {getVitalsValues('temperature')}
          </tr>
          <tr>
            <td>Pulse</td>
            {getVitalsValues('pulse')}
          </tr>
          <tr>
            <td>BP</td>
            {getVitalsValues('bp')}
          </tr>
          <tr>
            <td>BMI</td>
            {getVitalsValues('bmi')}
          </tr>
        </tbody>
      </Table>
    )
  }


  return (
   <>
    <Base
      title={'ENCOUNTER DETAILS'}
      showHeader={true}
      rightChild={getButtons()}
    >
      <Row className="">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Formik enableReinitialize onSubmit={saveNotesFunc} innerRef={formikRef} initialValues={initialValues}>
            {({ handleChange, handleSubmit, values }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Container fluid className="encounter-container">
                  <Row>
                    <CustomModals showModal={showModal} modalHeader={'LAB REQUEST'} setShowModal={setShowModal} dialogClassName="modal60h" >
                      {data && data.encounter && data.encounter.patient.id ? <LabsComponent
                        patient_id={data.encounter.patient.id}
                        setShowModal={setShowModal}
                        showModal={showModal}
                        fromEncounter={true}
                      /> : null}
                    </CustomModals>
                  { data ?
                  <Form.Label column sm={12} md={12} className="text-left text-uppercase textGrey textBold">
                    <div>
                      Patient Name:
                      <Link
                        className="d-inline-block ml-1"
                        to={`/patient/record/${data.encounter.patient.identifier}`}
                      >
                        <Button variant="link" className="p-0">
                          {data.encounter.patient.firstName}{" "}{displayMiddleName(data.encounter.patient.middleName)}{ data.encounter.patient.lastName}
                        </Button>
                      </Link>
                    </div>
                  </Form.Label>
                  : null }              
                    <Col xs={12} sm={12} lg={6}>
                      {data && data.encounter && data.encounter.appointment ? 
                        <div as={Row}>
                          <h6 className="d-inline-block text-left text-uppercase textGrey textBold">Appointment:</h6> 
                            <div>
                              Date:
                            <span className='ml-1'>{getDate(data.encounter.appointment.start)}</span>
                            </div>
                            <div>
                              Identifier:
                            <Link
                                className="d-inline-block ml-1"
                                to={`/appointment/detail/${data.encounter.appointment.id}`}
                              >
                                <Button variant="link" className="p-0">
                                  {data?.encounter?.appointment?.identifier}
                                </Button>
                              </Link>
                            </div>
                          </div>
                          : null}
                        {data && data.encounter && data?.encounter?.appointment && data?.encounter?.appointment?.intakeForms?.edges.length > 0 ?
                          <div as={Row}>
                            <h6 className="d-inline-block text-left text-uppercase textGrey textBold">Intake Forms:</h6>
                            <div>
                              {data?.encounter?.appointment?.intakeForms.edges.map(form => {
                                return (
                                  <Col md={6} className="px-0">
                                    <Link to={"/patient/intakeform/" + form.node.id} >{form.node.dynamicForm.name} </Link>
                                  </Col>
                                )
                              })}

                            </div>
                          </div>
                          : null}
                          {data && data?.encounter && data?.encounter?.encounterType && data?.encounter?.encounterType.name ? 
                            <Form.Group >
                              <Form.Label className="text-left text-uppercase textGrey textBold">
                                Encounter Type:
                              </Form.Label>
                                <div className="d-inline-block ml-1 text-capitalize">
                                  {data.encounter.encounterType.name}
                                </div>
                            </Form.Group>
                          : null}
                        {/* {encounter_types_obj && encounter_types_obj.encounterTypes.edges && encounter_types_obj.encounterTypes.edges.length > 0 ? <Form.Group as={Row}>
                          <Form.Label column sm={12} md={12} className="text-left text-uppercase textGrey textBold">
                            Encounter Type
                    </Form.Label>
                          <Col sm={8} md={8}>
                            <Form.Control
                              as="select"
                              name="encounter_type"
                              value={values.encounter_type}
                              disabled={true}
                              required
                              onChange={handleChange}
                            >
                              <option value={""} > Select Encounter Type</option>
                              {encounter_types_obj.encounterTypes.edges.map((item, index) => {
                                return (
                                  <option value={item.node.id} key={index}>
                                    {item.node.name}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Col>
                        </Form.Group> : null} */}
                        {doctors_obj && doctors_obj.doctors ? <Form.Group as={Row}>
                          <Form.Label column sm={12} md={12} className="text-left text-uppercase textGrey textBold">
                            Seen By
                          </Form.Label>
                          <Col sm={8} md={8}>
                            <Form.Control
                              autoComplete="off"
                              as="select"
                              name="doctor"
                              value={values.doctor}
                              onChange={handleDoctorChange}
                              required>
                              <option value={""} > Select Doctor</option>
                              {doctors_obj.doctors.edges.map((item, index) => {
                                return (
                                  <option value={item.node.id} key={index}>
                                    {item.node.firstName} - {item.node.lastName}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Col>
                        </Form.Group> : null}
                        {locations_obj && locations_obj.listEventLocation ? <Form.Group as={Row}>
                          <Form.Label column sm={12} md={12} className="text-left text-uppercase textGrey textBold">
                            Facility
                        </Form.Label>
                          <Col sm={8} md={8}>
                            <Form.Control
                              autoComplete="off"
                              as="select"
                              name="facility"
                              value={values.facility}
                              onChange={handleChange}
                              required>
                              <option value={""} > Select Facility</option>
                              {locations_obj.listEventLocation.edges.map((item, index) => {
                                return (
                                  <option value={item.node.id} key={index}>
                                    {item.node.title}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Col>
                        </Form.Group> : null}
                        <Row className='overflow-auto'>
                          <Col>
                            <h6 className="textGrey text-uppercase">
                              <b>Vitals</b>
                            </h6>
                            {vitalDataState && vitalDataState.edges.length > 0 ? 
                              renderVitals()
                            : 'No vitals here yet'}
                          </Col>
                        </Row>
                        {mainMedicalBlockPatient?.medicationFormBlock && data?mainMedicalBlockPatient?.medicationFormBlock.map((blockNode, index) => {
                          let block = blockNode.node.formBlock;
                          let initialValues = {};
                          let forms = block.blockFormFields.edges;
                          let is_prescription = block.name.toLowerCase().indexOf("prescription") > -1;
                          let medicationBlockForm = null;
                          let has_medication_block = true;
                          if (is_prescription) {
                            medicationBlockForm = DYNAMIC_FORM.dynamicFormBlocks.edges.find(i => i.node.formBlock.name.toLowerCase().indexOf("medication") > -1);
                            if (!medicationBlockForm) {
                              has_medication_block = false;
                            }
                          }
                          forms.map(form => {
                            let default_val = "";
                            if (form.node.formField.variant === "BOOLEAN") {
                              default_val = false;
                            }
                            return initialValues[form.node.formField.fieldName] = default_val;
                          })
                          let json_data = mainMedicalBlockPatient?.jsonDataMedical;
                          let blockData = {
                            blockId: block.id,
                            title: block.name,
                            id: block.id,
                            initialValues: initialValues,
                            forms: forms,
                            staticId: block.staticId,
                            patientFormData: patient_record?.node.formData.id,
                            relatedModelId:data.encounter.id,
                            json_data: json_data,
                          };
                          return (
                            <FormBlock setBlockData={setBlockData} blockPrintData={blockPrintData} blockData={blockData} key={index} REQUEST_UPDATE={REQUEST_PATIENT_VITAL} REQUEST_PATIENT_UPDATE={REQUEST_PATIENT_VITAL} medicationBlockForm={medicationBlockForm} has_medication_block={has_medication_block} medical_record_permission={encounter_permission} hide_edit={true} is_patient_record={true} 
                            />
                          )
                        }):
                         <Row className="patient_record_forms my-4">
                          <Col>
                          <div className="singleBlockContainer ">
                            <Row>
                              <Col xs={7} sm={7} md={7} className="text-left textBold textGrey singleBlockTitle col-form-label">
                                Main Medical Block
                              </Col>
                            </Row>
                            <div className="singleBlock ">
                              <div className="encounter_block thinScrollBar">
                                No Main Medical data
                              </div>
                            </div>
                          </div>
                          </Col>
                        </Row>
                        }

                        {encounternote_permission && encounternote_permission.includes("view") ? <div className="notes_container ">
                          <Button variant="link" href="#" className="d-flex justify-content-end px-0 icon-black expand-icon" onClick={() => setExpandNotesModal(true)}>
                            <i className="fa fa-expand mt-2"></i>
                          </Button>
                          <Notes
                            is_modal={false}
                            values={values}
                            setNotesValue={setNotesValue}
                            notesValue={notesValue}
                            encounternote_permission={encounternote_permission}
                            previousNotes={data && data.encounter ? data.encounter.notes : ""}
                            onSubmitNotes={onSubmitNotes}
                            setShowModalNotes={setShowModalNotes}
                            showModalNotes={showModalNotes}
                            setEditNoteTitle={setEditNoteTitle}
                            onSubmitSingleNote={onSubmitSingleNote}
                            activeNotesID={activeNotesID}
                            setActiveNotesID={setActiveNotesID}
                            initialValuesNotes={initialValuesNotes}
                            formikRefNotes={formikRefNotes}
                            patient={data && data.encounter && data.encounter.patient ? data.encounter.patient : null}
                          />
                          <Modal
                            show={expandNotesModal}
                            onHide={() => setExpandNotesModal(false)}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title id="example-custom-modal-styling-title">
                                Notes
                          </Modal.Title>
                              <Button variant="link" href="#" className="d-flex justify-content-end px-0 icon-black add_button modal_add" onClick={() => setShowModalNotes(true)}>
                                <i className="fa fa-plus-circle mt-2"></i>
                              </Button>
                            </Modal.Header>
                            <Modal.Body>
                              <Notes
                                is_modal={true}
                                values={values}
                                encounternote_permission={encounternote_permission}
                                setNotesValue={setNotesValue}
                                notesValue={notesValue}
                                previousNotes={data && data.encounter ? data.encounter.notes : ""}
                                onSubmitNotes={onSubmitNotes}
                                setShowModalNotes={setShowModalNotes}
                                showModalNotes={showModalNotes}
                                setEditNoteTitle={setEditNoteTitle}
                                onSubmitSingleNote={onSubmitSingleNote}
                                activeNotesID={activeNotesID}
                                setActiveNotesID={setActiveNotesID}
                                initialValuesNotes={initialValuesNotes}
                                formikRefNotes={formikRefNotes}
                                patient={data && data.encounter && data.encounter.patient ? data.encounter.patient : null}
                              />
                            </Modal.Body>
                            <Modal.Footer>
                              {encounternote_permission && encounternote_permission.includes("add") ? <Button
                                variant="primary"
                                size="md"
                                className="ml-3"
                                onClick={saveBigModal}
                              >SAVE</Button> : null}
                            </Modal.Footer>
                          </Modal>
                        </div> : null}
                        <div className="pb-5 m-3">
                          <div className="py-4">
                            
                              <Row style={{ background: '#e9ecef', borderRadius: 10 }}>
                                <Col>
                                  <Form.Group>
                                    <Row>
                                    <Form.Label column sm={12} md={12} >
                                      Follow Up
                                  </Form.Label>
                                  </Row>
                                  <Row className="mb-1">
                                    <Form.Label column sm={6} md={3}>
                                      Months
                                  </Form.Label>
                                    <Col sm={6} md={3}>
                                      <Form.Control
                                        autoComplete="off"
                                        as="input"
                                        name="months"
                                        value={followUpmonths}
                                        onChange={(e) => {
                                          setFollowUpMonths(e.target.value);
                                          setFollowUpWeeks('');
                                        }}
                                      />
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Form.Label column sm={6} md={3}>
                                      Weeks
                                  </Form.Label>
                                    <Col sm={6} md={3}>
                                      <Form.Control
                                        autoComplete="off"
                                        as="input"
                                        name="weeks"
                                        value={followUpWeeks}
                                        onChange={(e) => {
                                          setFollowUpWeeks(e.target.value);
                                          setFollowUpMonths('');
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  </Form.Group>
                                </Col>
                                {data && data.encounter && !data.encounter.appointment ? <Col md={12}>
                                  <Form.Group as={Row} >
                                    <Form.Label column sm={4} md={4} >
                                      Practitioner Session
                                  </Form.Label>
                                    <Col sm={8} md={8}>
                                      <Form.Control
                                        autoComplete="off"
                                        as="select"
                                        name="session"
                                        id="doctor-session"
                                        value={doctorSession}
                                        onChange={(e) => setDoctorSession(e.target.value)}>
                                        <option value="">Select Session</option>
                                        {selectedDoctorSeenBy && selectedDoctorSeenBy.node && selectedDoctorSeenBy.node.eventType && selectedDoctorSeenBy.node.eventType.edges.length > 0
                                          ? selectedDoctorSeenBy.node.eventType.edges.map((eventtype) => {
                                            return (
                                              <option value={eventtype.node.id} key={eventtype.node.id}>
                                                {eventtype.node.title.name}
                                              </option>
                                            );
                                          })
                                          : null}
                                      </Form.Control>
                                      {selectedDoctorSeenBy && doctorSession
                                        ? getDoctorSessionDetails(doctorSession)
                                        : null}
                                    </Col>
                                  </Form.Group>
                                </Col> : null}
                                {dynamic_forms_obj && dynamic_forms_obj.dynamicForms && dynamic_forms_obj.dynamicForms.edges.length > 0 ?
                                <Col md={12}>
                                  Requested Forms
                                <Row>
                                    {dynamic_forms_obj.dynamicForms.edges.map((item, index) => {
                                      return (
                                        <Col md={6}>
                                          <Form.Group as={Row} key={index}>
                                            <Col md={1} >
                                              <Form.Check
                                                className="mt-2"
                                                type="checkbox"
                                                value={requestedForms.includes(item.node.staticId)}
                                                // checked={requestedForms.includes(item.node.staticId)}
                                                name={item.node.staticId}
                                                onChange={dynamicFormSelect}
                                              />
                                            </Col>
                                            <Form.Label column md={10}>
                                              {item.node.name}
                                            </Form.Label>
                                          </Form.Group>
                                        </Col>)
                                    })}
                                  </Row>
                                </Col> : null}
                              </Row> 
                            {referral_permissions && referral_permissions.includes("add") ?
                              <div>
                                {getRefForms()}
                                <Button variant="link" className='px-0 ' onClick={addRefForms}>
                                  <i className="fa fa-plus pr-2" aria-hidden="true"></i> Add Referral{' '}
                                </Button>
                              </div> : null}
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} lg={6} className="page_save_cancel_container">
                        <Form.Group as={Row} className="text-left text-uppercase textGrey textBold">
                          <Form.Label column sm={12} md={12} xl={12}>
                            ENCOUNTER DATE
                      </Form.Label>
                          <Col xs={8} sm={8} md={8} xl={8}>
                            <DatePicker
                              selected={values.encounter_date ? values.encounter_date : ''}
                              onChange={(info) => setEncounterDate(info)}
                              name="encounter_date"
                              className="form-control"
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              timeCaption="time"
                              autocomplete="off"
                              dateFormat="dd/MMM/yyyy - h:mm aa"
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: '5px, 10px',
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: 'viewport',
                                },
                              }}
                            />
                          </Col>
                          <Col xs={4} sm={4} md={4} xl={4}>
                            <Button
                              variant="outline-primary"
                              onClick={() => setEncounterDate(new Date())}
                              className="borderRadius">
                              TODAY
                        </Button>
                          </Col>
                        </Form.Group>
                        {DYNAMIC_FORM && DYNAMIC_FORM.dynamicFormBlocks ? DYNAMIC_FORM.dynamicFormBlocks.edges.map((blockNode, index) => {
                          let block = blockNode.node.formBlock;
                          let initialValues = {};
                          let forms = block.blockFormFields.edges;
                          let is_prescription = block.name.toLowerCase().indexOf("prescription") > -1;
                          let medicationBlockForm = null;
                          let has_medication_block = true;
                          if (is_prescription) {
                            medicationBlockForm = DYNAMIC_FORM.dynamicFormBlocks.edges.find(i => i.node.formBlock.name.toLowerCase().indexOf("medication") > -1);
                            if (!medicationBlockForm) {
                              has_medication_block = false;
                            }
                          }
                          forms.map(form => {
                            let default_val = "";
                            if (form.node.formField.variant === "BOOLEAN") {
                              default_val = false;
                            }
                            return initialValues[form.node.formField.fieldName] = default_val;
                          })
                          let json_data = data.encounter.data ? data.encounter.data : {};
                          let blockData = {
                            blockId: block.id,
                            title: block.name,
                            id: block.id,
                            initialValues: initialValues,
                            forms: forms,
                            staticId: block.staticId,
                            relatedModelId: data.encounter.id,
                            json_data: json_data,
                          };
                          return (
                            <FormBlock setBlockData={setBlockData} blockPrintData={blockPrintData} blockData={blockData} key={index} REQUEST_UPDATE={REQUEST_ENCOUNTER} is_encounter={true} is_prescription={is_prescription} medicationBlockForm={medicationBlockForm} has_medication_block={has_medication_block} 
                            medical_record_permission={encounter_permission}
                            />
                          )
                        }) : null}
                        {encounter_permission && encounter_permission.includes("add") ? <Row className="pr-3 mb-2 page_save_cancel justify-content-end">
                          {data && data.encounter && data.encounter.patient ? <Link to={"/patient/record/" + data.encounter.patient.identifier}>
                            <Button type="button">CANCEL</Button>
                          </Link> : null}
                          <Button type="submit" className="ml-3">SAVE</Button>
                          {/* </Col> */}
                        </Row> : null}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
            <CustomModals showModal={printEncounterModal} modalHeader={'PRINT ENCOUNTER FOR ' + (data && data.encounter ? data.encounter.patient.firstName + " " +displayMiddleName(data.encounter.patient.middleName)+data.encounter.patient.lastName : "patient")} setShowModal={setPrintEncounterModal}
              dialogClassName="modal60h printEncounter">

              <Formik enableReinitialize onSubmit={onSubmitPrint} initialValues={initialValuesPrint} innerRef={formikRefPrint}>
                {({ handleSubmit, handleChange, values }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Row className="my-4 printModalScroll">
                      <Col xs={12} sm={12} md={12} className="mx-auto">
                        <h6 className="text-uppercase text-grey"> Select patient information to print </h6>
                        <h6 > Select: <span className="text-primary px-2 pointer" onClick={() => onPrintAll(values)} > all </span> <span className="text-primary pointer px-2" onClick={onClearPrint} > none </span> </h6>
                        <div className="pt-3 checkbox-wrapper">
                          {DYNAMIC_FORM && DYNAMIC_FORM.dynamicFormBlocks ? DYNAMIC_FORM.dynamicFormBlocks.edges.map((blockNode, index) => {
                            let block = blockNode.node.formBlock;
                            let json_data = data.encounter.data ? data.encounter.data : null;
                            let block_json = json_data ? json_data["block_" + block.staticId] : null;
                            if (block_json?.requires_approval) {
                              return (
                                // WORKING print modal window styling
                                <>
                                  <input type='checkbox' disabled />
                                  <span className='mx-2'>Medications needs to be approved</span>
                                </>
                              ) 
                            } else {
                              return (
                                <div className="py-1" key={index}>
                                  <Form.Group as={Row} className="justify-content-start position-relative mb-0">
                                    <Col xs={4} sm={7} md={3} lg={12} xl={12} className={"pst-absolute-160px d-flex"}>
                                      <Form.Check
                                        type="checkbox"
                                        name={block.staticId}
                                        onChange={() => handleChangeBlocks(block, block_json, values)}
                                        value={values[block.staticId]}
                                        checked={values[block.staticId]}
                                        />
                                      <Form.Label className="mb-0" >{block.name}</Form.Label>
                                      {block_json && block_json.form_block_instances.length > 1 ? <span className="mx-2 form_block_all" onClick={() => viewAllInstance(block_json)} > All  <i className="fa fa-chevron-down " aria-hidden="true"></i> </span> : null}
                                      {block_json?.requires_approval ? <span>Needs to be approved</span> : null}
                                    </Col>
                                  </Form.Group>
                                  {block_json && block_json.form_block_instances.length > 1 && openCheckboxId === parseInt(block.staticId) ? <div className="px-4">
                                    {block_json.form_block_instances.map((inst, index) => {
                                      return (<Form.Group as={Row} key={index} className="justify-content-start position-relative mb-0">
                                        <Col xs={4} sm={7} md={3} lg={12} xl={12} className={"pst-absolute-160px d-flex"}>
                                          <Form.Check
                                            type="checkbox"
                                            disabled={initialValuesPrint[block.staticId] ? false : true}
                                            name={inst.form_block_intance_id}
                                            onChange={() => handleInstanceSelect(block, inst, values)}
                                            value={values[inst.form_block_intance_id]}
                                            checked={values[inst.form_block_intance_id]}
                                          />
                                          <Form.Label className="mb-0 instance_select" >
                                            {inst.form_block_fields.map(fld => {
                                              return fld.form_field_value + " "
                                            })}
                                          </Form.Label>
                                        </Col>
                                      </Form.Group>)
                                    })}
                                  </div> : null}
                                </div>
                              )
                            }
                          }) : null}
                          <div className="py-1">
                            <Form.Group as={Row} className="justify-content-start position-relative mb-0">
                              <Col xs={4} sm={7} md={3} lg={12} xl={12} className={"pst-absolute-160px d-flex"}>
                                <Form.Check
                                  type="checkbox"
                                  name={"notes"}
                                  onChange={() => handleSelectNotes(values)}
                                  value={notesValue}
                                  checked={values["notes"]}
                                />
                                <Form.Label className="mb-0" > Notes </Form.Label>
                              </Col>
                            </Form.Group>
                          </div>
                        </div>

                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col xs={12} sm={12} md={12}
                        className="d-flex flex-wrap justify-content-center justify-content-sm-between">
                        <Button variant="primary" size="md" className='mb-1' onClick={() => setPrintEncounterModal(false)}>
                          CANCEL
                    </Button>
                        <Button variant="primary" size="md" className='ml-2 mr-2 mb-1' type="submit">
                          Print
                    </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CustomModals>
            <CustomModals showModal={showFilesModal} modalHeader={'UPLOAD PATIENT FILE'} setShowModal={setShowFilesModal} dialogClassName="modal60h" 
            >
              {data && data.encounter && data.encounter.patient && data.encounter.patient.id ? <PatientFileUploadModal
                patient_id={data.encounter.patient.id}
                encounter_id={data.encounter.id}
                setShowModal={setShowFilesModal}
                showModal={showFilesModal}
              /> : null}
            </CustomModals>
          </Col>
        </Row>

      </Base>

      <PrintComponent />

    </>
  );
}
export default withRouter(EncounterDetails);
