import React from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import SearchForPatient from './SearchForPatient';
import { error_options, SNACK_DURATION, ERROR_MESSAGE} from '../../Common/helpers';
import { useSnackbar } from 'react-simple-snackbar';

export const REQUEST_PATIENT = gql`
  query getPatients($search: String) {
    patients(searchText_Icontains: $search) {
      edges {
        node {
          firstName
          middleName
          lastName
          id
          email
          identifier
          phone
          civilId
          displayGender
          dob
          doctor{
            identifier
          }
          patientId
          discount
        }
      }
    }
  }
`;

const SearchForPatientHoc = (props) => {
  const {isModal,onSelectPatientFunc} = props;
  const [openSnackbar] = useSnackbar(error_options);
  const [getPatients, { data }] = useLazyQuery(REQUEST_PATIENT, {
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });
  const {setShowPatientSearch}=props;
  return (
      <SearchForPatient getPatients={getPatients} data={data} setShowPatientSearch={setShowPatientSearch} isModal={isModal} onSelectPatientFunc={onSelectPatientFunc}/>
  );
};

export default SearchForPatientHoc;
