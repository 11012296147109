import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import Base from './base.js';
import React, { useState, useRef, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getPermissionForAction,
  isUserStaff,
  getPatientsAge,
  getForamttedTime,
  getDay,
  getDate,
  replaceUnderscoreAndDashBySpace,
  getTotalRemaining,
  displayMiddleName
} from '../Common/helpers';
import PaymentDetail from 'component/AppointmentDetails/paymentDetail';
import Preloader from '../Common/Preloder/Preloader';
import { Formik } from 'formik';
// import DatePicker from 'react-datepicker';
import CustomModals from '../component/Modal/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave , faFile } from '@fortawesome/free-solid-svg-icons';
// import { faSmile} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Logo from '../assets/images/logo.png';
import { editableAppointmentVar } from '../cache/cache.js';

function AppointmentDetails(props) {
  var today = new Date();
	today = getDate(today) + ' ' + getForamttedTime(today);

  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const identifier = props.match.params.appointmentId;
  const [shouldBeSeen, setShouldBeSeen] = useState(null);
  const [enableEdit, setEnableEdit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [displayNoShow, setDisplayNoShow] = useState(false);
  const [noRefund, setNoRefund] = useState(false);
  const [hold, setHold] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const formikRef = useRef();
  const isStaff = isUserStaff();
  const [addReferralModal, setAddReferralModal] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const { t, i18n } = useTranslation();
  const formikRefReferral = useRef();



  const REQUEST_SETTINGS = gql`
      query {
        settings
      }
  `;

  // GET leave detail
  const { data: settings_obj = null } = useQuery(
    REQUEST_SETTINGS,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );


  const REQUEST_EVENT = gql`
  query {
    event(id:"${identifier}"){
      created
      title
      description
      start
      end
      id
      identifier
      duration
      discount
      status
      price
      displayStatus
      shouldBeSeen
      requirePayment
      cancelledReason
      onlineLink
      intakeForms{
        edges{
          node{
            id
            dynamicForm{
              name
            }
          }
        }
      }
      encounters{
        edges{
          node{
            id
          }
        }
      }
      eventType{
        id
        name
      }
      location{
        id
        title
      }
      patient{
        firstName
        middleName
        lastName
        phone
        dob
        id
        gender
        identifier
        civilId
      }
      paymentSources{
        edges{
          node{
            amountDebited
            status
            reference
            id
            currency
            paymentType
            amountAllocated
            linkMessage
            paymentLink
            smsSent
            sourceType{
              name
              code
            }
          }
        }
      }
      doctor{
        id
        firstName
        lastName  
        email
        phone   
        identifier 
        identifier
        availability{
          edges{
            node{
              day
              id
              shifts{
                edges{
                  node{
                    endTime
                    id
                    startTime
                  }
                }
              }
            }
          }
        }
        recurringEvents{
          edges {
            node {
              startDate
              startTime
              endTime
              title
              description
              everyday
              days
              dateUntil
            }
          }
        }
        eventType {
          edges {
            node {
              id
              title{
                name
              }
              buffer
              duration
              price
              eventLocation {
                id
                title
              }
              eventType {
                id
              }
            }
          }
        }    
      }
    }
  }
`;

  const CANCEL_APPOINTMENT = gql`
    mutation cancelEvent($id: ID!, $hold: Boolean!, $reason: String!) {
      cancelEvent(id: $id, hold: $hold, reason: $reason) {
        obj {
          status
          id
          holdWaitingReplacement
          cancelledReason
        }
      }
    }
  `;

  const CHANGE_APPOINTMENT_STATUS = gql`
    mutation changeEventStatus($id: ID!, $status: String!) {
      changeEventStatus(
        input:{
         id: $id
         status: $status
       }
     ) {
       errors{
         field
         messages
       }
       obj{
         status
       }
     }
    }
  `;

  const UPDATE_APPOINTMENT = gql`
    mutation updatePartialEvent(
      $id: ID!
      $requirePayment: Boolean!
      $shouldBeSeen: DateTime
      $description: String!
      $title: String!
      # $onlineLink: String
      $doctor: ID!
    ) {
      updatePartialEvent(
        input: {
          id: $id
          requirePayment: $requirePayment
          shouldBeSeen: $shouldBeSeen
          description: $description
          title: $title
          # onlineLink: $onlineLink
          doctor: $doctor
        }
      ){
        errors {
          field
          messages
        }
        obj {
          start
          end
          title
          description
          id
          eventType {
            name
            id
          }
          location {
            title
            id
          }
          doctor {
            id
            firstName
            lastName
            arabicName
          }
          duration
          price
        }
      }
    }
  `;

  const REQUEST_DOCTOR = gql`
  query {
    doctors {
      edges {
        node {
          firstName
          lastName
          identifier
          id
          eventType {
            edges {
              node {
                id
                title{
                  name
                }
                duration
                buffer
                price
                eventType {
                  id
                  name
                }
                eventLocation {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

  const { data, loading } = useQuery(REQUEST_EVENT, {
    fetchPolicy: 'network-only',
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const USER_DETAILS = gql`
  query receiveDate {
    userDetails @client
    }
  `;

  const CREATE_ENCOUNTER = gql`
    mutation createEncounter(
      $patient: ID!
      $doctor: ID!
      $facility: ID!
      $encounterType: ID!
      $appointment: ID
      $date:DateTime
    ) {
      createEncounter(
        input: {
          patient:$patient
          doctor:$doctor
          facility:$facility
          encounterType:$encounterType
          date:$date
          appointment:$appointment
        }
      ) {
        obj{
          id          
         }
         errors {
           field
           messages
         }
      }
    }
  `;


  const { data: userDetialsData } = useQuery(USER_DETAILS);
  let canEditAppointments = [];
  var userDetails = userDetialsData ? userDetialsData.userDetails : null;
  if (userDetails && userDetails.indexOf('username') > -1) {
    userDetails = JSON.parse(userDetails);
    canEditAppointments = userDetails.canEditAppointments.edges.map(doc => {
      return doc.node.identifier;
    })
  }


  // const { history } = props;
  const appointment_detail = data ? data.event : null;
  // console.log('appointment_detail! ', appointment_detail)

  useEffect(() => {
    if (appointment_detail && appointment_detail.shouldBeSeen) {
      setShouldBeSeen(new Date(appointment_detail.shouldBeSeen));
    }
  }, [appointment_detail]);

  const [cancelEvent] = useMutation(CANCEL_APPOINTMENT, {
    onCompleted: ({ cancelEvent }) => {
      if (cancelEvent.obj && cancelEvent.obj.status === 'CANCELLED') {
        openSnackbarSuccess('Appointment Cancelled', [SNACK_DURATION]);
        setShowAlert(false);
      }
    },
    refetchQueries: [
      { query: REQUEST_EVENT, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const [changeEventStatus] = useMutation(CHANGE_APPOINTMENT_STATUS, {
    onCompleted: ({ changeEventStatus }) => {
      console.log("changeEventStatus", changeEventStatus);
      openSnackbarSuccess('Appointment Status Changed to ' + changeEventStatus.obj.status);
    },
    refetchQueries: [
      { query: REQUEST_EVENT, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const [updatePartialEvent] = useMutation(UPDATE_APPOINTMENT, {
    onCompleted: ({ updatePartialEvent }) => {
      openSnackbarSuccess('Appointment Detail Updated', [SNACK_DURATION]);
      setEnableEdit(false);
    },
    refetchQueries: [
      { query: REQUEST_EVENT, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });


  const [createUpdateEncounter] = useMutation(CREATE_ENCOUNTER, {
    onCompleted: ({ createEncounter }) => {
      if (createEncounter.errors && createEncounter.errors.length > 0) {
        let error_messages = createEncounter.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (createEncounter.obj) {
        console.log("ENCOUNTER CREATED", createEncounter.obj);
        props.history.push("/encounter/detail/" + createEncounter.obj.id);
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  // GET DOCTOR DATA
  const { data: doctors_obj = null } = useQuery(
    REQUEST_DOCTOR,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );


  const cancelAppointment = () => {
    setShowAlert(true);
  };

  const onSubmit = (values, { resetForm }) => {
    let variables = values;
    variables['id'] = appointment_detail.id;
    variables['title'] = appointment_detail.title;
    if (shouldBeSeen) {
      variables['shouldBeSeen'] = shouldBeSeen.toISOString();
    }
    updatePartialEvent({ variables: variables });
  };

  const initialValues = {
    requirePayment: appointment_detail ? appointment_detail.requirePayment : true,
    description: appointment_detail ? appointment_detail.description : '',
    // onlineLink: appointment_detail ? appointment_detail.onlineLink : '',
    doctor: appointment_detail && appointment_detail.doctor && appointment_detail.doctor.id ? appointment_detail.doctor.id : '',
  };

  const cancelAppointmentClicked = () => {
    if (isStaff && cancelReason === '') {
      alert('You must provide a reason to cancel the appointment.');
    } else {
      cancelEvent({
        variables: { id: appointment_detail.id, hold: hold, reason: cancelReason },
      });
    }
  };

  const changeEventStatusFunc = () => {
    if (window.confirm("Confirm No Show?")) {
      changeEventStatus({
        variables: { id: appointment_detail.id, status: "no_show" },
      });
    }
  }


  const renderRightChild = (appointment_detail) => {
    if (appointment_detail && appointment_detail.status) {
      let status = appointment_detail.status;
      if (status === 'CANCELLED') {
        return <Button variant="danger" disabled className="ml-2 appointment-detail-btn"> 
        {t('patientAppointmentDetails.appointmentCancelled')}
         </Button>
      }
      else if ((canEditAppointments.includes(appointment_detail.doctor.identifier) && getPermissionForAction("appointment", "delete")) || !isStaff) {
        return (
          <div className="d-flex flex-column">
            {status === 'CONFIRMED' || status === 'PENDING' ? <Button variant="danger" className=" appointment-detail-btn" onClick={cancelAppointment}> {t('patientAppointmentDetails.cancelAppointment')} </Button> : null}
            {status === 'CONFIRMED' && isStaff && displayNoShow ? <Button variant="danger" className=" appointment-detail-btn" onClick={changeEventStatusFunc}> No Show </Button> : null}
          </div>
        )
      }
    }
  }


  const editAppointmentFunc=()=>{
    editableAppointmentVar(appointment_detail);
    props.history.push("/create/appointment");

  }

  const rightChildren = () => {
    if (appointment_detail?.doctor && appointment_detail?.patient) {
      let val = {
        appointment: appointment_detail.id,
        doctor: appointment_detail.doctor.id,
        patient: appointment_detail?.patient?.id,
        facility: appointment_detail.location.id,
        encounterType: appointment_detail.eventType.id,
        date: appointment_detail.encounter_date,
      };



      return (<>
        {/* WORKING what condition have to be here to show button regarding permissions?
        If appointment is paid (confirmed) - no button needed */}
        {isStaff && appointment_detail && appointment_detail.status !== 'CONFIRMED' ? 
          <Link
            className="d-inline-block manual-payment-app-link"
            // to={`/manual/payment`}
            to={{
              pathname: `/manual/payment`,
              state: {
                appointment_detail: appointment_detail,
                isAppointment: true
              }
            }}
          >
            <Button variant="primary" className='appointment-detail-btn w100' >
              Manual Payment
            </Button>
          </Link>
        : null}
        {isStaff && appointment_detail && getPermissionForAction("encounter", "add") && appointment_detail.encounters.edges.length === 0 ?
          <Button variant="primary" className='appointment-detail-btn' onClick={() => createUpdateEncounter({ variables: val })}>
            Create Encounter
          </Button>
        : null}
        
        <Button variant="primary" className='appointment-detail-btn' 
        onClick={editAppointmentFunc}
        >
          Edit Appointment
        </Button>

        {isStaff && appointment_detail && getPermissionForAction("encounter", "view") && appointment_detail.encounters.edges.length > 0 ?
          <Link
          className="d-inline-block manual-payment-app-link"
          to={`/encounter/detail/${appointment_detail.encounters.edges[0].node.id}`}
          >
            <Button variant="primary" className='appointment-detail-btn w100' >
              Go to Encounter
            </Button>
          </Link>
        : null}
        {renderRightChild(appointment_detail)}
      </>)
    }
  }
  const onSubmitReferral = (values, { resetForm }) => {
    let val = {
      doctor: values.doctor,
      doctor_session: values.session,
      patient: appointment_detail && appointment_detail.patient ? appointment_detail?.patient?.id : "",
      title: values.title,
      description: values.description,
      related_appointment: appointment_detail && appointment_detail.id ? appointment_detail.id : null,
    };

    if (values.months || values.weeks) {
      let recommended_date = new Date();
      if (values.months) {
        recommended_date.setMonth(recommended_date.getMonth() + values.months);
      }
      if (values.weeks) {
        recommended_date.setDate(recommended_date.getDate() + (parseInt(values.weeks) * 7));
      }
      console.log("today", new Date());
      console.log("recommended_date", recommended_date);
      val["recommendedDate"] = new Date(recommended_date);
    }

    console.log("valvalval", val);

  };
  const handleDoctor = (event) => {
    formikRefReferral.current.setFieldValue('doctor', event.target.value);
    let act_doc = doctors_obj.doctors.edges.find((i) => i.node.id === event.target.value);
    setSelectedDoctor(act_doc);
    formikRefReferral.current.setFieldValue('session', '');
    setSelectedSession(null);
    console.log("selectedSession", selectedSession)
  };

  const handleSession = (event) => {
    formikRefReferral.current.setFieldValue('session', event.target.value);
    let session_obj = selectedDoctor.node.eventType.edges.find(
      (i) => i.node.id === event.target.value,
    );
    setSelectedSession(session_obj);
  };

  const initialValuesReferral = {
    title: "",
    doctor: "",
    doctor_session: "",
    description: "",
    patient: appointment_detail && appointment_detail.patient ? appointment_detail?.patient?.id : ""
  };

  useEffect(() => {
    if (appointment_detail && settings_obj) {
      let today = new Date();
      let appointmentDate = new Date(appointment_detail.start);
      let hoursToSet = parseInt(today.getHours()) + parseInt(settings_obj.settings.allow_refund_hour);
      today.setHours(hoursToSet); // to check if the new date is is greater than the appointment date
      if (appointmentDate <= today) {
        setNoRefund(true);
      }
      else {
        setNoRefund(false);
      }
    }

    if (appointment_detail && appointment_detail.start) {
      let today = new Date();
      let appointmentDate = new Date(appointment_detail.start);
      if (today >= appointmentDate) {
        setDisplayNoShow(true);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment_detail]);

  const getDoctorSessionDetails = (session) => {
    if (selectedDoctor) {
      let selected_session = selectedDoctor.node.eventType.edges.find((i) => i.node.id === session);
      if (selected_session) {
        return (
          <>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Location: {selected_session.node.eventLocation.title}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Type: {selected_session.node.eventType.title}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
            {t('patientAppointmentDetails.duration')}: {selected_session.node.duration} Mins
            </Form.Control.Feedback>
          </>
        );
      }
    }
  };

  const price = appointment_detail ? parseFloat(appointment_detail.price) : null;


  const paymentSources = appointment_detail && appointment_detail.paymentSources && appointment_detail.paymentSources.edges ? appointment_detail.paymentSources.edges : null;

  const handleCancelReason = (event) => {
    setCancelReason(event.target.value);
  }

  const getReasonText = () => {
    // ? it works, but maybe we can optimize this solution
    // ! create appointment and cancel it as staff and then as patient
    if (isStaff
      && appointment_detail
      && appointment_detail.status === "CANCELLED"
      && appointment_detail.cancelledReason) {
      return (
        <h6>Cancelled Reason: {appointment_detail.cancelledReason}</h6>
      )
    } else if (!isStaff
      && appointment_detail
      && appointment_detail.status === "CANCELLED"
      && appointment_detail.cancelledReason) {
      return (
        <h6>Cancelled Reason: {appointment_detail.cancelledReason}</h6>
      )
    } else if (isStaff
      && appointment_detail
      && appointment_detail.status === "CANCELLED"
      && !appointment_detail.cancelledReason) {
      return (
        <h6>Cancelled by patient  </h6>
      )
    } else if (!isStaff
      && appointment_detail
      && appointment_detail.status === "CANCELLED"
      && !appointment_detail.cancelledReason) {
      return (
        <h6>{t('patientAppointmentDetails.cancelledByPatient')} </h6>
      )
    }
  }


  let date = appointment_detail && appointment_detail.start ? new Date(appointment_detail.start) : '';

  const validate = (values) => {
    let errors = {};
    if (values.onlineLink && values.onlineLink.substr(0, 7) !== 'http://' && values.onlineLink.substr(0, 8) !== 'https://') {
      errors.onlineLink = `Wrong format`;
    }
    return errors;
  }

  const renderPaymentDetail = () => {

    return (
			<tr className="page-table-cell">
        <td className="page-table-cell">
          <div className="page">
            <Row>
            {appointment_detail && appointment_detail.patient ? (
              <Col xs={12}>
                <h6 className="capitalize">
                  Patient Name: {appointment_detail?.patient?.firstName}{' '}{displayMiddleName(appointment_detail?.patient?.middleName)}{appointment_detail?.patient?.lastName}
                </h6>
                <h6 className="capitalize">
                  Phone: {appointment_detail?.patient?.phone}
                </h6>
                <h6 className="capitalize">
                  Civil ID: {appointment_detail?.patient?.civilId}
                </h6>
              </Col>
              ) : null}
              {appointment_detail ? (
                <Col xs={12}>
                  <h6 className="capitalize">
                    Appointment Date:{' '}
                    {getDate(appointment_detail.start)}{' '}
                    {getForamttedTime(appointment_detail.start)}
                  </h6>
                  <h6 className="capitalize">
                    Practitioner:{' '}
                    {i18n.language == 'en'? 
                      appointment_detail?.doctor.firstName + " " + appointment_detail?.doctor.lastName
                    :
                    appointment_detail?.doctor.arabicName
                    }

                  </h6>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col xs={12}>
                <h6 className="mb-0 mt-3">
                  <b>Payments:</b>
                </h6>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
              {appointment_detail && appointment_detail.paymentSources ? 
                <div className='payment-container'>
                  {appointment_detail.paymentSources.edges.map((item, index) => {
                    return item.node.status === 'Paid' ? (
                      <div key={index} className='mb-2'>
                        <h6 className="capitalize">
                          Amount:{' '}
                          {item.node.amountDebited}{' '}
                          {item.node.currency}
                        </h6>
                        <h6 className="capitalize">
                          Transaction Date:{' '}
                          {getDate(appointment_detail.created)}{' '}
                          {getForamttedTime(appointment_detail.start)}
                        </h6>
                        <h6 className="capitalize">
                          Reference Number:{' '}
                          {item.node.reference}
                        </h6>
                        {item.node.sourceType ? 
                          <>
                          {item.node.sourceType.code === 'cash' ?
                            <h6 className="capitalize">
                              Payment Type: {item.node.sourceType.name}
                            </h6>
                            :
                            <h6 className="capitalize">
                              Payment Type: {item.node.sourceType.name}{' '}(TAP)
                            </h6>}
                          </>
                        : null}
                      </div>
                    ) : null
                  })}
                </div>
                : null}
              </Col>
            </Row>
          </div>
        </td>
      </tr>
		)
  }
  

  const RenderPrintPaymentPageComponent = () => {
		return (
			<div className="print prescriptions-print-page">
        <div className="lab-request-header">
          <Row className="h100">
            <Col
              xs={6}
              sm={6}
              md={6}
              className="d-flex justify-content-start align-items-center"
            >
              <img alt={'Logo'} className="img-fluid printLogo" src={Logo} />
            </Col>
            <Col
              xs={6}
              sm={6}
              md={6}
              className="d-flex justify-content-end align-items-center"
            >
              {today}
            </Col>
          </Row>
        </div>

        <div className="page-footer">
          Al Tijaria Tower - Floor 23 - Alsoor Street - Kuwait City, Telephone -
          +965 22055334
        </div>

        <table>
          <thead>
            <tr>
              <td>
                <div className="page-header-space"></div>
              </td>
            </tr>
          </thead>

          <tbody>{renderPaymentDetail()}</tbody>

          <tfoot>
            <tr>
              <td>
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
		)
	}

  return (
    <>
    <Base
      title={t('patientAppointmentDetails.appointmentDetails')}
      showHeader={true}
      back="/patients"
      isPatientPortal={!isStaff}
      rightChild={rightChildren()}
    >
      {loading ? <Preloader /> :
        <>
          {!isStaff && appointment_detail && appointment_detail.onlineLink && appointment_detail.status.toLowerCase() === 'confirmed' ? 
            <Row>
              <Col md={6} className='mb-3'>
                <a className="payment-link-icon" rel="noreferrer" target="_blank" href={appointment_detail.onlineLink}>
                  <Button variant="primary" size="md" className="">
                    <b>{t('patientAppointmentDetails.appointmentLink')}</b>
                  </Button>
                </a>
              </Col>
            </Row>
          : null}
          <Row>
            <Col md={6} className='mb-3'>
              <h6><b> {t('patientAppointmentDetails.appointmentInformation')} </b></h6>
              {appointment_detail?.onlineLink && appointment_detail?.location?.title.includes('Online') ? <div>
                  <a
                    className='d-inline-block'
                    href={appointment_detail?.onlineLink}
                  >
                    <Button variant='primary' className="py-1 px-2 mb-2">
                      {t('patientAppointments.joinSession')}
                    </Button>
                  </a>
                </div> : null}
                {appointment_detail && appointment_detail.intakeForms.edges.length > 0 ? <div className="mt-2">
                    {!isStaff? 
                      <h6 className='d-inline-block'>                        
                        <b> {t('patientAppointmentDetails.fillTheFollowing')} </b>
                      </h6>
                      : <h6> The patient has the following forms to fill: </h6>}
                    <div>
                      {appointment_detail.intakeForms.edges.map(i => {
                        if (isStaff){
                          return <div className="m-2 mb-2"><FontAwesomeIcon icon={faFile}  className="mr-1  "/> {i.node.dynamicForm.name}</div>

                        } else {
                          return <>
                            <Link target="_blank" to={"/patientPortal/intakeform/" + i.node.id}>
                              <Button variant='link' className="p-0">
                              <FontAwesomeIcon icon={faFile}  className="mr-1 "/> {i.node.dynamicForm.name}
                              </Button>
                            </Link> <br />
                            </>
                          
                        }
                      })}
                    </div>
                  </div> : null}
              {appointment_detail ? <div>
                {appointment_detail.start ? <h6> {t('patientAppointmentDetails.date')}:
                  <span> {getDay(date.getDay())} </span>
                  <span>{getDate(date)}</span>
                </h6> : null}
                {appointment_detail.start ? <h6> {t('patientAppointmentDetails.time')}:
                  <span> {getForamttedTime(date)}</span>
                </h6> : null}
                {appointment_detail.eventType.name ? <h6> {t('patientAppointmentDetails.type')}: <span className="capitalize">{appointment_detail.eventType.name} </span> </h6> : null}
                {appointment_detail.location.title ? <h6> {t('patientAppointmentDetails.location')}: {appointment_detail.location.title}</h6> : null}
                {appointment_detail?.patient?.discount ? <h6> {t('patientAppointmentDetails.duration')}: {appointment_detail.discount} %</h6> : null}
                {appointment_detail.price ? <h6> {t('patientAppointmentDetails.price')}: {appointment_detail.price} KWD</h6> : null}
                {appointment_detail.paymentSources.edges[0] ? <h6> {t('patientAppointmentDetails.paid')}: {getTotalRemaining(paymentSources, price)[0]} KWD</h6> : null}
                {appointment_detail.paymentSources.edges[0] ? <h6> {t('patientAppointmentDetails.totalRemaining')}: {getTotalRemaining(paymentSources, price)[1]} KWD</h6> : null}
                {appointment_detail.status ? <h6 className='capitalize'> {t('patientAppointmentDetails.status')}: {replaceUnderscoreAndDashBySpace(appointment_detail.status.toLowerCase())}</h6> : null}
                {/* {appointment_detail.onlineLink && isStaff ? <h6>Link: <a className="payment-link-icon" rel="noreferrer" target="_blank" href={appointment_detail.onlineLink}>{appointment_detail.onlineLink}</a></h6> : null} */}
                {getReasonText()}
              </div> : null}
            </Col>
            <Col md={6} className='patient-info-appointment'>
              <h6><b> {t('patientAppointmentDetails.patientInformation')} </b></h6>
              {appointment_detail ? <div>
                <h6 className='d-inline-block'> {t('patientAppointmentDetails.name')}: {" "}
                  <Link
                    className='d-inline-block'
                    to={`/patient/record/${appointment_detail.patient ? appointment_detail?.patient?.identifier : null}`}
                  >
                    <Button variant='link' className="p-0">
                      {appointment_detail?.patient?.firstName + " " + displayMiddleName(appointment_detail?.patient?.middleName )+  appointment_detail?.patient?.lastName}
                    </Button>
                  </Link>
                </h6>
                {appointment_detail?.patient?.dob ? <h6 className="capitalize"> {t('patientAppointmentDetails.age')}:  {getPatientsAge(appointment_detail?.patient?.dob)} </h6> : null}
                {appointment_detail?.patient?.gender ? <h6> {t('patientAppointmentDetails.gender')}: <span className="capitalize">{appointment_detail?.patient?.gender.toLowerCase()} </span> </h6> : null}
                {appointment_detail?.patient?.phone ? <h6> {t('patientAppointmentDetails.phone')}: {appointment_detail?.patient?.phone}</h6> : null}
                {appointment_detail?.patient?.discount ? <h6> Discount provided: {appointment_detail.discount} %</h6> : null}
              </div> : null}
            </Col>
            <Col md={6} className="d-flex justify-content-start">
              <div>
                <h6>
                  <b>{t('patientAppointmentDetails.practitionerInformation')}</b>
                </h6>
                {appointment_detail ? (
                  <div>
                    <h6>
                      {' '}
                      {t('patientAppointmentDetails.practitionerName')}:{' '}
                      {i18n.language == 'en'? 
                        appointment_detail?.doctor.firstName + " " + appointment_detail?.doctor.lastName
                        :
                        appointment_detail?.doctor.arabicName
                        }
                    </h6>
                    {appointment_detail.doctor.email ? (
                      <h6> {t('patientAppointmentDetails.practitionerEmail')}: {appointment_detail.doctor.email} </h6>
                    ) : null}
                    {appointment_detail.doctor.phone ? (
                      <h6> {t('patientAppointmentDetails.practitionerPhone')}: {appointment_detail.doctor.phone}</h6>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
          <Formik onSubmit={onSubmit} initialValues={initialValues} innerRef={formikRef} validate={validate}>
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Row className={'mt-3 pb-4'}>
                  <Col sm={12} md={12} lg={6} className="mt-2" xs={{ order: 'last' }} >
                    <div className='d-flex align-items-end'>
                      <h6 className='d-inline-block'>
                        <b> {t('patientAppointmentDetails.notes')} </b>
                      </h6>
                      {getPermissionForAction("appointment", "edit") ?
                        <div className="d-inline-block ml-2">
                          <Button
                            variant="link"
                            type="submit"
                            className={enableEdit ? '' : ' d-none py-0'}
                          >
                            {' '}
                            <FontAwesomeIcon icon={faSave} className="mr-1 " />
                            <b> Save {enableEdit}</b>{' '}
                          </Button>
                          <Button
                            variant="link"
                            type="button"
                            onClick={() => {
                              setEnableEdit(true);
                            }}
                            className={enableEdit ? ' d-none py-0' : ''}
                          >
                            <FontAwesomeIcon icon={faEdit} className="mr-1 " />
                            {' '}
                            <b> Edit {enableEdit}</b>{' '}
                          </Button>
                        </div>
                        : null}
                    </div>
                    <div>
                      {enableEdit ?
                        <Form.Control
                          autoComplete="off"
                          as="textarea"
                          rows={5}
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                        />
                        :
                        <div>
                          {values.description}
                        </div>}
                      {/* {enableEdit ?
                        <div>
                          <Form.Group className="p-0 mt-2 m-0">
                            <Form.Label className="p-0 mr-1">Appointment Link:</Form.Label>
                            <Form.Control
                              autoComplete="off"
                              as="input"
                              name="onlineLink"
                              value={values.onlineLink}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          {errors.onlineLink && (
                            <div className="online-link-error valid-feedback">{errors.onlineLink}</div>
                          )}
                        </div> : null
                      } */}
                      {isStaff ? 
                        <Form.Group className="d-flex p-0 m-0">
                          <Form.Label className="d-inline-block p-0">{t('patientAppointmentDetails.requirePayment')}</Form.Label>
                          <Form.Check
                            type="checkbox"
                            disabled={!enableEdit}
                            name="requirePayment"
                            onChange={handleChange}
                            value={values.requirePayment}
                            checked={values.requirePayment}
                            className="d-inline-block pl-3 require-payment-checkbox"
                          />
                        </Form.Group>
                      : null}
                      {/* <Form.Group as={Row}>
                      <Form.Label column md={6} className="text-left pr-0">
                        Should be seen
                      </Form.Label>
                      <Col md={6}>
                        <DatePicker
                          disabled={!enableEdit}
                          selected={shouldBeSeen ? shouldBeSeen : ''}
                          onChange={(info) => setShouldBeSeen(info)}
                          name="should_be_seen"
                          className="form-control"
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="dd/MMM/yyyy - h:mm aa"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: '5px, 10px',
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: 'viewport',
                            },
                          }}
                        />
                      </Col>
                    </Form.Group> */}
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          <Row>
            <Modal show={showAlert} onHide={() => setShowAlert(false)}>
              <Modal.Header closeButton>
                <Modal.Title>{t('patientAppointmentDetails.cancelAppointment')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <>
                { isStaff? "Cancel Appointment for " +  (appointment_detail ? appointment_detail?.patient?.firstName + ' ' + displayMiddleName(appointment_detail?.patient?.middleName)+appointment_detail?.patient?.lastName : ' Patient ' +"?") : t('patientAppointmentDetails.areYouSure')}
                
              {noRefund ? <h6 className="textPrimary"> {t('patientAppointmentDetails.youWill')} </h6> : null}
                </>
                {isStaff? <div className="d-flex">
                  <Form.Check
                    className=""
                    type="checkbox"
                    value={hold}
                    checked={hold}
                    onChange={() => setHold(!hold)}
                  />
                  Hold the Slot
                </div>:null}
                <div className='mt-4'>
                  {isStaff ?
                    <Form.Group className="p-0 m-0">
                      <Form.Label className="p-0">Reason</Form.Label>
                      <Form.Control
                        required
                        autoComplete="off"
                        as="textarea"
                        rows={5}
                        name="cancelled_reason"
                        value={cancelReason}
                        onChange={handleCancelReason}
                      />
                    </Form.Group> : null}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowAlert(false)}>
                 {isStaff? "Cancel" : t('patientAppointmentDetails.no')}
                </Button>
                <Button variant="primary" onClick={cancelAppointmentClicked}>
                 {isStaff? "Submit" : t('patientAppointmentDetails.yes')}
                  
                </Button>
              </Modal.Footer>
            </Modal>
            {appointment_detail ? (
              <Col sm={12} md={12}>
                <PaymentDetail event={appointment_detail} />
              </Col>
            ) : null}
          </Row>
        </>
      }
      <CustomModals showModal={addReferralModal} modalHeader={'ADD REFERRAL'} setShowModal={setAddReferralModal}
        dialogClassName="modal60h">
        <Formik onSubmit={onSubmitReferral} initialValues={initialValuesReferral} innerRef={formikRefReferral}>
          {({ handleSubmit, handleChange, values }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Row className="mb-5 mt-5">
                <Col xs={12} sm={12} md={12} className="mx-auto">
                  {/* <Form.Group as={Row} className="text-right">
                    <Form.Label column sm={4} md={3} className="text-right required">
                      Title
                  </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        type="text"
                        name="title"
                        autoComplete="discount_value"
                        onChange={handleChange}
                        value={values.title ? values.title : ''}
                      />
                    </Col>
                  </Form.Group> */}
                  <Form.Group as={Row} className="text-right ">
                    <Form.Label column sm={4} md={3} className="required">
                      Practitioner
                  </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="select"
                        name="doctor"
                        value={values.doctor}
                        onChange={handleDoctor}
                        required>
                        <option value="">Select Practitioner</option>
                        {doctors_obj.doctors &&
                          doctors_obj.doctors.edges.map((doctor) => {
                            return (
                              <option value={doctor.node.id} key={doctor.node.id}>
                                {doctor.node.firstName} {doctor.node.lastName}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right ">
                    <Form.Label column sm={4} md={3} className="required">
                      Practitioner Session
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        required
                        as="select"
                        name="session"
                        id="doctor-session"
                        value={values.session}
                        onChange={handleSession}>
                        <option value="">Select Session</option>
                        {selectedDoctor
                          ? selectedDoctor.node.eventType.edges.map((eventtype) => {
                            return (
                              <option value={eventtype.node.id} key={eventtype.node.id}>
                                {eventtype.node.title.name}
                              </option>
                            );
                          })
                          : null}
                      </Form.Control>
                      {selectedDoctor && values.session
                        ? getDoctorSessionDetails(values.session)
                        : null}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={3} className="text-right ">
                      {"Description"}
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="textarea"
                        rows={5}

                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={3} className="text-right required">
                      Date
                        </Form.Label>
                    <Col sm={4} md={4}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        type="number"
                        placeholder="Months"
                        name="months"
                        required
                        value={values.months}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col sm={4} md={4}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        type="number"
                        placeholder="Weeks"
                        name="weeks"
                        required
                        value={values.weeks}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col xs={12} sm={12} md={12}
                  className="d-flex flex-wrap justify-content-center justify-content-sm-between">
                  <Button variant="primary" size="md" className='mb-1' onClick={() => setAddReferralModal(false)}>
                    CANCEL
                    </Button>
                  <Button variant="primary" size="md" className='ml-2 mr-2 mb-1' type="submit">
                    Add
                    </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </CustomModals>
    </Base>

    <RenderPrintPaymentPageComponent />
    </>
  );
}
export default withRouter(AppointmentDetails);
