import React, { useState } from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import Base from "./base.js";
import { Link, withRouter } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  // getPermissionForAction,
  // isUserStaff,
  getDate,
  displayMiddleName
  // getForamttedTime,
  // urlActions,
} from "../Common/helpers";
import { useSnackbar } from "react-simple-snackbar";
import { useTranslation } from "react-i18next";
import Preloader from "../Common/Preloder/Preloader";

function ApprovedBlocksList(props) {
  const [approvedEncounter, setApprovedEncounter] = useState();
  const [openSnackbar] = useSnackbar(error_options);
  const { t } = useTranslation();

  const REQUEST_APPROVED_BLOCKS = gql`
    query {
      approvedBlockNotifications {
        edges {
          node {
            id
            created
            encounter {
              id
              doctor {
                firstName
                lastName
              }
            }
            patient {
              id
              firstName
              middleName
              lastName
            }
          }
        }
      }
    }
  `;

  const DELETE_APPROVED_BLOCK = gql`
    mutation deleteBlockApprovedNotification($id: ID!) {
      deleteBlockApprovedNotification(id: $id) {
        deleted
      }
    }
  `;

  const { data: approvedBlocks = null, loading } = useQuery(
    REQUEST_APPROVED_BLOCKS,
    {
      fetchPolicy: "network-only",
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    }
  );

  const [deleteBlockApprovedNotification] = useMutation(DELETE_APPROVED_BLOCK, {
    onCompleted: ({ deleteBlockApprovedNotification }) => {
      if (
        deleteBlockApprovedNotification.errors &&
        deleteBlockApprovedNotification.errors.length > 0
      ) {
        let error_messages = deleteBlockApprovedNotification.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbar(error, [SNACK_DURATION]);
        }
      } else if (deleteBlockApprovedNotification.deleted) {
        props.history.push(`/encounter/detail/${approvedEncounter}`);
      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const removeApprovedBlock = (block) => {
    deleteBlockApprovedNotification({ variables: { id: block.node.id } });
    setApprovedEncounter(block.node.encounter.id);
  };

  return (
    <Base title={t("patientrequestReport.approvedBlocks")} showHeader={true}>
      {loading ? (
        <Preloader />
      ) : (
        <Row>
          <Col sm={12} md={12}>
            {approvedBlocks?.approvedBlockNotifications?.edges.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th>Patient</th>
                    {/* <th>Form Block</th> */}
                    <th>Created</th>
                    <th>Doctor</th>
                    {/* <th>Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {approvedBlocks.approvedBlockNotifications.edges.map(
                    (block, index) => {
                      return (
                        <tr key={index} onClick={() => removeApprovedBlock(block)} className='card-approved-block'>
                          <td>
                            {block.node.patient.firstName}{" "}
                            {displayMiddleName(block.node.patient.middleName)}
                            {block.node.patient.lastName}
                          </td>
                          {/* <td>{block.node.block.name}</td> */}
                          {/* "created" comes not from encounter but from node itself */}
                          <td>{getDate(block.node.created)}</td>
                          {/* do we need time here? */}
                          {/* + ' ' + getForamttedTime(block.node.created)}</td> */}
                          <td>
                            {block.node.encounter?.doctor?.firstName}{" "}
                            {block.node.encounter?.doctor?.lastName}
                          </td>
                          {/* <td> */}
                            {/* <Link
                              to={
                                block.node.encounter
                                  ? `/encounter/detail/${block.node.encounter.id}`
                                  : `/patient/record/${block.node.patient.identifier}`
                              }
                            > */}
                              {/* {" "} */}
                              {/* View and Remove{" "} */}
                            {/* </Link> */}
                          {/* </td> */}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            ) : (
              t("patientrequestReport.noApprovedBlocks")
            )}
          </Col>
        </Row>
      )}
    </Base>
  );
}

export default withRouter(ApprovedBlocksList);
