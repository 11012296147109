import { Row, Col, Button, Form, Container } from 'react-bootstrap';
import React, { useState, useRef, useEffect } from 'react';
import Base from './base.js';
import { Formik } from 'formik';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  paymentReason,
  getDate,
  getForamttedTime,
  getTotalRemaining,
  displayMiddleName
} from '../Common/helpers.js';
import SelectPatientModal from './SearchForPatient/selectPatientModal';

function ManualPayment(props) {
  const [selectedPatient, setSelectedPatient] = useState({});
  const [openSnackbarError] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  // appointment input enable/disable
  const [appointmentEnable, setAppointmentEnable] = useState(false);
  const formikRef = useRef();
  const appointment_detail = props.location.state ? props.location.state.appointment_detail : null;
  const isAppointment = props.location.state ? props.location.state.isAppointment : null;
  const [identifier, setIdentifier] = useState(null);
  const [check, setCheck] = useState(false);

  // Payment reason
  // const paymentReason = {
  //   appointment: 'Appointment',
  //   report: 'Report',
  //   phone_call: 'Phone Call',
  //   others: 'Others',
  // };
  // Payment types
  const PaymentTypes = {
    manual: 'Offline',
    online: 'Online',
  };

  const REQUEST_EVENT = gql`
    query {
      event(id:"${identifier}"){
        price
        paymentSources{
          edges{
            node{
              amountAllocated
              status
            }
          }
        }
      }
    }
  `;

  const REQUEST_UNPAID_APPOINTMENT = gql`
    query getUnpaidAppointments($patient_identifier: String!, $unpaid: Boolean!) {
      events(patient_Identifier: $patient_identifier, unpaid: $unpaid) {
        edges {
          node {
            title
            id
            start
            end
            status
          }
        }
      }
    }
  `;

  const MANUAL_PAYMENT = gql`
    mutation createUpdatePayment(
      $patient: ID!
      $reason: String!
      $order: ID!
      $amountAllocated: Float!
      $sourceType: String!
      $notes: String!
    ) {
      createUpdatePayment(
        input: {
          patient: $patient
          reason: $reason
          appointment: $order
          amount: $amountAllocated
          paymentType: $sourceType
          notes: $notes
        }
      ) {
        errors {
          field
          messages
        }
        obj {
          id
          paid
        }
      }
    }
  `;

  const [manualPayment] = useMutation(MANUAL_PAYMENT, {
    onCompleted: ({ createUpdatePayment }) => {
      if (createUpdatePayment.errors && createUpdatePayment.errors.length > 0) {
        let error_messages = createUpdatePayment.errors[0].messages;
        for (let i in error_messages) {
          let error = error_messages[i];
          openSnackbarError(error, [SNACK_DURATION]);
        }
      } else if (createUpdatePayment.obj) {
        openSnackbarSuccess('Payment Successsful.');
        formikRef.current.handleReset();
        setSelectedPatient(null);
        setCheck(false);
        setAppointmentEnable(false);
        if (isAppointment) {
          props.history.push('/appointment/detail/' + appointment_detail.id);
        }
      }
    },
    onError: () => {
      openSnackbarError(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const [getUnpaidAppointments, { data: unpaidAppointments = [] }] = useLazyQuery(
    REQUEST_UNPAID_APPOINTMENT,
    {
      onError: () => {
        openSnackbarError(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );

  const [getSelectedAppontmentInfo, { data: selectedAppointmentInfo, loading }] = useLazyQuery(REQUEST_EVENT, {
    fetchPolicy: 'network-only',
    onError: () => {
      openSnackbarError(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  const searchPatientRef = useRef();

  const onSelectPatient = (patientData) => {
    formikRef.current.setFieldValue('patient', `${patientData.firstName} ${displayMiddleName(patientData.middleName)}${patientData.lastName}`);
    setSelectedPatient(patientData);
    getUnpaidAppointments({
      variables: { patient_identifier: patientData.identifier, unpaid: true },
    });
  };

  useEffect(() => {
    if (isAppointment) {
      setSelectedPatient(appointment_detail.patient);
    }
  }, [isAppointment])

  const initialValues = {
    patient: isAppointment ? appointment_detail.patient.firstName + ' ' +displayMiddleName(appointment_detail.patient.middleName)+  appointment_detail.patient.lastName : '',
    reason: isAppointment ? paymentReason.appointment : '',
    order: '',
    amount: '',
    appointment: isAppointment ? appointment_detail.id : '',
    payment_method: '',
    note: '',
  };

  const handleChangeReason = (event) => {
    if (event.target.value === 'appointment') {
      setAppointmentEnable(true);
      setCheck(true);
    } else {
      setAppointmentEnable(false);
      setCheck(false);
    }
    formikRef.current.setFieldValue('reason', event.target.value);
    formikRef.current.setFieldValue('appointment', '');
    formikRef.current.setFieldTouched('appointment', true, true);
  };

  const handleChangeAppointment = (e) => {
    if (e.target.value === 'Select appointment' || e.target.value === '') {
      setAppointmentEnable(false);
    }
    formikRef.current.setFieldValue('appointment', e.target.value);
    setIdentifier(e.target.value);
    setAppointmentEnable(true);
    getSelectedAppontmentInfo();
  }

  const onSubmit = (values, { resetForm }) => {
    let val = {
      patient: selectedPatient.id,
      reason: isAppointment ? 'appointment' : values.reason,
      order: isAppointment ? appointment_detail.id : values.appointment,
      amountAllocated: values.amount,
      sourceType: values.payment_method,
      notes: values.note,
    };
    manualPayment({ variables: val });
  };

  const app_name = (app) => {
    let date = getDate(isAppointment ? app.start : app.node.start)
    let startHours = getForamttedTime(new Date(isAppointment ? app.start : app.node.start));
    let endHours = getForamttedTime(new Date(isAppointment ? app.start : app.node.end));
    return `${date} ${startHours} to ${endHours}`;
  }

  const renderAppointmentDropdown = () => {
    if (unpaidAppointments && unpaidAppointments.events) {
      return unpaidAppointments.events.edges.map((app, index) => {
        if (app.node.status !== "COMPLETED" && app.node.status !== "CANCELLED") {
          return (
            <option value={app.node.id} key={index}>
              {app_name(app)}
            </option>
          );
        }
      });
    }
  };

  const price = appointment_detail ? parseFloat(appointment_detail.price) : null;
  const paymentSources = appointment_detail && appointment_detail.paymentSources && appointment_detail.paymentSources.edges ? appointment_detail.paymentSources.edges : null;

  const unpaidAppointmentPrice = selectedAppointmentInfo && selectedAppointmentInfo.event ? parseFloat(selectedAppointmentInfo.event.price) : null;
  const selectedUnpaidAppointment = check && appointmentEnable && selectedAppointmentInfo && selectedAppointmentInfo.event && selectedAppointmentInfo.event.paymentSources && selectedAppointmentInfo.event.paymentSources.edges ? selectedAppointmentInfo.event.paymentSources.edges : null; 

  return (
    <Base title={'Manual Payment'} showHeader={true}>
      <Row>
        <Col xs={12} sm={12} md={7} lg={7} xl={5}>
          <Formik onSubmit={onSubmit} initialValues={initialValues} innerRef={formikRef}>
            {({ handleSubmit, handleBlur, handleChange, values }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Container>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={4} className="text-right pr-0">
                      Patient
                    </Form.Label>
                    <Col
                      sm={8}
                      md={8}
                      className="payment-section__patient-search"
                      ref={searchPatientRef}>
                      <Form.Control
                        autoComplete="off"
                        placeholder="Select Patient"
                        type="input"
                        onBlur={handleBlur}
                        name="patient"
                        required
                        onChange={handleChange}
                        value={values.patient}
                        className="mr-sm-2 pr-0 float-left pr-5"
                        disabled={isAppointment ? true : false}
                      />
                      <span onClick={() => setShowPatientSearch(isAppointment ? false : true)} className={`search-icon ${isAppointment ? 'search-icon-disabled' : ''}`}>
                        <i className="fa fa-search"></i>
                      </span>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={4} className="text-right pr-0">
                      <span className="">Reason </span>
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="select"
                        name="reason"
                        value={values.reason}
                        onChange={handleChangeReason}
                        required
                        disabled={isAppointment}
                      >
                        {isAppointment ? 
                          <option>{paymentReason.appointment}</option>
                          : 
                          <>
                            <option value="">Select reason</option>
                            {Object.entries(paymentReason).map(([key, value], index) => {
                              return (
                                <option value={key} key={index}>
                                  {value}
                                </option>
                              );
                            })}
                          </>
                        }
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  {check ? 
                    <Form.Group as={Row}>
                      <Form.Label column sm={4} md={4} className="text-right pr-0">
                        <span className="">Appointment </span>
                      </Form.Label>
                      <Col sm={8} md={8}>
                        <Form.Control
                          autoComplete="off"
                          as="select"
                          onBlur={handleBlur}
                          name="appointment"
                          value={values.appointment}
                          onChange={handleChangeAppointment}
                          disabled={!appointmentEnable}
                        >
                          {isAppointment ? 
                            <option>
                              {app_name(appointment_detail)}
                            </option>
                          : <option>Select appointment</option>}
                          
                          {renderAppointmentDropdown()}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  : null}
                  {isAppointment ? 
                    <Form.Group as={Row}>
                      <Form.Label column sm={4} md={4} className="text-right pr-0">
                        Total Remaining
                      </Form.Label>
                      <Col
                        sm={8}
                        md={8}
                        className="payment-section__patient-search d-flex align-items-center"
                      >
                        {getTotalRemaining(paymentSources, price)[1]} KWD
                      </Col>
                    </Form.Group>
                  : null}
                  {formikRef?.current?.values?.appointment && !isAppointment ? 
                    <Form.Group as={Row}>
                      <Form.Label column sm={4} md={4} className="text-right pr-0">
                        Total Remaining
                      </Form.Label>
                      <Col
                        sm={8}
                        md={8}
                        className="payment-section__patient-search d-flex align-items-center"
                      >
                        {getTotalRemaining(selectedUnpaidAppointment, unpaidAppointmentPrice)[1]} KWD
                      </Col>
                    </Form.Group>
                  : null}
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={4} className="text-right pr-0">
                      <span className="">Amount </span>
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        type="number"
                        onBlur={handleBlur}
                        name="amount"
                        value={values.amount}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={4} className="text-right pr-0">
                      <span className="">Payment Method </span>
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="select"
                        onBlur={handleBlur}
                        name="payment_method"
                        value={values.payment_method}
                        onChange={handleChange}
                        required>
                        <option value="">Select payment method</option>
                        {Object.entries(PaymentTypes).map(([key, value], index) => {
                          return (
                            <option value={key} key={index}>
                              {value}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} >
                    <Form.Label column  sm={12} md={4} className="text-right pr-0">
                    <span className="">Note</span>
                    </Form.Label>
                    <Col sm={12} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="textarea"
                        rows={5}
                        type="textarea"
                        onBlur={handleBlur}
                        name="note"
                        value={values.note}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Form.Group>
                  <div className="d-flex justify-content-between mt-4 mb-2">
                    <Button variant="primary" size="sm" onClick={() => props.history.goBack()}>
                      Cancel
                    </Button>
                    <Button variant="primary" size="sm" type="submit">
                      Submit
                    </Button>
                  </div>
                </Container>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <SelectPatientModal
        showPatientSearch={showPatientSearch}
        setShowPatientSearch={setShowPatientSearch}
        onSelectPatientFunc={onSelectPatient}
      />
    </Base>
  );
}
export default ManualPayment;
