import { Row, Col, Button, Card } from 'react-bootstrap';
import Base from '../base.js';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSmile} from "@fortawesome/free-solid-svg-icons";
import { faRedo, faClipboard, faCalendar, faSmile, faHandHoldingMedical, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import {
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  getDate,
  getForamttedTime,
  // getPermissionForAction,
  getPatientsAge,
} from '../../Common/helpers';
import { currentPageDataVar } from '../../cache/cache.js';
import { useSnackbar } from 'react-simple-snackbar';
import { gql, useQuery } from '@apollo/client';
import Preloader from '../../Common/Preloder/Preloader';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import { useTranslation } from "react-i18next";

var startDate = new Date();
startDate = startDate.toISOString();

function Dashboard(props) {
  const [openSnackbar] = useSnackbar(error_options);
  const { t, i18n } = useTranslation();

  const SELECTED_PATIENT_DATA = gql`
    query receivePatientData {
      dataPatient @client
    }
  `;
  const { data: patientData = [] } = useQuery(SELECTED_PATIENT_DATA);
  const REQUEST_EVENTS = gql`
    query{
      events(start:"${startDate}",orderby:"start", active: true) {
        totalCount
        edges {
          node {
            title
            id
            status
            start
            end
            cancelledReason
            intakeForms{
              edges{
                node{
                  id
                }
              }
            }
            doctor {
              id
              firstName
              lastName
              arabicName
            }
            eventType{
              name
              id
            }
            location{
              id
              title
            }
            patient {
              id
              firstName
              middleName
              lastName
              identifier
              dob
            }
          }
        }
      }
    }
  `;



  const { data: eventsList, loading: loadingEvents } = useQuery(REQUEST_EVENTS, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });


  const REQUEST_SURVEY_FORMS = gql`
  query {
    surveys{
      edges {
        node {
            id
           patient{
            firstName
            middleName
            lastName
          }
          data
          dynamicForm{
            name
            id
            formType
            formBlocks{
              edges{
                node {
                  name
                  id
                  staticId
                  formFields{
                    edges{
                      node{
                        id
                        name
                        fieldName
                        variant
                        staticId
                        blockFormFields{
                          edges{
                            node{
                              formField{
                                name                  
                              }
                              formBlock{
                                id
                              }
                              required
                            }
                          }
                        }
                        defaultBooleanVariant
                        options{
                          edges{
                            node{
                              id     
                              staticId                   
                              value
                              default
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

  const { data: survey_forms_obj, loading: loadingSurvey } = useQuery(REQUEST_SURVEY_FORMS, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });




  const REQUEST_INTAKE_FORMS = gql`
  query {
  intakeForms{
    edges {
      node {
          id
         patient{
          firstName
          middleName
          lastName
        }
       dynamicForm{
        name
        id
        formType
        formBlocks{
          edges{
            node {
              name
              id
              staticId
              formFields{
                edges{
                  node{
                    id
                    name
                    fieldName
                    variant
                    staticId
                    blockFormFields{
                      edges{
                        node{
                          formField{
                            name                  
                          }
                          formBlock{
                            id
                          }
                          required
                        }
                      }
                    }
                    defaultBooleanVariant
                    options{
                      edges{
                        node{
                          id     
                          staticId                   
                          value
                          default
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
        data
      }
    }
  }
}
`;


  const { data: intake_forms_obj, loading: loadingIntake } = useQuery(REQUEST_INTAKE_FORMS, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });


  const saveFormInfo = (form) => {
    currentPageDataVar(form);
  }
  let event = eventsList && eventsList.events && eventsList.events.edges.length > 0 ? eventsList.events.edges[0].node : null;
  let date = null;
  if (event) {
    date = getDate(new Date(event.start)) + " " + getForamttedTime(new Date(event.start));
  }

  const renderGridContent = () => {
    let final_dashboard_arr = [];
    // Intake forms for this patient
    let intake_forms = intake_forms_obj && intake_forms_obj.intakeForms && intake_forms_obj.intakeForms.edges.length > 0 ? intake_forms_obj.intakeForms.edges : [];
    let intake_forms_elem = intake_forms.map((form, index) => {
      return ({
        link: "/patientPortal/intakeform/" + form.node.id,
        onClickFunc: saveFormInfo,
        title: form.node.dynamicForm.name,
        passData: form,
        subTitle: t('patientDashboard.pleaseFill'),
        buttonText: t('patientDashboard.fillForm'),
        icon: faClipboard,
        backgroundClass: "green"
      })
    });

    // Survey forms for this patient
    let survey_forms = survey_forms_obj && survey_forms_obj.surveys && survey_forms_obj.surveys.edges.length > 0 ? survey_forms_obj.surveys.edges : [];

    let form = survey_forms[0];
    let survey_forms_elem;
    if (survey_forms.length === 1) {
      survey_forms_elem = {
        link: "/patientPortal/surveyform/" + form.node.id,
        onClickFunc: saveFormInfo,
        title: form.node.dynamicForm.name,
        passData: form,
        subTitle: t('patientDashboard.letUsKnowHowyYourLastAppointmentWas'),
        buttonText: t('patientDashboard.fillForm'),
        icon: faSmile,
        backgroundClass: "green"
      }
    } else if (survey_forms.length > 1) {
      survey_forms_elem = {
        link: '/patientPortal/surveyformlist',
        title: form.node.dynamicForm.name,
        passData: form,
        subTitle: "You have multiple survey forms. Click here to view.",
        buttonText: t('patientDashboard.book'),
        icon: faSmile,
        backgroundClass: "green"
      }
    }


    let ref_link = "/patientPortal/referrals/" + (patientData && patientData.dataPatient ? patientData.dataPatient.id : "");
    let follow_up_link = "/patientPortal/followups/" + (patientData && patientData.dataPatient ? patientData.dataPatient.id : "");
    let has_referrals = patientData && patientData.dataPatient && patientData.dataPatient.referrals && patientData.dataPatient.referrals.edges.length > 0 ? true : false;

    const refs = patientData?.dataPatient?.referrals?.edges?.filter((ref, index) => {
      return ref.node.recommendedDate;
    })

    const followUps = patientData?.dataPatient?.referrals?.edges?.filter((ref, index) => {
      return !ref.node.recommendedDate;
    })

    let referrals_elem = {
      link: ref_link,
      title: t('patientDashboard.bookReferral'),
      subTitle: t('patientDashboard.youReceived'),
      buttonText: t('patientDashboard.book'),
      icon: faRedo,
      backgroundClass: "orange1"
    };

    let follow_up_elem = {
      link: follow_up_link,
      title: t('patientDashboard.bookFollowUp'),
      subTitle: t('patientDashboard.youReceivedFollow'),
      buttonText: t('patientDashboard.book'),
      icon: faRedo,
      backgroundClass: "orange1"
    };
    

    // let upcoming_app_elem = {
    //   link: "/patientPortal/appointments",
    //   title: "Upcoming appointments",
    //   date: event ? date : "",
    //   subTitle: event ? event.doctor.firstName + " " + event.doctor.lastName : "No Upcoming Appointments",
    //   buttonText: "View",
    //   icon: faCalendar,
    //   backgroundClass: "orange2"
    // }
    // if (event) {
    //   final_dashboard_arr.push(upcoming_app_elem);
    // }
    if (has_referrals) {
      if (refs) {
        final_dashboard_arr.push(referrals_elem);
      } 
      if (followUps) {
        final_dashboard_arr.push(follow_up_elem);
      }
    } 

    let req_app_elem = {
      link: "/patientPortal/create/appointment",
      title: t('patientDashboard.requestAnAppointment'),
      subTitle: t('patientDashboard.checkAvailableAppointments'),
      buttonText: t('patientDashboard.book'),
      icon: faHandHoldingMedical,
      backgroundClass: "blue"
    }
    final_dashboard_arr.push(req_app_elem)

    if (survey_forms_elem) {
      final_dashboard_arr.push(survey_forms_elem)
    }

    final_dashboard_arr.push(...intake_forms_elem);
    // final_dashboard_arr.push(...referralDynamicFormsElem);

    return final_dashboard_arr.map((item, index) => (
      <Col xs={12} sm={12} md={6} lg={4} key={index}>
        <Link to={item.link} onClick={() => item.onClickFunc && item.passData ? item.onClickFunc(item.passData) : ""} >
          <Card className={item.backgroundClass + ' patient-card'}>
            <Card.Body className="text-center patient-card-body">
              <div>
                <h6 className="text-center font-bold text-uppercase patient-card-title">{item.title}</h6>
                <FontAwesomeIcon icon={item.icon} className="icon_large mx-3 my-4" />
                <h4 className="mb-0"> <b> {item.date} </b> </h4>
                <p className="mb-0 text-uppercase font-bold" >
                  {item.subTitle}
                </p>
              </div>
              <div className="text-center">
                {/* <i className={"fa "+ item.icon + " mx-3 icon_large"}></i> */}
                <div className="mt-2 text_container">
                  <Button block variant="link" className="font-bold d-flex justify-content-end align-items-center">
                    {item.buttonText} <i className="fa fa-long-arrow-right ml-3"></i>
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Link>
      </Col>
    ));
  };

  let loadingPage = loadingEvents || loadingIntake || loadingSurvey;

  return (
    <Base title={t('patientDashboard.dashboard')} isDashboard={true} showHeader={true} isPatientPortal={true} containerClass="mt-0"
      rightContainerClass={loadingPage ? "page_loader_base" : ""}>
      {loadingPage ? <Preloader /> : <Row className="px-4 justify-content-center patient_dashboard">
        <Col xs={12} md={{ span: 12, order: 'last' }} lg={12} sm={{ span: 12, order: 'last' }} className='patient-cards-container'>
          <Row>
            {eventsList?.events?.edges.length > 1 ? 
            <Col xs={12} sm={12} md={6} lg={4} >
              <Link to={"/patientPortal/appointments/" + eventsList.events.edges[0].node.patient.identifier}>
                <Card className={"blue patient-card "}>
                  <Card.Body className="text-center patient-card-body">
                    <div className="">
                      <h6 className="text-center font-bold text-uppercase patient-card-title"> {t('patientDashboard.upcomingAppointments')} </h6>
                      <FontAwesomeIcon icon={faCalendar} className="icon_large mx-3 my-4" />
                      <h6 className="mb-0"> <b> {date} </b> </h6>
                      <p className="mb-0 text-uppercase font-bold upcoming-appointments-items">
                      {t('patientUpcomingAppointments.practitioner')}:{' '}
                      <EllipsisWithTooltip placement="top" style={{ display: 'inline' }}>
                      {i18n.language == 'en'? 
                              event.doctor.firstName + " " + event.doctor.lastName
                              :
                              event.doctor.arabicName
                            
                              }<br/>
                      </EllipsisWithTooltip>
                        {t('patientUpcomingAppointments.sessionType')}:{' '}
                        <EllipsisWithTooltip placement="top" style={{ display: 'inline' }}>
                          {event.eventType.name} <br/>
                        </EllipsisWithTooltip>
                        {t('patientUpcomingAppointments.location')}:  {event.location.title} 
                      </p>
                    </div>
                    {/* <i className={"fa "+ item.icon + " mx-3 icon_large"}></i> */}
                    <div>
                      <div className={"mt-2 text_container d-flex " + (event && event.intakeForms.edges.length > 0 ? "justify-content-between" : "justify-content-end")}>
                        {event && event.intakeForms.edges.length > 0 ?
                          <Button variant="link" className="font-bold pl-0 d-flex justify-content-between align-items-center">
                            {/* <i className="fa fa-file-text-o mr-1"></i> */}
                        </Button> : null}
                        <Button variant="link" className="font-bold d-flex justify-content-between align-items-center">
                          {t('patientRecordFiles.view')} <i className="fa fa-long-arrow-right ml-3"></i>
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Link>
              </Col>
               : 
                <>
                  {eventsList?.events?.edges.length === 1 ? <Col xs={12} sm={12} md={6} lg={4} >
                    <Link to={"/patientPortal/appointment/detail/" + event.id}>
                      <Card className={"blue patient-card "}>
                        <Card.Body className="text-center patient-card-body">
                          <div className="">
                            <h6 className="text-center font-bold text-uppercase patient-card-title"> {t('patientDashboard.upcomingAppointments')} </h6>
                            <FontAwesomeIcon icon={faCalendar} className="icon_large mx-3 my-4" />
                            <h6 className="mb-0"> <b> {date} </b> </h6>
                            <p className="mb-0 text-uppercase font-bold upcoming-appointments-items">
                            {t('patientUpcomingAppointments.practitioner')}:{' '}
                            <EllipsisWithTooltip placement="top" style={{ display: 'inline' }}>
                            {i18n.language == 'en'? 
                              event.doctor.firstName + " " + event.doctor.lastName
                              :
                              event.doctor.arabicName
                            
                              }
                              <br/>
                            </EllipsisWithTooltip>
                              {t('patientUpcomingAppointments.sessionType')}:{' '}
                              <EllipsisWithTooltip placement="top" style={{ display: 'inline' }}>
                                {event.eventType.name} <br/>
                              </EllipsisWithTooltip>
                              {t('patientUpcomingAppointments.location')}:  {event.location.title} 
                            </p>
                          </div>
                          {/* <i className={"fa "+ item.icon + " mx-3 icon_large"}></i> */}
                          <div>
                            <div className={"mt-2 text_container d-flex " + (event && event.intakeForms.edges.length > 0 ? "justify-content-between" : "justify-content-end")}>
                              {event && event.intakeForms.edges.length > 0 ?
                                <Button variant="link" className="font-bold pl-0 d-flex justify-content-between align-items-center">
                                  {/* <i className="fa fa-file-text-o mr-1"></i> */}
                              </Button> : null}
                              <Button variant="link" className="font-bold d-flex justify-content-between align-items-center">
                                {t('patientDashboard.book')} <i className="fa fa-long-arrow-right ml-3"></i>
                              </Button>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col> : null}
                </>
              }
            {renderGridContent()}
          </Row>
        </Col>
        {/* {patientData && patientData.dataPatient ?
          <Col className='px-0' xs={{ span: 12, order: 'first' }} sm={{ span: 12, order: 'first' }} md={{ span: 12, order: 'first' }} lg={{ span: 12, order: 'first' }}>
            <Link to={'/registration/complete/form?profileEdit=true'}>
              <Card className={"honey patient-personal-data-card"}>
                <Card.Body className="px-5">
                  <Col lg={12} md={12} sm={12}>
                    <div className="d-flex flex-column align-items-center">
                      <h6 className="text-center text-uppercase font-bold mt-2 mb-3"> {t('patientDashboard.information')} </h6>
                      <FontAwesomeIcon icon={faUserCircle} className="mx-3 icon_large" />
                      <h6 className="text-center text-uppercase font-bold mt-2"> {patientData.dataPatient.firstName} {patientData.dataPatient.middleName ? patientData.dataPatient.middleName : ""} {patientData.dataPatient.lastName}</h6>
                    </div>
                    <Row>
                      {patientData.dataPatient.dob ?
                        <Col xl={6} lg={6} md={6} sm={6} xs={12} className='px-0'>
                          <Row>
                            <Col className="text-uppercase font-bold" xl={6} lg={12} md={12} sm={12} xs={12}>

                              <span> {t('patientDashboard.age')} </span>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className='patient-card-value'>
                              <span> {getPatientsAge(patientData.dataPatient.dob)}</span>
                            </Col>
                          </Row>
                        </Col>
                        : null}
                      {patientData.dataPatient.email ?
                        <Col xl={6} lg={6} md={6} sm={6} xs={12} className='px-0'>
                          <Row>
                            <Col className="text-uppercase font-bold" xl={6} lg={12} md={12} sm={12} xs={12}>
                              <span> {t('patientDashboard.email')} </span>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className='patient-card-value list-item-ellipsis pr-5'>
                              <EllipsisWithTooltip placement="top">
                                <span> {patientData.dataPatient.email}</span>
                              </EllipsisWithTooltip>
                            </Col>
                          </Row>
                        </Col>
                        : null}
                      {patientData.dataPatient.civilId ?
                        <Col xl={6} lg={6} md={6} sm={6} xs={12} className='px-0'>
                          <Row>
                            <Col className="text-uppercase font-bold" xl={6} lg={12} md={12} sm={12} xs={12}>
                              <span> {t('patientDashboard.civilid')} </span>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className='patient-card-value'>
                              <span> {patientData.dataPatient.civilId}</span>
                            </Col>
                          </Row>
                        </Col>
                        : null}
                      {patientData.dataPatient.displayGender ?
                        <Col xl={6} lg={6} md={6} sm={6} xs={12} className='px-0'>
                          <Row>
                            <Col className="text-uppercase font-bold" xl={6} lg={12} md={12} sm={12} xs={12}>
                              <span> {t('patientDashboard.gender')} </span>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className='patient-card-value'>
                              <span> {patientData.dataPatient.displayGender}</span>
                            </Col>
                          </Row>
                        </Col>
                        : null}
                      {patientData.dataPatient.phone ?
                        <Col xl={6} lg={6} md={6} sm={6} xs={12} className='px-0'>
                          <Row>
                            <Col className="text-uppercase font-bold" xl={6} lg={12} md={12} sm={12} xs={12}>
                              <span> {t('patientDashboard.phone')} </span>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className='patient-card-value'>
                              <span> {patientData.dataPatient.phone}</span>
                            </Col>
                          </Row>
                        </Col>
                        : null}
                      {patientData.dataPatient.country ?
                        <Col xl={6} lg={6} md={6} sm={6} xs={12} className='px-0'>
                          <Row>
                            <Col className="text-uppercase font-bold" xl={6} lg={12} md={12} sm={12} xs={12}>
                              <span> {t('patientDashboard.country')} </span>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className='patient-card-value'>
                              <span> {patientData.dataPatient.country.name}</span>
                            </Col>
                          </Row>
                        </Col>
                        : null}
                      {patientData.dataPatient.governorate ?
                        <Col xl={6} lg={6} md={6} sm={6} xs={12} className='px-0'>
                          <Row>
                            <Col className="text-uppercase font-bold" xl={6} lg={12} md={12} sm={12} xs={12}>
                              <span> {t('patientDashboard.governorate')} </span>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className='patient-card-value'>
                              <span> {patientData.dataPatient.governorate.name}</span>
                            </Col>
                          </Row>
                        </Col>
                        : null}
                      {patientData.dataPatient.area ?
                        <Col xl={6} lg={6} md={6} sm={6} xs={12} className='px-0'>
                          <Row>
                            <Col className="text-uppercase font-bold" xl={6} lg={12} md={12} sm={12} xs={12}>
                              <span> {t('patientDashboard.area')} </span>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className='patient-card-value'>
                              <span> {patientData.dataPatient.area.name}</span>
                            </Col>
                          </Row>
                        </Col>
                        : null}
                      {patientData.dataPatient.address ?
                        <Col xl={6} lg={6} md={6} sm={6} xs={12} className='px-0'>
                          <Row>
                            <Col className="text-uppercase font-bold" xl={6} lg={12} md={12} sm={12} xs={12}>
                              <span> {t('patientDashboard.address')} </span>
                            </Col>
                            <EllipsisWithTooltip placement="top">
                              <Col lg={12} md={12} sm={12} xs={12} className='patient-card-value list-item-ellipsis pr-5'>
                                <span> {patientData.dataPatient.address}</span>
                              </Col>
                            </EllipsisWithTooltip>
                          </Row>
                        </Col>
                        : null}
                      <Button block variant="link" className="font-bold d-flex justify-content-end align-items-center">
                        {t('patientDashboard.update')} <i className="fa fa-long-arrow-right ml-3"></i>
                      </Button>
                    </Row>
                  </Col>
                </Card.Body>
              </Card>
            </Link>
          </Col> : null} */}
      </Row>}
    </Base >
  );
}
export default Dashboard;
