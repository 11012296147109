import Base from './base.js';
import React, { useState, useEffect, useRef } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { Link, withRouter } from 'react-router-dom';
import { Pagination, Row, Col, Container, Button, Form, DropdownButton, Dropdown, Table, Badge } from 'react-bootstrap';
import Preloader from '../Common/Preloder/Preloader';
import { useSnackbar } from 'react-simple-snackbar';
import { error_options, SNACK_DURATION, ERROR_MESSAGE, PER_PAGE_COUNT, urlActions, getPermissionForAction, getValidDateStringForGraphQL, removeEmpty,displayMiddleName } from '../Common/helpers';
import { dataPatientInVar } from '../cache/cache.js';
import { Formik } from 'formik';
import CustomModals from '../component/Modal/modal';



function Patients(props) {

  const [openSnackbar] = useSnackbar(error_options);
  const [searchTerm, setSearchTerm] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [searchTermFetch, setSearchTermFetch] = useState('');
  const [searchByMed, setSearchByMed] = useState('');
  const [search, setSearch] = useState('');
  const { history } = props;
  const has_add_permission = getPermissionForAction('patient', 'add');
  const formikRef = useRef();
  const [time, setTime] = React.useState(null);
  const [timeSearch, setTimeSearch] = React.useState(null);


  useEffect(() => {
    let page = urlActions(window.location.href, 'get', 'page');
    if (page) {
      setActivePage(parseInt(page));
    }

    let search = urlActions(window.location.href, 'get', 'search');
    if (search) {
      setSearchTermFetch(search);
    }

    let searchByMed = urlActions(window.location.href, 'get', 'searchByMed');
    if (searchByMed) {
      setSearchByMed(searchByMed);
    }
    
  }, []);

  const GET_COUNTRIES = gql`
  query{
    countries
  } 
`;


  const GET_GOVERNORATES = gql`
  query{
    governorates{
      edges{
        node{
          id
          name
        }
      }
    }
  } 
  `;

  const GET_AREAS = gql`
  query areas($governorate: ID!) {
    areas(governorate_Id:$governorate){
      totalCount
      edges{
        node{
          id
          name
        }
      }
    }
  } 
`;


  const REQUEST_PATIENT = gql`
  query($governorate: String, $area:String,$country:String, $dobLt:Date, $dobGt:Date,$gender:String, $phone:String,$formFieldName:ID, $formFieldValue:String,$search:String){
    patients(searchText_Icontains:"${searchTermFetch}",search:$search, first:${PER_PAGE_COUNT}, offset:${PER_PAGE_COUNT * (activePage - 1)}, governorate_Name_Icontains:$governorate, area_Name_Icontains:$area, country:$country,dob_Lt:$dobLt, dob_Gt: $dobGt,gender:$gender, phone:$phone ,
    searchDetail_FormField:$formFieldName, searchDetail_ValueText_Icontains:$formFieldValue) {
      totalCount
      edges {
        node {
          firstName
          middleName
          lastName
          civilId
          id
          email
          identifier
          phone
          governorate{
            name
          }
          doctor{
            firstName
            lastName
            id
            identifier
          }
        }
      }
    }
  }
`;


  const [getRequestPatient, { data, loading }] = useLazyQuery(REQUEST_PATIENT, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      if (showModal) {
        setShowModal(false);

      }
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });


  const REQUEST_FORM_FIELDS = gql`
  query {
    formFields(filterable:true){
      edges {
        node {
            id
            name
          }
        }
      }
    }
`;

  const { data: formFieldsObj } = useQuery(REQUEST_FORM_FIELDS, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });



  const TIME_TO_FETCH = 500;

  var patients = data && data.patients ? data.patients.edges : [];

  const { data: countriesData = null } = useQuery(GET_COUNTRIES);
  const { data: governoratesData = null } = useQuery(GET_GOVERNORATES);

  const [getAreasForGov, { data: areasData = [] }] = useLazyQuery(GET_AREAS, {
    fetchPolicy: "network-only",

    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const onChangeGovernorate = (e) => {
    formikRef.current.setFieldValue('governorate', e.target.value);
    let gov = governoratesData.governorates.edges.find(i => i.node.id === e.target.value);
    if (gov) {
      let gov_id = gov.node.id;
      let val = {
        governorate: gov_id
      }
      getAreasForGov({ variables: val });
    }
  }

  const handleChange = (event) => {
    let searchValue = event.target.value;
    setSearchTerm(searchValue);
  };

  const handleChangeSearch = (event) => {
    let searchValue = event.target.value;
    setSearch(searchValue);
  };

  useEffect(() => {
      clearTimeout(time);
      setTime(
        setTimeout(() => {
          setSearchTermFetch(searchTerm);
          setActivePage(1)
          if (searchTerm) {
            window.history.pushState(null, 'patients', '/patients?page=1&search=' + searchTerm);
            getRequestPatient();
          } else{
            console.log('page num:', activePage)
            window.history.pushState(null, 'patients', '/patients?page=' + activePage);
            getRequestPatient({ variables: appliedFilters });
          }
        }, TIME_TO_FETCH),
      );
    
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
      clearTimeout(timeSearch);
      setTimeSearch(
        setTimeout(() => {
          setSearchByMed(search);
          setActivePage(1)
          if (search) {
            window.history.pushState(null, 'patients', '/patients?page=1&searchByMed=' + search);
            getRequestPatient();
          } else{
            console.log('page num:', activePage)
            window.history.pushState(null, 'patients', '/patients?page=' + activePage);
            getRequestPatient({ variables: appliedFilters });
          }
        }, TIME_TO_FETCH),
      );
    
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);




  const getButtons = () => {
    return (
      <div className="d-flex">
        <Link to="/create/patient">
          {' '}
          <Button variant="primary" className='back'>
            {' '}
            <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i> Create new Patient{' '}
          </Button>
        </Link>
      </div>
    );
  };

  const handlePageChange = (number) => {
    setActivePage(number);
    if (searchTerm) {
      window.history.pushState(null, 'patients', '/patients?page=' + number + '&search=' + searchTerm);  
    } else {

      window.history.pushState(null, 'patients', '/patients?page=' + number);
    }
  };

  const renderPages = () => {
    let total_pages = data && data.patients && data.patients.totalCount ? Math.ceil(data.patients.totalCount / PER_PAGE_COUNT) : 1;
    let items = [];
    for (let number = 1; number <= total_pages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === activePage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>,
      );
    }
    return items;
  }
  const createAppointment = (patient) => {
    dataPatientInVar(patient.node);
  }

  const resetSearchMedical = () => {
    if (formikRef && formikRef.current) {
      formikRef.current.handleReset();
    }
    setAppliedFilters({});
    getRequestPatient();
  }

  const renderList = (patients) => {
    return patients.map((patient, index) => {
      return (
        <tr className="  patient_table_row" key={index}>
          <td > <Link to={'/patient/record/' + patient.node.identifier}>{patient.node.firstName +" "+ displayMiddleName(patient.node.middleName) + ' ' + patient.node.lastName} </Link></td>
          <td >{patient.node.email}{patient.node.email ? <br /> : null} {patient.node.phone}</td>
          <td >{patient.node.civilId ? patient.node.civilId : "-"}</td>
          <td >
            <DropdownButton id="dropdown-basic-button" variant="link" title="Manage">
              <Dropdown.Item >
                <Link to={'/patient/record/' + patient.node.identifier}>  View </Link>
              </Dropdown.Item>
              <Dropdown.Item >
                <Link to={'/edit/patient/' + patient.node.identifier}>  Edit </Link>
              </Dropdown.Item>
              <Dropdown.Item >
                <Link to={'/create/appointment'} onClick={() => createAppointment(patient)}>
                  Create Appointment
                </Link>
              </Dropdown.Item>
            </DropdownButton>
          </td>
        </tr>
      );
    })
  }

  var initialValues = {
    fieldName: "",
    fieldValue: "",
    governorate: "",
    area: "",
    age: "",
    gender: "",
    country: ""
  }
  const onSubmit = (values) => {
    let val = {};

    if (values.fieldName) {
      val["formFieldName"] = values.fieldName;
    }

    if (values.fieldValue) {
      val["formFieldValue"] = values.fieldValue;
    }

    if (values.age) {
      let dobGt = new Date();
      dobGt.setFullYear(dobGt.getFullYear() - parseInt(values.age));
      dobGt.setMonth(0);
      dobGt.setDate(1);

      let dobLt = new Date();
      let addYear = dobGt.getFullYear() + 1;
      dobLt.setFullYear(addYear);
      dobLt.setMonth(0);
      dobLt.setDate(1);

      val["age"] = values.age;
      val["dobGt"] = getValidDateStringForGraphQL(dobGt);
      val["dobLt"] = getValidDateStringForGraphQL(dobLt);
    }

    if (values.country) {
      val["country"] = values.country;
    }

    let governorateObj = governoratesData && governoratesData.governorates ? governoratesData.governorates.edges.find(i => i.node.id === values.governorate) : null;
    if (governorateObj) {
      val["governorate"] = governorateObj.node.name.toLowerCase();
    }

    let areaObj = areasData && areasData.areas ? areasData.areas.edges.find(i => i.node.id === values.area) : null;
    if (areaObj) {
      val["area"] = areaObj.node.name.toLowerCase();
    }

    if (values.gender) {
      val["gender"] = values.gender.toLowerCase();
    }

    if (values.medication) {
      val["search"] = values.medication;
    }
    console.log("val", val);
    let removeEmptyValues = removeEmpty(values);
    setAppliedFilters(removeEmptyValues);
    getRequestPatient({ variables: val });

  }


  if (appliedFilters) {
    initialValues["fieldName"] = appliedFilters.fieldName;
    initialValues["fieldValue"] = appliedFilters.fieldValue;
    initialValues["gender"] = appliedFilters.gender;
    initialValues["age"] = appliedFilters.age;
    initialValues["governorate"] = appliedFilters.governorate;
    initialValues["area"] = appliedFilters.area;
    initialValues["country"] = appliedFilters.country;
    initialValues["medication"] = appliedFilters.medication;
  }

  return (
    <Base title={'Patients'} showHeader={true} rightChild={has_add_permission ? getButtons() : null}>
      <Container fluid className="mb-4">
        <Row className="mb-2">
          <Col lg={9}>
            <Form.Group as={Row} >
              <Col sm={12} md={8} xl={8} className="px-0 d-flex align-items-center">
                <Form.Label className="text-uppercase pr-3 mb-0">
                  Search
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  placeholder="Patient Name, Civil ID, Phone number"
                  value={searchTerm}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col lg={3} className="d-flex justify-content-end align-items-center patients_filter px-0">
            {Object.keys(appliedFilters).length > 0 ? <Button variant="link" size="md" className="ml-2 px-0" onClick={() => resetSearchMedical()}>
              Reset
            </Button> : null}
            <Button variant="link btn-link-no-padding" onClick={() => setShowModal(true)}>
              <i className="fa fa-filter mx-2 i-no-margin " aria-hidden="true"></i>
              <span>FILTERS </span>
              {Object.keys(appliedFilters).length > 0 ? <Badge variant="primary" className="filterCount">{Object.keys(appliedFilters).length}</Badge> : null}
            </Button>

            <CustomModals dialogClassName="modal60h"  showModal={showModal} modalHeader={'FILTER'} setShowModal={setShowModal}>
              <Formik enableReinitialize onSubmit={onSubmit} initialValues={initialValues} innerRef={formikRef}>
                {({ handleSubmit, handleBlur, handleChange, values }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off" className="w100">
                    <Row >
                      <Col md={5} >
                        {console.log("values",values)}
                        <Form.Group as={Row}>
                          <Form.Label column sm={4} md={4} className="text-right pr-0">
                            <span className=""> Filter </span>
                          </Form.Label>
                          <Col sm={8} md={8}>
                            <Form.Control
                              autoComplete="off"
                              as="select"
                              name="fieldName"
                              value={values.fieldName}
                              required={values.fieldValue && values.fieldValue!=="default"? true:false}
                              onChange={handleChange}
                            >
                              <option value="">Select Field</option>
                              {formFieldsObj && formFieldsObj.formFields ?
                                formFieldsObj.formFields.edges.map((fld, index) => {
                                  return (<option value={fld.node.id} key={index}> {fld.node.name} </option>)
                                })
                                : null}
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col md={5} >
                        <Form.Group as={Row} >
                          <Form.Label column sm={4} md={4} className="text-right pr-0">
                            <span className="">Value </span>
                          </Form.Label>
                          <Col sm={8} md={8}>
                            <Form.Control
                              autoComplete="off"
                              as="input"
                              name="fieldValue"
                              required={values.fieldName? true:false}
                              value={values.fieldValue}
                              onChange={handleChange}
                            >
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      </Col>
                      </Row>
                      <Row>
                      {/* <Col md={5}>
                        <Form.Group as={Row} >
                          <Form.Label column sm={4} md={4} className="text-right pr-0">
                            <span className="">Dob </span>
                          </Form.Label>
                          <Col sm={8} md={8}>
                            <DatePicker
                              name="dob"
                              autoComplete="off"
                              showMonthDropdown
                              showYearDropdown
                              selected={startDate ? startDate : ''}
                              onChange={(date) => setStartDate(date)}
                              className="form-control"
                              dateFormat="dd/MMM/yyyy"
                            />
                          </Col>
                        </Form.Group>
                      </Col> */}
                      <Col md={5}>
                        <Form.Group as={Row} >
                          <Form.Label column sm={4} md={4} className="text-right pr-0">
                            <span className=""> Age </span>
                          </Form.Label>
                          <Col sm={8} md={8}>
                            <Form.Control
                              autoComplete="off"
                              as="input"
                              name="age"
                              placeholder="Enter Age"
                              value={values.age}
                              onChange={handleChange}
                            >
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col md={5}>
                        <Form.Group as={Row} >
                          <Form.Label column sm={4} md={4} className="text-right pr-0">
                            <span className="">Gender </span>
                          </Form.Label>
                          <Col sm={8} md={8}>
                            <Form.Control
                              autoComplete="off"
                              as="select"
                              name="gender"
                              value={values.gender}
                              onChange={handleChange}
                            >
                              <option value="">Select Gender</option>
                              <option value="female">Female</option>
                              <option value="male">Male</option>
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col md={5}>
                        {countriesData ? <Form.Group as={Row} >
                          <Form.Label column xs={12} sm={4} md={4} className="text-right pr-0">
                            Country
                          </Form.Label>
                          <Col xs={12} sm={8} md={8}>
                            <Form.Control
                              onBlur={handleBlur}
                              as="select"
                              name="country"
                              value={values.country}
                              onChange={handleChange}>
                              <option value="">Select Country</option>
                              {Object.entries(countriesData.countries).map(([key, value], index) => {
                                return (
                                  <option value={key} key={index}>
                                    {value}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Col>
                        </Form.Group> : null}
                      </Col>

                      {governoratesData && governoratesData.governorates && governoratesData.governorates.edges && values.country === "KW" ? <Col md={5}> <Form.Group as={Row} className="text-right">
                        <Form.Label column xs={12} sm={4} md={4} >
                          Governorate
                         </Form.Label>
                        <Col xs={12} sm={8} md={8}>
                          <Form.Control
                            onBlur={handleBlur}
                            as="select"
                            name="governorate"
                            value={values.governorate}
                            onChange={onChangeGovernorate}>
                            <option value="">Select Governorate</option>
                            {governoratesData.governorates.edges.map((item, index) => {
                              return (
                                <option value={item.node.id} key={index}>
                                  {item.node.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group>  </Col> : null}
                      {areasData && areasData.areas && areasData.areas.edges && values.country === "KW" ? <Col md={5}> <Form.Group as={Row} className="text-right">
                        <Form.Label column xs={12} sm={4} md={4} >
                          Area
                        </Form.Label>
                        <Col xs={12} sm={8} md={8}>
                          <Form.Control
                            onBlur={handleBlur}
                            as="select"
                            name="area"
                            value={values.area}
                            onChange={handleChange}>
                            <option value="">Select Area</option>
                            {areasData.areas.edges.map((item, index) => {
                              return (
                                <option value={item.node.id} key={index}>
                                  {item.node.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group> </Col> : null}
                      <Col md={5}>
                        <Form.Group as={Row} >
                          <Form.Label column sm={4} md={4} className="text-right pr-0">
                            <span className=""> Medication </span>
                          </Form.Label>
                          <Col sm={8} md={8}>
                            <Form.Control
                              autoComplete="off"
                              as="input"
                              name="medication"
                              placeholder="Search by Medication"
                              value={values.medication}
                              onChange={handleChange}
                            >
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <div className="d-flex justify-content-end ">
                          <Button variant="primary" size="md" type="submit">
                            Search
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CustomModals>
          </Col>
        </Row>
        {loading ? (
          <Preloader />
        ) : patients.length > 0 ? (
          <Row>
            <Table responsive className=" table-borderless">
              <thead>
                <tr className="patient_table_row tableHeader">
                  <th scope="col " className="text-uppercase">Patients Name</th>
                  <th scope="col " className="text-uppercase">Contact Information</th>
                  <th scope="col " className="text-uppercase">Civil ID</th>
                  <th scope="col " className="text-uppercase"></th>
                </tr>
              </thead>
              <tbody>
                {patients ? renderList(patients) : null}
              </tbody>
            </Table>
          </Row>
        ) : (
          <Row className="pointer patient_table_row">
            <Col>Patients not available </Col>
          </Row>
        )}
        {data && data.patients && data.patients.totalCount > PER_PAGE_COUNT ? <div className="d-flex justify-content-center pt-5">
          <Pagination>{renderPages()}</Pagination>
        </div> : null}
      </Container>
    </Base>
  );
}
export default withRouter(Patients);
