import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import Base from './base.js';
import { withRouter, Link } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  displayMiddleName
  // getPermissionForAction,
} from '../Common/helpers';
import Preloader from '../Common/Preloder/Preloader';


function LabRequests(props) {

  const identifier = props.match.params.identifier;


  const [openSnackbar] = useSnackbar(error_options);
  const REQUEST_LAB_REQUESTS = gql`
  query {
    labRequests(patient_Identifier:"${identifier}"){
      edges {
        node {
            id
            searchText
            usedTemplate{
              name
            }
          patient{
            id
            identifier
            firstName
            middleName
            lastName
          }
          groupTests{
            edges{
              node{
                name  
                labTests{
                  edges{
                    node{
                      name
                    }
                  }
                }
                children{
                  edges{
                    node{
                      name
                    }
                  }
                }
              }
            }
          }
          
          specificTests{
            edges{
              node{
                name
                
              }
            }
          }
          testResults{
            edges{
              node{
                specificTest{
                  name
                }
                result
              }
            }
          }
        }
      }
    }
  }
`;


  const DELETE_LAB_REQUEST = gql`  
    mutation deleteLabRequest($id:ID!){
      deleteLabRequest(id:$id){
        deleted
      }
    } 
    `;

  const { data, loading } = useQuery(REQUEST_LAB_REQUESTS, {
    fetchPolicy: 'network-only',
    onError: (e) => {
      console.log('REQUEST_LAB_REQUESTS  error: ', e.errors);
    },
  });

  // DELETE_ LAB REQ
  const [deleteLabRequest] = useMutation(DELETE_LAB_REQUEST, {
    onCompleted: ({ deleteLabRequest }) => {
      console.log("deleteLabRequest", deleteLabRequest);
    },
    refetchQueries: [
      { query: REQUEST_LAB_REQUESTS, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });

  var labRequests = data && data.labRequests ? data.labRequests.edges : [];

  let patient_name = labRequests && labRequests.length > 0 ? labRequests[0].node.patient.firstName + " " + displayMiddleName(labRequests[0].node.patient.middleName)+labRequests[0].node.patient.lastName : "";

  const deleteRefFunc = (id) => {
    if (window.confirm("Delete Referralsss?",)) {
      let val = {};
      val["id"] = id;
      console.log("VALL", val)
      deleteLabRequest({ variables: val });
    }
  }

  

  return (
    <Base page_loading={loading} title={patient_name ? patient_name + ' LAB REQUESTS' : 'LAB REQUESTS'} showHeader={true}>
      {loading ? <Preloader /> :
        <Row>
          {labRequests && labRequests.length > 0 ? labRequests.map((lab, index) => {
            return (
              lab.node.testResults.edges.length > 0 ?
                <Col key={index} xs={12} md={4} className="d-flex-column-str  mb-4">
               
                    <Card>
                      <Card.Body className="p-4">
                        <Row className='mb-2'>
                          <Col md={6} className='d-flex justify-content-start align-items-center'>
                            <Link to={"/patient/labrequests/detail/"+lab.node.id}>
                              <h6 className="textPrimaryBold mb-0 ">Tests</h6>
                            </Link>
                          </Col>
                          <Col md={6} className="d-flex justify-content-end">
                            <Button 
                              variant="link" 
                              className="danger-color  p-0" 
                              onClick={() => deleteRefFunc(lab.node.id)}
                            >
                              Delete
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <h6 className='capitalize'><b>{lab.node.usedTemplate.name}</b></h6>
                          </Col>
                        </Row>
                        {lab.node.testResults.edges.map((result, index) => {
                          return (<Row key={index}>
                            <Col md={12}>
                              <h6> {result.node.specificTest.name} </h6>
                            </Col>
                          </Row>)
                        })}

                      </Card.Body>
                    </Card>
                
                </Col> : null
            )
          }) : <Col xs={12} md={6}>No Lab Requests for this patient </Col>}
        </Row>}
    </Base>
  );
}
export default withRouter(LabRequests);
