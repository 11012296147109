import { InMemoryCache, makeVar } from '@apollo/client';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
        dateEvent: {
          read() {
            return dateEventInVar();
          },
        },
        dataPatient: {
          read() {
            return dataPatientInVar();
          },
        },
        referralObj: {
          read() {
            return dataReferralObject();
          },
        },
        userDetails: {
          read() {
            return userDetailsVar();
          }
        },
        userPermissions: {
          read() {
            return userPermissionsVar();
          }
        },
        calendarSlots: {
          read() {
            return calendarSlotsVar();
          }
        },
        myAppointmentDetails: {
          read() {
            return myAppointmentDetailsVar();
          }
        },
        currentPageData: {
          read() {
            return currentPageDataVar();
          }
        },
        eventsData: {
          read() {
            return eventsDataVar();
          }
        },
        calendarActiveMonth: {
          read() {
            return calendarActiveMonthVar();
          }
        },
        appLanguage: {
          read() {
            return appLanguageVar();
          }
        },
        accountsFilter: {
          read() {
            return accountsFilterVar();
          }
        },
        currentSlotIndex: {
          read() {
            return currentSlotIndexVar();
          }
        },
        editableAppointment: {
          read() {
            return editableAppointmentVar();
          }
        },
        appFromCal: {
          read() {
            return appFromCalVar();
          }
        },
      },
    },
  },
});
export const isLoggedInVar = makeVar(!!localStorage.getItem('token'));
export const dateEventInVar = makeVar([]);
export const calendarSlotsVar = makeVar(null);
export const dataPatientInVar = makeVar(null);
export const dataReferralObject = makeVar(null);
export const userDetailsVar = makeVar(localStorage.getItem('user'));
export const userPermissionsVar = makeVar(localStorage.getItem('user_permissions'));
export const myAppointmentDetailsVar = makeVar({});
export const currentPageDataVar = makeVar({});
export const eventsDataVar = makeVar({});
export const calendarActiveMonthVar = makeVar(null);
export const appLanguageVar = makeVar(localStorage.getItem('appLanguage'));
export const accountsFilterVar = makeVar(null);
export const currentSlotIndexVar = makeVar(0);
export const editableAppointmentVar = makeVar(null);
export const appFromCalVar = makeVar(null);

