import React, { useEffect, useRef, useState, useCallback } from 'react';
import style from './CreateEventForm.module.css';
import { Button, Form, Col, Row, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Formik } from 'formik';
import { dataPatientInVar, dataReferralObject, dateEventInVar, calendarActiveMonthVar, currentSlotIndexVar, appFromCalVar } from '../../../cache/cache';
import { getForamttedTime, isUserStaff, SNACK_DURATION, ERROR_MESSAGE, success_options, error_options, getDate,displayMiddleName } from '../../../Common/helpers.js';
import SelectPatientModal from '../../../Views/SearchForPatient/selectPatientModal';
import { withRouter } from 'react-router-dom';
import { gql, useQuery, } from '@apollo/client';
import { useSnackbar } from 'react-simple-snackbar';
import { useTranslation } from "react-i18next";
import { useReactiveVar, useMutation} from '@apollo/client';
import { calendarSlotsVar,editableAppointmentVar } from '../../../cache/cache.js';

const CreateEventForm = (props)=> {
  const {
    createEvent,
    doctorList,
    patientDataOfSearchPage,
    selectedDateEvent,
    selectedDoctor,
    setSelectedDoctor,
    doctorLeaves,
    fullCalendarRef,
    setSelectedSession,
    setSelectedPatient,
    selectedPatient,
    selectedSession,
    waitingList,
    calendarData,
    formikRef,
    GetEvents,
    referralObjectData,
    history,
    serviceList,
    getDoctorsListFromService,
    sessionName
  } =props;
  const [openSnackbar] = useSnackbar(error_options);
  const searchPatientRef = useRef();
  const [selectedDate, setSelectedDate] = useState([]);
  const [leastPriority, setLeastPriority] = useState(null);
  const [showWaitingList, setShowWaitingList] = useState(false);
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [fromReferral, setFromReferral] = useState(false);
  const isStaff = isUserStaff();
  const calendarActiveMonth = useReactiveVar(calendarActiveMonthVar);
  const currentSlotIndex = useReactiveVar(currentSlotIndexVar);
  const editableAppointment = useReactiveVar(editableAppointmentVar);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const appFromCal = useReactiveVar(appFromCalVar);
  const appCreateFromCal = props?.location?.state?.appCreateFromCal;

  console.log("appCreateFromCal",appCreateFromCal)
  console.log("appFromCal",appFromCal)

  const { t } = useTranslation();

  const REQUEST_SETTINGS = gql`
      query {
        settings
      }
  `;


  const UPDATE_APPOINTMENT = gql`
    mutation updateEvent(
      $id: ID!
      $title: String!
      $description: String!
      $start: DateTime!
      $doctorEventType: ID!
    ) {
      updateEvent(
        input: {
          id: $id
          title: $title
          description: $description
          start: $start
          doctorEventType:$doctorEventType
        }
      ){
        errors {
          field
          messages
        }
        obj {
          start
          end
          title
          description
          id
        }
      }
    }
  `;


  useEffect(() => {
    if (appFromCal && appCreateFromCal) { //appCreateFromCal boolean to check if this variable can be used or not
      if(appFromCal?.patientData){
        setSelectedPatient(appFromCal?.patientData);
        console.log("appFromCal?.patientData",appFromCal?.patientData)
        formikRef.current.setFieldValue(
          'patient',
          `${appFromCal?.patientData?.firstName} ${appFromCal?.patientData?.lastName} (${appFromCal?.patientData?.identifier})`,
          );
      }

      if(appFromCal.doctor){
        formikRef.current.setFieldValue('doctor', appFromCal.doctor.node.identifier);
        setSelectedDoctor(appFromCal.doctor);
      }
      if(appFromCal.selectedDateStr&& fullCalendarRef.current){
          let dd=String(appFromCal.selectedDateStr.getDate()).padStart(2, '0');
          let mm=String(appFromCal.selectedDateStr.getMonth() + 1).padStart(2, '0');
          let yy=appFromCal.selectedDateStr.getFullYear();
          let time="T"+String(appFromCal.selectedDateStr.getHours()).padStart(2, '0')+":"+String(appFromCal.selectedDateStr.getMinutes()).padStart(2, '0')+":00+03:00";
          let calDate=yy+"-"+mm+"-"+dd+time;
          console.log("calDate",calDate);
          // calDate="2022-01-11T15:00:00+03:00";
          fullCalendarRef.current.getApi().changeView('timeGridDay', calDate);
      }
    }
  }, [appFromCal,fullCalendarRef,appCreateFromCal]);


  const [updateEvent] = useMutation(UPDATE_APPOINTMENT, {
    onCompleted: ({ updateEvent }) => {
      console.log("updateEvent",updateEvent)
      openSnackbarSuccess('Appointment Updated', [SNACK_DURATION]);
      history.push('/appointment/detail/'+updateEvent.obj.id);
    },
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });


  // GET leave detail
  const { data: settings_obj = null } = useQuery(
    REQUEST_SETTINGS,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );


  const getNextMonth = () => {
    console.log("getNextMonth getMonth",)
    let slotsUntil = calendarData && calendarData.calendarSlots ? calendarData.calendarSlots.slotsUntil : null;
    let current_month = slotsUntil.getMonth();
    let current_year = slotsUntil.getFullYear();
    // currentSlotIndexVar(0);
    calendarActiveMonthVar(current_month);
    let dateStart = new Date(current_year, current_month, 1);
    let dateEnd = new Date(current_year, current_month + 1, 1);
    if (dateStart && dateEnd && selectedDoctor) {
      GetEvents({
        variables: {
          doctor_identifier: selectedDoctor.node.identifier,
          start: dateStart,
          end: dateEnd,
          action: "next"
        },
      });
    }
  }

  const getPrevMonth = () => {
    console.log("getPrevMonth getMonth",)
    let slotsUntil = calendarData && calendarData.calendarSlots ? calendarData.calendarSlots.slotsUntil : null;
    let current_month = slotsUntil.getMonth();
    let current_year = slotsUntil.getFullYear();
    calendarActiveMonthVar(current_month - 2);
    let dateStart = new Date(current_year, current_month - 2, 1);
    let dateEnd = new Date(current_year, current_month - 1, 1);
    // if (current_month - 2 < new Date().getMonth()) {
    //   // disablePrev = true;
    //   currentSlotIndexVar(0);
    // }
    if (dateStart && dateEnd && selectedDoctor) {
      GetEvents({
        variables: {
          doctor_identifier: selectedDoctor.node.identifier,
          start: dateStart,
          end: dateEnd,
          action: "prev"
        },
      });
    }
  }

  // ON NEXT AVAILABLE APPOINTMENT BUTTON CLICK
  const onChoseAppointment = (action) => {
    let nextIndex = currentSlotIndex!==null?currentSlotIndex:0;
    let allAvailableEvents = calendarData && calendarData.calendarSlots ? calendarData.calendarSlots.allFilters : [];

    if (allAvailableEvents && allAvailableEvents.length > 0) {
      if (nextIndex < allAvailableEvents.length) {
        if (selectedDateEvent && selectedDateEvent.dateEvent && selectedDateEvent.dateEvent.start) {
          let current_date = selectedDateEvent.dateEvent.start;
          let current_index = allAvailableEvents.findIndex((i) => {
            let slotStart = new Date(i.start).toString();
            let currentStart = new Date(current_date).toString();
            if (slotStart === currentStart) {
              return true;
            }
            return false;
          });
          if (action === "next") {
            if (current_index < allAvailableEvents.length - 1) {
              nextIndex = current_index + 1;
            }
            else {
              getNextMonth();
              return;
            }
          }
          else if (action === "prev") {
            if (current_index > 0) {
              nextIndex = current_index - 1;
            }
            else {
              let slotsUntil = calendarData && calendarData.calendarSlots ? calendarData.calendarSlots.slotsUntil : null;
              let current_month = slotsUntil.getMonth();
              if (current_month - 2 >= new Date().getMonth()) {
                getPrevMonth();
                return;
              }
            }
          }
        }
        let nextAvailable = allAvailableEvents[nextIndex];
        if (nextAvailable && nextAvailable.start) {
          if (fullCalendarRef && fullCalendarRef.current) {
            setTimeout(() => {
              let currentView = fullCalendarRef.current.getApi().view;
              let calDate = currentView.activeStart;              
              if((currentView.type!=="timeGridDay" )||( new Date(nextAvailable.start).getDate()!==calDate.getDate())){
                fullCalendarRef.current.getApi().changeView('timeGridDay', nextAvailable.start);
              }
              else{
                let currentTimeDisplay = nextAvailable.start.split('T')[1];
                if (currentTimeDisplay) {
                  currentTimeDisplay += ":00";
                  fullCalendarRef.current.getApi().scrollToTime(currentTimeDisplay);
                }
              }
            }, 200);
          }
          dateEventInVar({
            start: nextAvailable.start,
            end: nextAvailable.end,
          });

          let newIndex = nextIndex;
          if (action === "next") {
            newIndex = newIndex + 1;
          }
          else if (action === "prev" && newIndex>0) {
            newIndex = newIndex - 1;
          }
          currentSlotIndexVar(newIndex);
        }
      } else  if(selectedDoctor && selectedDoctor.node && calendarData.calendarSlots){
        if(action === "next"){
          getNextMonth();
          return;
        }
      }
    }
    else if (selectedDoctor && selectedDoctor.node && calendarData.calendarSlots) {
      if(action === "next"){
        getNextMonth();
        return;
      }
    }
    localStorage.setItem('calendarViewStored', 'timeGridDay');
  };

  const handleDoctor = (event) => {
    formikRef.current.setFieldValue('doctor', event.target.value);
    let act_doc = eventData.doctors.edges.find((i) => i.node.identifier === event.target.value);
    setSelectedDoctor(act_doc);
    if (act_doc && act_doc.node.identifier) {
      doctorLeaves({ variables: { doctor_identifier: act_doc.node.identifier } });
    }
    if (!isStaff) {
      let currentService = formikRef.current.values.service;


      let sessionObj = act_doc?.node?.eventType?.edges.filter(et => {
        return et.node.title.name == sessionName
      })[0];
      if (sessionObj?.node) {
        formikRef.current.setFieldValue('session', sessionObj.node.id);

      }
      else sessionObj = null;
      setSelectedSession(sessionObj);
    }
    else {
      formikRef.current.setFieldValue('session', '');
      setSelectedSession(null);
    }
    dateEventInVar([]);
  };

  const handleSession = (event) => {
    formikRef.current.setFieldValue('session', event.target.value);
    let session_obj = selectedDoctor.node.eventType.edges.find(
      (i) => i.node.id === event.target.value,
    );
    setSelectedSession(session_obj);
  };

  // const handleOnlineLink = (event) => {
  //   formikRef.current.setFieldValue('onlineLink', event.target.value);
  // }

  const onSelectPatient = (patientData) => {
    setSelectedPatient(patientData);
    dataPatientInVar(patientData);
  };

  const onCancelForm = (handleReset) => {
    handleReset();
    setSelectedPatient(null);
    setSelectedDoctor(null);
    dataPatientInVar(null);
    dateEventInVar([]);
    history.goBack();
  };

  useEffect(() => {
    dateEventInVar({}); // reset selected date time input when the session is changed so that the next available selects the first option based on new session 
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSession]);


  useEffect(() => {
    console.log("calendarData changed",calendarData)
    let allAvailableEvents = calendarData && calendarData.calendarSlots ? calendarData.calendarSlots.allFilters : [];
    if(allAvailableEvents.length===0){
      dateEventInVar({
        start:'',
        end:'',
      });
    }
    let selected_button = document.activeElement.id;
    let newSlotIndex = null;
    if (selected_button === "next-appointment") {
      newSlotIndex = 0;
    }
    else if (selected_button === "prev-appointment") {
      if(allAvailableEvents.length>0){
        newSlotIndex = allAvailableEvents.length - 1;
      }
      else{
        newSlotIndex = 0;
      }
    }
    currentSlotIndexVar(newSlotIndex);
    let nextAvailable = allAvailableEvents[newSlotIndex];
    if (nextAvailable && nextAvailable.start && nextAvailable.end) {
      dateEventInVar({
        start: nextAvailable.start,
        end: nextAvailable.end,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarData]);

  

  useEffect(() => {
    if (editableAppointment) {
      console.log("editableAppointment,",editableAppointment)
      formikRef.current.setFieldValue(
        'patient',
        `${editableAppointment?.patient?.firstName} ${editableAppointment?.patient?.lastName} (${editableAppointment?.patient?.identifier})`,
      );
      formikRef.current.setFieldValue('doctor', editableAppointment?.doctor.identifier);
      let doctorObj = editableAppointment.doctor;
      doctorObj = 
      setSelectedDoctor({node: editableAppointment.doctor});
      let session_obj = editableAppointment.doctor?.eventType?.edges.find(
        (i) => i.node.eventType.id === editableAppointment?.eventType?.id,
        );
      setSelectedSession(session_obj);
      formikRef.current.setFieldValue('session',session_obj.node.id);
    }
  }, [editableAppointment, formikRef]);

  useEffect(() => {
    return function cleanup() {
      dataReferralObject(null);
      editableAppointmentVar(null);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let least_priority = Math.max.apply(Math, waitingList.map(function (o) { return o.node.priority; }));
    if (least_priority) {
      let leastPriorityNode = waitingList.find(i => i.node.priority === least_priority);
      setLeastPriority(leastPriorityNode);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingList]);

  // WHEN PATIENT IS SELECTED FROM THE MODAL
  useEffect(() => {
    console.log("patientDataOfSearchPage.dataPatient",patientDataOfSearchPage.dataPatient)
    if (patientDataOfSearchPage && patientDataOfSearchPage?.dataPatient) {
      setSelectedPatient(patientDataOfSearchPage.dataPatient);
      if (patientDataOfSearchPage.dataPatient.discount) {
        formikRef.current.setFieldValue(
          'discount_value',
          patientDataOfSearchPage.dataPatient.discount,
        );
        if (patientDataOfSearchPage.dataPatient.discount > 0) {
          formikRef.current.setFieldValue('show_discount', true);
          setShowDiscount(true);
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDataOfSearchPage, setSelectedPatient]);

  // INITIALIZING DATA FOR APPOINTMENT WHEN IT IS BEING CREATED FROM REFERRALS
  useEffect(() => {
    if (referralObjectData && referralObjectData.referralObj) {
      if (referralObjectData.referralObj.patient) {
        setSelectedPatient(referralObjectData.referralObj.patient);
      }
      if (referralObjectData.referralObj.referral && referralObjectData.referralObj.referral.referredDoctor) {
        setSelectedPatient(referralObjectData.referralObj.patient);
        let patient_doctor_identifier = referralObjectData.referralObj.referral.referredDoctor.identifier;
        if (referralObjectData.referralObj.referral.referredDoctor) {
          setSelectedDoctor({ node: referralObjectData.referralObj.referral.referredDoctor });
          if (formikRef && formikRef.current) {
            formikRef.current.setFieldValue('doctor', patient_doctor_identifier);
          }
          if (patient_doctor_identifier) {
            doctorLeaves({ variables: { doctor_identifier: patient_doctor_identifier } });
          }
          if (referralObjectData.referralObj.referral && referralObjectData.referralObj.referral.referredDoctorSession) {
            let session_id = referralObjectData.referralObj.referral.referredDoctorSession.id;
            formikRef.current.setFieldValue('session', session_id);
            setSelectedSession({ node: referralObjectData.referralObj.referral.referredDoctorSession });
          }
        }
      }

      setFromReferral(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralObjectData]);

  // On change of patient
  useEffect(() => {
    if (selectedPatient && selectedPatient.id) {
      formikRef.current.setFieldValue(
        'patient',
        `${selectedPatient.firstName} ${displayMiddleName(selectedPatient.middleName)}${selectedPatient.lastName} (${selectedPatient.identifier})`,
      );
    }
  }, [selectedPatient, formikRef]);

  const eventData = {
    doctors: doctorList.doctors,
    listEventLocation: [],
    listEventType: [],
  };

  console.log("selectedPatient",selectedPatient)
  const onSubmit = (values, { resetForm }) => {
    if (editableAppointment) {
      let val = {
        id: editableAppointment.id,
        title: editableAppointment.title,
        description: values.descriptionEn
        ? values.descriptionEn
        : `${editableAppointment?.patient?.firstName} ${editableAppointment?.patient?.lastName}`,
        start: selectedDate.start,
        doctorEventType:values.session,
      };
      console.log("valvalvalval",val)
      updateEvent({
        variables: val,
      })
      return;
    }
    
    
    
    let title = selectedPatient.firstName + " " +displayMiddleName(selectedPatient.middleName)+ selectedPatient.lastName;
    // if (selectedSession) {
    //   title += ", Location: " + selectedSession.node.eventLocation.title + ", Type: " + selectedSession.node.eventType.name;
    // }
    let val = {
      patient: selectedPatient.id,
      doctor: selectedDoctor.node.id,
      start: selectedDate.start,
      title: title,
      doctorEventType: values.session,
      description: values.descriptionEn
        ? values.descriptionEn
        : `${selectedPatient.firstName} ${displayMiddleName(selectedPatient.middleName)} ${selectedPatient.lastName}`,
      require_payment: values.require_payment,
      // onlineLink: values.onlineLink,
    };

    if (values.waiting_list && isStaff) {
      val['waitinglist'] = values.waiting_list;
    }
    if(!isStaff && showWaitingList ){
      val['waitinglist'] = leastPriority.node.id;
    }
    if (showDiscount) {
      val['discount'] = values.discount_value;
    }
    if (referralObjectData && referralObjectData.referralObj && referralObjectData.referralObj.referral && referralObjectData.referralObj.referral.id) {
      val['referral'] = referralObjectData.referralObj.referral.id;
    }

    // new condition added to alert user of the downpayment cut off 
    if (values.require_payment) {
      let paymentCutOffTime=selectedSession?.node.notification?.downPaymentNotificationCutoff;
      if(!paymentCutOffTime){
        paymentCutOffTime=settings_obj?.settings?.downpayment_cutoff_time;
      }
      if (paymentCutOffTime) {
        let currentTime = new Date();
        currentTime.setHours(currentTime.getHours() + paymentCutOffTime);
        let proposedAppDate = new Date(selectedDate.start);
        if (proposedAppDate > currentTime) {
          console.log("valvalval1",val);
          if(val.patient && val.doctor && val.start && val.doctorEventType){
            createEvent({
              variables: val,
            });
          }
          else {
            alert("Please fill all fields")
          }
        }
        else {
          alert("You are about to book an appointment that will be cancelled due to payment cut off time. Remove required payment to continue booking an appointment");
        }
      }
    }
    else {
      console.log("valvalval2",val)
      if(val.patient && val.doctor && val.start && val.doctorEventType){
        createEvent({
          variables: val,
        });
      }
      else {
        alert("Please fill all fields")
      }
    }
    // new condition added to alert user of the downpayment cut off 

  };

  const initialValues = {
    doctor: '',
    patient: '',
    start: '',
    date: '',
    title: '',
    descriptionEn: '',
    eventType: '',
    session: '',
    discount_value: '0',
    show_discount: false,
    require_payment: true,
    should_be_seen: '',
    // onlineLink: '',
  };

  const formatEventDate = useCallback(
    (selectedDate) => {
      let eventDate_st = new Date(selectedDate.start);
      let eventDate_en = new Date(selectedDate.end);
      let time_st = getForamttedTime(eventDate_st);
      let time_en = getForamttedTime(eventDate_en);

      let date = eventDate_st.toLocaleString('en-GB', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      });
      formikRef.current.setFieldValue('start', time_st + ' - ' + time_en);
      formikRef.current.setFieldValue('date', date);
    },
    [formikRef],
  );

  useEffect(() => {
    if (selectedDateEvent.dateEvent.start) {
      setSelectedDate(selectedDateEvent.dateEvent);
      formatEventDate(selectedDateEvent.dateEvent);
      let currentView = fullCalendarRef.current.getApi().view;
      let calDate = currentView.activeStart;
      if(calDate){
        if((currentView.type!=="timeGridDay") || (new Date(selectedDateEvent.dateEvent.start).getDate()!==calDate.getDate())){
          fullCalendarRef.current.getApi().changeView('timeGridDay', selectedDateEvent.dateEvent.start);
        }
      }
    } else if (formikRef && formikRef.current) {
      formikRef.current.setFieldValue('date', '');
      formikRef.current.setFieldValue('start', '');
      currentSlotIndexVar(0);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateEvent]);

  const getDoctorSessionDetails = (session, values) => {
    if (selectedDoctor) {
      let selected_session = selectedSession;
      if (selected_session) {
        return (
          <>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Location: {selected_session.node.eventLocation.title}
            </Form.Control.Feedback>
            {/* <Form.Control.Feedback type="valid" className="doctor_session">
              Type: {selected_session.node.eventType.name}
            </Form.Control.Feedback> */}
            <Form.Control.Feedback type="valid" className="doctor_session">
              Duration: {selected_session.node.duration} Mins
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Price: {values.discount_value > 0 ? getDiscountPrice(values.discount_value, selected_session.node.price) : selected_session.node.price} KWD
            </Form.Control.Feedback>
          </>
        );
      }
    }
  };

  // const handleDiscountChange=(e)=>{
  //   if(e.target.value<=100){
  //     formikRef.current.setFieldValue('discount_value', e.target.value);
  //   }
  // }

  const getDiscountPrice = (discount_value, price) => {
    let disc = (discount_value / 100) * price;
    return price - disc;
  }

  const handleService = (event) => {
    let value = event.target.value;
    let serviceObjectArray = serviceList.filter(item => {
      return item.node.id == value;
    });
    let service = serviceObjectArray[0]?.node
    formikRef.current.setFieldValue('service', value);
    let val = {
      ...event.target.value
    };
    setSelectedDoctor(null);
    getDoctorsListFromService({ ...service });
    formikRef.current.setFieldValue('doctor',"");
    formikRef.current.setFieldValue('date',"");
    formikRef.current.setFieldValue('start',"");
    calendarSlotsVar(null);
  }

  const validate = (values) => {
    let errors = {};
    if (values.onlineLink && values.onlineLink.substr(0, 7) !== 'http://' && values.onlineLink.substr(0, 8) !== 'https://') {
      errors.onlineLink = `Wrong format`;
    }
    return errors;
  }

  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues} innerRef={formikRef} validate={validate}>
        {({ handleSubmit, handleChange, values, handleReset, errors }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Container>
              {/* {!isStaff && referralObjectData && referralObjectData.referralObj && referralObjectData.referralObj.referral ?
                <div><h6>Please fill the following forms before the visitng for the appointment: </h6>
                  {referralObjectData.referralObj.referral.dynamicForms.edges.map(form => {
                    return <Link to={"/patientPortal/intakeform/"+form.node.id}>{form.node.name}</Link>
                  })}
                </div> : null} */}
              {isStaff ? <Form.Group as={Row} className="text-right mb-4">
                <Form.Label column sm={4} md={4}>
                  Patient
                </Form.Label>
                {referralObjectData && referralObjectData.referralObj && referralObjectData.referralObj.referral ?
                  <Col sm={8} md={8} className={!isStaff ? "" : "d-flex align-items-center"} > <h6 className="text-left mb-0"> {referralObjectData.referralObj.patient.firstName + " " +displayMiddleName(referralObjectData.referralObj.patient.middleName)+ referralObjectData.referralObj.patient.lastName}</h6>
                  </Col>
                  : <Col sm={8} md={8} className={style.form__search} ref={searchPatientRef}>
                    <Form.Control
                      required
                      autoComplete="off"
                      placeholder="Select Patient"
                      type="text"
                      name="patient"
                      disabled={fromReferral}
                      value={values.patient}
                      onChange={handleChange}
                      disabled={editableAppointment ? true : false}
                      className={`mr-sm-2 pr-0 float-left pr-5 ${style.bg_img_none}`}
                    />
                    <span onClick={() => { setShowPatientSearch(!fromReferral) }} className="search-icon">
                      <i className="fa fa-search"></i>
                    </span>
                  </Col>}
              </Form.Group> : null}
              {!isStaff ? <Form.Group as={Row} className="text-right">
                <Form.Label column sm={4} md={4}>
                  {t('createEventForm.service')}
                </Form.Label>
                <Col sm={8} md={8} className="d-flex align-items-center" >
                  {referralObjectData && referralObjectData.referralObj && referralObjectData.referralObj.referral ?
                    <h6 className="text-left mb-0"> {referralObjectData.referralObj.referral.referredDoctorSession.title.name}</h6>
                    : <Form.Control
                      className={style.bg_img_none}
                      autoComplete="off"
                      as="select"
                      name="service"
                      value={values.service}
                      disabled={editableAppointment ? true : false}
                      onChange={(value) => {handleService(value);}}
                      required>
                      <option value="">{t('createEventForm.selectService')}</option>
                      {serviceList &&
                        serviceList.map((service) => {
                          return (
                            <option value={service.node.id} key={service.node.id}>
                              {service.node.name}
                            </option>
                          );
                        })}
                    </Form.Control>}
                </Col>
              </Form.Group> : null}
              {referralObjectData?.referralObj?.referral?.recommendedDate ? 
                <Form.Group as={Row} className="text-right">
                  <Form.Label column sm={4} md={4}>
                  {t('createEventForm.shouldBeSeen')}
                  </Form.Label>
                  <Col sm={8} md={8} className="d-flex align-items-center" >
                    <h6 className="text-left mb-0"> 
                      {getDate(new Date(referralObjectData.referralObj.referral.recommendedDate))}
                    </h6>
                  </Col>
                </Form.Group>
              : null}
              <Form.Group as={Row} className="text-right">
                <Form.Label column sm={4} md={4}>
                  {t('createEventForm.practitioner')}
                </Form.Label>
                <Col sm={8} md={8} className={!isStaff ? "" : "d-flex align-items-center"}>
                  {referralObjectData && referralObjectData.referralObj && referralObjectData.referralObj.referral ?
                    <h6 className="text-left mb-0"> {referralObjectData.referralObj.referral.referredDoctor.firstName + " " + referralObjectData.referralObj.referral.referredDoctor.lastName}</h6>
                    :
                    <Form.Control
                      className={style.bg_img_none}
                      autoComplete="off"
                      as="select"
                      name="doctor"
                      value={values.doctor}
                      disabled={fromReferral}
                      onChange={handleDoctor}
                      disabled={editableAppointment ? true : false}
                      required>
                      <option value="">{t('createEventForm.selectPractitioner')}</option>
                      {isStaff ? eventData.doctors &&
                        eventData.doctors.edges.map((doctor) => {
                          return (
                            <option value={doctor.node.identifier} key={doctor.node.id}>
                              {doctor.node.firstName} {doctor.node.lastName}
                            </option>
                          );
                        }) : null}
                      {!isStaff && values.service ? eventData?.doctors?.edges?.length > 0 &&
                        eventData.doctors.edges.map((doctor) => {
                          return (
                            <option value={doctor.node.identifier} key={doctor.node.id}>
                              {doctor.node.firstName} {doctor.node.lastName}
                            </option>
                          );
                        }) : null}
                    </Form.Control>}
                  {!isStaff && selectedDoctor && values.session
                    ? getDoctorSessionDetails(values.session, values)
                    : null}
                </Col>
              </Form.Group>
              {isStaff ? <Form.Group as={Row} className="text-right">
                <Form.Label column sm={4} md={4}>
                  Practitioner Session
                </Form.Label>
                <Col sm={8} md={8} >
                  {referralObjectData && referralObjectData.referralObj && referralObjectData.referralObj.referral ?
                    <h6 className="text-left py-2"> {referralObjectData.referralObj.referral.referredDoctorSession.title.name}</h6>
                    :
                    <Form.Control
                      autoComplete="off"
                      className={style.bg_img_none}
                      required
                      as="select"
                      name="session"
                      id="doctor-session"
                      value={values.session}
                      // disabled={fromReferral}
                      disabled={editableAppointment || fromReferral ? true : false}
                      onChange={handleSession}>
                      <option value="">Select Session</option>
                      {selectedDoctor
                        ? selectedDoctor.node.eventType.edges.map((eventtype) => {
                          return (
                            <option value={eventtype.node.id} key={eventtype.node.id}>
                              {eventtype.node.title.name}
                            </option>
                          );
                        })
                        : null}
                    </Form.Control>}
                  {selectedDoctor && values.session
                    ? getDoctorSessionDetails(values.session, values)
                    : null}
                </Col>
              </Form.Group> : null}
              {/* {isStaff && selectedSession && selectedSession.node && selectedSession.node.eventLocation && selectedSession.node.eventLocation.title === 'Online' ?
                <Form.Group as={Row} className="text-right">
                  <Form.Label column sm={4} md={4}>
                    Link
                  </Form.Label>
                  <Col sm={8} md={8}>
                    <Form.Control
                      onChange={handleOnlineLink}
                      autoComplete="off"
                      type="text"
                      name="onlineLink"
                      value={values.onlineLink}
                      className={`mr-sm-2 bg-white ${style.bg_img_none}`}
                    />
                    {errors.onlineLink && (
                      <div className="online-link-error valid-feedback">{errors.onlineLink}</div>
                    )}
                  </Col>
                </Form.Group> : null} */}
              <Form.Group as={Row} className="text-right">
                <Form.Label column sm={4} md={4}>
                  {t('createEventForm.date')}
                </Form.Label>
                <Col sm={8} md={8}>
                  <Form.Control
                    onChange={(event) => event.preventDefault()}
                    required
                    autoComplete="off"
                    id="next-event-date"
                    data-readonly
                    type="text"
                    name="date"
                    value={values.date}
                    className={`mr-sm-2 bg-white ${style.bg_img_none}`}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="text-right">
                <Form.Label column sm={4} md={4}>
                  {t('createEventForm.time')}
                </Form.Label>
                <Col sm={8} md={8}>
                  <Form.Control
                    required
                    autoComplete="off"
                    data-readonly
                    onChange={(event) => event.preventDefault()}
                    type="text"
                    name="start"
                    value={values.start}
                    className={`mr-sm-2 bg-white ${style.bg_img_none}`}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm={{ span: 8, offset: 4 }} md={{ span: 8, offset: 4 }}>
                  <Button
                    className={style.form__button + ' mb-2 '}
                    variant="primary"
                    id="prev-appointment"
                    onClick={() => onChoseAppointment("prev")}
                    disabled={(!selectedDoctor || !selectedSession) && (!referralObjectData || !referralObjectData.referralObj)}
                    size="sm">
                    {t('createEventForm.previous')}
                  </Button>
                </Col>
                <Col sm={{ span: 8, offset: 4 }} md={{ span: 8, offset: 4 }}>
                  <Button
                    className={style.form__button + ' mb-2 '}
                    variant="primary"
                    id="next-appointment"
                    disabled={(!selectedDoctor || !selectedSession) && (!referralObjectData || !referralObjectData.referralObj)}
                    onClick={() => onChoseAppointment("next")}
                    size="sm">
                    {t('createEventForm.nextAvailable')}
                  </Button>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="d-flex justify-content-start position-relative">
                <Col className={style.form__waiting_list + " text-right "} xs={8} sm={5} md={9} lg={9} xl={7}>
                  <Form.Label>{t('createEventForm.addToWaitingList')}</Form.Label>
                </Col>
                <Col xs={2} sm={4} md={2} lg={2} xl={3} className={`${style.checkbox__container} pst-absolute-160px`}>
                  <Form.Check
                    className="pl-0"
                    type="checkbox"
                    value={showWaitingList}
                    checked={showWaitingList}
                    onChange={() => setShowWaitingList(!showWaitingList)}
                  />
                  <OverlayTrigger
                      placement='top'
                      overlay={
                        <Tooltip id='tooltip-top'>
                          {isStaff? 
                          t("If an earlier appointment slot becomes available, notify this person may be selected to fill that slot"): 
                          t("If an earlier appointment becomes available, you will be notified")}
                          </Tooltip>
                      }
                    >
                      <div className={style.tooltip__element}>i</div>
                    </OverlayTrigger>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className={isStaff && showWaitingList ? ' ' : ' d-none '}>
                <Form.Label column sm={4} md={4} className="text-right">
                  {' '}
                  Waiting list
                </Form.Label>
                <Col sm={8} md={8}>
                  <Form.Control
                    as="select"
                    autoComplete="off"
                    name="waiting_list"
                    id="waiting_list"
                    value={values.waiting_list}
                    onChange={handleChange}
                    defaultValue="Emergency">
                    <option value="">Select waiting list</option>
                    {waitingList
                      ? waitingList.map((waiting) => {
                        return (
                          <option value={waiting.node.id} key={waiting.node.id}>
                            {waiting.node.name}
                          </option>
                        );
                      })
                      : null}
                  </Form.Control>
                </Col>
              </Form.Group>
              {isStaff ? <Form.Group as={Row} className="justify-content-start position-relative">
                <Col className={style.form__waiting_list + " text-right "} xs={8} sm={5} md={9} lg={9} xl={7}>
                  <Form.Label>Require Payment</Form.Label>
                </Col>
                <Col xs={4} sm={7} md={3} lg={3} xl={5} className={`${style.checkbox__container} pst-absolute-160px`}>
                  <Form.Check
                    className="pl-0"
                    type="checkbox"
                    name="require_payment"
                    onChange={handleChange}
                    value={values.require_payment}
                    checked={values.require_payment}
                  />
                </Col>
              </Form.Group> : null}
              {/* {isStaff? <Form.Group as={Row} className="justify-content-start position-relative">
                <Col className={style.form__waiting_list + " text-right "} xs={8} sm={5} md={9} lg={9} xl={7}>
                  <Form.Label>Apply Discount</Form.Label>
                </Col>
                <Col xs={4} sm={7} md={3} lg={3} xl={5} className={`${style.checkbox__container} pst-absolute-160px`}>
                  <Form.Check
                    className="pl-0"
                    type="checkbox"
                    value={showDiscount}
                    checked={showDiscount}
                    onChange={() => setShowDiscount(!showDiscount)}
                  />
                </Col>
              </Form.Group>:null}
              {showDiscount ? (
                <Form.Group as={Row} className="text-right">
                  <Form.Label column sm={4} md={4} className="text-right">
                    Discount %
                  </Form.Label>
                  <Col sm={8} md={8}>
                    <Form.Control
                      type="text"
                      name="discount_value"
                      autoComplete="discount_value"
                      onChange={handleDiscountChange}
                      value={values.discount_value ? values.discount_value : ''}
                    />
                  </Col>
                </Form.Group>
              ) : null} */}

              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} className="text-left">
                  {isStaff ? "Notes about the session" : t('createEventForm.reasonForAppointmentRequest')}
                </Form.Label>
                <Col sm={12} md={12}>
                  <Form.Control
                    autoComplete="off"
                    as="textarea"
                    rows={5}
                    maxLength={!isStaff ? '300' : null}
                    name="descriptionEn"
                    value={values.descriptionEn}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="justify-content-between d-flex">
                <Button
                  className={style.form__button}
                  variant="primary"
                  onClick={() => onCancelForm(handleReset)}
                  size="sm">
                  {t('createEventForm.cancel')}
                </Button>
                <Button className={style.form__button} variant="primary" type="submit" size="sm">
                  {isStaff ? " Save " : t('createEventForm.submit')}
                </Button>
              </Form.Group>
            </Container>
          </Form>
        )}
      </Formik>
      <SelectPatientModal
        showPatientSearch={showPatientSearch}
        setShowPatientSearch={setShowPatientSearch}
        onSelectPatientFunc={onSelectPatient}
      />
    </>
  );
};

export default withRouter(CreateEventForm);
