import React from 'react';
import Navigation from '../component/Navigation/Navigation';
// import { gql, useQuery } from '@apollo/client';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { withRouter,  useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft,faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { isUserStaff } from '../Common/helpers';
import Preloader from 'Common/Preloder/Preloader';
import { appLanguageVar } from "../cache/cache";
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from "react-i18next";

const Base = (props) => {
  const appLanguage = useReactiveVar(appLanguageVar);

  let history = useHistory();
  const isStaff = isUserStaff();
  const { t } = useTranslation();
  let nav_classes = " navigation_conainer d-print-none";
  let right_container_classes = "right_container d-print-none "
  if (props.isPatientPortal) {
    nav_classes += " patient_nav ";
    right_container_classes += " greyBg "
  }
  // console.log("langChangeLoader",langChangeLoader)
  // if(langChangeLoader){
  //   return <Preloader/>
  // }
  return (
    <Container fluid>
      <Row className={"h100vh"}>
        <Col md={3} lg={3} xl={3} className={nav_classes}>
          <Navigation isPatientPortal={props.isPatientPortal}
          // langChangeLoader={langChangeLoader} setLangChangeLoader={setLangChangeLoader}
          />
        </Col>
        <Col md={9} lg={9} xl={9} className={right_container_classes + props.rightContainerClass}>
          {props.showHeader && !props.page_loading ?
            <Container className="no-col-padding" fluid>
              {!props.isDashboard ? 
                <Row>
                  <Col>
                    <div className="backBtn"></div>
                    <Button variant='link' className={`d-print-none px-0 mt-4 mb-1 ${!isStaff ? 'back-button-color' : ''}`} onClick={() => history.goBack()}>
                      <FontAwesomeIcon icon={appLanguage==="ar"?faAngleRight :faAngleLeft} className="icon_large mx-2" />
                      <div className="backBtn">
                        {t('base.back')}
                        </div>
                    </Button>
                  </Col>
                </Row>
              : null}
              <Row className="page_header header-container-with-create-link d-print-none">
                <Col sm={{ span: 7, order: 'first' }} xs={{ span: 12, order: 'last' }} md={{ span: 7, order: 'first' }} className="px-0">
                  <h4 className="base_title section-header" id="page_title">{props.page_loading ? '' : props.title}</h4>
                </Col>
                <Col sm={{ span: 5, order: 'last' }} xs={{ span: 12, order: 'first' }} md={{ span: 5, order: 'last' }} className="d-flex justify-content-end right-child-buttons">
                  {props.rightChild && !props.page_loading ? props.rightChild : null}
                </Col>
              </Row>
            </Container>
            : null}
          <Container fluid className={props.containerClass ? props.containerClass : "mt-4 "}>
            {!props.page_loading? props.children:<Preloader /> }
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Base);