import Base from './base.js';
import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Form, Table, Button, DropdownButton, Dropdown, Card } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { SNACK_DURATION, ERROR_MESSAGE, error_options, getDate, success_options } from '../Common/helpers';
import { Formik } from 'formik';
import { useSnackbar } from 'react-simple-snackbar';
import { Link } from 'react-router-dom';
import CustomModals from '../component/Modal/modal';
import Preloader from '../Common/Preloder/Preloader';


function UserLeaves(props) {
  const formikRef = useRef();
  // const [startDate, setStartDate] = useState('');
  const [openSnackbar] = useSnackbar(error_options);
  const [addLeaveRequestModal, setAddLeaveRequestModal] = useState(false);
  // const [leaveStart, setLeaveStart] = useState(null);
  const [editLeaveObject, setEditLeaveObject] = useState(null);
  // const [leaveEnd, setLeaveEnd] = useState(null);
  const [openSnackbarSuccess] = useSnackbar(success_options);

  const { history } = props;


  const REQUEST_LEAVES = gql`  
    query  {
      leaveRequests{
        edges {
          node {
              id
              startDate 
              endDate
              totalDays
              status
              leaveType
              created
              description
              user {
                firstName
                lastName
                phone
                id
                hr{
                  position{
                    name
                  }
                  department{
                    name
                  }
                  numberAnnualLeaves
                  consumedSickLeaves
                  remainingAnnualLeaves
                }
              }
          }
        }
      }
    }
  `;

  const CANCEL_LEAVE_REQUEST = gql`
  mutation cancelLeaveRequest(
    $id:ID!
  ) {
    cancelLeaveRequest(
      id:$id
    ) {
      obj{
        created
        id
        startDate
        endDate
        leaveType
        status
      }
    }
  }
  `;

  const CREATE_LEAVE_REQUEST = gql`
  mutation createUpdateLeaveRequest(
    $id:ID
    $startDate:Date!
    $endDate:Date!
    $leaveType:String!
    $description:String!
  ){
    createUpdateLeaveRequest(input:{
      id:$id
      startDate: $startDate
      endDate: $endDate
      leaveType:$leaveType
      description: $description      
    }){
      obj{
        created
        id
        startDate
        endDate
        leaveType       
      }
       errors {
        field
        messages
      }
    }
    
  }
  `;

  const [cancelLeaveRequest] = useMutation(CANCEL_LEAVE_REQUEST, {
    onCompleted: ({ cancelLeaveRequest }) => {
      console.log("cancelLeaveRequest", cancelLeaveRequest);
      if (cancelLeaveRequest.obj) {
        let status = cancelLeaveRequest.obj.status;
        openSnackbarSuccess('Leave Request ' + status, [SNACK_DURATION]);
      } else {
        let alert_msg = '';
        cancelLeaveRequest.errors.map((error) => {
          alert_msg += error.messages.join(' ') + ' ';
          openSnackbar(alert_msg, [50000]);
          return null;
        });
      }
    },
    refetchQueries: [
      { query: REQUEST_LEAVES, fetchPolicy: 'network-only' }
    ],
    onError: (e) => {
      console.log("eeeeee", e)
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const [createUpdateLeaveRequest] = useMutation(CREATE_LEAVE_REQUEST, {
    onCompleted: ({ createUpdateLeaveRequest }) => {
      console.log("createLeaveRequest", createUpdateLeaveRequest);
      if (createUpdateLeaveRequest.obj) {
        openSnackbarSuccess('Leave Request Created', [SNACK_DURATION]);
        setAddLeaveRequestModal(false);
      } else {
        let alert_msg = '';
        createUpdateLeaveRequest.errors.map((error) => {
          alert_msg += error.messages.join(' ') + ' ';
          openSnackbar(alert_msg, [50000]);
          return null;
        });
      }
    },
    refetchQueries: [
      { query: REQUEST_LEAVES, fetchPolicy: 'network-only' }
    ],
    onError: (e) => {
      console.log("eeeeee", e)
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION])
    },
  });

  const [getLeavesFilter, { data: leavesObjFilter = null, loading }] = useLazyQuery(REQUEST_LEAVES);

  useEffect(() => {
    getLeavesFilter();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const REQUEST_SETTINGS = gql`
      query {
        settings
      }
  `;

  // GET leave detail
  const { data: settings_obj = null } = useQuery(
    REQUEST_SETTINGS,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );


  const sickLeaveQuota = leavesObjFilter && leavesObjFilter.leaveRequests && leavesObjFilter.leaveRequests.edges && leavesObjFilter.leaveRequests.edges.length > 0 ? Math.ceil((leavesObjFilter.leaveRequests.edges[0].node.user.hr.consumedSickLeaves) / 6) : 0;


  const cancelLeaveRequestFunc = (leaveObj) => {
    if (window.confirm("Delete Leave Request?",)) {
      let val = {};
      val["id"] = leaveObj.id;
      cancelLeaveRequest({ variables: val });
    }
  }


  // WORKING
  const LeaveTypes = {
    leave: 'Annual',
    unpaid: 'Unpaid',
    sick: 'Sick',
    haj: 'Haj',
    maternity: 'Maternity',
    conference: 'Conference',
  };



  const onSubmit = (values, { resetForm }) => {
    let val = {
      startDate: values.startDate.getFullYear() + "-" + String((values.startDate.getMonth() + 1)).padStart(2, '0') + "-" + String(values.startDate.getDate()).padStart(2, '0'),
      endDate: values.endDate.getFullYear() + "-" + String((values.endDate.getMonth() + 1)).padStart(2, '0') + "-" + String(values.endDate.getDate()).padStart(2, '0'),
      description: values.description,
      leaveType: values.leaveType,
    };

    if (editLeaveObject && editLeaveObject.id) {
      val["id"] = editLeaveObject.id;
    }
    console.log("valvalval", val);
    createUpdateLeaveRequest({ variables: val });
  };

  const setActionDate = (info, elemRef, fldName) => {
    elemRef.current.setFieldValue(fldName, info);
  }

  const editLeaveRequestFunc = (obj) => {
    setEditLeaveObject(obj);
    setAddLeaveRequestModal(true);
  }

  const initialValues = {
    startDate: "",
    endDate: "",
    description: "",
    leaveType: "",
  };

  if (editLeaveObject) {
    initialValues["startDate"] = new Date(editLeaveObject.startDate);
    initialValues["endDate"] = new Date(editLeaveObject.endDate);
    initialValues["leaveType"] = editLeaveObject.leaveType.toLowerCase();
    initialValues["description"] = editLeaveObject.description;
  }
  if (loading) {
    return (
      <Preloader />
    )
  }

  console.log("settings_obj", settings_obj)
  return (
    <Base title={'Annual and Sick Leaves'} showHeader={true} page_loading={loading}>

      <Row>
        {leavesObjFilter && leavesObjFilter.leaveRequests && leavesObjFilter.leaveRequests.edges.length > 0 ?
          <>
            <Col md={3} >
              <Card >
                <Card.Body className="d-flex align-items-center justify-content-center">
                  <div>
                    <h6 className="text-center text-uppercase"> ANNUAL LEAVE </h6>
                    <h6 className="textPrimaryBold text-center">{leavesObjFilter?.leaveRequests?.edges[0]?.node?.user?.hr?.remainingAnnualLeaves}</h6>
                    <h6 className="text-center"> Days Remaining </h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} >
              {settings_obj && settings_obj.settings ? <Card>
                <Card.Body>
                  <h6 className="text-center text-uppercase"> SICK LEAVE </h6>
                  <h6 className="text-center "> Consumed:<span className="textPrimaryBold "> {leavesObjFilter?.leaveRequests?.edges[0]?.node?.user?.hr?.consumedSickLeaves} </span></h6>
                  <h6 className={"text-center " + (sickLeaveQuota === 1 || sickLeaveQuota === 0 ? " border_primary " : " ")}>Full Pay: <span className="textPrimaryBold ">{settings_obj.settings.sick_full_pay} </span></h6>
                  <h6 className={"text-center" + (sickLeaveQuota === 2 ? " border_primary " : " ")}>3/4 Pay: <span className="textPrimaryBold ">{settings_obj.settings.sick_three_quaters_pay} </span></h6>
                  <h6 className={"text-center" + (sickLeaveQuota === 3 ? " border_primary " : " ")}>1/2 Pay: <span className="textPrimaryBold ">{settings_obj.settings.sick_half_pay} </span></h6>
                  <h6 className={"text-center" + (sickLeaveQuota === 4 ? " border_primary " : " ")}>1/4 Pay: <span className="textPrimaryBold ">{settings_obj.settings.sick_one_fourth_pay} </span></h6>
                  <h6 className={"text-center" + (sickLeaveQuota === 5 ? " border_primary " : " ")}>No Pay: <span className="textPrimaryBold ">{settings_obj.settings.sick_no_pay} </span></h6>
                </Card.Body>
              </Card> : null}
            </Col></> : null}
        <Col md={3} className="ml-auto ">
          <div className="d-flex justify-content-end">
            <Button variant="primary" size="md" onClick={() => setAddLeaveRequestModal(true)}>
              Request Leave
          </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <h6 className="py-3 textGrey"><b> REQUESTS </b></h6>
        <Table responsive className=" table-borderless">
          <thead>
            <tr className="patient_table_row tableHeader">
              <th scope="col " className="text-uppercase">
                DATE OF REQUEST
              </th>
              <th scope="col " className="text-uppercase">
                LEAVE TYPE
              </th>
              <th scope="col " className="text-uppercase">
                START DATE
              </th>
              <th scope="col " className="text-uppercase">
                END DATE
              </th>
              <th scope="col " className="text-uppercase text-center">
                STATUS
              </th>
              <th scope="col " className="text-uppercase text-center">
              </th>
            </tr>
          </thead>
          <tbody>
            {leavesObjFilter && leavesObjFilter.leaveRequests && leavesObjFilter.leaveRequests.edges.length > 0 ?
              leavesObjFilter.leaveRequests.edges.map((leave, index) => {
                let leaveObj = leave.node;
                return (<tr className="patient_table_row" key={index}>
                  <td><Link to={"/leave/request/" + leaveObj.id}> {getDate(new Date(leaveObj.created))} </Link> </td>
                  <td>{leaveObj.leaveType}</td>
                  <td>{getDate(new Date(leaveObj.startDate))}</td>
                  <td>{getDate(new Date(leaveObj.endDate))}</td>
                  <td className="text-center">{leaveObj.status}</td>
                  <td className="justify-content-center">
                    <DropdownButton id="dropdown-basic-button" variant="link" title="Manage">
                      <Dropdown.Item >
                        <Link to={"/leave/request/" + leaveObj.id}>  View </Link>
                      </Dropdown.Item>
                      <Dropdown.Item >
                        <Button variant="link" className="px-0" onClick={() => editLeaveRequestFunc(leaveObj)} > Edit </Button>
                      </Dropdown.Item>
                      <Dropdown.Item >
                        <Button variant="link" className="px-0" onClick={() => cancelLeaveRequestFunc(leaveObj)} > Cancel </Button>
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>)
              })
              : <tr><td> No Leave requests made </td> </tr>}
          </tbody>
        </Table>
      </Row>
      <CustomModals showModal={addLeaveRequestModal} modalHeader={'ADD LEAVE REQUEST'} setShowModal={setAddLeaveRequestModal}
        dialogClassName="modal60h">
        <Formik onSubmit={onSubmit} initialValues={initialValues} innerRef={formikRef}>
          {({ handleSubmit, handleChange, values }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Row className="mb-5 mt-5">
                <Col xs={12} sm={12} md={12}>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column sm={3} md={3}>
                      Start  Date
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <DatePicker
                        selected={values.startDate ? values.startDate : ''}
                        onChange={(info) => setActionDate(info, formikRef, "startDate")}
                        name="startDate"
                        className="form-control"
                        autocomplete="off"
                        dateFormat="dd/MMM/yyyy"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="text-right">
                    <Form.Label column sm={3} md={3}>
                      End  Date
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <DatePicker
                        selected={values.endDate ? values.endDate : ''}
                        onChange={(info) => setActionDate(info, formikRef, "endDate")}
                        name="endDate"
                        className="form-control"
                        autocomplete="off"
                        dateFormat="dd/MMM/yyyy"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={3} className="text-right pr-0">
                      <span className=""> Leave Types</span>
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="select"
                        name="leaveType"
                        value={values.leaveType}
                        onChange={handleChange}
                        required>
                        <option value="">Select Leave Type</option>
                        {Object.entries(LeaveTypes).map(([key, value], index) => {
                          return (
                            <option value={key} key={index}>
                              {value}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={3} className="text-right">
                      {"Description"}
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="textarea"
                        rows={5}
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col xs={12} sm={12} md={12}
                  className="d-flex flex-wrap justify-content-center justify-content-sm-between">
                  <Button variant="primary" size="md" className='mb-1' active onClick={() => history.goBack()}>
                    CANCEL
                </Button>
                  <Button variant="primary" size="md" className='ml-2 mr-2 mb-1' type="submit">
                    Add
                </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </CustomModals>
    </Base >
  );
}
export default UserLeaves;
