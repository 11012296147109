import React, { useState, useRef, useEffect } from 'react';
import style from './SearchForPatient.module.css';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { dataPatientInVar } from '../../cache/cache.js';
import {
  getPermissionForAction,
  displayMiddleName
} from '../../Common/helpers';


const SearchForPatient = (props) => {
  const { getPatients, data, setShowPatientSearch, isModal, onSelectPatientFunc } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = React.useState([]);
  const [selectedPatient, setSelectedPatient] = useState([]);
  let returnAllSectionPermissions = true;
  const patient_permission = getPermissionForAction('patient', '', returnAllSectionPermissions);
  const onSelectPatient = (patientData) => {
    setSelectedPatient(patientData);
  };

  const selectAndCloseModal = () => {
    if (selectedPatient) {
      onSelectPatientFunc(selectedPatient);
    }
    setShowPatientSearch(false);
  }
  const onClickToCreateAppointment = () => {
    if (setShowPatientSearch) {
      setShowPatientSearch(false);
    }
    dataPatientInVar(selectedPatient);
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (searchTerm.length > 1) {
      getPatients({
        variables: { search: `${searchTerm}` },
      });
    }
    if (data) {
      const patient = data.patients.edges.map((patient) => {
        return patient.node;
      });
      setResults(patient);
    }
    if (searchTerm.length < 1) {
      setResults([]);
      setSelectedPatient([]);
    }
  }, [searchTerm, getPatients, data]);

  const patientRef = useRef(null);
  useEffect(() => {
    patientRef.current.scrollTo(0, patientRef.current.scrollHeight);
  }, [results]);

  return (
    <Col md={12} className={style.searchForPatient_container + " searchForPatient-mobile "}>
      <Row className="mt-5">
        <Col>
          <Form.Group as={Row} className="text-right">
            <Form.Label column md={2} sm={3}>
              Search
            </Form.Label>
            <Col sm={9} md={4} xl={3}>
              <Form.Control
                autoComplete="off"
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={8}>
          <div className={'patient__block'}>
            <ul ref={patientRef}>
              {results.map((item) => (
                <li
                  onClick={() => onSelectPatient(item)}
                  key={item.id}
                  className={selectedPatient.id === item.id ? 'active' : ''}>
                  {item.firstName} {displayMiddleName(item.middleName)}{item.lastName} - Civil ID: {item.civilId} - Mobile:{' '}
                  {item.phone}
                </li>
              ))}
            </ul>
            {isModal ? null : <div className="d-flex justify-content-center align-items-center">
             {patient_permission && patient_permission.includes("add")? <Link to="/create/patient">
                <Button className={style.patient__block_button} variant="primary" size="lg" active>
                  Create new Patient Record
                </Button>
              </Link>:null}
            </div>}
          </div>
        </Col>
      </Row>
      {onSelectPatientFunc ?
        <Row className="justify-content-center mt-4">
          <Col className="d-flex justify-content-sm-end justify-content-center " md={4}>
            <Button
              onClick={selectAndCloseModal}
              className={style.patient__block_button}
              disabled={!selectedPatient.id}
              variant="secondary"
              size="lg"
              block
              active>
              Select Patient
          </Button>
          </Col>
        </Row>
        : <Row className="justify-content-center mt-4">
          <Col className="d-flex  justify-content-sm-end justify-content-center" md={8}>
            <Link to="/create/appointment">
              <Button
                onClick={onClickToCreateAppointment}
                className={style.patient__block_button}
                disabled={!selectedPatient.id}
                variant="secondary"
                size="lg"
                block
                active>
                Select Patient to Create Appointment
            </Button>
            </Link>
          </Col>
        </Row>}
    </Col>
  );
};

export default SearchForPatient;
