import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import Base from './base.js';
import { withRouter } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { dataReferralObject } from '../cache/cache.js';
import { useSnackbar } from 'react-simple-snackbar';
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  isUserStaff,
  getDate,
  getForamttedTime,
  displayMiddleName
  // getPermissionForAction,
} from '../Common/helpers';
import Preloader from '../Common/Preloder/Preloader';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import { useTranslation } from "react-i18next";


function PatientReferrals(props) {
  const isStaff = isUserStaff();
  const identifier =isStaff? props.match.params.identifier:'';
  const { t } = useTranslation();
  const isClickedFromStaff = props.location.state;

  const SELECTED_PATIENT_DATA = gql`
    query receivePatientData {
      dataPatient @client
    }
  `;

  const { data: patientData = [] } = useQuery(SELECTED_PATIENT_DATA);

  const REQUEST_PATIENT_REFERRALS = gql`
  query {
    referrals(patient_Identifier:"${identifier}"){
      edges {
        node {
          title
          id
          followUp
          recommendedDate
          dynamicForms{
            edges{
              node{
                name
                id
                formType
              }
            }
          }
          encounter{
            date
          }
          description
          referredDoctor{
            firstName
            lastName
            id
            identifier
            eventType {
              edges {
                node {
                  id
                  title{
                    name
                  }
                  duration
                  buffer
                  price
                  eventType {
                    id
                    name
                    color{
                      hexCode
                      name
                      colorId
                    }
                  }
                  eventLocation {
                    id
                    title
                  }
                }
              }
            }
            recurringEvents{
              edges {
                node {
                  startDate
                  startTime
                  endTime
                  title
                  description
                  everyday
                  days
                  dateUntil
                }
              }
            }
            availability {
              edges {
                node {
                  id
                  day
                  shifts{
                    edges {
                      node {
                        startTime
                        endTime
                        id
                      }
                    }
                  }
                }
              }
            }
          }
          patient {
            firstName
            middleName
            lastName
            id
            identifier
          }
          referredDoctorSession{
            id
            title{
              name
            }
            duration
            buffer
            price
            eventType {
              id
              name
              color{
                hexCode
                name
                colorId
              }
            }
            eventLocation {
              id
              title
            }
            availability{
              edges {
                node{
                  day
                  shifts{
                    edges{
                       node {
                        startTime
                        endTime
                      }
                    }
                  }
                }
              }                
            }
          }
          createdAppointment{
            start
          }
        }
      }
    }
  }
`;

  const DELETE_REFERRAL = gql`  
mutation deleteReferral($id:ID!){
  deleteReferral(id:$id){
    deleted
  }
} 
`;

  const [openSnackbar] = useSnackbar(error_options);

  // // GET REQUEST_PATIENT_REFERRALS DATA
  const { data: referralData = null, loading: loading_referrals = false } = useQuery(
    REQUEST_PATIENT_REFERRALS,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        // setSkipUpcomingQuery(true);
        openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
      },
    },
  );


  var patient_record = null;
  if (patientData && patientData.dataPatient) {
    let p_data = {
      node: patientData.dataPatient
    }
    patient_record = p_data;
  }


  const createAppFromRef = (referral) => {
    let patient = {
      id: referral && referral.patient? referral.patient.id : null,
      firstName: referral && referral.patient?  referral.patient.firstName : null,
      middleName: referral && referral.patient?  displayMiddleName(referral.patient.middleName) : null,
      lastName: referral && referral.patient?  referral.patient.lastName : null,
      identifier: referral && referral.patient?  referral.patient.identifier : null
    }
    let ref_obj = {
      patient: patient,
      referral: referral
    }
    dataReferralObject(ref_obj);
    if (isStaff) {
      props.history.push("/create/appointment");
      // props.history.push({
      //   pathname: '/create/appointment',
      // });
    } else {
      props.history.push("/patientPortal/create/appointment");
    }
  }

  // DELETE_REFERRAL
  const [deleteReferral] = useMutation(DELETE_REFERRAL, {
    onCompleted: ({ deleteReferral }) => {
      console.log("deleteReferral", deleteReferral);
    },
    refetchQueries: [
      { query: REQUEST_PATIENT_REFERRALS, fetchPolicy: 'network-only' }
    ],
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });
  const deleteRefFunc = (id) => {
    if (window.confirm("Delete Referral?",)) {
      let val = {};
      val["id"] = id;
      console.log("VALL", val)
      deleteReferral({ variables: val });
    }
  }

  const renderReferrals = (referrals_list) => {
    return referrals_list.map((item, index) => {
      return (
        isClickedFromStaff ? 
          <Col xs={12} sm={6} md={6} lg={4} className="mb-4" key={index}>
          <Card>
            <Card.Body className="p-4">
              <div className="mb-2 text-uppercase"> {item.node.title ? item.node.title : null}</div>
              {item.node.recommendedDate ? 
                <div className='mt-1'> {t('patientReferrals.shouldBeSeen')}: {getDate(new Date(item.node.recommendedDate))}</div>
              : null}
              {item.node.followUp && item.node.encounter.date ?  <h6 className="textPrimary"> {t('patientReferrals.followUpFor')} : {getDate(new Date(item.node.encounter.date))} </h6>:null}
              <div className="mt-1 text-capitalize"> {t('patientReferrals.doctor')} {item.node.referredDoctor.firstName} {item.node.referredDoctor.lastName} </div>
              <div className="mt-1 text-capitalize"> {t('patientReferrals.duration')}: {item.node.referredDoctorSession.duration} {t('patientReferrals.min')} </div>
              <div className="mt-1 text-capitalize"> {t('patientReferrals.type')}: {item.node.referredDoctorSession.eventType.name} </div>
              {item.node.referredDoctorSession.eventLocation.title ? (
                <div className="mt-1 text-capitalize"> {t('patientReferrals.location')}: {item.node.referredDoctorSession.eventLocation.title} </div>
              ) : null}
              <div className='mt-1'> {t('patientReferrals.price')}:  {item.node.referredDoctorSession.price} {t('patientReferrals.kwd')} </div>
              {item.node.description?<div className='mt-1'>
                <div>{t('patientReferrals.description')}:</div>
                <EllipsisWithTooltip placement="top">
                  {item.node.description}
                </EllipsisWithTooltip>
              </div>:null}
              <Button variant="primary" block className="back mt-4" onClick={() => createAppFromRef(item.node,referrals_list)} >
              {t('patientReferrals.createAppointment')}
              </Button>
              {isStaff ? 
                <Button variant="danger" block className="back mt-2" onClick={() => deleteRefFunc(item.node.id)} >
                  {t('patientReferrals.deleteReferrals')} 
                </Button>
              : null}
            </Card.Body>
          </Card>
        </Col>
         : !item.node.recommendedDate ? 
         <Col xs={12} sm={6} md={6} lg={4} className="mb-4" key={index}>
         <Card>
           <Card.Body className="p-4">
             <div className="mb-2 text-uppercase"> {item.node.title ? item.node.title : null}</div>
             {item.node.recommendedDate ? 
               <div className='mt-1'> {t('patientReferrals.shouldBeSeen')}: {getDate(new Date(item.node.recommendedDate))}</div>
             : null}
             {item.node.followUp && item.node.encounter.date ?  <h6 className="textPrimary"> {t('patientReferrals.followUpFor')} : {getDate(new Date(item.node.encounter.date))} </h6>:null}
             <div className="mt-1 text-capitalize"> {t('patientReferrals.doctor')}: {item.node.referredDoctor.firstName} {item.node.referredDoctor.lastName} </div>
             <div className="mt-1 text-capitalize"> {t('patientReferrals.duration')}: {item.node.referredDoctorSession.duration} {t('patientReferrals.min')} </div>
             <div className="mt-1 text-capitalize"> {t('patientReferrals.type')}: {item.node.referredDoctorSession.eventType.name} </div>
             {item.node.referredDoctorSession.eventLocation.title ? (
               <div className="mt-1 text-capitalize"> {t('patientReferrals.location')}: {item.node.referredDoctorSession.eventLocation.title} </div>
             ) : null}
             <div className='mt-1'> {t('patientReferrals.price')}:  {item.node.referredDoctorSession.price} {t('patientReferrals.kwd')} </div>
             <div className='mt-1'>
               <div>{t('patientReferrals.description')}:</div>
               <EllipsisWithTooltip placement="top">
                 {item.node.description}
               </EllipsisWithTooltip>
             </div>
             <Button variant="primary" block className="back mt-4" onClick={() => createAppFromRef(item.node,referrals_list)} >
             {t('patientReferrals.createAppointment')}
             </Button>
             {isStaff ? 
               <Button variant="danger" block className="back mt-2" onClick={() => deleteRefFunc(item.node.id)} >
                 {t('patientReferrals.deleteReferrals')} 
               </Button>
             : null}
           </Card.Body>
         </Card>
       </Col>
          : null
      )
      
      // isClickedFromStaff ? {
      // return (
        
      // )
    })
  };

  return (
    <Base
      isPatientPortal={!isStaff}
      page_loading={loading_referrals}
      title={patient_record ? t('patientReferrals.yourReferrals') : t('patientReferrals.yourReferrals')}
      showHeader={true}>
      {loading_referrals ? <Preloader />:<Row className="mt-5">
        {referralData && referralData.referrals && referralData.referrals.edges.length > 0 ? (
          renderReferrals(referralData.referrals.edges)
        ) : (
          t('patientReferrals.noReferrals')
        )}
      </Row>}
    </Base>
  );
}
export default withRouter(PatientReferrals);
