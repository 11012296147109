import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import Calendar from './Calendar';
import { error_options, SNACK_DURATION, ERROR_MESSAGE } from '../../../Common/helpers';
import { useSnackbar } from 'react-simple-snackbar';
// import Preloader from '../../../Common/Preloder/Preloader';


const SELECTED_DATE_EVENT = gql`
  query receiveDate {
    dateEvent @client
  }
`;

const CALENDAR_SLOTS = gql`
  query receiveDate {
    calendarSlots @client
  }
`;


const REFERRAL_OBJECT = gql`
query receiveReferralData {
  referralObj @client
}
`;
export default function CalendarHoc(props) {
  const {
    selectedDoctor,
    doctorLeavesList,
    fullCalendarRef,
    myAppointments,
    selectedSession,
    GetEvents,
    // eventDisplay,
    error,
  } = props;
  const [openSnackbar] = useSnackbar(error_options);
  // const isStaff = isUserStaff();
  const { data: calendarData = null } = useQuery(CALENDAR_SLOTS);
  const { data: selectedDateEvent = [] } = useQuery(SELECTED_DATE_EVENT, {
    onError: () => {
      openSnackbar(ERROR_MESSAGE, [SNACK_DURATION]);
    },
  });
  const { data: referralObjectData = [] } = useQuery(REFERRAL_OBJECT);
  let doctor_identifier = selectedDoctor ? selectedDoctor.node.identifier : null;

  useEffect(() => {
    let calendarView = fullCalendarRef.current.getApi().view;
    let calendarEnd = calendarView.activeEnd;
    let dateStart = new Date(calendarView.activeStart);;
    let current_month = dateStart.getMonth();
    let current_year = dateStart.getFullYear();
    if(dateStart.getDate()>25){
      current_month+=1;
    }
    let dateEnd = new Date(current_year, current_month + 1, 1);
    if (calendarEnd > dateEnd) {
      dateEnd = calendarEnd;
    }
    let today = new Date();
    if (!myAppointments && dateStart < today) {
      dateStart = today;
    }
    if (doctor_identifier && selectedSession) {
      // dateEnd.setHours(23);
      GetEvents({
        variables: {
          doctor_identifier: doctor_identifier,
          start: dateStart,
          end: dateEnd,
          loadView: calendarView.type,
        },
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctor_identifier, selectedSession]);

  if (error) return `Error! ${error.message}`;
  // if (loading) return <Preloader />;

  return (
    <Calendar
      error={error}
      referralObjectData={referralObjectData}
      myAppointments={myAppointments}
      selectedDoctor={selectedDoctor}
      selectedDateEvent={selectedDateEvent}
      doctorLeavesList={doctorLeavesList}
      fullCalendarRef={fullCalendarRef}
      selectedSession={selectedSession}
      calendarData={calendarData}
      GetEvents={GetEvents}

    />
  );
}
